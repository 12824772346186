import React, { useState } from 'react';
import { Pagination } from 'antd';
import './ClientsPages.less';
import clientsData from '../../Data/SampleClients';
import ClientInfoColumn from './ClientsTableColumns/ClientInfoColumn';
import ContactInfoColumn from './ClientsTableColumns/ContactInfoColumn';
import HoursAndCurrencyColumn from './ClientsTableColumns/HoursAndCurrencyColumn';
import ClientStatusColumn from './ClientsTableColumns/ClientStatusColumn';
import AccountManagerColumn from './ClientsTableColumns/AccountManagerColumn';
import NotesColumn from './ClientsTableColumns/NotesColumn';
import { useClientsTableLogic } from './ClientsTableColumns/ClientsTableLogic';
import ClientsTableSort from './ClientsTableColumns/ClientsTableSort';
import RowOptions from './ClientsTableColumns/RowOptions'; // Import the new RowOptions

const TableRow = ({ row, columns }) => (
  <tr className="ct-table-row">
    {columns.map((column, colIndex) => (
      <td key={column.accessor} className="ct-cell">
        {column.render ? column.render(null, row) : row[column.accessor]}
      </td>
    ))}
    <td className="row-options-cell">
      <RowOptions />
    </td>
  </tr>
);

const Clients = () => {
  const { isMobile } = useClientsTableLogic();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Manage rows per page

  // Get current page rows
  const currentRows = clientsData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const columns = [
    { Header: 'Client', accessor: 'clientInfo', render: ClientInfoColumn.render },
    { Header: 'Info', accessor: 'contactInfo', render: ContactInfoColumn.render },
    { Header: 'Hours', accessor: 'hoursAndCurrency', render: HoursAndCurrencyColumn.render },
    { Header: 'Status', accessor: 'clientStatus', render: ClientStatusColumn.render },
    { Header: 'Manager', accessor: 'accountManager', render: AccountManagerColumn.render },
    { Header: 'Notes', accessor: 'notes', render: NotesColumn.render },
  ];

  return (
    <div className="ct-clients-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-building"></i>
        </span>
        All Clients
      </p>
      <ClientsTableSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> {/* Pass props here */}
      <div className="ct-table-container">
      <div className="main-table-main-container">
        <div className="main-table-container">
          <table className="main-table">
            <thead className="main-table-head">
              <tr className="main-table-head-row">
                {columns.map((column) => (
                  <th key={column.accessor} className="main-table-head-cell">
                    {column.Header}
                  </th>
                ))}
                <th className="row-options-header-cell"></th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row) => (
                <TableRow key={row.clientEmail} row={row} columns={columns} />
              ))}
            </tbody>
          </table>
        </div>

      </div>
      <div className="main-pagination-container">
      <Pagination
        current={currentPage}
        pageSize={rowsPerPage}
        total={clientsData.length}
        onChange={setCurrentPage}
        className="main-pagination"
      />
      </div>
    </div>
    </div>
  );
};

export default Clients;
