// SampleDashboardData.js

import { fillMissingDates, today, startDate90DaysAgo, startDate60DaysAgo, startDate30DaysAgo, startDate7DaysAgo, startDate180DaysAgo, startDate365DaysAgo } from '../Utils/DashboardChartDates';

const dataLast90Days = [
  { d: '2024-01-01', t: 28, aC: 12, hW: 58, hB: 50 },
  { d: '2024-01-02', t: 24, aC: 12, hW: 62, hB: 48 },
  { d: '2024-01-03', t: 38, aC: 12, hW: 60, hB: 60 },
  { d: '2024-01-04', t: 16, aC: 12, hW: 48, hB: 48 },
  { d: '2024-01-05', t: 20, aC: 12, hW: 42, hB: 42 },
  { d: '2024-01-06', t: 0, aC: 12, hW: 0, hB: 0 },
  { d: '2024-01-07', t: 0, aC: 13, hW: 0, hB: 0 },
  { d: '2024-01-08', t: 28, aC: 13, hW: 68, hB: 64 },
  { d: '2024-01-09', t: 32, aC: 14, hW: 64, hB: 58 },
  { d: '2024-01-10', t: 36, aC: 14, hW: 72, hB: 70 },
  { d: '2024-01-11', t: 39, aC: 14, hW: 62, hB: 64 },
  { d: '2024-01-12', t: 19, aC: 14, hW: 58, hB: 60 },
  { d: '2024-01-13', t: 3, aC: 14, hW: 8, hB: 10 },
  { d: '2024-01-14', t: 0, aC: 14, hW: 0, hB: 0 },
  { d: '2024-01-15', t: 46, aC: 14, hW: 68, hB: 70 },
  { d: '2024-01-16', t: 41, aC: 14, hW: 62, hB: 70 },
  { d: '2024-01-17', t: 38, aC: 14, hW: 65, hB: 70 },
  { d: '2024-01-18', t: 51, aC: 14, hW: 69, hB: 70 },
  { d: '2024-01-19', t: 39, aC: 13, hW: 72, hB: 76 },
  { d: '2024-01-20', t: 0, aC: 13, hW: 0, hB: 0 },
  { d: '2024-01-21', t: 0, aC: 13, hW: 0, hB: 0 },
  { d: '2024-01-22', t: 49, aC: 13, hW: 82, hB: 84 },
  { d: '2024-01-23', t: 41, aC: 14, hW: 84, hB: 84 },
  { d: '2024-01-24', t: 48, aC: 14, hW: 84, hB: 84 },
  { d: '2024-01-25', t: 47, aC: 14, hW: 90, hB: 100 },
  { d: '2024-01-26', t: 52, aC: 15, hW: 94, hB: 115 },
  { d: '2024-01-27', t: 0, aC: 15, hW: 0, hB: 0 },
  { d: '2024-01-28', t: 2, aC: 15, hW: 6, hB: 8 },
  { d: '2024-01-29', t: 42, aC: 15, hW: 80, hB: 80 },
  { d: '2024-01-30', t: 39, aC: 15, hW: 84, hB: 84 },
  { d: '2024-01-31', t: 37, aC: 15, hW: 92, hB: 100 },
  { d: '2024-02-01', t: 41, aC: 16, hW: 90, hB: 110 },
  { d: '2024-02-02', t: 46, aC: 16, hW: 88, hB: 98 },
  { d: '2024-02-03', t: 1, aC: 16, hW: 3, hB: 6 },
  { d: '2024-02-04', t: 0, aC: 16, hW: 0, hB: 0 },
  { d: '2024-02-05', t: 37, aC: 17, hW: 81, hB: 80 },
  { d: '2024-02-06', t: 39, aC: 17, hW: 87, hB: 90 },
  { d: '2024-02-07', t: 48, aC: 17, hW: 84, hB: 90 },
  { d: '2024-02-08', t: 42, aC: 17, hW: 85, hB: 90 },
  { d: '2024-02-09', t: 39, aC: 17, hW: 92, hB: 100 },
  { d: '2024-02-10', t: 1, aC: 17, hW: 4, hB: 8 },
  { d: '2024-02-11', t: 0, aC: 17, hW: 0, hB: 0 },
  { d: '2024-02-12', t: 39, aC: 16, hW: 92, hB: 108 },
  { d: '2024-02-13', t: 38, aC: 16, hW: 90, hB: 105 },
  { d: '2024-02-14', t: 49, aC: 16, hW: 88, hB: 96 },
  { d: '2024-02-15', t: 51, aC: 16, hW: 87, hB: 95 },
  { d: '2024-02-16', t: 31, aC: 16, hW: 85, hB: 100 },
  { d: '2024-02-17', t: 0, aC: 16, hW: 0, hB: 0 },
  { d: '2024-02-18', t: 0, aC: 16, hW: 0, hB: 0 },
  { d: '2024-02-19', t: 48, aC: 16, hW: 92, hB: 100 },
  { d: '2024-02-20', t: 51, aC: 16, hW: 96, hB: 106 },
  { d: '2024-02-21', t: 54, aC: 16, hW: 88, hB: 95 },
  { d: '2024-02-22', t: 47, aC: 18, hW: 89, hB: 96 },
  { d: '2024-02-23', t: 39, aC: 18, hW: 78, hB: 86 },
  { d: '2024-02-24', t: 0, aC: 18, hW: 0, hB: 0 },
  { d: '2024-02-25', t: 0, aC: 18, hW: 0, hB: 0 },
  { d: '2024-02-26', t: 57, aC: 19, hW: 102, hB: 112 },
  { d: '2024-02-27', t: 51, aC: 19, hW: 98, hB: 110 },
  { d: '2024-02-28', t: 52, aC: 20, hW: 110, hB: 115 },
  { d: '2024-02-29', t: 48, aC: 20, hW: 108, hB: 114 },
  { d: '2024-03-01', t: 47, aC: 20, hW: 89, hB: 120 },
  { d: '2024-03-02', t: 4, aC: 20, hW: 12, hB: 24 },
  { d: '2024-03-03', t: 2, aC: 20, hW: 6, hB: 12 },
  { d: '2024-03-04', t: 59, aC: 21, hW: 99, hB: 110 },
  { d: '2024-03-05', t: 54, aC: 21, hW: 89, hB: 100 },
  { d: '2024-03-06', t: 46, aC: 21, hW: 91, hB: 100 },
  { d: '2024-03-07', t: 44, aC: 22, hW: 79, hB: 86 },
  { d: '2024-03-08', t: 41, aC: 22, hW: 70, hB: 82 },
  { d: '2024-03-09', t: 0, aC: 23, hW: 0, hB: 0 },
  { d: '2024-03-10', t: 0, aC: 23, hW: 0, hB: 0 },
  { d: '2024-03-11', t: 54, aC: 23, hW: 102, hB: 106 },
  { d: '2024-03-12', t: 47, aC: 23, hW: 108, hB: 120 },
  { d: '2024-03-13', t: 41, aC: 23, hW: 99, hB: 110 },
  { d: '2024-03-14', t: 58, aC: 23, hW: 90, hB: 112 },
  { d: '2024-03-15', t: 57, aC: 23, hW: 109, hB: 115 },
  { d: '2024-03-16', t: 0, aC: 23, hW: 0, hB: 0 },
  { d: '2024-03-17', t: 0, aC: 24, hW: 0, hB: 0 },
  { d: '2024-03-18', t: 37, aC: 25, hW: 88, hB: 96 },
  { d: '2024-03-19', t: 36, aC: 24, hW: 78, hB: 82 },
  { d: '2024-03-20', t: 38, aC: 24, hW: 102, hB: 116 },
  { d: '2024-03-21', t: 49, aC: 24, hW: 98, hB: 110 },
  { d: '2024-03-22', t: 51, aC: 24, hW: 102, hB: 118 },
  { d: '2024-03-23', t: 0, aC: 24, hW: 0, hB: 0 },
  { d: '2024-03-24', t: 0, aC: 24, hW: 0, hB: 0 },
  { d: '2024-03-25', t: 58, aC: 24, hW: 102, hB: 110 },
  { d: '2024-03-26', t: 57, aC: 26, hW: 120, hB: 125 },
  { d: '2024-03-27', t: 62, aC: 26, hW: 134, hB: 160 },
  { d: '2024-03-28', t: 64, aC: 26, hW: 130, hB: 160 },
  { d: '2024-03-29', t: 51, aC: 26, hW: 114, hB: 135 },
  { d: '2024-03-30', t: 2, aC: 25, hW: 6, hB: 12 },
  { d: '2024-03-31', t: 1, aC: 26, hW: 5, hB: 8 },
  { d: '2024-04-01', t: 47, aC: 26, hW: 91, hB: 110 },
  { d: '2024-04-02', t: 50, aC: 27, hW: 100, hB: 110 },
  { d: '2024-04-03', t: 52, aC: 27, hW: 105, hB: 115 },
  { d: '2024-04-04', t: 55, aC: 27, hW: 110, hB: 120 },
  { d: '2024-04-05', t: 53, aC: 27, hW: 108, hB: 118 },
  { d: '2024-04-06', t: 49, aC: 27, hW: 98, hB: 105 },
  { d: '2024-04-07', t: 0, aC: 28, hW: 0, hB: 0 },
  { d: '2024-04-08', t: 60, aC: 28, hW: 115, hB: 125 },
  { d: '2024-04-09', t: 58, aC: 28, hW: 112, hB: 122 },
  { d: '2024-04-10', t: 56, aC: 28, hW: 110, hB: 120 },
  { d: '2024-04-11', t: 54, aC: 28, hW: 108, hB: 118 },
  { d: '2024-04-12', t: 57, aC: 29, hW: 112, hB: 122 },
  { d: '2024-04-13', t: 0, aC: 29, hW: 0, hB: 0 },
  { d: '2024-04-14', t: 60, aC: 29, hW: 120, hB: 130 },
  { d: '2024-04-15', t: 58, aC: 29, hW: 115, hB: 125 },
  { d: '2024-04-16', t: 55, aC: 30, hW: 110, hB: 120 },
  { d: '2024-04-17', t: 53, aC: 30, hW: 108, hB: 118 },
  { d: '2024-04-18', t: 50, aC: 30, hW: 105, hB: 115 },
  { d: '2024-04-19', t: 0, aC: 31, hW: 0, hB: 0 },
  { d: '2024-04-20', t: 65, aC: 31, hW: 125, hB: 135 },
  { d: '2024-04-21', t: 63, aC: 31, hW: 120, hB: 130 },
  { d: '2024-04-22', t: 61, aC: 31, hW: 115, hB: 125 },
  { d: '2024-04-23', t: 60, aC: 31, hW: 112, hB: 122 },
  { d: '2024-04-24', t: 58, aC: 32, hW: 110, hB: 120 },
  { d: '2024-04-25', t: 0, aC: 32, hW: 0, hB: 0 },
  { d: '2024-04-26', t: 62, aC: 32, hW: 115, hB: 125 },
  { d: '2024-04-27', t: 60, aC: 32, hW: 112, hB: 122 },
  { d: '2024-04-28', t: 59, aC: 32, hW: 110, hB: 120 },
  { d: '2024-04-29', t: 57, aC: 32, hW: 108, hB: 118 },
  { d: '2024-04-30', t: 55, aC: 33, hW: 106, hB: 116 },
  { d: '2024-05-01', t: 0, aC: 33, hW: 0, hB: 0 },
  { d: '2024-05-02', t: 65, aC: 33, hW: 125, hB: 135 },
  { d: '2024-05-03', t: 63, aC: 33, hW: 120, hB: 130 },
  { d: '2024-05-04', t: 61, aC: 33, hW: 118, hB: 128 },
  { d: '2024-05-05', t: 60, aC: 34, hW: 115, hB: 125 },
  { d: '2024-05-06', t: 58, aC: 34, hW: 113, hB: 123 },
  { d: '2024-05-07', t: 0, aC: 34, hW: 0, hB: 0 },
  { d: '2024-05-08', t: 70, aC: 34, hW: 130, hB: 140 },
  { d: '2024-05-09', t: 68, aC: 34, hW: 125, hB: 135 },
  { d: '2024-05-10', t: 65, aC: 34, hW: 122, hB: 132 },
  { d: '2024-05-11', t: 63, aC: 35, hW: 120, hB: 130 },
  { d: '2024-05-12', t: 61, aC: 35, hW: 118, hB: 128 },
  { d: '2024-05-13', t: 0, aC: 35, hW: 0, hB: 0 },
  { d: '2024-05-14', t: 65, aC: 35, hW: 125, hB: 135 },
  { d: '2024-05-15', t: 63, aC: 35, hW: 120, hB: 130 },
  { d: '2024-05-16', t: 62, aC: 35, hW: 118, hB: 128 },
  { d: '2024-05-17', t: 60, aC: 35, hW: 115, hB: 125 },
  { d: '2024-05-18', t: 58, aC: 35, hW: 112, hB: 122 },
  { d: '2024-05-19', t: 0, aC: 36, hW: 0, hB: 0 },
  { d: '2024-05-20', t: 68, aC: 36, hW: 128, hB: 138 },
  { d: '2024-05-21', t: 66, aC: 36, hW: 125, hB: 135 },
  { d: '2024-05-22', t: 65, aC: 36, hW: 122, hB: 132 },
  { d: '2024-05-23', t: 63, aC: 36, hW: 120, hB: 130 },
  { d: '2024-05-24', t: 61, aC: 36, hW: 118, hB: 128 },
  { d: '2024-05-25', t: 0, aC: 37, hW: 0, hB: 0 },
  { d: '2024-05-26', t: 70, aC: 37, hW: 130, hB: 140 },
  { d: '2024-05-27', t: 68, aC: 37, hW: 125, hB: 135 },
  { d: '2024-05-28', t: 66, aC: 37, hW: 122, hB: 132 },
  { d: '2024-05-29', t: 65, aC: 37, hW: 120, hB: 130 },
  { d: '2024-05-30', t: 63, aC: 37, hW: 118, hB: 128 },
  { d: '2024-05-31', t: 61, aC: 37, hW: 115, hB: 125 },
  { d: '2024-06-01', t: 0, aC: 37, hW: 0, hB: 0 },
  { d: '2024-06-02', t: 70, aC: 38, hW: 135, hB: 145 },
  { d: '2024-06-03', t: 68, aC: 38, hW: 130, hB: 140 },
  { d: '2024-06-04', t: 66, aC: 38, hW: 125, hB: 135 },
  { d: '2024-06-05', t: 65, aC: 38, hW: 122, hB: 132 },
  { d: '2024-06-06', t: 63, aC: 38, hW: 120, hB: 130 },
  { d: '2024-06-07', t: 61, aC: 38, hW: 118, hB: 128 },
  { d: '2024-06-08', t: 75, aC: 38, hW: 140, hB: 150 },
{ d: '2024-06-09', t: 78, aC: 38, hW: 145, hB: 155 },
{ d: '2024-06-10', t: 80, aC: 38, hW: 150, hB: 160 },
{ d: '2024-06-11', t: 85, aC: 39, hW: 155, hB: 165 },
{ d: '2024-06-12', t: 83, aC: 39, hW: 152, hB: 162 },
{ d: '2024-06-13', t: 88, aC: 39, hW: 160, hB: 170 },
{ d: '2024-06-14', t: 0, aC: 39, hW: 0, hB: 0 },
{ d: '2024-06-15', t: 90, aC: 39, hW: 165, hB: 175 },
{ d: '2024-06-16', t: 92, aC: 39, hW: 170, hB: 180 },
{ d: '2024-06-17', t: 95, aC: 40, hW: 175, hB: 185 },
{ d: '2024-06-18', t: 97, aC: 40, hW: 180, hB: 190 },
{ d: '2024-06-19', t: 100, aC: 40, hW: 185, hB: 195 },
{ d: '2024-06-20', t: 0, aC: 40, hW: 0, hB: 0 },
{ d: '2024-06-21', t: 102, aC: 41, hW: 190, hB: 200 },
{ d: '2024-06-22', t: 105, aC: 41, hW: 195, hB: 205 },
{ d: '2024-06-23', t: 108, aC: 41, hW: 200, hB: 210 },
{ d: '2024-06-24', t: 110, aC: 41, hW: 205, hB: 215 },
{ d: '2024-06-25', t: 112, aC: 42, hW: 210, hB: 220 },
{ d: '2024-06-26', t: 0, aC: 42, hW: 0, hB: 0 },
{ d: '2024-06-27', t: 115, aC: 42, hW: 215, hB: 225 },
{ d: '2024-06-28', t: 118, aC: 42, hW: 220, hB: 230 },
{ d: '2024-06-29', t: 120, aC: 42, hW: 225, hB: 235 },
{ d: '2024-06-30', t: 123, aC: 43, hW: 230, hB: 240 },
{ d: '2024-07-01', t: 125, aC: 43, hW: 235, hB: 245 },
{ d: '2024-07-02', t: 0, aC: 43, hW: 0, hB: 0 },
{ d: '2024-07-03', t: 128, aC: 43, hW: 240, hB: 250 },
{ d: '2024-07-04', t: 130, aC: 44, hW: 245, hB: 255 },
{ d: '2024-07-05', t: 133, aC: 44, hW: 250, hB: 260 },
{ d: '2024-07-06', t: 135, aC: 44, hW: 255, hB: 265 },
{ d: '2024-07-07', t: 138, aC: 44, hW: 260, hB: 270 },
];




const dataLast7Days = dataLast90Days.slice(-7);
const dataLast30Days = dataLast90Days.slice(-30);
const dataLast60Days = dataLast90Days.slice(-60); // Add this line
const dataLast180Days = dataLast90Days.slice(-180); // Add this line
const dataLast365Days = dataLast90Days.slice(-365); // Add this line

export { dataLast7Days, dataLast30Days, dataLast60Days, dataLast90Days, dataLast180Days, dataLast365Days };
