import React from 'react';
import { Pagination, ConfigProvider } from 'antd';
import locale from 'antd/es/date-picker/locale/en_GB'; // Ensure the correct locale is used
import 'dayjs/locale/en';
import './ProjectsPages.less';
import ManageTeamModal from '../../Modals/ManageTeamModal';
import ProjectsTableSort from './ProjectsTableColumns/ProjectsTableSort';
import useProjectTableLogic from './ProjectsTableLogic';
import RowOptions from './ProjectsTableColumns/RowOptions';
import teamMembers from '../../Data/TeamSampleData'; // Correct import for teamMembers
import clientsData from '../../Data/SampleClients'; // Correct import for clientsData

const TableRow = ({ row, index, columns, showModal }) => (
  <tr className="pt-table-row">
    {columns.map((column, colIndex) => (
      <td key={column.accessor} className="pt-cell">
        {column.render ? column.render(null, row, index) : row[column.accessor]}
      </td>
    ))}
    <td className="row-options-cell">
      <RowOptions />
    </td>
  </tr>
);

const Projects = () => {
  const {
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    isModalVisible,
    handleCancel,
    selectedProject,
    projects,
    currentRows,
    columns,
    showModal
  } = useProjectTableLogic();

  return (
    <div className="pt-projects-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-briefcase"></i>
        </span>
        All Projects
      </p>
      <ProjectsTableSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
      <div className="pt-table-container">
      <div className="main-table-main-container">
        <div className="main-table-container">
          <table className="main-table">
            <thead className="main-table-head">
              <tr className="main-table-head-row">
                {columns.map((column) => (
                  <th key={column.accessor} className="main-table-head-cell">
                    {column.Header}
                  </th>
                ))}
                <th className="row-options-header-cell"></th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <TableRow key={index} row={row} index={index} columns={columns} showModal={showModal} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="main-pagination-container">
      <Pagination
        current={currentPage}
        pageSize={rowsPerPage}
        total={projects.length}
        onChange={setCurrentPage}
        className="main-pagination"
      />
      </div>
      <ManageTeamModal
        isVisible={isModalVisible}
        selectedProject={selectedProject}
        toggleModal={handleCancel}
        teamMembers={teamMembers} // Corrected variable name
        clientsData={clientsData} // Corrected variable name
      />
    </div>
    </div>
  );
};

export default Projects;
