import React from 'react';
import { Select } from 'antd';
import SingleAvatar2 from '../../../Components/SingleAvatar2';

const { Option } = Select;

const accountManagers = [
  'Noman Hossain',
  'Vitalii Zurov',
  'Alex Rozario',
  'Forhad Uddin',
  'Mayowa Ajakaiye'
];

const AccountManagerColumn = {
  title: 'Manager',
  key: 'accountManager',
  className: 'ct-account-manager',
  render: (text, record) => {
    const handleManagerChange = (value) => {
      // Implement the logic to update the account manager in your data source
      console.log(`Manager changed to: ${value}`);
    };

    const renderManagerOption = (manager) => (
      <div className="ct-account-manager-option">
        <SingleAvatar2 name={manager} />
        <span className="ct-account-manager-name">{manager}</span>
      </div>
    );

    return (
      <Select
        defaultValue={record.accountManager}
        onChange={handleManagerChange}
        className="ct-account-manager-select"
        dropdownClassName="ct-account-manager-select-dropdown"
        optionLabelProp="label"
      >
        {accountManagers.map((manager) => (
          <Option key={manager} value={manager} label={renderManagerOption(manager)}>
            {renderManagerOption(manager)}
          </Option>
        ))}
      </Select>
    );
  },
};

export default AccountManagerColumn;
