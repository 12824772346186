const clients = [
  { id: 1, name: 'Apple Inc.', type: 'Client' },
  { id: 2, name: 'Microsoft Corp.', type: 'Client' },
  { id: 3, name: 'Amazon.com Inc.', type: 'Client' },
  { id: 4, name: 'Google LLC', type: 'Client' },
  { id: 5, name: 'Facebook Inc.', type: 'Client' },
  { id: 6, name: 'Tesla Inc.', type: 'Client' },
  { id: 7, name: 'Berkshire Hathaway Inc.', type: 'Client' },
  { id: 8, name: 'Johnson & Johnson', type: 'Client' },
  { id: 9, name: 'JPMorgan Chase & Co.', type: 'Client' },
  { id: 10, name: 'Visa Inc.', type: 'Client' },
  { id: 11, name: 'Procter & Gamble Co.', type: 'Client' },
  { id: 12, name: 'Samsung Electronics Co.', type: 'Client' },
  { id: 13, name: 'Intel Corp.', type: 'Client' },
  { id: 14, name: 'Cisco Systems Inc.', type: 'Client' },
  { id: 15, name: 'Oracle Corp.', type: 'Client' },
  { id: 16, name: 'IBM Corp.', type: 'Client' },
  { id: 17, name: 'AT&T Inc.', type: 'Client' },
  { id: 18, name: 'Verizon Communications Inc.', type: 'Client' },
  { id: 19, name: 'Coca-Cola Co.', type: 'Client' },
  { id: 20, name: 'PepsiCo Inc.', type: 'Client' },
  { id: 21, name: 'Netflix Inc.', type: 'Client' },
{ id: 22, name: 'Adobe Systems Inc.', type: 'Client' },
{ id: 23, name: 'Salesforce.com Inc.', type: 'Client' },
{ id: 24, name: 'Spotify Technology SA', type: 'Client' },
{ id: 25, name: 'Airbnb Inc.', type: 'Client' },
{ id: 26, name: 'Uber Technologies Inc.', type: 'Client' },
{ id: 27, name: 'Lyft Inc.', type: 'Client' },
{ id: 28, name: 'Slack Technologies Inc.', type: 'Client' },
{ id: 29, name: 'Pinterest Inc.', type: 'Client' },
{ id: 30, name: 'Zoom Video Communications Inc.', type: 'Client' },
{ id: 31, name: 'Square Inc.', type: 'Client' },
{ id: 32, name: 'Snap Inc.', type: 'Client' },
{ id: 33, name: 'Shopify Inc.', type: 'Client' },
{ id: 34, name: 'Twitter Inc.', type: 'Client' },
{ id: 35, name: 'Dropbox Inc.', type: 'Client' },
{ id: 36, name: 'Palantir Technologies Inc.', type: 'Client' },
{ id: 37, name: 'Stripe Inc.', type: 'Client' },
{ id: 38, name: 'Roblox Corporation', type: 'Client' },
{ id: 39, name: 'Coinbase Global Inc.', type: 'Client' },
{ id: 40, name: 'Reddit Inc.', type: 'Client' },
{ id: 41, name: 'General Electric', type: 'Client' },
{ id: 42, name: 'Walmart Inc.', type: 'Client' },
{ id: 43, name: 'Pfizer Inc.', type: 'Client' },
{ id: 44, name: 'ExxonMobil', type: 'Client' },
{ id: 45, name: 'Boeing Co.', type: 'Client' },
{ id: 46, name: 'Citigroup Inc.', type: 'Client' },
{ id: 47, name: 'Goldman Sachs', type: 'Client' },
{ id: 48, name: 'Morgan Stanley', type: 'Client' },
{ id: 49, name: 'Delta Air Lines', type: 'Client' },
{ id: 50, name: 'American Airlines', type: 'Client' },
{ id: 51, name: 'Southwest Airlines', type: 'Client' },
{ id: 52, name: 'Marriott International', type: 'Client' },
{ id: 53, name: 'Hilton Worldwide', type: 'Client' },
{ id: 54, name: 'Starbucks Corp.', type: 'Client' },
{ id: 55, name: 'McDonald\'s Corp.', type: 'Client' },
{ id: 56, name: 'Ford Motor Co.', type: 'Client' },
{ id: 57, name: 'General Motors', type: 'Client' },
{ id: 58, name: 'Honda Motor Co.', type: 'Client' },
{ id: 59, name: 'Toyota Motor Corp.', type: 'Client' },
{ id: 60, name: 'Nissan Motor Co.', type: 'Client' },
{ id: 61, name: 'Hyundai Motor Co.', type: 'Client' },
{ id: 62, name: 'Kia Motors Corp.', type: 'Client' },
{ id: 63, name: 'LG Electronics', type: 'Client' },
{ id: 64, name: 'Sony Corp.', type: 'Client' },
{ id: 65, name: 'Panasonic Corp.', type: 'Client' },
{ id: 66, name: 'Toshiba Corp.', type: 'Client' },
{ id: 67, name: 'Hitachi Ltd.', type: 'Client' },
{ id: 68, name: 'Mitsubishi Corp.', type: 'Client' },
{ id: 69, name: 'Sumitomo Corp.', type: 'Client' },
{ id: 70, name: 'Fujitsu Ltd.', type: 'Client' },
{ id: 71, name: 'NEC Corp.', type: 'Client' },
{ id: 72, name: 'Nokia Corp.', type: 'Client' },
{ id: 73, name: 'Ericsson', type: 'Client' },
{ id: 74, name: 'Siemens AG', type: 'Client' },
{ id: 75, name: 'ABB Ltd.', type: 'Client' },
{ id: 76, name: 'Schneider Electric', type: 'Client' },
{ id: 77, name: 'Thales Group', type: 'Client' },
{ id: 78, name: 'Bosch GmbH', type: 'Client' },
{ id: 79, name: 'Continental AG', type: 'Client' },
{ id: 80, name: 'Michelin', type: 'Client' },
];

const projects = [
  { id: 1, name: 'Internet of Things Management', type: 'Project' },
  { id: 2, name: 'Branding Redesign', type: 'Project' },
  { id: 3, name: 'E-commerce Platform Development', type: 'Project' },
  { id: 4, name: 'Social Media Campaign', type: 'Project' },
  { id: 5, name: 'Mobile App Development', type: 'Project' },
  { id: 6, name: 'Website Optimization', type: 'Project' },
  { id: 7, name: 'Customer Relationship Management', type: 'Project' },
  { id: 8, name: 'Cloud Infrastructure Setup', type: 'Project' },
  { id: 9, name: 'Big Data Analytics', type: 'Project' },
  { id: 10, name: 'AI Integration', type: 'Project' },
  { id: 11, name: 'Blockchain Implementation', type: 'Project' },
  { id: 12, name: 'Cybersecurity Enhancement', type: 'Project' },
  { id: 13, name: 'Digital Transformation Strategy', type: 'Project' },
  { id: 14, name: 'User Experience Design', type: 'Project' },
  { id: 15, name: 'Content Management System', type: 'Project' },
  { id: 16, name: 'Search Engine Optimization', type: 'Project' },
  { id: 17, name: 'Marketing Automation', type: 'Project' },
  { id: 18, name: 'Data Migration', type: 'Project' },
  { id: 19, name: 'Video Production', type: 'Project' },
  { id: 20, name: 'Interactive Ad Campaign', type: 'Project' },
  { id: 21, name: 'Augmented Reality App Development', type: 'Project' },
 { id: 22, name: 'Machine Learning Model Training', type: 'Project' },
 { id: 23, name: 'Blockchain-based Voting System', type: 'Project' },
 { id: 24, name: 'Smart Home Automation', type: 'Project' },
 { id: 25, name: 'Virtual Reality Experience Design', type: 'Project' },
 { id: 26, name: 'Digital Twin Simulation', type: 'Project' },
 { id: 27, name: 'Natural Language Processing Tool', type: 'Project' },
 { id: 28, name: 'Predictive Maintenance System', type: 'Project' },
 { id: 29, name: 'Robotic Process Automation', type: 'Project' },
 { id: 30, name: 'Edge Computing Implementation', type: 'Project' },
 { id: 31, name: 'Quantum Computing Research', type: 'Project' },
 { id: 32, name: '5G Network Deployment', type: 'Project' },
 { id: 33, name: 'Autonomous Vehicle Testing', type: 'Project' },
 { id: 34, name: 'Renewable Energy Integration', type: 'Project' },
 { id: 35, name: 'Smart Grid Management', type: 'Project' },
 { id: 36, name: 'Wearable Technology Development', type: 'Project' },
 { id: 37, name: 'E-Sports Platform Expansion', type: 'Project' },
 { id: 38, name: 'Telemedicine Service Launch', type: 'Project' },
 { id: 39, name: 'Digital Health Record System', type: 'Project' },
 { id: 40, name: 'Biometric Authentication System', type: 'Project' },
 { id: 41, name: 'IoT Device Integration', type: 'Project' },
{ id: 42, name: 'Enterprise Data Warehouse', type: 'Project' },
{ id: 43, name: 'Cloud Migration Strategy', type: 'Project' },
{ id: 44, name: 'Customer Data Platform', type: 'Project' },
{ id: 45, name: 'Digital Payment Gateway', type: 'Project' },
{ id: 46, name: 'Supply Chain Optimization', type: 'Project' },
{ id: 47, name: 'HR Management System', type: 'Project' },
{ id: 48, name: 'Predictive Analytics Dashboard', type: 'Project' },
{ id: 49, name: 'Content Delivery Network Setup', type: 'Project' },
{ id: 50, name: 'Customer Support Chatbot', type: 'Project' },
{ id: 51, name: 'Digital Twin Development', type: 'Project' },
{ id: 52, name: 'Robotic Automation System', type: 'Project' },
{ id: 53, name: 'Virtual Event Platform', type: 'Project' },
{ id: 54, name: 'Augmented Reality Marketing', type: 'Project' },
{ id: 55, name: 'Smart Factory Implementation', type: 'Project' },
{ id: 56, name: 'Voice Assistant Development', type: 'Project' },
{ id: 57, name: 'Fleet Management System', type: 'Project' },
{ id: 58, name: 'Geospatial Analytics Tool', type: 'Project' },
{ id: 59, name: 'AI-powered Fraud Detection', type: 'Project' },
{ id: 60, name: 'User Behavior Analytics', type: 'Project' },
{ id: 61, name: 'Personalized Marketing Engine', type: 'Project' },
{ id: 62, name: 'Cross-platform Mobile App', type: 'Project' },
{ id: 63, name: 'Blockchain Asset Management', type: 'Project' },
{ id: 64, name: 'Cloud Security Enhancement', type: 'Project' },
{ id: 65, name: 'Digital Forensics Platform', type: 'Project' },
{ id: 66, name: 'E-commerce Personalization', type: 'Project' },
{ id: 67, name: 'Energy Management System', type: 'Project' },
{ id: 68, name: 'Financial Risk Management', type: 'Project' },
{ id: 69, name: 'Intelligent Document Processing', type: 'Project' },
{ id: 70, name: 'IoT-based Asset Tracking', type: 'Project' },
{ id: 71, name: 'Knowledge Management System', type: 'Project' },
{ id: 72, name: 'Omnichannel Retail Platform', type: 'Project' },
{ id: 73, name: 'Performance Monitoring Tool', type: 'Project' },
{ id: 74, name: 'Real-time Collaboration Suite', type: 'Project' },
{ id: 75, name: 'Remote Work Enablement', type: 'Project' },
{ id: 76, name: 'SaaS Product Development', type: 'Project' },
{ id: 77, name: 'Sentiment Analysis Dashboard', type: 'Project' },
{ id: 78, name: 'Smart Building Solutions', type: 'Project' },
{ id: 79, name: 'Telematics System Integration', type: 'Project' },
{ id: 80, name: 'Virtual Classroom Platform', type: 'Project' },
];

const teamMembers = [
  { id: 1, name: 'Noman Hossain', jobTitle: 'CEO', email: 'noman@example.com', type: 'Team Member' },
  { id: 2, name: 'Sazzad Mahmud', jobTitle: 'Web Developer', email: 'sazzad@example.com', type: 'Team Member' },
  { id: 3, name: 'Muhammad Afaq', jobTitle: 'Web Developer', email: 'afaq@example.com', type: 'Team Member' },
  { id: 4, name: 'Shanto H', jobTitle: 'WordPress Developer', email: 'shanto@example.com', type: 'Team Member' },
  { id: 5, name: 'Forhad Uddin', jobTitle: 'Account Manager', email: 'forhad@example.com', type: 'Team Member' },
  { id: 6, name: 'Alex Rozario', jobTitle: 'Web Developer', email: 'alex@example.com', type: 'Team Member' },
  { id: 7, name: 'Mayowa A.', jobTitle: 'Marketing Specialist', email: 'mayowa@example.com', type: 'Team Member' },
  { id: 8, name: 'Vitalii Zurov', jobTitle: 'Web Developer', email: 'vitalii@example.com', type: 'Team Member' },
  { id: 9, name: 'John Doe', jobTitle: 'Web Developer', email: 'john@example.com', type: 'Team Member' },
];


const comments = [
  { id: 1, name: 'Review meeting notes from last week and provide feedback.', type: 'Comment' },
  { id: 2, name: 'Can we push the deadline for the current sprint?', type: 'Comment' },
  { id: 3, name: 'Update on the progress of the new UI design?', type: 'Comment' },
  { id: 4, name: 'Please ensure all changes are documented in the project wiki.', type: 'Comment' },
  { id: 5, name: 'The recent update caused a bug in the login module, please fix.', type: 'Comment' },
  { id: 6, name: 'Great job on the presentation!', type: 'Comment' },
  { id: 7, name: 'Can we schedule a quick sync-up meeting tomorrow?', type: 'Comment' },
  { id: 8, name: 'Please review the attached document and share your thoughts on the matter because I am getting too stressed just thinking about this crazy client.', type: 'Comment' },
  { id: 9, name: 'Client requested additional features to be added to the current scope.', type: 'Comment' },
  { id: 10, name: 'Any update on the status of the security audit?', type: 'Comment' }
];

const tasks = [
  { id: 1, name: 'Design new landing page for website.', type: 'Task' },
  { id: 2, name: 'Develop backend API for user authentication.', type: 'Task' },
  { id: 3, name: 'Set up continuous integration pipeline.', type: 'Task' },
  { id: 4, name: 'Write unit tests for the payment gateway integration.', type: 'Task' },
  { id: 5, name: 'Conduct user research for new feature development.', type: 'Task' },
  { id: 6, name: 'Create marketing materials for the product launch.', type: 'Task' },
  { id: 7, name: 'Optimize database queries for performance improvements.', type: 'Task' },
  { id: 8, name: 'Update documentation for the new API endpoints.', type: 'Task' },
  { id: 9, name: 'Implement caching strategy for the application.', type: 'Task' },
  { id: 10, name: 'Fix bugs reported in the latest release.', type: 'Task' }
];

export { clients, projects, teamMembers, comments, tasks };
