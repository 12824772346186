// ProjectOverviewChartData.js

// Simulated task data over a month with daily entries
export const taskStatusData = [
  { date: '2023-09-01', notStarted: 100, inProgress: 0, inReview: 0, completed: 0 },
  { date: '2023-09-02', notStarted: 100, inProgress: 0, inReview: 0, completed: 0 }, // Weekend
  { date: '2023-09-03', notStarted: 100, inProgress: 0, inReview: 0, completed: 0 }, // Weekend
  { date: '2023-09-04', notStarted: 95, inProgress: 5, inReview: 0, completed: 0 },
  { date: '2023-09-05', notStarted: 90, inProgress: 10, inReview: 0, completed: 0 },
  { date: '2023-09-06', notStarted: 85, inProgress: 14, inReview: 1, completed: 0 },
  { date: '2023-09-07', notStarted: 80, inProgress: 18, inReview: 2, completed: 0 },
  { date: '2023-09-08', notStarted: 75, inProgress: 22, inReview: 3, completed: 0 },
  { date: '2023-09-09', notStarted: 75, inProgress: 22, inReview: 3, completed: 0 }, // Weekend
  { date: '2023-09-10', notStarted: 75, inProgress: 22, inReview: 3, completed: 0 }, // Weekend
  { date: '2023-09-11', notStarted: 70, inProgress: 25, inReview: 4, completed: 1 },
  { date: '2023-09-12', notStarted: 65, inProgress: 28, inReview: 5, completed: 2 },
  { date: '2023-09-13', notStarted: 60, inProgress: 31, inReview: 6, completed: 3 },
  { date: '2023-09-14', notStarted: 55, inProgress: 34, inReview: 7, completed: 4 },
  { date: '2023-09-15', notStarted: 50, inProgress: 37, inReview: 8, completed: 5 },
  { date: '2023-09-16', notStarted: 50, inProgress: 37, inReview: 8, completed: 5 }, // Weekend
  { date: '2023-09-17', notStarted: 50, inProgress: 37, inReview: 8, completed: 5 }, // Weekend
  { date: '2023-09-18', notStarted: 45, inProgress: 39, inReview: 9, completed: 7 },
  { date: '2023-09-19', notStarted: 40, inProgress: 41, inReview: 10, completed: 9 },
  { date: '2023-09-20', notStarted: 35, inProgress: 43, inReview: 11, completed: 11 },
  { date: '2023-09-21', notStarted: 30, inProgress: 45, inReview: 12, completed: 13 },
  { date: '2023-09-22', notStarted: 25, inProgress: 47, inReview: 13, completed: 15 },
  { date: '2023-09-23', notStarted: 25, inProgress: 47, inReview: 13, completed: 15 }, // Weekend
  { date: '2023-09-24', notStarted: 25, inProgress: 47, inReview: 13, completed: 15 }, // Weekend
  { date: '2023-09-25', notStarted: 20, inProgress: 48, inReview: 14, completed: 18 },
  { date: '2023-09-26', notStarted: 15, inProgress: 49, inReview: 15, completed: 21 },
  { date: '2023-09-27', notStarted: 10, inProgress: 50, inReview: 15, completed: 25 },
  { date: '2023-09-28', notStarted: 5, inProgress: 50, inReview: 15, completed: 30 },
  { date: '2023-09-29', notStarted: 0, inProgress: 50, inReview: 15, completed: 35 },
  { date: '2023-09-30', notStarted: 0, inProgress: 15, inReview: 5, completed: 80 }, // Final state
];

// Note: The last day's counts are adjusted to match the desired percentages.
