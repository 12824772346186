// TicketsTablePage.js

import React, { useState } from 'react';
import './Tickets.less'; // Assuming you have a separate LESS file for tickets styles
import TicketsTable from './TicketsComponents/TicketsTable';
import TicketsSort from './TicketsComponents/TicketsSort';
import { Pagination } from 'antd';

const TicketsTablePage = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
  const [currentPage, setCurrentPage] = useState(1); // Default current page

  const totalTickets = 50; // Assuming you have 50 tickets

  return (
    <div className="ttp-page-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rs-ticket-alt"></i>
        </span>
        Tickets
      </p>

      {/* Sorting Component */}
      <TicketsSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />

      {/* Tickets Table */}
      <div className="ttp-table-container">
        <TicketsTable currentPage={currentPage} rowsPerPage={rowsPerPage} />
      </div>

      {/* Pagination Component Below the Table */}
      <div className="pagination-container">
        <Pagination
          current={currentPage}
          pageSize={rowsPerPage}
          total={totalTickets}
          onChange={(page) => setCurrentPage(page)}
          className="main-pagination"
        />
      </div>
    </div>
  );
};

export default TicketsTablePage;
