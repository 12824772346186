import React, { useState } from 'react';
import TicketDrawer from './TicketDrawer'; // Import your TicketDrawer component
import { Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

const TicketStats = () => {
  const [drawerVisible, setDrawerVisible] = useState(false); // State to manage drawer visibility

  const toggleDrawerVisibility = () => {
    setDrawerVisible(!drawerVisible); // Toggle drawer visibility
  };

  const stats = [
    { title: 'All ', count: 2400, className: 'tsc-all-tickets-box' },
    { title: 'Open', count: 1200, className: 'tsc-open-tickets-box' },
    { title: 'In Progress', count: 450, className: 'tsc-inprogress-tickets-box' },
    { title: 'Closed', count: 1800, className: 'tsc-closed-tickets-box' }
  ];

      return (
      
        <div className="whitebox">
          <div className="tsc-ticket-stats-header">
            <h3>Tickets Overview</h3>
            <Button
      // onClick={toggleDrawerVisibility}
      icon={<SettingOutlined />}
      >
  
</Button>
      </div>
      <div className="tsc-ticket-stats-cards">
        {stats.map((stat, index) => (
          <div key={index} className={`tsc-ticket-stat-card ${stat.className}`}>
            <div className="tsc-ticket-info">
              <p className="tsc-ticket-title">{stat.title}</p>
              <p className="tsc-ticket-count">{stat.count.toLocaleString()}</p>
            </div>
            <div className="button-3">
              <i className="fi fi-rr-ticket-alt"></i>
            </div>
          </div>
        ))}
      </div>
      {/* Include TicketDrawer component */}
      <TicketDrawer
        visible={drawerVisible}
        onClose={toggleDrawerVisibility} // Close drawer when called
      />
    </div>
  );
};

export default TicketStats;
