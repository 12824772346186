// AutomationstableColumns/AutomationsTableLogic.js

import { useEffect, useState } from 'react';

export const useAutomationsTableLogic = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler once on mount to set the initial value
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};
