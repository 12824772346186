import React from 'react';
import { Card, Avatar, Button, Row, Col, Tag } from 'antd';


const TicketPreview = () => {
  return (

   
      
      



    <div className="tpw-ticket-preview-container">


      
 {/* Page Title and Icon */}
 <div className="ttp-page-title">
        <div className="ttp-page-title-icon-container">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6667 5.51365V2.82032C14.6667 1.76032 14.24 1.33365 13.18 1.33365H10.4867C9.42667 1.33365 9 1.76032 9 2.82032V5.51365C9 6.57365 9.42667 7.00032 10.4867 7.00032H13.18C14.24 7.00032 14.6667 6.57365 14.6667 5.51365Z" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.99943 5.68032V2.65365C6.99943 1.71365 6.57276 1.33365 5.51276 1.33365H2.81943C1.75943 1.33365 1.33276 1.71365 1.33276 2.65365V5.67365C1.33276 6.62032 1.75943 6.99365 2.81943 6.99365H5.51276C6.57276 7.00031 6.99943 6.62032 6.99943 5.68032Z" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.99943 13.18V10.4867C6.99943 9.42667 6.57276 9 5.51276 9H2.81943C1.75943 9 1.33276 9.42667 1.33276 10.4867V13.18C1.33276 14.24 1.75943 14.6667 2.81943 14.6667H5.51276C6.57276 14.6667 6.99943 14.24 6.99943 13.18Z" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M10 10.3336H14" stroke="#626262" stroke-linecap="round"/>
<path opacity="0.4" d="M10 13H14" stroke="#626262" stroke-linecap="round"/>
</svg>

        </div>
        <div className="ttp-page-title-text">Ticket preview</div>
      </div>

      <Card
        className="tpw-ticket-card"
        title="Ticket Preview"
        bordered={false}
      >
        {/* From Section */}
        <div class="tpw-from-section">
  <span class="from-label">From:</span>
  <span class="from-text">Aio Solution Support</span>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 6.00003C12 6.00003 9.05407 10 8 10C6.94587 10 4 6 4 6" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</div>




        <div class="ticket-wrapper">
    <span class="priority-tag">Low</span>
    <button class="ticket-button">Open Ticket</button>
    <button class="ticket-button">Design & Development</button>
</div>

        {/* Body Content */}
        <div className="tpw-ticket-body">
          <p>Hello Kristen,</p>
          <p>Thanks for letting me know you have logged into the Aio Client portal.</p>
          <p>Please check the left menu panel and you should see the following options:</p>
          <ul>
            <li>Hours Chart: This shows your Aio hours spend history in a chart.</li>
            <li>Work: This is where you see the Kanban board which contains all to-do, ongoing, and completed tasks. You can click on each task box to see more info about the tasks or leave comments for the particular project or issue.</li>
          </ul>
        </div>

       {/* Ticket Info */}
<div className="tpw-ticket-info">
  <div className="tpw-ticket-info-item">
    <p><strong>Ticket ID:</strong> #TC-100</p>
  </div>
  <div className="tpw-ticket-info-item">
    <p><strong>Request Date:</strong> 08/13/2024</p>
  </div>
</div>


        {/* Attachment Section */}
        <div className="tpw-attachment-section">
          <p><strong>Attachment:</strong></p>
          <Avatar
  className="custom-avatar"
  size={64}
  shape="square"
  src="https://www.figma.com/design/SR2xj7zIrUuaUANx7BvSb6/AIO-Solutions?node-id=1117-13738&t=8AhiOTaDLcxRvExu-4"
  alt="Attachment Logo"
/>

        </div>
      </Card>
    </div>
  );
}

export default TicketPreview;
