// TicketsTable.js

import React from 'react';
import tickets from '../SampleTicketsData';
import { UserAvatar } from '../../../Components/UserAvatars2';

// Function to assign a CSS class based on the priority
const getPriorityClass = (priority) => {
  switch (priority) {
    case 'High':
      return 'priority-high';
    case 'Medium':
      return 'priority-medium';
    case 'Low':
      return 'priority-low';
    case 'Critical':
      return 'priority-critical';
    default:
      return '';
  }
};

const TicketsTable = ({ currentPage, rowsPerPage }) => {
  const indexOfLastTicket = currentPage * rowsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - rowsPerPage;
  const currentTickets = tickets.slice(indexOfFirstTicket, indexOfLastTicket);

  return (
    <div className="tk-charts-main-container">
      <div className="main-table-main-container">
        <div className="main-table-container">
          <table className="main-table">
            <thead className="main-table-head">
              <tr className="main-table-head-row">
                <th className="main-table-head-cell">ID</th>
                <th className="main-table-head-cell">Subject</th>
                <th className="main-table-head-cell">Priority</th>
                <th className="main-table-head-cell">Client</th>
                <th className="main-table-head-cell">Date</th>
              </tr>
            </thead>
            <tbody className="main-table-body">
              {currentTickets.map((ticket) => (
                <tr className="main-table-row" key={ticket.id}>
                  <td className="main-table-cell">{ticket.id}</td>
                  <td className="main-table-cell">{ticket.subject}</td>
                  <td className="main-table-cell">
                    <span
                      className={`priority-circle ${getPriorityClass(
                        ticket.priority
                      )}`}
                    ></span>
                    {ticket.priority}
                  </td>
                  <td className="main-table-cell">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <UserAvatar name={ticket.client} className="small-avatar" />
                      <span className="client-name" style={{ marginLeft: '8px' }}>
                        {ticket.client}
                      </span>
                    </div>
                  </td>
                  <td className="main-table-cell">{ticket.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TicketsTable;
