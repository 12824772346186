import React from 'react';
import CustomAvatarGroup from '../../../Components/UserAvatars2';
import AddMemberButton from '../../../Components/AddMemberButton';


const MemberList = ({ members, showModal }) => {
  const defaultMembers = [
    { name: 'Clyde Drexler' }, // Blue
    { name: 'Walt Frazier' }, // Red
    { name: 'Earl Jones', }, // Pink
    { name: 'LeBron James',  }, // Orange
    { name: 'Hakeem Olajuan',  }, // Yellow
  ];

  // Use passed members or default ones
  const teamMembers = members || defaultMembers;

  return (
    <div className="m-member-list-container">
      <div className="m-ticket-info">
        <p className="m-page-title">
          <span className="m-page-title-icon">
            <i className="fi fi-rr-briefcase"></i>
          </span>
          Tickets
        </p>
      </div>

      <div className="m-team-container">
        {/* Left side: Members */}
        <div className="m-team-cell">
          <CustomAvatarGroup members={teamMembers} maxCount={5} />
        </div>

        {/* Right side: Add Member Button */}
        <div className="m-add-member">
          <AddMemberButton  />
        </div>
      </div>
    </div>
  );
};

export default MemberList;
