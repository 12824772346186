// RequestAutomationModal.js

import React from 'react';
import { Form, Input, Select, Button, message } from 'antd';
import DefaultModal from './DefaultModal';
import './Modals.less';

const { TextArea } = Input;
const { Option } = Select;

const RequestAutomationModal = ({ isVisible, toggleModal }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log('Form values:', values);
    message.success('Your automation request has been sent successfully!');
    form.resetFields();
    toggleModal();
  };

  const handleCancel = () => {
    message.info('Automation request has been cancelled.');
    toggleModal();
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()}>
      Send Request
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title="Request New Automation"
      icon="code-pull-request"
      footerContent={footerContent}
      className="request-automation-modal"
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form request-automation-form">
        <Form.Item name="description" label="Automation Description" rules={[{ required: true, message: 'Please enter the automation description' }]}>
          <TextArea placeholder="Enter automation description" rows={4} />
        </Form.Item>
        <Form.Item name="priority" label="Priority Level" rules={[{ required: true, message: 'Please select the priority level' }]}>
          <Select placeholder="Select priority level">
            <Option value="low">Low</Option>
            <Option value="medium">Medium</Option>
            <Option value="high">High</Option>
          </Select>
        </Form.Item>
        <Form.Item name="notes" label="Additional Notes">
          <TextArea placeholder="Enter any additional notes" rows={4} />
        </Form.Item>
      </Form>
    </DefaultModal>
  );
};

export default RequestAutomationModal;
