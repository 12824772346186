import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';


const TicketsSort = ({ rowsPerPage, setRowsPerPage }) => {
  const [visibleDate, setVisibleDate] = useState(false);
  const [visibleType, setVisibleType] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility

  const handleMenuClick = (e) => {
    console.log('Sorting option selected:', e.key);
  };

  const sortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="asc">Ascending</Menu.Item>
      <Menu.Item key="desc">Descending</Menu.Item>
    </Menu>
  );

  const rowsMenu = (
    <Menu onClick={(e) => setRowsPerPage(Number(e.key))}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
      <Menu.Item key="50">50</Menu.Item>
      <Menu.Item key="100">100</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="ttp-sort-container">
      <div className="ttp-sort-options-row">
        <div className="ttp-sort-buttons">
          <span className="ttp-sort-text">Sort by: </span>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleDate}
            onVisibleChange={setVisibleDate}
          >
            <Button>
              Date <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleType}
            onVisibleChange={setVisibleType}
          >
            <Button>
              Ticket Type <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <div className="ttp-add-client-row">
            <div className="ttp-rows-text">Rows: </div>
            <Dropdown overlay={rowsMenu} trigger={['click']}>
              <Button className="ttp-rows-button">
                {rowsPerPage} <i className="fi fi-rr-angle-small-down"></i>
              </Button>
            </Dropdown>
          </div>
        </div>
        
      </div>

      
    </div>
  );
};

export default TicketsSort;
