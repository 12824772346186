// AutomationstableColumns/AutomationsTableSort.js

import React, { useState } from 'react';
import { Button, Dropdown, Menu, Modal, Select } from 'antd';
import RequestAutomationModal from '../../../Modals/RequestAutomationModal'; // Import the new RequestAutomationModal

const { Option } = Select;

const AutomationsTableSort = ({ rowsPerPage, setRowsPerPage }) => {
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visibleExecutions, setVisibleExecutions] = useState(false);
  const [visibleCreatedDate, setVisibleCreatedDate] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMenuClick = (e) => {
    console.log('Sorting option selected:', e.key);
  };

  const sortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="asc">Ascending</Menu.Item>
      <Menu.Item key="desc">Descending</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="at-sort-container">
      <div className="at-sort-options-row">
        <div className="at-sort-buttons">
          <span className="at-sort-text">Sort: </span>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleStatus}
            onVisibleChange={setVisibleStatus}
          >
            <Button>
              Status <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleExecutions}
            onVisibleChange={setVisibleExecutions}
          >
            <Button>
              Executions <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleCreatedDate}
            onVisibleChange={setVisibleCreatedDate}
          >
            <Button>
              Date Created <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>

          <div className="at-add-automation-row">
            <div className="at-rows-text">Rows: </div>
            <Select
              defaultValue={rowsPerPage}
              style={{ width: 60 }}
              onChange={(value) => setRowsPerPage(value)}
              className="at-rows-button"
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
            </Select>
          </div>
        </div>
        <div className="at-rows-options">
          <Button className="button-1" onClick={showModal}>Request Automation</Button>
          <RequestAutomationModal
            isVisible={isModalVisible}
            toggleModal={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default AutomationsTableSort;
