// src/Data/ProjectsTableSampleData.js

const tasks = [
  {
    taskId: 1,
    taskName: 'Design Homepage',
    subtasks: [
      { subtaskId: 11, taskName: 'Create wireframe', assignee: 'Sazzad Mahmud', status: 'Completed', dueDate: '2024-06-10', timeSpent: '04:00' },
      { subtaskId: 12, taskName: 'Design mockup', assignee: 'Mayowa B.', status: 'Completed', dueDate: '2024-06-12', timeSpent: '06:00' }
    ],
    assignee: 'Noman Hossain',
    status: 'In Progress',
    dueDate: '2024-06-15',
    timeSpent: '07:25',
    projectManager: 'Sazzad Mahmud',
    priority: 'Low'
  },
  {
    taskId: 2,
    taskName: 'Create new Glo Tanning Campaign Strategy & Research new location slogan ideas',
    subtasks: [
      { subtaskId: 21, taskName: 'Convert mockup to HTML/CSS', assignee: 'Kobe Bryant', status: 'Completed', dueDate: '2024-06-17', timeSpent: '00:00' },
      { subtaskId: 22, taskName: 'Implement animations', assignee: 'Malcolm X', status: 'Not Started', dueDate: '2024-06-19', timeSpent: '00:00' }
    ],
    assignee: 'Noman Hossain',
    status: 'Not Started',
    dueDate: '2024-06-20',
    timeSpent: '01:05',
    projectManager: 'Sazzad Mahmud',
    priority: 'Medium'
  },
  {
    taskId: 3,
    taskName: 'Content Creation',
    subtasks: [
      { subtaskId: 31, taskName: 'Write homepage content', assignee: 'Mother Theresa', status: 'Completed', dueDate: '2024-06-11', timeSpent: '05:00' },
      { subtaskId: 32, taskName: 'Write about us page content', assignee: 'Martin Luther King', status: 'In Progress', dueDate: '2024-06-14', timeSpent: '02:00' }
    ],
    assignee: 'Sazzad Mahmud',
    status: 'Completed',
    dueDate: '2024-06-18',
    timeSpent: '00:50',
    projectManager: 'Sazzad Mahmud',
    priority: 'Low'
  },
  {
    taskId: 4,
    taskName: 'SEO Optimization',
    subtasks: [
      { subtaskId: 41, taskName: 'Keyword research', assignee: 'Sazzad Mahmud', status: 'Completed', dueDate: '2024-06-09', timeSpent: '03:00' },
      { subtaskId: 42, taskName: 'Optimize meta tags', assignee: 'Mayowa B.', status: 'In Progress', dueDate: '2024-06-13', timeSpent: '02:00' }
    ],
    assignee: 'Noman Hossain',
    status: 'In Progress',
    dueDate: '2024-06-19',
    timeSpent: '02:30',
    projectManager: 'Sazzad Mahmud',
    priority: 'Urgent'
  },
  {
    taskId: 5,
    taskName: 'Social Media Marketing',
    subtasks: [
      { subtaskId: 51, taskName: 'Create social media plan', assignee: 'Martin Luther King', status: 'Completed', dueDate: '2024-06-08', timeSpent: '04:00' },
      { subtaskId: 52, taskName: 'Schedule posts', assignee: 'Mother Theresa', status: 'Completed', dueDate: '2024-06-16', timeSpent: '00:00' }
    ],
    assignee: 'Kobe Bryant',
    status: 'In Progress',
    dueDate: '2024-06-21',
    timeSpent: '03:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'High'
  },
  {
    taskId: 6,
    taskName: 'Redesign Landing Page',
    subtasks: [
      { subtaskId: 61, taskName: 'Gather requirements', assignee: 'Noman Hossain', status: 'Completed', dueDate: '2024-06-10', timeSpent: '03:00' },
      { subtaskId: 62, taskName: 'Create initial design', assignee: 'Michael Jordan', status: 'On Hold', dueDate: '2024-06-15', timeSpent: '05:00' },
      { subtaskId: 63, taskName: 'Revise design based on feedback', assignee: 'Afaq Ahmed', status: 'Not Started', dueDate: '2024-06-18', timeSpent: '00:00' }
    ],
    assignee: 'Noman Hossain',
    status: 'In Review',
    dueDate: '2024-06-25',
    timeSpent: '3:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'Medium'
  },
  {
    taskId: 7,
    taskName: 'Develop Mobile App',
    subtasks: [
      { subtaskId: 71, taskName: 'Setup project structure', assignee: 'Kobe Bryant', status: 'Completed', dueDate: '2024-06-05', timeSpent: '04:00' },
      { subtaskId: 72, taskName: 'Develop authentication module', assignee: 'Sazzad Mahmud', status: 'In Progress', dueDate: '2024-06-12', timeSpent: '06:00' }
    ],
    assignee: 'Michael Jordan',
    status: 'Completed',
    dueDate: '2024-06-22',
    timeSpent: '2:15',
    projectManager: 'Sazzad Mahmud',
    priority: 'Low'
  },
  {
    taskId: 8,
    taskName: 'Optimize Website Performance',
    subtasks: [
      { subtaskId: 81, taskName: 'Analyze current performance', assignee: 'Noman Hossain', status: 'Completed', dueDate: '2024-06-08', timeSpent: '03:00' },
      { subtaskId: 82, taskName: 'Implement caching strategies', assignee: 'Afaq Ahmed', status: 'In Progress', dueDate: '2024-06-11', timeSpent: '05:00' },
      { subtaskId: 83, taskName: 'Optimize images', assignee: 'Michael Jordan', status: 'Not Started', dueDate: '2024-06-15', timeSpent: '00:00' },
      { subtaskId: 84, taskName: 'Minify CSS and JS files', assignee: 'Kobe Bryant', status: 'Not Started', dueDate: '2024-06-18', timeSpent: '00:00' }
    ],
    assignee: 'Afaq Ahmed',
    status: 'In Progress',
    dueDate: '2024-06-20',
    timeSpent: '08:20',
    projectManager: 'Sazzad Mahmud',
    priority: 'Urgent'
  },
  {
    taskId: 9,
    taskName: 'Setup CI/CD Pipeline',
    subtasks: [
      { subtaskId: 91, taskName: 'Configure build tools', assignee: 'Sazzad Mahmud', status: 'Completed', dueDate: '2024-06-06', timeSpent: '04:00' },
      { subtaskId: 92, taskName: 'Setup deployment scripts', assignee: 'Noman Hossain', status: 'In Progress', dueDate: '2024-06-13', timeSpent: '06:00' }
    ],
    assignee: 'Afaq Ahmed',
    status: 'In Progress',
    dueDate: '2024-06-21',
    timeSpent: '4:30',
    projectManager: 'Sazzad Mahmud',
    priority: 'Medium'
  },
  {
    taskId: 10,
    taskName: 'Conduct Usability Testing',
    subtasks: [
      { subtaskId: 101, taskName: 'Prepare test scenarios', assignee: 'Michael Jordan', status: 'Completed', dueDate: '2024-06-07', timeSpent: '03:00' },
      { subtaskId: 102, taskName: 'Recruit test participants', assignee: 'Afaq Ahmed', status: 'Completed', dueDate: '2024-06-10', timeSpent: '05:00' },
      { subtaskId: 103, taskName: 'Conduct testing sessions', assignee: 'Sazzad Mahmud', status: 'Not Started', dueDate: '2024-06-13', timeSpent: '00:00' },
      { subtaskId: 104, taskName: 'Analyze test results', assignee: 'Noman Hossain', status: 'Completed', dueDate: '2024-06-16', timeSpent: '00:00' },
      { subtaskId: 105, taskName: 'Report findings and recommendations', assignee: 'Kobe Bryant', status: 'Not Started', dueDate: '2024-06-19', timeSpent: '00:00' }
    ],
    assignee: 'Afaq Ahmed',
    status: 'In Progress',
    dueDate: '2024-06-23',
    timeSpent: '08:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'High'
  },
  {
    taskId: 11,
    taskName: 'Create Marketing Plan',
    subtasks: [],
    assignee: 'Noman Hossain',
    status: 'Not Started',
    dueDate: '2024-07-01',
    timeSpent: '00:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'Medium'
  },
  {
    taskId: 12,
    taskName: 'Develop Backend API',
    subtasks: [
      { subtaskId: 121, taskName: 'Setup database schema', assignee: 'Michael Jordan', status: 'Completed', dueDate: '2024-06-25', timeSpent: '04:00' },
      { subtaskId: 122, taskName: 'Implement authentication', assignee: 'Afaq Ahmed', status: 'In Progress', dueDate: '2024-06-28', timeSpent: '06:00' },
      { subtaskId: 123, taskName: 'Write API documentation', assignee: 'Sazzad Mahmud', status: 'Not Started', dueDate: '2024-07-05', timeSpent: '00:00' }
    ],
    assignee: 'Michael Jordan',
    status: 'Completed',
    dueDate: '2024-07-10',
    timeSpent: '10:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'Urgent'
  },
  {
    taskId: 13,
    taskName: 'Run Security Audit',
    subtasks: [
      { subtaskId: 131, taskName: 'Identify vulnerabilities', assignee: 'Noman Hossain', status: 'Completed', dueDate: '2024-06-20', timeSpent: '03:00' },
      { subtaskId: 132, taskName: 'Implement security patches', assignee: 'Kobe Bryant', status: 'In Progress', dueDate: '2024-06-22', timeSpent: '05:00' }
    ],
    assignee: 'Sazzad Mahmud',
    status: 'In Review',
    dueDate: '2024-06-30',
    timeSpent: '08:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'High'
  },
  {
    taskId: 14,
    taskName: 'Setup Analytics Dashboard',
    subtasks: [],
    assignee: 'Afaq Ahmed',
    status: 'Not Started',
    dueDate: '2024-07-05',
    timeSpent: '00:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'Medium'
  },
  {
    taskId: 15,
    taskName: 'Implement CI/CD Pipeline',
    subtasks: [
      { subtaskId: 151, taskName: 'Configure build automation', assignee: 'Mayowa B.', status: 'Completed', dueDate: '2024-06-15', timeSpent: '04:00' },
      { subtaskId: 152, taskName: 'Setup deployment pipeline', assignee: 'Michael Jordan', status: 'On Hold', dueDate: '2024-06-18', timeSpent: '06:00' }
    ],
    assignee: 'Noman Hossain',
    status: 'In Progress',
    dueDate: '2024-06-25',
    timeSpent: '10:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'Urgent'
  },
  {
    taskId: 16,
    taskName: 'Design User Interface',
    subtasks: [
      { subtaskId: 161, taskName: 'Create wireframes', assignee: 'Afaq Ahmed', status: 'Completed', dueDate: '2024-06-10', timeSpent: '05:00' },
      { subtaskId: 162, taskName: 'Design mockups', assignee: 'Noman Hossain', status: 'Completed', dueDate: '2024-06-15', timeSpent: '08:00' },
      { subtaskId: 163, taskName: 'Review with stakeholders', assignee: 'Sazzad Mahmud', status: 'Not Started', dueDate: '2024-06-20', timeSpent: '00:00' }
    ],
    assignee: 'Michael Jordan',
    status: 'In Progress',
    dueDate: '2024-06-30',
    timeSpent: '1:30',
    projectManager: 'Sazzad Mahmud',
    priority: 'Medium'
  },
  {
    taskId: 17,
    taskName: 'Write Technical Documentation',
    subtasks: [
      { subtaskId: 171, taskName: 'API documentation', assignee: 'Sazzad Mahmud', status: 'Completed', dueDate: '2024-06-08', timeSpent: '04:00' },
      { subtaskId: 172, taskName: 'User guides', assignee: 'Mayowa B.', status: 'On Hold', dueDate: '2024-06-12', timeSpent: '06:00' },
      { subtaskId: 173, taskName: 'Developer tutorials', assignee: 'Michael Jordan', status: 'Not Started', dueDate: '2024-06-15', timeSpent: '00:00' }
    ],
    assignee: 'Afaq Ahmed',
    status: 'In Progress',
    dueDate: '2024-06-20',
    timeSpent: '3:30',
    projectManager: 'Sazzad Mahmud',
    priority: 'Low'
  },
  {
    taskId: 18,
    taskName: 'Optimize SEO',
    subtasks: [],
    assignee: 'Kobe Bryant',
    status: 'Not Started',
    dueDate: '2024-07-01',
    timeSpent: '00:00',
    projectManager: 'Sazzad Mahmud',
    priority: 'Low'
  },
  {
    taskId: 19,
    taskName: 'Perform Code Review',
    subtasks: [
      { subtaskId: 191, taskName: 'Review frontend code', assignee: 'Afaq Ahmed', status: 'Completed', dueDate: '2024-06-10', timeSpent: '03:00' },
      { subtaskId: 192, taskName: 'Review backend code', assignee: 'Noman Hossain', status: 'In Progress', dueDate: '2024-06-15', timeSpent: '04:00' },
      { subtaskId: 193, taskName: 'Review database code', assignee: 'Sazzad Mahmud', status: 'Not Started', dueDate: '2024-06-18', timeSpent: '00:00' }
    ],
    assignee: 'Michael Jordan',
    status: 'In Review',
    dueDate: '2024-06-25',
    timeSpent: '07:10',
    projectManager: 'Sazzad Mahmud',
    priority: 'High'
  },
  {
    taskId: 20,
    taskName: 'Launch Product',
    subtasks: [
      { subtaskId: 201, taskName: 'Prepare release notes', assignee: 'Kobe Bryant', status: 'Completed', dueDate: '2024-06-10', timeSpent: '02:00' },
      { subtaskId: 202, taskName: 'Deploy to production', assignee: 'Michael Jordan', status: 'Completed', dueDate: '2024-06-12', timeSpent: '03:00' },
      { subtaskId: 203, taskName: 'Monitor post-launch', assignee: 'Sazzad Mahmud', status: 'Not Started', dueDate: '2024-06-15', timeSpent: '00:00' }
    ],
    assignee: 'Afaq Ahmed',
    status: 'In Review',
    dueDate: '2024-06-20',
    timeSpent: '03:30',
    projectManager: 'Sazzad Mahmud',
    priority: 'Urgent'
  }
];

export default tasks;
