// ClientChatReactions.js
import React from 'react';
import { Tooltip } from 'antd';
import { SmileOutlined, CheckOutlined, LikeOutlined, EyeOutlined, FireOutlined } from '@ant-design/icons';


const reactionEmojis = {
  check: '✅',
  thumbsUp: '👍',
  eyes: '👀',
  celebrate: '🎉',
  laugh: '😂',
};

const reactionIcons = [
  { key: 'check', icon: <CheckOutlined /> },
  { key: 'thumbsUp', icon: <LikeOutlined /> },
  { key: 'eyes', icon: <EyeOutlined /> },
  { key: 'celebrate', icon: <FireOutlined /> }, // You can choose appropriate icons
  { key: 'laugh', icon: <SmileOutlined /> },
];

const ClientChatReactions = ({ reactions, onAddReaction }) => {
  return (
    <div className="ct-chat-reactions-container">
      {Object.keys(reactions).map((reactionKey) => (
        <Tooltip
          key={reactionKey}
          title={reactions[reactionKey].join(', ')} // Show names of users who reacted
        >
          <span className="ct-chat-reaction">
            {reactionEmojis[reactionKey]} {reactions[reactionKey].length}
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default ClientChatReactions;
