import React from 'react';


const AccountPlan = () => {
  return (
    <div className="ap-plan-container">
      <div className="ap-plan-title-container">
        <h2>Choose Your Plan</h2>
        <div className="ap-plan-text">At All-In-One Solutions, we are currently in the Beta phase and are pleased to offer our new clients exclusive, low-cost packages. While our software is still being refined, you can take advantage of premium features at a significantly reduced rate. If you have any questions or need assistance, please don't hesitate to reach out to us.</div>
      </div>
      <div className="ap-plan-cards">
        <div className="ap-plan-card ap-plan-card-free">
          <div className="ap-card-header">
            <div className="ap-plan-graphic">
              <img src="https://i.ibb.co/qRTtS5p/Starter.png" alt="Artboard-2-copy-4-2x-8" border="0" />

            </div>

            <div className="ap-plan-name">Starter</div>
            <p className="ap-price">Free Forever!</p>
            <button className="ap-select-button">Start Free</button>
          </div>
          <div className="ap-card-body">
            <ul className="ap-features-list">
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Project Management</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Client Management </li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Tasks Data Visualization</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Team Tasks Monitoring</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Approvals</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Advanced Searching</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Up to 2 Team Members</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> 10 Active Clients</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> 20 Active Projects</li>
            </ul>
          </div>
        </div>
        <div className="ap-plan-card ap-plan-card-business">
          <div className="ap-card-header">
            <div className="ap-plan-graphic">
              <img src="https://i.ibb.co/nQbw3fH/Business.png" alt="Artboard-2-copy-4-2x-8" border="0" />

            </div>
            <div className="ap-plan-name">Business</div>
            <p className="ap-price">$15/User/Month</p>
            <button className="ap-select-button">Get Started</button>
          </div>
          <div className="ap-card-body">
            <ul className="ap-features-list">
              <li className="ap-everything-plus"> Everything in Starter, plus:</li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Kanban View <span className="lite-text"> (Coming Soon)</span> </li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Gantt View <span className="lite-text"> (Coming Soon)</span> </li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Calendar View <span className="lite-text"> (Coming Soon)</span> </li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> 50 Active Clients </li>
              <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> 100 Active Projects </li>
            </ul>
          </div>
        </div>
      </div>


      <div className="ap-plan-addons-container">
        <h2>Hours Topup</h2>

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-box-open"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>40 Hours Package</h3>
            <p>This is our minimum Hours Package to Topup.</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <p className="ap-addon-price">$400</p>
              <button className="ap-addon-button">Purchase Today</button>
            </div>
          </div>
        </div>

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-box-open"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>60 Hours Package</h3>
            <p>Has a big load of work ! You can have this</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <p className="ap-addon-price">$600</p>
              <button className="ap-addon-button">Purchase Today</button>
            </div>
          </div>
        </div>
      </div>

      <div className="ap-plan-addons-container">
        <h2>Addons</h2>

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-box-open"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>White-Label</h3>
            <p>Our software, your brand’s name! White-labeled and hosted on your domain, it's the perfect way to serve your clients.</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <p className="ap-addon-price">$100/month</p>
              <button className="ap-addon-button">Request White-Label</button>
            </div>
          </div>
        </div>

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-user-robot"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>Managed Automations</h3>
            <p>Sit back and relax while we manage your automations! From setup to maintenance, we do it all. Prices vary by task. Get in touch to get started!</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <button className="ap-addon-button">Request Automation</button>
            </div>
          </div>
        </div>
      </div>








    </div>
  );
};

export default AccountPlan;
