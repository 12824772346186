// CustomEvent.js (You can place this in the same file or create a new file)

import React from 'react';
import SingleAvatar2 from '../../../Components/SingleAvatar2';

const CustomEvent = ({ event }) => {
  return (
    <div className="custom-event">
      <span className="event-title">{event.title}</span>
      <SingleAvatar2 name={event.resource} size={24} fontSize={12} />
    </div>
  );
};

export default CustomEvent;
