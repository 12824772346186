// ProjectOverview.js

import React, { useState, useEffect } from 'react';
import { Layout, Button } from 'antd';
import AddSingleCard from './ProjectOverviewComponents/AddSingleCard';
import UserAvatarsGrid from '../../Components/UserAvatars/UserAvatarsGrid';
import ManageTeamModal from '../../Modals/ManageTeamModal';
import { projects, teamMembers as allTeamMembers, clientsData } from '../../Data/ProjectsSampleData';
import '../../custom.less';
import ProjectOverviewTasksBar from './ProjectOverviewComponents/ProjectOverviewTasksBar';
import ProjectOverviewChat from './ProjectOverviewComponents/ProjectOverviewChat'; // Import the chat component

import ProjectOverviewChart from './ProjectOverviewComponents/ProjectOverviewChart';
import ProjectOverviewStatusChart from './ProjectOverviewComponents/ProjectOverviewStatusChart';




const { Content } = Layout;

const ProjectOverview = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tasksSummary, setTasksSummary] = useState({
    notStarted: 3,
    inProgress: 5,
    completed: 2,
  });

  useEffect(() => {
    // Find the project with ID: 1
    const project = projects.find((project) => project.id === 1); // Assuming project ID is 1

    if (project) {
      const members = project.members.map((memberId) => {
        const member = allTeamMembers.find((member) => member.id === memberId);
        return member;
      });
      setTeamMembers(members);
      setSelectedProject(project);
    }
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="pmt-overview-main-container">



      <Content className="project-container">



        <div className="pmt-project-first-container">
          {/* Column 1: Project Overview and Cards */}
          <div className="pmt-project-overview-column">
            <div className="pmt-overview-column-box">
              <AddSingleCard />

              <div className="pmt-overview-header">
                <div className="pmt-overview-header-created-last-updated-container">
                  <div className="pmt-overview-header-info-item">
                    <div className="pmt-overview-header-icon-circle">
                      <i className="fi fi-rr-calendar"></i>
                    </div>
                    <div className="pmt-overview-header-info-text">
                      <div className="pmt-overview-header-label">Created:</div>
                      <div className="pmt-overview-header-datetime">Jul 17, 2024 | 2:30pm</div>
                    </div>
                  </div>
                  <div className="pmt-overview-header-info-item">
                    <div className="pmt-overview-header-icon-circle">
                      <i className="fi fi-rr-clock"></i>
                    </div>
                    <div className="pmt-overview-header-info-text">
                      <div className="pmt-overview-header-label">Last Update:</div>
                      <div className="pmt-overview-header-datetime">Jul 17, 2024 | 2:30pm</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Column 2: Team Members and Task Summary */}
          <div style={{ flex: 2 }}>
            <div className="whitebox">
              <h3>Team</h3>
              <UserAvatarsGrid
                members={teamMembers}
                project={selectedProject}
                handleAddMember={showModal}
              />
            </div>

            <div className="pmt-overview-comments-container">

                    <ProjectOverviewChat />

            </div>
          </div>
        </div>
      </Content>

      {/* Full-width Chat Component */}
      <div className="pmt-overview-chart-container">
      <div className="whitebox">
      <div className="whitebox-title">
      <h3>Progress Chart </h3>
      <Button className="button-3"> <i class="fi fi-rr-settings-sliders"></i> </Button>
      </div>
      <div className="pmt-overview-chart-row">
        <div className="pmt-overview-stacked-chart-container">
        <p> Progress Over Time </p>
          <ProjectOverviewChart />
          </div>
          <div className="pmt-overview-pie-chart-container">
          <p> Current Task Statuses </p>
          <ProjectOverviewStatusChart />
          </div>
</div>


          {/* Legend Component */}
          <div className="pmt-overview-chart-legend">
            <div className="pmt-legend-item">
              <span
                className="pmt-legend-circle"
                style={{ backgroundColor: '#cbd5e1' }}
              ></span>
              <span className="pmt-legend-text">Not Started</span>
            </div>
            <div className="pmt-legend-item">
              <span
                className="pmt-legend-circle"
                style={{ backgroundColor: '#73A6D4' }}
              ></span>
              <span className="pmt-legend-text">In Progress</span>
            </div>
            <div className="pmt-legend-item">
              <span
                className="pmt-legend-circle"
                style={{ backgroundColor: '#E0607E' }}
              ></span>
              <span className="pmt-legend-text">In Review</span>
            </div>
            <div className="pmt-legend-item">
              <span
                className="pmt-legend-circle"
                style={{ backgroundColor: '#3FDE7A' }}
              ></span>
              <span className="pmt-legend-text">Completed</span>
            </div>
          </div>







      </div>
      </div>

      <ManageTeamModal
        isVisible={isModalVisible}
        selectedProject={selectedProject}
        toggleModal={handleCancel}
        teamMembers={allTeamMembers}
        clientsData={clientsData}
      />
    </div>
  );
};

export default ProjectOverview;
