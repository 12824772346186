import React from 'react';
import { Button } from 'antd';

const GanttControls = ({ activeScale, changeScale }) => {
  return (
    <div className="gt-gantt-controls">
    <div className="gt-gantt-controls-left">
      <button
        className={`gt-gantt-date-filter-button gt-dfb-1 ${activeScale === 'day' ? 'gt-gantt-date-filter-button-active' : 'gt-gantt-date-filter-button-inactive'}`}
        onClick={() => changeScale('day')}
      >
        Day View
      </button>
      <button
        className={`gt-gantt-date-filter-button gt-dfb-2 ${activeScale === 'week' ? 'gt-gantt-date-filter-button-active' : 'gt-gantt-date-filter-button-inactive'}`}
        onClick={() => changeScale('week')}
      >
        Week View
      </button>
      <button
        className={`gt-gantt-date-filter-button gt-dfb-3 ${activeScale === 'month' ? 'gt-gantt-date-filter-button-active' : 'gt-gantt-date-filter-button-inactive'}`}
        onClick={() => changeScale('month')}
      >
        Month View
      </button>
      </div>
      <div className="gt-gantt-controls-right">
      <Button  className="pmt-header-add-task-button">
        <i className="fi fi-rr-plus"></i> Add Task
      </Button>
      </div>
    </div>
  );
};

export default GanttControls;
