// Text.js

import React from 'react';

const Text = () => {
  return (
    <div className="internal-text-section">
    <h3> Text </h3>
      <p className="header">Header (className="header")</p>
      <p className="subheader">Subheader Text (className="subheader")</p>
      <p>Paragraph text (no className)</p>
      <p className="lite-text">Lite Text (className="lite-text")</p>
    </div>
  );
};

export default Text;
