// ClientChatTextBox.js
import React, { useState } from 'react';
import { Button, Upload, message } from 'antd';

import TextEditor from '../../../Components/TextEditor'; // Import TextEditor

const ClientChatTextBox = ({ onSendMessage }) => {
  const [newMessage, setNewMessage] = useState('');

  // Function to handle message sending
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      onSendMessage(newMessage);
      setNewMessage(''); // Clear the editor after sending
    } else {
      message.warning('Please enter a message before sending.');
    }
  };

  // Function to handle file uploads
  const handleUpload = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <div className="ct-chat-textbox-container">
      {/* TextEditor Component */}
      <TextEditor value={newMessage} onChange={setNewMessage} />

      {/* Upload and Send Buttons */}
    <div className="ct-chat-send-button-container">
      <Button className="ct-chat-send-button">
        <i class="fi fi-rr-paper-plane"></i>Send
      </Button>
      </div>
    </div>
  );
};

export default ClientChatTextBox;
