// ProjectOverviewChart.js

import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { taskStatusData } from './ProjectOverviewChartData';

const ProjectOverviewChart = () => {
  return (
    <div className="pmt-project-overview-chart-container">
      <ResponsiveContainer width="100%" height={240}>
        <AreaChart
          data={taskStatusData}
          margin={{ top: 0, right: 16, left: 0, bottom: 0 }}
          className="pmt-area-chart"
          stackOffset="expand" // This normalizes the data to percentages
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            className="pmt-x-axis"
            tickFormatter={(dateStr) => {
              // Format date to 'DD', or 'DD/MM' on the first of the month
              const date = new Date(dateStr);
              const day = date.getDate();
              const month = date.getMonth() + 1;
              if (day === 1) {
                return `${day}/${month}`;
              } else {
                return `${day}`;
              }
            }}
          />
          <YAxis
            className="pmt-y-axis"
            tickFormatter={(value) => `${(value * 100).toFixed(0)}%`}
          />
          <Tooltip
            className="pmt-tooltip"
            formatter={(value, name) => [`${(value * 100).toFixed(2)}%`, name]}
          />
          <Area
            type="linear" // Straight lines
            dataKey="notStarted"
            stackId="1"
            stroke="#cbd5e1"
            fill="#cbd5e1"
            fillOpacity={1} // Full opacity
            name="Not Started"
          />
          <Area
            type="linear"
            dataKey="inProgress"
            stackId="1"
            stroke="#73A6D4"
            fill="#73A6D4"
            fillOpacity={1}
            name="In Progress"
          />
          <Area
            type="linear"
            dataKey="inReview"
            stackId="1"
            stroke="#E0607E"
            fill="#E0607E"
            fillOpacity={1}
            name="In Review"
          />
          <Area
            type="linear"
            dataKey="completed"
            stackId="1"
            stroke="#3FDE7A"
            fill="#3FDE7A"
            fillOpacity={1}
            name="Completed"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProjectOverviewChart;
