// AddTeamMemberModal.js

import React from 'react';
import { Form, Input, Checkbox, Button, message } from 'antd';
import DefaultModal from './DefaultModal';
import './Modals.less';

const AddTeamMemberModal = ({ isVisible, toggleModal }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log('Team member details:', values);
    message.success('Your invite has been sent!');
    form.resetFields();
    toggleModal();
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
      Cancel
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()}>
      Send Invite
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title="Add Team Member"
      icon="user-add"
      footerContent={footerContent}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form add-team-member-form">
        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the team member\'s name' }]}>
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter the team member\'s email' }]}>
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item name="role" label="Role">
          <Input placeholder="Enter role" />
        </Form.Item>
        <Form.Item name="permissions" label="Permissions">
          <Checkbox.Group>
            <Checkbox value="general">General Team Member</Checkbox>
            <Checkbox value="admin">Admin</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </DefaultModal>
  );
};

export default AddTeamMemberModal;
