import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const RowOptions = () => {
  const [visible, setVisible] = useState(false);

  const handleMenuClick = () => {
    setVisible(false);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Link to='/work/individual-project'>
        <Menu.Item key="1">View Project</Menu.Item>
      </Link>
      <Menu.Item key="2">Settings</Menu.Item>
    </Menu>
  );

  return (
    <div className="row-options-container">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <div className="row-options">
          <i className="fi fi-br-menu-dots-vertical"></i>
        </div>
      </Dropdown>
    </div>
  );
};

export default RowOptions;
