// Table.js

import React from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './InternalPages.less'; 

const Table = () => {
  // Sample columns and data
  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    // Add more columns as needed
  ];

  const sampleData = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Smith' },
    // Add more data as needed
  ];

  // Copy the table code to clipboard
  const copyToClipboard = () => {
    const codeToCopy = `
<div className="main-table-main-container">
  <div className="main-table-container">
    <table className="main-table">
      <thead className="main-table-head">
        <tr className="main-table-head-row">
          <th className="main-table-head-cell">ID</th>
          <th className="main-table-head-cell">Name</th>
        </tr>
      </thead>
      <tbody className="main-table-body">
        <tr className="main-table-row">
          <td className="main-table-cell">1</td>
          <td className="main-table-cell">John Doe</td>
        </tr>
        <tr className="main-table-row">
          <td className="main-table-cell">2</td>
          <td className="main-table-cell">Jane Smith</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
    `;
    navigator.clipboard.writeText(codeToCopy).then(
      () => message.success('Code copied to clipboard!'),
      () => message.error('Failed to copy code.')
    );
  };

  return (
    <div className="section-container">
    <h3> Table </h3>
      <div className="section-and-code">
        {/* Table Display */}
        <div className="main-table-main-container">
          <div className="main-table-container">
            <table className="main-table">
              <thead className="main-table-head">
                <tr className="main-table-head-row">
                  {columns.map((column) => (
                    <th key={column.accessor} className="main-table-head-cell">
                      {column.Header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="main-table-body">
                {sampleData.map((row) => (
                  <tr key={row.id} className="main-table-row">
                    <td className="main-table-cell">{row.id}</td>
                    <td className="main-table-cell">{row.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Code Display with Copy Button */}
        <div className="code-container">
        <Button
          icon={<CopyOutlined />}
          onClick={copyToClipboard}
          className="copy-code-button"
        >
          Copy Code
        </Button>
          <pre className="code-snippet">
{`
<div className="main-table-main-container">
  <div className="main-table-container">
    <table className="main-table">
      <thead className="main-table-head">
        <tr className="main-table-head-row">
          <th className="main-table-head-cell">ID</th>
          <th className="main-table-head-cell">Name</th>
        </tr>
      </thead>
      <tbody className="main-table-body">
        <tr className="main-table-row">
          <td className="main-table-cell">1</td>
          <td className="main-table-cell">John Doe</td>
        </tr>
        <tr className="main-table-row">
          <td className="main-table-cell">2</td>
          <td className="main-table-cell">Jane Smith</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
`}
          </pre>

        </div>
      </div>
    </div>
  );
};

export default Table;
