import Gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

const initGantt = (tasks) => {
  Gantt.config.xml_date = "%Y-%m-%d"; // Expect dates in this format

  Gantt.config.columns = [
    { name: "text", label: "Tasks", width: "*", tree: true },
  ];

  // Customize the class for the top header row
  Gantt.templates.scale_cell_class = function(date) {
    return "gt-gantt-header-top-row"; // Apply this class to the top row
  };

  // Hardcoded class names based on task status
  Gantt.templates.task_class = function (start, end, task) {
    if (task.status === "completed") {
      return "gt-task-completed";
    } else if (task.status === "not started") {
      return "gt-task-not-started";
    } else if (task.status === "in progress") {
      return "gt-task-in-progress";
    } else if (task.status === "in review") {
      return "gt-task-in-review";
    }
    return ""; // Default case, no additional class
  };

  // Add an event listener to log task IDs when clicked (for debugging)
  Gantt.attachEvent("onTaskClick", function(id) {
    console.log("Clicked task ID:", id);
    if (!Gantt.getTask(id)) {
      console.error(`Task not found id=${id}`);
    }
    return true;
  });

  Gantt.init('gantt_here');
  Gantt.parse({ data: tasks, links: [] });
};

const clearGantt = () => {
  Gantt.clearAll();
};

const setScaleConfig = (scale) => {
  switch (scale) {
    case 'day':
      Gantt.config.scale_unit = 'day';
      Gantt.config.date_scale = '%d %M %Y';
      Gantt.config.subscales = [{ unit: 'hour', step: 1, date: '%H:%i' }];
      Gantt.config.scale_height = 60;
      break;
    case 'week':
      Gantt.config.scale_unit = 'week';
      Gantt.config.date_scale = 'Week #%W %Y';
      Gantt.config.subscales = [{ unit: 'day', step: 1, date: '%d %M' }];
      Gantt.config.scale_height = 60;
      break;
    case 'month':
      Gantt.config.scale_unit = 'month';
      Gantt.config.date_scale = '%F %Y';
      Gantt.config.subscales = [{ unit: 'week', step: 1, date: 'Week #%W' }];
      Gantt.config.scale_height = 60;
      break;
    default:
      break;
  }
  Gantt.render();
};

export { initGantt, clearGantt, setScaleConfig };
