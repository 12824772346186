import React from 'react';
import { DndContext, DragOverlay, closestCorners } from '@dnd-kit/core';
import { useKanbanLogic } from './KanbanLogic';
import KanbanColumn from './KanbanComponents/KanbanColumn';
import KanbanTask from './KanbanComponents/KanbanTask';
import './Kanban.less';

function Kanban() {
  const { columns, activeId, sensors, handleDragStart, handleDragEnd, handleDragCancel } = useKanbanLogic();

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <div className="kb-main-container">
        {Object.keys(columns).map((columnId) => (
          <KanbanColumn key={columnId} id={columnId} items={columns[columnId]} />
        ))}
      </div>
      <DragOverlay>
        {activeId ? <KanbanTask id={activeId} /> : null}
      </DragOverlay>
    </DndContext>
  );
}

export default Kanban;
