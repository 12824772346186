// AutomationstableColumns/NameColumn.js

import React from 'react';
import { createAvatar } from '@dicebear/core';
import * as collection from '@dicebear/collection';

const NameColumn = {
  title: 'Name',
  key: 'name',
  className: 'at-name',
  render: (text, record, { index, expandedRows, toggleRow }) => {
    // Generate the avatar URL using Dicebear
    const avatar = createAvatar(collection.bottts, {
      seed: record.title, // Use the automation title as the seed for the avatar
    });

    const avatarUrl = avatar.toDataUriSync();

    return (
      <div className="at-name-column">
        <i
          className={`fi ${expandedRows.has(index) ? 'fi-rr-angle-small-down' : 'fi-rr-angle-small-right'}`}
          onClick={() => toggleRow(index)}
          style={{ cursor: 'pointer', marginRight: '8px' }}
        ></i>
        <img src={avatarUrl} alt={record.title} className="at-avatar" />
        <span>{record.title}</span>
      </div>
    );
  }
};

export default NameColumn;
