// EditCompanyDetailsModal.js

import React, { useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DefaultModal from './DefaultModal';
import './Modals.less';

const EditCompanyDetailsModal = ({ isVisible, toggleModal }) => {
  const [form] = Form.useForm();
  const [companyLogo, setCompanyLogo] = useState(null);
  const [personalPicture, setPersonalPicture] = useState(null);

  const handleSubmit = (values) => {
    console.log('Company details:', values);
    message.success('Company details saved successfully!');
    toggleModal();
  };

  const handleCompanyLogoChange = (info) => {
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCompanyLogo(e.target.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };

  const handlePersonalPictureChange = (info) => {
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPersonalPicture(e.target.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
      Close
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()}>
      Save Changes
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title="Edit Company Details"
      icon="file-circle-info"
      footerContent={footerContent}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form edit-company-details-form">
        <Form.Item name="companyName" label="Company Name" initialValue="Darlene INC">
          <Input placeholder="Enter company name" />
        </Form.Item>
        <Form.Item name="tagline" label="Tagline" initialValue="Innovating the future">
          <Input placeholder="Enter tagline" />
        </Form.Item>
        <Form.Item name="overview" label="Company Overview" initialValue="Darlene INC is a leader in innovation solution, driving progress & efficiency">
          <Input.TextArea placeholder="Enter company overview" />
        </Form.Item>
        <div className="form-row">
          <Form.Item name="phoneNumber" label="Phone Number" className="form-item-half" initialValue="(555) 123-4568">
            <Input placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item name="email" label="Email" className="form-item-half" initialValue="darlene@gmail.com">
            <Input placeholder="Enter email" />
          </Form.Item>
        </div>
        <Form.Item name="address" label="Address" initialValue="2118 Thornridge Cir. Syracuse, Connecticut 35624">
          <Input placeholder="Enter address" />
        </Form.Item>
      </Form>
    </DefaultModal>
  );
};

export default EditCompanyDetailsModal;
