import React from 'react';
import SingleAvatar2 from '../../Components/SingleAvatar2';

const PersonalDetails = ({ personalPicture, handlePersonalPictureChange, togglePersonalModal }) => {
  return (
    <div className="ap-personal-details-container">
      <div className="ap-profile-title-row">
        <h2>Personal Details:</h2>
      </div>
      <div className="ap-personal-details">
        <div className="ap-company-content">
          <div className="ap-company-logo">
            <div className="ap-company-logo-image">
              {personalPicture ? (
                <img src={personalPicture} alt="Personal" />
              ) : (
                <SingleAvatar2 name="Darlene Robertson" size="150" fontSize="64" />
              )}
            </div>
            <input type="file" onChange={handlePersonalPictureChange} style={{ display: 'none' }} id="upload-personal" />
            <label htmlFor="upload-personal" className="ap-upload-logo-button">
              <i className="fi fi-rr-upload"></i> Upload Picture
            </label>
          </div>
          <div className="ap-company-info">
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-user"></i>
                </div>
                <span>Full Name: Darlene Robertson</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-briefcase"></i>
                </div>
                <span>Role: Product Designer</span>
              </div>
            </div>
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-phone-call"></i>
                </div>
                <span>Phone Number: (555) 123-4568</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-envelope"></i>
                </div>
                <span>Email Address: darlenelrobertson@gmail.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
