import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import RangeSelector from './RangeSelector';
import ChartFilter from './ChartFilter';
import { formatXAxis, getTickValues } from './XAxisFormatter';
import { dataLast7Days, dataLast30Days, dataLast60Days, dataLast90Days, dataLast180Days, dataLast365Days } from '../../Data/SampleDashboardData';
import DashboardLegend from './DashboardLegend';

const DashboardChart = () => {
  const [selectedRange, setSelectedRange] = useState('7');
  const [data, setData] = useState(dataLast7Days);
  const [selectedValues, setSelectedValues] = useState(['tasks', 'activeClients', 'hoursWorked', 'hoursBilled']);
  const [ticks, setTicks] = useState(getTickValues(dataLast7Days, '7'));

  useEffect(() => {
    let newData;
    switch (selectedRange) {
      case '7':
        newData = dataLast7Days;
        break;
      case '30':
        newData = dataLast30Days;
        break;
      case '60':
        newData = dataLast60Days;
        break;
      case '90':
        newData = dataLast90Days;
        break;
      case '180':
        newData = dataLast180Days;
        break;
      case '365':
        newData = dataLast365Days;
        break;
      default:
        newData = dataLast7Days;
    }
    setData(newData);
    setTicks(getTickValues(newData, selectedRange));
  }, [selectedRange]);

  return (
    <div>
      <div className="db-chart-topbar">

      <div className="db-chart-topbar-title">
      Performance Chart

      </div>

        <RangeSelector
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          setData={setData}
          dataLast7Days={dataLast7Days}
          dataLast30Days={dataLast30Days}
          dataLast60Days={dataLast60Days}
          dataLast90Days={dataLast90Days}
          dataLast180Days={dataLast180Days}
          dataLast365Days={dataLast365Days}
        />
      </div>
      <div className="db-chart">
        <ResponsiveContainer width="100%" height={360}>

          <LineChart data={data}>
            <XAxis dataKey="d" tickFormatter={formatXAxis} ticks={ticks} />
            <YAxis />
             <CartesianGrid stroke="#ddd" strokeOpacity={0.5} strokeDasharray="7 3" />
            <Tooltip />

            {selectedValues.includes('tasks') && (
              <Line
                type="monotone"
                dataKey="t"
                stroke="#F3B743"
                strokeWidth={1.25}
                dot={false}
              />
            )}
            {selectedValues.includes('activeClients') && (
              <Line
                type="monotone"
                dataKey="aC"
                stroke="#4DA957"
                strokeWidth={1.25}
                dot={false}
              />
            )}
            {selectedValues.includes('hoursWorked') && (
              <Line
                type="monotone"
                dataKey="hW"
                stroke="#3A4B90"
                strokeWidth={1.25}
                dot={false}
              />
            )}
            {selectedValues.includes('hoursBilled') && (
              <Line
                type="monotone"
                dataKey="hB"
                stroke="#42ABDF"
                strokeWidth={1.25}
                dot={false}
              />
            )}
          </LineChart>

        </ResponsiveContainer>

        <div className="db-chart-filters-container">
        <ChartFilter selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
      </div>
      </div>
    </div>
  );
};

export default DashboardChart;
