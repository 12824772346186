import React from 'react';
import { Descriptions, Input, DatePicker, Select } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2'; // Adjust the path if necessary
import dayjs from 'dayjs';

const { Option } = Select;

const ModalSubtasks = ({
  subtasks,
  handleSubtaskChange,
  projectTeamMembers
}) => (
  <div className="tdm-modal-subtasks">
    <Descriptions bordered column={1} title="Subtasks" className="tdm-descriptions">
      {subtasks.map((subtask) => (
        <Descriptions.Item key={subtask.subtaskId} className="tdm-descriptions-item">
          <Input
            value={subtask.taskName}
            onChange={(e) => handleSubtaskChange(subtask.subtaskId, 'taskName', e.target.value)}
            style={{ width: '68%' }}
            className="border-1 tdm-subtask-input "
          />
          <DatePicker
            value={subtask.dueDate ? dayjs(subtask.dueDate) : null}
            onChange={(date) => handleSubtaskChange(subtask.subtaskId, 'dueDate', date)}
            format="YYYY-MM-DD"
            getPopupContainer={(trigger) => trigger.parentNode}
            className=" border-1 tdm-due-date-picker"
            style={{ width: '20%' }}
          />
          <Select
            value={subtask.assignee}
            onChange={(value) => handleSubtaskChange(subtask.subtaskId, 'assignee', value)}
            style={{ width: '10%' }}
            className="border-1 tdm-assignee-select"
            dropdownClassName="tdm-assignee-dropdown"
          >
            {projectTeamMembers.map((member) => (
              <Option key={member.id} value={member.name}>
                <SingleAvatar2 name={member.name} />
              </Option>
            ))}
          </Select>
        </Descriptions.Item>
      ))}
    </Descriptions>
  </div>
);

export default ModalSubtasks;
