import React, { useState, useContext } from 'react';
import { Drawer, Button, Form, Input, Select, Radio, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AppStateContext } from '../../../Context/AppStateContext'; // Adjust the path if necessary
import SingleAvatar2 from '../../../Components/SingleAvatar2'; // Adjust the path if necessary

const { Option } = Select;
const { TextArea } = Input;

const TicketDrawer = ({ visible, onClose }) => {
  const [priority, setPriority] = useState('medium');
  const [searchAssignee, setSearchAssignee] = useState(''); // For search
  const [searchClient, setSearchClient] = useState('');     // For search
  const { projects, teamMembers } = useContext(AppStateContext); // Get project and team data from context
  
  const handlePriorityChange = e => {
    setPriority(e.target.value);
  };

  const onFinish = async values => {
    console.log('Form values:', values);
    
      message.success({ 
        content: 'Ticket Created: Your ticket has been created successfully!',
        style: { color: '#1890ff' } // Use your primary color
      });
      onClose(); // Close the drawer after submitting the form
  
  };

  // Find the project by projectId (for demonstration, hardcoded to 1)
  const project = projects.find(project => project.id === 1);

  // Filter the team members for the project
  const projectTeamMembers = project ? teamMembers.filter(member => project.members.includes(member.id)) : [];

  // Filter the assignee options based on search input
  const filteredAssigneeOptions = projectTeamMembers
    .filter(member => member.name.toLowerCase().includes(searchAssignee.toLowerCase()))
    .map(member => (
      <Option key={member.id} value={member.name}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SingleAvatar2 name={member.name} />
          <span style={{ marginLeft: '8px' }}>{member.name}</span>
        </div>
      </Option>
    ));

  // Same logic for the client options
  const filteredClientOptions = projectTeamMembers
    .filter(member => member.name.toLowerCase().includes(searchClient.toLowerCase()))
    .map(member => (
      <Option key={member.id} value={member.name}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SingleAvatar2 name={member.name} />
          <span style={{ marginLeft: '8px' }}>{member.name}</span>
        </div>
      </Option>
    ));

  const uploadProps = {
    name: 'file',
    multiple: true,
    action: '/upload', // Replace this with your actual upload URL
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Drawer
      title={
        <div className="custom-drawer-header">
          <span className="custom-drawer-title">Create New Ticket</span>
          <div className="custom-drawer-actions">
            <i className="fi fi-tr-expand"></i> {/* Expand icon */}
            <i className="fi fi-tr-circle-xmark" onClick={onClose}></i> {/* Close icon */}
          </div>
        </div>
      }
      width={500}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      className="ttd-ticket-drawer"
      footer={
        <div className="ttd-drawer-footer">
          <Button 
            onClick={() => {
              message.info('You have cancelled the ticket creation.'); // Show message
              onClose();
            }} 
            className="button-2"
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" form="ticketForm" className="button-1">
            Create Ticket
          </Button>
        </div>
      }
    >
      <Form id="ticketForm" layout="vertical" onFinish={onFinish} className="ttd-ticket-form">
        <Form.Item
          name="title"
          label="Subject"
        >
          <Input placeholder="Enter the Subject" />
        </Form.Item>

        <Form.Item label="Priority">
          <Radio.Group onChange={handlePriorityChange} value={priority} className="ttd-priority-radio-group">
            <Radio.Button value="low" className={`ttd-ant-radio-button-wrapper low ${priority === 'low' ? 'ttd-ant-radio-button-wrapper-checked' : ''}`}>Low</Radio.Button>
            <Radio.Button value="medium" className={`ttd-ant-radio-button-wrapper medium ${priority === 'medium' ? 'ttd-ant-radio-button-wrapper-checked' : ''}`}>Medium</Radio.Button>
            <Radio.Button value="high" className={`ttd-ant-radio-button-wrapper high ${priority === 'high' ? 'ttd-ant-radio-button-wrapper-checked' : ''}`}>High</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* Use dynamic assignees from the project */}
        <Form.Item
          name="assignee"
          label="Assignee"
        >
          <Select
            placeholder="Select an assignee"
            showSearch
            onSearch={setSearchAssignee} // Update search input
            filterOption={false}         // Disable built-in filtering
          >
            {filteredAssigneeOptions}     {/* Dynamically filter options */}
          </Select>
        </Form.Item>

        {/* Client Select */}
        <Form.Item
          name="client"
          label="Client"
        >
          <Select
            placeholder="Select a client"
            showSearch
            onSearch={setSearchClient}    // Update search input
            filterOption={false}          // Disable built-in filtering
          >
            {filteredClientOptions}        {/* Dynamically filter options */}
          </Select>
        </Form.Item>

        <Form.Item
          name="type"
          label="Ticket Type"
        >
          <Select placeholder="Select a category">
            <Option value="bug">Technical</Option>
            <Option value="feature">General</Option>
            <Option value="support">Billing</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="description"
          label="Ticket Description"
        >
          <TextArea rows={4} placeholder="Write something..." className="ant-input-textarea" />
        </Form.Item>


        <Form.Item label="Attachment">
    <div className="ttd-upload-area">
        <Upload.Dragger {...uploadProps} style={{ fontSize: '16px', width: '100%', height: '80px', padding: '10px' }}>
            <p className="ant-upload-drag-icon">
                <UploadOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">Support for a single or bulk upload.</p>
        </Upload.Dragger>
    </div>
</Form.Item>


      </Form>
    </Drawer>
  );
};

export default TicketDrawer;
