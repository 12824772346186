// SampleHoursData.js

const hoursData = [
  { d: '05/01/24', t: 'Chef Curt Website Revisions', a: 'Afaq', tS: '1:30:00', tL: '66:50:00' },
  { d: '05/01/24', t: 'Proforma Support', a: 'Mayowa', tS: '2:30:00', tL: '64:20:00' },
  { d: '05/01/24', t: 'Company Store Data Scrapping', a: 'Faiyaj', tS: '5:00:00', tL: '59:20:00' },
  { d: '05/01/24', t: 'Company Store + Acquisition Newsletter', a: 'Mizan', tS: '3:35:00', tL: '55:45:00' },
  { d: '05/02/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '2:00:00', tL: '53:45:00' },
  { d: '05/03/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:25:00', tL: '52:20:00' },
  { d: '05/07/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:35:00', tL: '50:45:00' },
  { d: '05/07/24', t: 'Proforma Data Scraping and Polishing', a: 'Faiyaj Hossain', tS: '3:00:00', tL: '47:45:00' },
  { d: '05/08/24', t: 'Embedding Instagram', a: 'Muhammad Afaq', tS: '0:30:00', tL: '47:15:00' },
  { d: '05/03/24', t: 'Proforma Data Scraping and Polishing', a: 'Faiyaj Hossain', tS: '6:00:00', tL: '41:15:00' },
  { d: '05/05/24', t: 'Homepage Designing', a: 'Muhammad Afaq', tS: '3:00:00', tL: '38:15:00' },
  { d: '05/05/24', t: 'Core4Consultant Website Content (4 Pages)', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '37:15:00' },
  { d: '04/30/24', t: 'Chef Curt Menu Responsiveness', a: 'Afaq', tS: '2:40:00', tL: '34:35:00' },
  { d: '05/01/24', t: 'Chef Curt Website Revisions', a: 'Afaq', tS: '1:30:00', tL: '33:05:00' },
  { d: '05/01/24', t: 'Proforma Support', a: 'Mayowa', tS: '2:30:00', tL: '30:35:00' },
  { d: '05/01/24', t: 'Company Store Data Scrapping', a: 'Faiyaj', tS: '5:00:00', tL: '25:35:00' },
  { d: '05/01/24', t: 'Company Store + Acquisition Newsletter', a: 'Mizan', tS: '3:35:00', tL: '22:00:00' },
  { d: '05/02/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '2:00:00', tL: '20:00:00' },
  { d: '05/03/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:25:00', tL: '18:35:00' },
  { d: '05/07/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:35:00', tL: '17:00:00' },
  { d: '05/07/24', t: 'Proforma Data Scraping and Polishing', a: 'Faiyaj Hossain', tS: '3:00:00', tL: '14:00:00' },
  { d: '05/03/24', t: 'Proforma Data Scraping and Polishing', a: 'Faiyaj Hossain', tS: '6:00:00', tL: '8:00:00' },
  { d: '05/14/24', t: 'Inner Pages Designing', a: 'Muhammad Afaq', tS: '3:40:00', tL: '4:20:00' },
  { d: '05/14/24', t: 'Mobile Responsiveness', a: 'Muhammad Afaq', tS: '1:30:00', tL: '2:50:00' },
  { d: '05/15/24', t: 'Weekly Meeting', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '1:50:00' },
  { d: '05/15/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '0:50:00' },
  { d: '05/15/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:30:00', tL: '-0:40:00' },
  { d: '05/15/24', t: 'Making Video + Revisions', a: 'Muhammad Afaq', tS: '0:30:00', tL: '-1:10:00' },
  { d: '05/16/24', t: 'Testing Purpose', a: 'Mashfiq', tS: '2:45:00', tL: '-3:55:00' },
  { d: '05/16/24', t: 'Prestige Digital', a: 'Muhammad Afaq', tS: '12:50:00', tL: '-16:45:00' },
  { d: '05/16/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:30:00', tL: '-18:15:00' },
  { d: '05/16/24', t: 'Proforma Meeting with Client', a: 'Mayowa Ajakaiye', tS: '0:30:00', tL: '-18:45:00' },
  { d: '05/16/24', t: 'Core4Consultant Revisions', a: 'Mayowa Ajakaiye', tS: '2:00:00', tL: '-20:45:00' },
  { d: '05/17/24', t: 'Inner Pages designing', a: 'Muhammad Afaq', tS: '12:50:00', tL: '-33:35:00' },
  { d: '05/17/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '-34:35:00' },
  { d: '05/17/24', t: 'Core4Consultant Revisions', a: 'Mayowa Ajakaiye', tS: '0:30:00', tL: '-35:05:00' },
  { d: '05/17/24', t: 'Revisions', a: 'Muhammad Afaq', tS: '4:50:00', tL: '-39:55:00' },
  { d: '05/17/24', t: 'email newsletter', a: 'Mizan Hossain', tS: '2:40:00', tL: '-42:35:00' },
  { d: '05/21/24', t: 'GoHighLavel automation research', a: 'Alex Benedict Rozario', tS: '1:50:00', tL: '-44:25:00' },
  { d: '05/21/24', t: 'Hotworx Data', a: 'Faiyaj Hossain', tS: '8:00:00', tL: '-52:25:00' },
  { d: '05/21/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '3:00:00', tL: '-55:25:00' },
  { d: '05/22/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '3:00:00', tL: '-58:25:00' },
  { d: '05/22/24', t: 'CBS Proposal Template', a: 'Mayowa Ajakaiye', tS: '0:30:00', tL: '-58:55:00' },
  { d: '05/23/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '2:00:00', tL: '-60:55:00' },

  { d: '05/23/24', t: 'Proposal Template design', a: 'Fahim', tS: '4:10:00', tL: '14:55:00' },

  { d: '05/28/24', t: 'Logo Versions', a: 'Fahim', tS: '1:50:00', tL: '93:05:00' },
  { d: '05/29/24', t: '2nd Logo Versions', a: 'Fahim', tS: '2:10:00', tL: '90:55:00' },
  { d: '05/31/24', t: 'Meeting', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '89:55:00' },
  { d: '05/31/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '88:55:00' },
  { d: '06/05/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '2:00:00', tL: '86:55:00' },
  { d: '06/05/24', t: 'Proposal update', a: 'Fahim', tS: '0:20:00', tL: '86:35:00' },
  { d: '06/07/24', t: 'Proforma Support (Airtable Automation)', a: 'Mayowa Ajakaiye', tS: '4:00:00', tL: '82:35:00' },
  { d: '06/10/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '3:30:00', tL: '79:05:00' },
  { d: '06/11/24', t: 'Proforma Email Scraping', a: 'Faiyaj Hossain', tS: '3:30:00', tL: '75:35:00' },
  { d: '06/12/24', t: 'Proforma Support + Meeting', a: 'Mayowa Ajakaiye', tS: '1:30:00', tL: '74:05:00' },
  { d: '06/13/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '2:00:00', tL: '72:05:00' },
  { d: '06/13/24', t: 'booklet design', a: 'Fahim', tS: '2:40:00', tL: '69:25:00' },
  { d: '06/19/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '3:00:00', tL: '66:25:00' },
  { d: '06/19/24', t: 'Mindbody API Request', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '65:25:00' },
  { d: '06/20/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '2:00:00', tL: '63:25:00' },
  { d: '06/26/24', t: 'Tellez HVAC Revisions + Meeting', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '62:25:00' },
  { d: '06/27/24', t: 'Tellez HVAC', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '61:25:00' },
  { d: '07/02/24', t: 'Better Fit Everyday Figma Mockup', a: 'Al Mamun', tS: '5:00:00', tL: '56:25:00' },
  { d: '07/04/24', t: 'Metabolic Makeover Academy Figma Mockup', a: 'Al Mamun', tS: '5:10:00', tL: '51:15:00' },
  { d: '07/08/24', t: 'Meeting', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '50:15:00' },
  { d: '07/09/24', t: 'Catalyst Business Proposal Revisions', a: 'Mayowa Ajakaiye', tS: '1:00:00', tL: '49:15:00' },
  { d: '07/10/24', t: 'Catalyst Business Proposal Revisions', a: 'Fahim', tS: '2:30:00', tL: '46:45:00' },
  { d: '07/10/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '0:30:00', tL: '46:15:00' },
  { d: '07/11/24', t: 'Catalyst Business Proposal Revisions', a: 'Fahim', tS: '1:30:00', tL: '44:45:00' },
  { d: '07/11/24', t: 'Proforma Support', a: 'Mayowa Ajakaiye', tS: '1:30:00', tL: '43:15:00' },
  { d: '07/12/24', t: 'Catalyst Business Proposal Revisions', a: 'Fahim', tS: '2:30:00', tL: '40:45:00' },
  { d: '07/12/24', t: 'Proforma Custom Mockup', a: 'Mohammad Tawhid', tS: '0:30:00', tL: '40:15:00' }
];

export default hoursData;
