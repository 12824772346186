import React from 'react';
import { Row, Col, Descriptions, Select, DatePicker, ConfigProvider } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2'; // Adjust the path if necessary
import EditableTimeSpentCell from '../../Components/EditableTimeSpentCell'; // Adjust the path if necessary
import getPriorityTag from '../../Components/PriorityTags'; // Adjust the path if necessary
import getStatusTag from '../../Components/StatusTags2'; // Adjust the path if necessary
import dayjs from 'dayjs';

const { Option } = Select;

const TaskSummary = ({
  assignee,
  dueDate,
  timeSpent,
  projectManager,
  priority,
  status,
  assigneeOptions,
  projectManagerOptions,
  priorityOptions,
  statusOptions,
  handleAssigneeChange,
  handleDueDateChange,
  handleTimeSpentChange,
  handleProjectManagerChange,
  handlePriorityChange,
  handleStatusChange
}) => (
  <div className="tdm-task-summary">
    <Row gutter={16} className="tdm-task-summary-row">
      <Col span={12} className="tdm-task-summary-col tdm-task-summary-col-left">
        <Descriptions title="" bordered column={1} className="tdm-descriptions">
          <Descriptions.Item label="Assignee:" className="tdm-descriptions-item">
            <Select
              value={assignee}
              onChange={handleAssigneeChange}
              style={{ width: '100%' }}
              className="tdm-assignee-select border-1"
            >
              {assigneeOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label="Due Date:" className="tdm-descriptions-item">
            <ConfigProvider locale="en">
              <DatePicker
                value={dueDate ? dayjs(dueDate) : null}
                onChange={handleDueDateChange}
                style={{ width: '100%' }}
                format="YYYY-MM-DD"
                getPopupContainer={(trigger) => trigger.parentNode}
                className="tdm-due-date-picker border-1"
              />
            </ConfigProvider>
          </Descriptions.Item>
          <Descriptions.Item label="Time Spent:" className="tdm-descriptions-item">
            <EditableTimeSpentCell
              value={timeSpent}
              onChange={handleTimeSpentChange}
              className="tdm-time-spent-input border-1"
            />
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={12} className="tdm-task-summary-col tdm-task-summary-col-right">
        <Descriptions bordered column={1} className="tdm-descriptions">
          <Descriptions.Item label="Project Manager:" className="tdm-descriptions-item">
            <Select
              value={projectManager}
              onChange={handleProjectManagerChange}
              style={{ width: '100%' }}
              className="tdm-project-manager-select border-1"
            >
              {projectManagerOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label="Priority:" className="tdm-descriptions-item">
            <Select
              value={priority}
              onChange={handlePriorityChange}
              style={{ width: '100%' }}
              className="tdm-priority-select"
            >
              {priorityOptions}
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label="Status:" className="tdm-descriptions-item">
            <Select
              value={status}
              onChange={handleStatusChange}
              style={{ width: '100%' }}
              className="tdm-status-select"
            >
              {statusOptions}
            </Select>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  </div>
);

export default TaskSummary;
