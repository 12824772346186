// OverviewFiles.js

import React from 'react';
import { Tooltip } from 'antd';
import '../WorkPages.less';

const fileTypeToEmoji = {
  pdf: '📄',
  doc: '📄',
  docx: '📄',
  xls: '📊',
  xlsx: '📊',
  png: '🖼️',
  jpg: '🖼️',
  jpeg: '🖼️',
  gif: '🖼️',
  txt: '📄',
  zip: '📦',
  rar: '📦',
  mp3: '🎵',
  mp4: '🎥',
  avi: '🎥',
  ppt: '📊',
  pptx: '📊',
  // Add more mappings as needed
};

const OverviewFiles = ({ files }) => {
  const getEmojiForFileType = (fileName) => {
    const ext = fileName.split('.').pop().toLowerCase();
    return fileTypeToEmoji[ext] || '📁'; // Default emoji if no match
  };

  return (
    <div className="pmt-file-list">
      <div className="pmt-file-list-container">
        {Array.from(files).map((file, index) => {
          const emoji = getEmojiForFileType(file.name);
          const fullFileName = file.name;
          const displayFileName =
            fullFileName.length > 35
              ? fullFileName.slice(0, 32) + '...'
              : fullFileName;
          return (
            <div className="pmt-file" key={index}>
              <Tooltip title={fullFileName}>
                <div className="pmt-file-emoji">{emoji}</div>
              </Tooltip>
              <div className="pmt-file-name">{displayFileName}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverviewFiles;
