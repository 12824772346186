import { useState } from 'react';
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import tasksData from '../../../Data/ProjectsTableSampleData';

const initialColumns = {
  'Not Started': [],
  'In Progress': [],
  'In Review': [],
  'Completed': [],
};

// Categorize tasks based on their status
tasksData.forEach(task => {
  if (initialColumns[task.status]) {
    initialColumns[task.status].push(task.taskId.toString());
  }
});

export function useKanbanLogic() {
  const [columns, setColumns] = useState(initialColumns);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (!over) return;

    const activeContainer = findContainer(columns, active.id);
    const overContainer = findContainer(columns, over.id);

    if (!activeContainer || !overContainer) return;

    if (activeContainer === overContainer) {
      const activeIndex = columns[activeContainer].indexOf(active.id);
      const overIndex = columns[overContainer].indexOf(over.id);

      if (activeIndex !== overIndex) {
        const updatedItems = arrayMove(columns[activeContainer], activeIndex, overIndex);
        setColumns({
          ...columns,
          [activeContainer]: updatedItems,
        });
      }
    } else {
      const activeIndex = columns[activeContainer].indexOf(active.id);
      const overIndex = over.id ? columns[overContainer].indexOf(over.id) : 0;

      const activeItems = [...columns[activeContainer]];
      const overItems = [...columns[overContainer]];

      activeItems.splice(activeIndex, 1);
      overItems.splice(overIndex, 0, active.id);

      setColumns({
        ...columns,
        [activeContainer]: activeItems,
        [overContainer]: overItems,
      });
    }
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  return {
    columns,
    activeId,
    sensors,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
  };
}

function findContainer(columns, id) {
  return Object.keys(columns).find((key) => columns[key].includes(id));
}
