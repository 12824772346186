import React, { useContext, useState } from 'react';
import { Button, Row, Col, Select, message, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './FilterSortByOverlay.less';
import columns from './TableColumns/TableColumn';
import { AppStateContext } from '../../Context/AppStateContext';
import { sortTaskAction } from '../../Utils/taskActions';

const { Option } = Select;
const orderOptions = [
  { value: 'asc', label: 'A - Z' },
  { value: 'desc', label: 'Z - A' }
]
const FilterSortByOverlay = () => {
  const { sortByColumns, setSortByColumns, tasks, setTasks } = useContext(AppStateContext);
  const [column, setColumn] = useState('')
  const [order, setOrder] = useState('')
  const filteredColumns = columns.filter((item) => !sortByColumns.map((iteam2) => (iteam2.column)).includes(item.accessor))
  function onAddColumn() {
    if (column && order) {
      const newConditions = [...sortByColumns]
      newConditions.push({ column, order })
      sortTaskAction(tasks, newConditions)
      setSortByColumns([...newConditions])
      setTasks([...tasks])
      setColumn('')
      setOrder("")
    } else {
      message.error('Please enter a column and order')
    }
  }
  function onRemoveColumn(index) {
    const newConditions = [...sortByColumns]
    newConditions.splice(index, 1)
    sortTaskAction(tasks, newConditions)
    setSortByColumns([...newConditions])
    setTasks([...tasks])
  }
  return <div className='task-sort-dropdown-overlay'>
    {sortByColumns.map((column, index) => (<Row key={index} gutter={16}>
      <Col>
        <Input className='input' readOnly value={columns.find((item) => item.accessor === column.column)?.Header} />
      </Col>
      <Col>
        <Input className='input' readOnly value={orderOptions.find((item) => item.value === column.order)?.label} />
      </Col>
      <Col>
        <Button type="dashed" onClick={() => onRemoveColumn(index)} className='input remove' icon={<DeleteOutlined />}>Remove</Button>
      </Col>
    </Row>))}
    {(sortByColumns.length < columns.length) && <Row gutter={16}>
      <Col>
        <Select onChange={setColumn} value={column} className='input'>
          {filteredColumns.map((item, index) => (<Option key={index} value={item.accessor}>{item.Header}</Option>))}
        </Select>
      </Col>
      <Col>
        <Select onChange={setOrder} value={order} className='input'>
          {orderOptions.map((item, index) => (<Option key={index} value={item.value}>{item.label}</Option>))}
        </Select>
      </Col>
      <Col>
        <Button type="primary" className='input' onClick={onAddColumn}>Add Column</Button>
      </Col>
    </Row>}
  </div>
}

export default FilterSortByOverlay;
