// ClientsTableColumns/ClientsTableLogic.js

import { useState, useEffect } from 'react';

const useClientsTableLogic = () => {
  const [isMobile, setIsMobile] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  return {
    isMobile,
  };
};

export { useClientsTableLogic };
