// HoursTracker.js

import React, { useState } from 'react';
import { Pagination, Menu } from 'antd';
import './HoursTracker.less';
import hoursData from '../../Data/SampleHoursData'; // Correct import for default export
import HoursTrackerTable from './HoursTrackerTable'; // This will contain the table rendering code
import HoursTrackerTableSort from './HoursTrackerTableSort'; // Import the new sorting component
import HoursTrackerChart from './HoursTrackerChart'; // Placeholder for the chart component

const HoursTracker = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedMenu, setSelectedMenu] = useState('table');

  const currentRows = hoursData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
  };

  return (
    <div className="ht-hours-tracker-container">
    <div className="ct-title-container">
      <div className="ct-page-title">
      <div className="ct-page-title-icon-container">
        <span className="ct-page-title-icon">
          <i className="fi fi-rr-clock"></i>
        </span>
        Hours Tracker
        </div>
        <div>
        <Menu
          onClick={handleMenuClick}
          selectedKeys={[selectedMenu]}
          mode="horizontal"
          className="ht-menu"
        >
          <Menu.Item key="table" className="ht-menu-item ht-table-menu-item">
          <div className="ht-menu-icon-container">  <i class="fi fi-rr-table-list"> </i></div>
          <div className="ht-menu-text-container">Table</div>
          </Menu.Item>
          <Menu.Item key="chart" className="ht-menu-item ht-chart-menu-item">
          <div className="ht-menu-icon-container">   <i class="fi fi-rr-chart-line-up"></i></div>
            <div className="ht-menu-text-container">  Chart</div>
          </Menu.Item>
        </Menu>
      </div>
      </div>
      </div>
      {selectedMenu === 'table' && (
        <>
          <HoursTrackerTableSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> {/* Add the sorting component */}
          <div className="ht-hours-tracker-table-container">
          <HoursTrackerTable rows={currentRows} />
          <Pagination
            current={currentPage}
            pageSize={rowsPerPage}
            total={hoursData.length}
            onChange={setCurrentPage}
            className="ht-pagination"
          />
          </div>
        </>
      )}
      {selectedMenu === 'chart' && (
        <div className="">
          <HoursTrackerChart /> {/* Placeholder for the chart component */}
        </div>
      )}
    </div>
  );
};

export default HoursTracker;
