import React, { useContext } from 'react';
import { AppStateContext } from '../../Context/AppStateContext';
import FilterSection from './FilterSection';
import './WorkPages.less';
import PGGroupedTable from './PGGroupedTable';
import TableSingle from './TableSingle';
import SectionAdd from './SectionAdd';

const ProjectManagementTable = () => {
  const { taskGroups } = useContext(AppStateContext);
  return (
    <div className="pmt-project-container">
      <FilterSection />
      {taskGroups.length > 0 ? <PGGroupedTable /> : <TableSingle />}
      <SectionAdd/>
    </div>
  );
};

export default ProjectManagementTable;
