import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_GB'; // Ensure the correct locale is used

const { RangePicker } = DatePicker;

const countWorkdays = (startDate, endDate) => {
  let count = 0;
  let current = startDate.clone();

  while (current <= endDate) {
    if (current.day() !== 0 && current.day() !== 6) { // Exclude Sundays (0) and Saturdays (6)
      count++;
    }
    current = current.add(1, 'day');
  }

  return count;
};

const DateColumn = ({ record, index, handleDateChange }) => {
  const startDate = dayjs(record.startDate);
  const endDate = dayjs(record.endDate);
  const today = dayjs();

  const projectDuration = countWorkdays(startDate, endDate);
  const daysLeft = countWorkdays(today, endDate);

  return (
    <ConfigProvider locale={locale}>
      <div>
        <RangePicker
          className="pt-date-range-picker"
          value={[startDate, endDate]}
          onChange={(dates) => handleDateChange(index, 'date', dates)}
          format="DD/MM/YY"
          getPopupContainer={(trigger) => trigger.parentNode}
        />
        <div className="pt-workdays-container">
          <span className="pt-total-workdays-text">
            <span className="pt-timeline-value-text">{projectDuration}</span> Workdays Total.
          </span>
          <span className="pt-workdays-left-text">
            {daysLeft > 0 ? (
              <>
                <span className="pt-timeline-value-text">{daysLeft}</span> Workdays Left.
              </>
            ) : (
              'Project Ended.'
            )}
          </span>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default DateColumn;
