import React from 'react';
import { Tag } from 'antd';
import './Components.less';

const getPriorityTag = (priority) => {
  let colorClass, iconClass;
  switch (priority) {
    case 'Low':
      colorClass = 'priority-tag-green-100';
      iconClass = 'priority-tag-icon-green-500';
      break;
    case 'Medium':
      colorClass = 'priority-tag-blue-100';
      iconClass = 'priority-tag-icon-blue-500';
      break;
    case 'High':
      colorClass = 'priority-tag-orange-100';
      iconClass = 'priority-tag-icon-orange-500';
      break;
    case 'Urgent':
      colorClass = 'priority-tag-red-100';
      iconClass = 'priority-tag-icon-red-500';
      break;
    default:
      colorClass = 'priority-tag-slate-100';
      iconClass = 'priority-tag-icon-slate-500';
  }

  return (
    <Tag
      icon={<i className="fi fi-sr-flag-alt priority-tag-icon"></i>}
      className={`priority-tag ${colorClass}`}
    >
      {priority}
    </Tag>
  );
};

export default getPriorityTag;
