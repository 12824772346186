import { useState } from 'react';
import sampleFilesData from '../../../Data/SampleFilesData';

export const useFilesLogic = () => {
  const [currentPath, setCurrentPath] = useState(['My Files']); // Start at the root
  const [currentFolder, setCurrentFolder] = useState(sampleFilesData); // Start at the root level (array of folders)
  const [selectedImage, setSelectedImage] = useState(null); // New state for the selected image

  const navigateToFolder = (folderName) => {
    setSelectedImage(null); // Reset selected image when navigating to a folder

    if (folderName === 'My Files') {
      setCurrentPath(['My Files']);
      setCurrentFolder(sampleFilesData);
    } else {
      const newPath = [...currentPath, folderName];
      setCurrentPath(newPath);
      const folder = findFolder(sampleFilesData, newPath);
      setCurrentFolder(folder.files || []); // Ensure it's an array
    }
  };

  const selectImage = (image) => {
    setSelectedImage(image); // Set the selected image
    setCurrentPath([...currentPath, image.name]); // Add the image name to the breadcrumb
  };

  const goBack = (index) => {
    setSelectedImage(null); // Reset selected image when navigating back
    if (index === 0) {
      setCurrentPath(['My Files']);
      setCurrentFolder(sampleFilesData);
    } else {
      const newPath = currentPath.slice(0, index + 1);
      setCurrentPath(newPath);
      const folder = findFolder(sampleFilesData, newPath);
      setCurrentFolder(folder.files || []); // Ensure it's an array
    }
  };

  const findFolder = (folderArray, path) => {
    let current = folderArray;
    for (let i = 1; i < path.length; i++) {
      if (!Array.isArray(current)) {
        current = current.files || []; // Reset current to an empty array if it is not valid
      }
      current = current.find(item => item.name === path[i]);
      if (current && current.type === 'folder') {
        current = current.files;
      }
    }
    return { files: current || [] }; // Ensure the return is an object with files array
  };

  return { currentFolder, currentPath, selectedImage, navigateToFolder, goBack, selectImage };
};
