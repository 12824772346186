// ProjectOverviewComponents/ProjectOverviewChat.js

import React, { useState } from 'react';
import { List, Input, Button } from 'antd';
import SingleAvatar2 from '../../../Components/SingleAvatar2'; // Adjust the import path accordingly
import { initialMessages } from './ProjectOverviewChatData';

const ProjectOverviewChat = () => {
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState('');

  const currentUser = 'Alice Johnson'; // Replace with the actual current user

  // Function to handle sending a new message
  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;
    const now = new Date();
    const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const date = now.toLocaleDateString('en-GB'); // 'dd/mm/yyyy'
    setMessages([
      ...messages,
      {
        id: messages.length + 1,
        user: `${currentUser} (Me)`,
        content: newMessage,
        time,
        date,
      },
    ]);
    setNewMessage('');
  };

  return (
    <div className="pmt-chat-container whitebox">
      <h3>Comments</h3>
      {/* Message List */}
      <List
        className="pmt-chat-message-list"
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={(item) => (
          <List.Item className="pmt-chat-message-item">
            <List.Item.Meta
              className="pmt-chat-message-meta"
              avatar={
                <SingleAvatar2
                  name={item.user}
                  size="34"
                  fontSize="14"
                  className="pmt-chat-avatar"
                />
              }
              title={
                <div className="pmt-chat-message-header">
                  <span className="pmt-chat-username">{item.user}</span>
                  <span className="pmt-chat-time">{item.time}</span>
                </div>
              }
              description={
                <div className="pmt-chat-message-content">{item.content}</div>
              }
            />
          </List.Item>
        )}
      />
      {/* Input for new messages */}
      <div className="pmt-chat-input-container">
        <Input.TextArea
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          rows={2}
          placeholder="Type a message..."
          className="pmt-chat-input"
        />
        <Button  onClick={handleSendMessage} className="pmt-chat-send-message-button">
          <i class="fi fi-rr-paper-plane"></i>
        </Button>
      </div>
    </div>
  );
};

export default ProjectOverviewChat;
