// RangeSelector.js

import React from 'react';
import { Select } from 'antd';
import { fillMissingDates, today, startDate90DaysAgo, startDate60DaysAgo, startDate30DaysAgo, startDate7DaysAgo, startDate180DaysAgo, startDate365DaysAgo } from '../../Utils/DashboardChartDates';

const { Option } = Select;

const RangeSelector = ({ selectedRange, setSelectedRange, setData, dataLast7Days, dataLast30Days, dataLast60Days, dataLast90Days, dataLast180Days, dataLast365Days }) => {

  const handleRangeChange = (range) => {
    setSelectedRange(range);

    switch (range) {
      case '7':
        setData(fillMissingDates(dataLast7Days, startDate7DaysAgo, today));
        break;
      case '30':
        setData(fillMissingDates(dataLast30Days, startDate30DaysAgo, today));
        break;
      case '60':
        setData(fillMissingDates(dataLast60Days, startDate60DaysAgo, today));
        break;
      case '90':
        setData(fillMissingDates(dataLast90Days, startDate90DaysAgo, today));
        break;
      case '180':
        setData(fillMissingDates(dataLast180Days, startDate180DaysAgo, today));
        break;
      case '365':
        setData(fillMissingDates(dataLast365Days, startDate365DaysAgo, today));
        break;
      default:
        setData(fillMissingDates(dataLast7Days, startDate7DaysAgo, today));
        break;
    }
  };

  return (
    <div className="db-date-range-selector">
    <div className="db-chart-calendar-icon-container">
    <i class="fi fi-rr-calendar"></i>
    </div>
      <Select value={selectedRange} onChange={handleRangeChange} style={{ width: 106 }}>
        <Option value="7">7 Days</Option>
        <Option value="30">1 Month</Option>
        <Option value="60">2 Months</Option>
        <Option value="90">3 Months</Option>
        <Option value="180">6 Months</Option>
        <Option value="365">1 Year</Option>
      </Select>
    </div>
  );
};

export default RangeSelector;
