// ProjectsTableColumns/ProjectsTableSort.js

import React, { useState } from 'react';
import { Button, Dropdown, Menu, Select } from 'antd';
import AddProjectModal from '../../../Modals/AddProjectModal'; // Import the new AddProjectModal

const { Option } = Select;

const ProjectsTableSort = ({ rowsPerPage, setRowsPerPage }) => {
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visibleTasksOverview, setVisibleTasksOverview] = useState(false);
  const [visibleDate, setVisibleDate] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMenuClick = (e) => {
    console.log('Sorting option selected:', e.key);
  };

  const sortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="asc">Ascending</Menu.Item>
      <Menu.Item key="desc">Descending</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="pt-sort-container">
      <div className="pt-sort-options-row">
        <div className="pt-sort-buttons">
          <span className="pt-sort-text">Sort: </span>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleStatus}
            onVisibleChange={setVisibleStatus}
          >
            <Button>
              Status <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleTasksOverview}
            onVisibleChange={setVisibleTasksOverview}
          >
            <Button>
              Tasks Overview <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleDate}
            onVisibleChange={setVisibleDate}
          >
            <Button>
              Date <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>

          <div className="pt-add-project-row">
            <div className="pt-rows-text">Rows: </div>
            <Select
              defaultValue={rowsPerPage}
              style={{ width: 60 }}
              onChange={(value) => setRowsPerPage(value)}
              className="pt-rows-button"
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
            </Select>
          </div>
        </div>
        <div className="pt-rows-options">
          <Button className="button-1" onClick={showModal}>Add New Project</Button>
          <AddProjectModal
            isVisible={isModalVisible}
            toggleModal={handleCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectsTableSort;
