import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TaskDetailsModal from '../../../../Modals/TaskDetailsModal'; // Import the TaskDetailsModal component
import tasksData from '../../../../Data/ProjectsTableSampleData';
import SingleAvatar2 from '../../../../Components/SingleAvatar2'; // Import the SingleAvatar2 component

function KanbanTask({ id }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  // Find the task details by id
  const task = tasksData.find(task => task.taskId.toString() === id);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,

    margin: '8px 0',
    backgroundColor: isDragging ? '#e0e0e0' : 'white',
    borderRadius: '6px',
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    flexDirection: 'column',
    cursor: 'default', // Show grab cursor when hovering over the task
  };

  const handleOpenModal = (e) => {
    e.stopPropagation(); // Prevent drag event when clicking the button
    setIsModalVisible(true);
  };

  // Prevent the task from being draggable when clicking the button
  const handleDragPrevent = (e) => {
    e.stopPropagation();
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {/* Apply listeners to the entire task container */}
      <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="kb-task">
        <div className="kb-task-topbar">
        <span className="kb-task-name">{task.taskName}</span>
          <button
            onClick={handleOpenModal}
            onPointerDown={handleDragPrevent} // Prevent drag event on button press
            className="kb-open-task-modal-button"
          >
            <i className="fi fi-rr-arrow-up-right-from-square"></i> {/* Eye icon or any icon to represent viewing details */}
          </button>
        </div>



        <div className="kb-bottom-bar">
          <div className="kb-bottom-bar-left">
            <span className="kb-subtask-box"><i className="fi fi-rr-diagram-subtask"></i> 2 </span>
            <span className="kb-comment-box"><i className="fi fi-rr-comment-alt-dots"></i> 1 </span>
          </div>
          <div className="kb-bottom-bar-right">
            <SingleAvatar2 name={task.assignee} />
          </div>
        </div>
      </div>

      {/* TaskDetailsModal Component */}
      {isModalVisible && (
        <TaskDetailsModal
          task={task}
          visible={isModalVisible}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}

export default KanbanTask;
