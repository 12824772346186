import React, { useContext } from 'react';
import { AppStateContext } from '../../Context/AppStateContext';
import './WorkPages.less';
import TableSingle from './TableSingle';

const PGGroupedTable = () => {
  const { taskGroups } = useContext(AppStateContext);
  return <>
    {taskGroups.map((item, index) => (<TableSingle key={index} groupIndex={index} />))}
  </>
};

export default PGGroupedTable;
