// ProjectsTableColumns/TeamCell.js
import React from 'react';
import CustomAvatarGroup from '../../../Components/UserAvatars2';
import AddMemberButton from '../../../Components/AddMemberButton';

const TeamCell = ({ record, showModal }) => {
  return (
    <div className="pt-team-container">
    <div className="pt-team-cell">
      <CustomAvatarGroup members={record.teamMembers} maxCount={5} />
      <AddMemberButton project={record} handleAddMember={showModal} />
    </div>
      <div className="pt-pm-text"><i class="fi fi-sr-boss"></i>{record.accountManager} </div>
    </div>

  );
};

export default TeamCell;
