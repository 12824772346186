import React, { useRef, useEffect, useState } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import CommentMarker from './CommentMarker';

const ImageAnnotator = ({
  imageUrl,
  comments,
  addComment,
  onMarkerClick,
  highlightedCommentId,
  backgroundColor
}) => {
  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const [image, setImage] = useState(null);
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [minScale, setMinScale] = useState(1); // Track the minimum scale for zooming

  useEffect(() => {
    const img = new window.Image();
    img.src = imageUrl;
    img.onload = () => {
      setImage(img);

      const containerWidth = containerRef.current.offsetWidth;
      const aspectRatio = img.height / img.width;

      // Calculate the height based on the container width and the image aspect ratio
      const height = containerWidth * aspectRatio;

      setStageSize({ width: containerWidth, height });
      const initialScale = containerWidth / img.width; // Calculate the scale that fits the image within the screen width
      setScale(initialScale);
      setMinScale(initialScale); // Set the initial scale as the minimum scale
      setPosition({ x: 0, y: 0 }); // Center the image
    };
  }, [imageUrl]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current && image) {
        const containerWidth = containerRef.current.offsetWidth;
        const aspectRatio = image.height / image.width;

        // Recalculate the height based on the new container width
        const height = containerWidth * aspectRatio;

        setStageSize({
          width: containerWidth,
          height,
        });
        const initialScale = containerWidth / image.width; // Recalculate scale on resize
        setScale(initialScale);
        setMinScale(initialScale); // Update the minimum scale
        setPosition({ x: 0, y: 0 }); // Re-center the image on resize
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [image]);

  const handleStageClick = (event) => {
    const stage = event.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    const { x, y } = pointerPosition;

    // Add comment at the clicked position
    addComment(x, y);
  };

  const clampPosition = (position, scale, stageSize, imageSize) => {
    // Maximum and minimum positions for the image
    const maxX = Math.max(0, stageSize.width - imageSize.width * scale);
    const maxY = Math.max(0, stageSize.height - imageSize.height * scale);
    const minX = Math.min(0, stageSize.width - imageSize.width * scale);
    const minY = Math.min(0, stageSize.height - imageSize.height * scale);

    // Clamp the position to stay within these bounds
    return {
      x: Math.max(minX, Math.min(maxX, position.x)),
      y: Math.max(minY, Math.min(maxY, position.y)),
    };
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

    // Limit the scale so the image doesn't get smaller than the screen fit
    if (newScale < minScale) newScale = minScale;

    setScale(newScale);

    // Calculate new position while ensuring it doesn't pan outside the image
    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    setPosition(clampPosition(newPos, newScale, stageSize, { width: image.width, height: image.height }));
  };

  const handleDragMove = (e) => {
    const stage = e.target.getStage();
    const newPos = {
      x: stage.x(),
      y: stage.y(),
    };
    setPosition(clampPosition(newPos, scale, stageSize, { width: image.width, height: image.height }));
  };

  return (
    <div
      className="image-container"
      ref={containerRef}
      style={{
        backgroundColor,
        width: '100%',
        height: stageSize.height, // Set the height dynamically based on the image aspect ratio
        overflow: 'hidden', // Prevent any overflow
      }}
    >
      <Stage
        width={stageSize.width}
        height={stageSize.height}
        onClick={handleStageClick}
        scaleX={scale}
        scaleY={scale}
        x={position.x}
        y={position.y}
        draggable={scale > minScale} // Only allow dragging when zoomed in
        onWheel={handleWheel}
        onDragMove={handleDragMove} // Restrict dragging to within image bounds
      >
        <Layer>
          {image && (
            <KonvaImage
              image={image}
              width={image.width}
              height={image.height}
              x={0}
              y={0}
              ref={imageRef}
              style={{ border: '1px solid gray' }}
            />
          )}
          {comments.map((comment, index) => (
            <CommentMarker
              key={index}
              x={comment.x}
              y={comment.y}
              id={comment.id}
              highlighted={highlightedCommentId === comment.id}
              onClick={() => onMarkerClick(index)}
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
};

export default ImageAnnotator;
