// src/Utils/taskActions.js

import { sortItemsAction } from "./SortingActions";

/*
conditions = [{
  column:"taskName",
  order:"asc"
}......]
*/
export function sortTaskAction(tasks, conditions) {
  if (conditions.length) {
    sortItemsAction(tasks, conditions)
  } else {
    sortItemsAction(tasks, [{
      column: "position",
      order: "asc"
    }])
  }
};
// Example Column Keys:
// const keys = ['assignee', 'status'];
export function groupTasksAction(tasks, keys) {
  if (keys.length < 1) {
    return []
  }
  // Helper function to generate unique key for grouping
  function generateKey(task) {
    return keys.map(key => `${key}:${task[key]}`).join('|');
  }

  // Dictionary to store unique groupings
  const groups = {};

  // Process each task
  tasks.forEach(task => {
    const groupKey = generateKey(task);

    if (!groups[groupKey]) {
      // Initialize the group if it doesn't exist
      groups[groupKey] = {
        attributes: {},
        tasks: []
      };

      // Set attributes
      keys.forEach(key => {
        groups[groupKey].attributes[key] = task[key];
      });
    }

    // Add the task to the correct group
    groups[groupKey].tasks.push(task);
  });

  // Convert dictionary to array format as requested
  return Object.values(groups);
}
export function getGroupIndex(taskGroups, taskId) {
  return taskGroups.findIndex((item) => (item.tasks.map((item) => (item.taskId)).includes(taskId)))
};
export function getGroupIndexFromSubtask(taskGroups, taskIndex, subtaskIndex, subtaskId) {
  return taskGroups.findIndex((item) => {
    const task = item.tasks[taskIndex]
    if (!task) {
      return false
    }
    const subTask = task.subtasks[subtaskIndex]
    if (!subTask || subTask.subtaskId !== subtaskId) {
      return false
    }
    return true
  })
};
export function updateTaskAction(tasks, taskIndex, updatedTask, conditions) {
  const task = tasks[taskIndex]
  tasks[taskIndex] = { ...task, ...updatedTask }
  sortTaskAction(tasks, conditions)
};

export const addSubtask = (tasks, taskId, newSubtask) => {
  return tasks.map(task =>
    task.taskId === taskId ? { ...task, subtasks: [...task.subtasks, newSubtask] } : task
  );
};

export const updateSubtask = (tasks, taskIndex, subtaskIndex, updatedSubtask) => {
  const subtask = tasks[taskIndex].subtasks[subtaskIndex]
  tasks[taskIndex].subtasks[subtaskIndex] = { ...subtask, ...updatedSubtask }
  return [...tasks]
};

export const deleteSubtask = (tasks, taskId, subtaskId) => {
  return tasks.map(task =>
    task.taskId === taskId ? {
      ...task,
      subtasks: task.subtasks.filter(subtask => subtask.subtaskId !== subtaskId)
    } : task
  );
};

export const addNewSubtask = (tasks, taskId) => {
  const newSubtask = {
    subtaskId: new Date().getTime(), // Unique ID for the new subtask
    taskName: '',
    assignee: '',
    status: 'Not Started',
    dueDate: null,
    timeSpent: '0h'
  };

  return tasks.map(task =>
    task.taskId === taskId ? { ...task, subtasks: [...task.subtasks, newSubtask] } : task
  );
};

export const foccusTaskRow = (taskId) => {
  setTimeout(() => {
    const element = document.querySelector(`#task-${taskId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('glow-effect');
      element.querySelector(`input`)?.focus()

      setTimeout(() => {
        element.classList.remove('glow-effect');
      }, 2000)
    }
  }, 10)
};

export const addTaskAction = (tasks, index = false) => {
  const newTask = {
    taskId: Math.max(...(tasks.map((i) => (i.taskId)))) + 1,
    position: tasks.length,
    taskName: '',
    subtasks: [],
    assignee: '',
    status: 'Not Started',
    dueDate: null,
    timeSpent: '0h',
  };
  if (index !== false) {
    tasks.splice(index + 1, 0, newTask);
    tasks.map((item, index) => {
      item.position = index + 1
      return item
    })
  } else {
    tasks.push(newTask)
  }
  return newTask
  // sortTaskAction(tasks, sortingConditions)
};

export const addSubtaskAction = (tasks, taskIndex, subtaskIndex) => {
  const newSubtask = {
    subtaskId: new Date().getTime(),
    taskName: '',
    assignee: '',
    status: 'Not Started',
    dueDate: null,
    timeSpent: '0h',
  };
  const task = tasks[taskIndex]
  const subTasks = task.subtasks
  // console.log('addSubtaskBelow called with:',task, subTasks);
  subTasks.splice(subtaskIndex + 1, 0, newSubtask)

  // console.clear()
  // console.log('addSubtaskBelow called with:', subTasks);
  // console.log('addSubtaskBelow called with:', tasks[taskIndex].task.subTasks, subtaskIndex);
  // return tasks
  return [...tasks]

  // return tasks.map(task =>
  //   task.taskId === taskId ? {
  //     ...task,
  //     subtasks: [
  //       ...task.subtasks.slice(0, subtaskIndex + 1),
  //       newSubtask,
  //       ...task.subtasks.slice(subtaskIndex + 1),
  //     ]
  //   } : task
  // );
};
