// EditPersonalDetailsModal.js

import React, { useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DefaultModal from './DefaultModal';
import SingleAvatar2 from '../Components/SingleAvatar2';
import './Modals.less';

const EditPersonalDetailsModal = ({ isVisible, toggleModal }) => {
  const [form] = Form.useForm();
  const [personalPicture, setPersonalPicture] = useState(null);

  const handleSubmit = (values) => {
    console.log('Personal details:', values);
    message.success('Personal details saved successfully!');
    toggleModal();
  };

  const handlePersonalPictureChange = (info) => {
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPersonalPicture(e.target.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
      Close
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()}>
      Save Changes
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title="Edit Personal Details"
      icon="info"
      footerContent={footerContent}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form edit-personal-details-form">
        <div className="form-row">

        </div>

        <Form.Item name="fullName" label="Full Name" initialValue="Darlene Robertson">
          <Input placeholder="Enter full name" />
        </Form.Item>
        <Form.Item name="role" label="Role" initialValue="Product Designer">
          <Input placeholder="Enter role" />
        </Form.Item>
        <div className="form-row">
          <Form.Item name="phoneNumber" label="Phone Number" className="form-item-half" initialValue="(555) 123-4568">
            <Input placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item name="email" label="Email" className="form-item-half" initialValue="darlenelrobertson@gmail.com">
            <Input placeholder="Enter email" />
          </Form.Item>
        </div>
      </Form>
    </DefaultModal>
  );
};

export default EditPersonalDetailsModal;
