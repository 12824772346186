// SampleTicketsData.js
// const tickets = [
//   { id: '#TC-100', subject: 'Issue with login', priority: 'High', client: 'Client A', date: '2024-08-16' },
//   { id: '#TC-101', subject: 'Feature request for dashboard', priority: 'Medium', client: 'Client B', date: '2024-08-15' },
//   { id: '#TC-102', subject: 'Bug in payment gateway', priority: 'Critical', client: 'Client C', date: '2024-08-14' },
//   { id: '#TC-103', subject: 'UI improvement suggestion', priority: 'Low', client: 'Client D', date: '2024-08-13' },
//   { id: '#TC-104', subject: 'Server downtime issue', priority: 'High', client: 'Client E', date: '2024-08-12' },
//   { id: '#TC-105', subject: 'API integration problem', priority: 'Medium', client: 'Client F', date: '2024-08-11' },
// ];

// export default tickets;


const tickets = [


  { id: 'TC-100', subject: 'Issue with login', priority: 'High', status: 'Open', client: 'Client A', description: 'User cannot log in to the dashboard. It seems to be an issue with session management.', date: '2024-08-16' },
  { id: 'TC-101', subject: 'Feature request for dashboard', priority: 'Medium', status: 'Closed', client: 'Client B', description: 'Request to add a new analytics feature to the dashboard for better data visualization.', date: '2024-08-15' },
  { id: 'TC-102', subject: 'Bug in payment gateway', priority: 'Critical', status: 'Open', client: 'Client C', description: 'Payments are not going through on the platform. It appears to be an issue with the third-party payment service.', date: '2024-08-14' },
  { id: 'TC-103', subject: 'UI improvement suggestion', priority: 'Low', status: 'Pending', client: 'Client D', description: 'The user interface for the settings page could be improved to enhance usability.', date: '2024-08-13' },
  { id: 'TC-104', subject: 'Server downtime issue', priority: 'High', status: 'Resolved', client: 'Client E', description: 'The main server went down for several hours last night, causing significant disruption.', date: '2024-08-12' },





  { id: '#TC-100', subject: 'This is the landing page for users after logging in...', priority: 'High', client: 'Wade ', date: '2024-08-13 06:30 AM' },
  { id: '#TC-101', subject: 'Shows an overview of tickets, including summary stats...', priority: 'Medium', client: 'Lexie Alexander', date: '2024-08-13 06:30 AM' },
  { id: '#TC-102', subject: 'Shows an overview of tickets, including summary stats...', priority: 'Low', client: 'Wade ', date: '2024-08-13 06:30 AM' },
  { id: '#TC-100', subject: 'This is the landing page for users after logging in...', priority: 'High', client: 'Warren', date: '2024-08-13 06:30 AM' },
  { id: '#TC-101', subject: 'Shows an overview of tickets, including summary stats...', priority: 'Medium', client: 'Lexie Alexander', date: '2024-08-13 06:30 AM' },
  { id: '#TC-109', subject: 'Shows an overview of tickets, including summary stats...', priority: 'HIGH', client: 'Tushar', date: '2024-08-13 06:30 AM' },
  { id: '#TC-100', subject: 'Issue with login', priority: 'High', client: 'Client A', date: '2024-08-16' },
  { id: '#TC-101', subject: 'Feature request for dashboard', priority: 'Medium', client: 'Client B', date: '2024-08-15' },
  { id: '#TC-102', subject: 'Bug in payment gateway', priority: 'Critical', client: 'Client C', date: '2024-08-14' },
  { id: '#TC-103', subject: 'UI improvement suggestion', priority: 'Low', client: 'Client D', date: '2024-08-13' },
  { id: '#TC-104', subject: 'Server downtime issue', priority: 'High', client: 'Client E', date: '2024-08-12' },
  { id: '#TC-105', subject: 'API integration problem', priority: 'Medium', client: 'Client F', date: '2024-08-11' },
  { id: '#TC-106', subject: 'test data', priority: 'Medium', client: 'Tushar Faruk', date: '2024-08-11' },

  { id: '#TC-100', subject: 'Issue with login', priority: 'High', client: 'Client A', date: '2024-08-16' },
  { id: '#TC-101', subject: 'Feature request for dashboard', priority: 'Medium', client: 'Client B', date: '2024-08-15' },
  { id: '#TC-102', subject: 'Bug in payment gateway', priority: 'Critical', client: 'Client C', date: '2024-08-14' },
  { id: '#TC-103', subject: 'UI improvement suggestion', priority: 'Low', client: 'Client D', date: '2024-08-13' },
  { id: '#TC-104', subject: 'Server downtime issue', priority: 'High', client: 'Client E', date: '2024-08-12' },
  { id: '#TC-105', subject: 'API integration problem', priority: 'Medium', client: 'Client F', date: '2024-08-11' },
  { id: '#TC-106', subject: 'test data', priority: 'Medium', client: 'Tushar Faruk', date: '2024-08-11' },

  { id: '#TC-100', subject: 'Issue with login', priority: 'High', client: 'Client A', date: '2024-08-16' },
  { id: '#TC-101', subject: 'Feature request for dashboard', priority: 'Medium', client: 'Client B', date: '2024-08-15' },
  { id: '#TC-102', subject: 'Bug in payment gateway', priority: 'Critical', client: 'Client C', date: '2024-08-14' },
  { id: '#TC-103', subject: 'UI improvement suggestion', priority: 'Low', client: 'Client D', date: '2024-08-13' },
  { id: '#TC-104', subject: 'Server downtime issue', priority: 'High', client: 'Client E', date: '2024-08-12' },
  { id: '#TC-105', subject: 'API integration problem', priority: 'Medium', client: 'Client F', date: '2024-08-11' },
  { id: '#TC-106', subject: 'test data', priority: 'Medium', client: 'Tushar Faruk', date: '2024-08-11' },
  
];

export default tickets;

