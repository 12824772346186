// AutomationstableColumns/ExecutionsColumn.js

import React from 'react';
import { Table } from 'antd';

const ExecutionsColumn = {
  title: 'Executions',
  key: 'executions',
  className: 'at-executions',
  render: (text, record) => (
    <table className="at-executions-table">
      <thead>
        <tr>
          <th className="at-executions-label">Today</th>
          <th className="at-executions-label">7 Days</th>
          <th className="at-executions-label">30 Days</th>
          <th className="at-executions-label">All Time</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="at-executions-value">{record.stats.today}</td>
          <td className="at-executions-value">{record.stats.last7Days}</td>
          <td className="at-executions-value">{record.stats.last30Days}</td>
          <td className="at-executions-value">{record.stats.allTime}</td>
        </tr>
      </tbody>
    </table>
  ),
};

export default ExecutionsColumn;
