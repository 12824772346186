// ClientMainFolders.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const ClientMainFolders = () => {
  const navigate = useNavigate();

  return (
    <div className="ct-mp-folders-container">
      {/* First Row */}
      <div className="ct-mp-folders-row">
        {/* Projects Folder */}
        <div className="ct-mp-folder-card ct-mp-projects-box">
        <div className="ct-mp-folder-top">
          <div className="ct-mp-folder-header">
            <div className="ct-mp-folder-icon">
              <i className="fi fi-rr-folder"></i>
            </div>

          </div>
          <h3 className="ct-mp-folder-title">Projects</h3>
          </div>
          <div className="ct-mp-folder-content">
            <div className="ct-mp-folder-grid ct-mp-grid-4-items">
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Not Started: </span>
                <span className="ct-mp-item-value">12</span>
              </div>
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">In Progress: </span>
                <span className="ct-mp-item-value">08</span>
              </div>
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">In Review: </span>
                <span className="ct-mp-item-value">06</span>
              </div>
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Completed: </span>
                <span className="ct-mp-item-value">04</span>
              </div>
            </div>
          </div>
        </div>

        {/* Files Folder */}
        <div className="ct-mp-folder-card ct-mp-files-box">
        <div className="ct-mp-folder-top">
          <div className="ct-mp-folder-header">
            <div className="ct-mp-folder-icon">
              <i className="fi fi-rr-file"></i>
            </div>

          </div>
          <h3 className="ct-mp-folder-title">Files</h3>
          </div>
          <div className="ct-mp-folder-content">
            <div className="ct-mp-folder-grid ct-mp-grid-2-items">
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Total Files: </span>
                <span className="ct-mp-item-value">123</span>
              </div>
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Last Updated: </span>
                <span className="ct-mp-item-value">Sep 27, 2023</span>
              </div>
            </div>
          </div>
        </div>

        {/* Hours Tracker Folder */}
        <div className="ct-mp-folder-card ct-mp-hours-box">
        <div className="ct-mp-folder-top">
          <div className="ct-mp-folder-header">
            <div className="ct-mp-folder-icon">
              <i className="fi fi-rr-clock"></i>
            </div>

          </div>
          <h3 className="ct-mp-folder-title">Hours Tracker</h3>
          </div>
          <div className="ct-mp-folder-content">
            <div className="ct-mp-folder-grid ct-mp-grid-3-items">
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Total Hours: </span>
                <span className="ct-mp-item-value">480</span>
              </div>
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Current Package: </span>
                <span className="ct-mp-item-value">80</span>
              </div>
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Remaining Hours: </span>
                <span className="ct-mp-item-value">27h 35m</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Second Row */}
      <div className="ct-mp-folders-row">
        {/* Automations Folder */}
        <div className="ct-mp-folder-card ct-mp-automations-box">
        <div className="ct-mp-folder-top">
          <div className="ct-mp-folder-header">
            <div className="ct-mp-folder-icon">
              <i className="fi fi-rr-robot"></i>
            </div>

          </div>
          <h3 className="ct-mp-folder-title">Automations</h3>
          </div>
          <div className="ct-mp-folder-content">
            <div className="ct-mp-folder-grid ct-mp-grid-2-items">
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Active: </span>
                <span className="ct-mp-item-value">17</span>
              </div>
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Inactive: </span>
                <span className="ct-mp-item-value">3</span>
              </div>
            </div>
          </div>
        </div>

        {/* Chat Folder */}
        <div className="ct-mp-folder-card ct-mp-chat-box">
        <div className="ct-mp-folder-top">
          <div className="ct-mp-folder-header">
            <div className="ct-mp-folder-icon">
              <i className="fi fi-rr-comment"></i>
            </div>

          </div>
          <h3 className="ct-mp-folder-title">Chat</h3>
          </div>
          <div className="ct-mp-folder-content">
            <div className="ct-mp-folder-grid ct-mp-grid-1-item">
              <div className="ct-mp-folder-grid-item">
                <span className="ct-mp-item-title">Last Message: </span>
                <span className="ct-mp-item-value">Sep 27, 2023</span>
              </div>
            </div>
          </div>
        </div>

        {/* Empty Column */}
        <div className="ct-mp-folder-card ct-mp-empty-box">
          {/* Empty content */}
        </div>
      </div>
    </div>
  );
};

export default ClientMainFolders;
