import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountProfile from './AccountProfile';
import AccountTeam from './AccountTeam';
import AccountBilling from './AccountBilling';
import AccountPlan from './AccountPlan';
import AccountSupport from './AccountSupport';
import './AccountPages.less';

const AccountPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get('tab') || 'profile';
  const [current, setCurrent] = useState(tabParam);

  useEffect(() => {
    setCurrent(tabParam);
  }, [tabParam]);

  const handleClick = (e) => {
    const newTab = e.key;
    setCurrent(newTab);
    navigate(`/account?tab=${newTab}`);
  };

  const renderComponent = () => {
    switch (current) {
      case 'profile':
        return <AccountProfile />;
      case 'team':
        return <AccountTeam />;
      case 'billing':
        return <AccountBilling />;
      case 'plan':
        return <AccountPlan />;
      case 'support':
        return <AccountSupport />;
      default:
        return <AccountProfile />;
    }
  };

  return (
    <div>
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-settings"></i>
        </span>
        Account
      </p>

      <Menu className="ap-menu" onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="profile">Profile</Menu.Item>
        <Menu.Item key="team">Team</Menu.Item>
        <Menu.Item key="billing">Billing</Menu.Item>
        <Menu.Item key="plan">Plan</Menu.Item>
        <Menu.Item key="support">Support</Menu.Item>
      </Menu>
      <div>
        {renderComponent()}
      </div>
    </div>
  );
};

export default AccountPage;
