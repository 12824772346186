// Colors.js

import React, { useState } from 'react';
import { Row, Col, Divider, Typography, Button, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Title } = Typography;

const colorCategories = [
  {
    title: 'Green Colors (Primary)',
    colors: [
      { className: 'green-50', label: '50', hex: '#EEFCF3' },
      { className: 'green-100', label: '100', hex: '#DCF9E7' },
      { className: 'green-200', label: '200', hex: '#A8F0C2' },
      { className: 'green-300', label: '300', hex: '#3FDE7A' },
      { className: 'green-400', label: '400 (Primary)', hex: '#22C55E' },
      { className: 'green-500', label: '500', hex: '#1EAE53' },
      { className: 'green-600', label: '600', hex: '#157A3A' },
    ],
  },
  {
    title: 'Purple Colors',
    colors: [
      { className: 'purple-50', label: '50', hex: '#F1F0F9' },
      { className: 'purple-100', label: '100', hex: '#E2E1F4' },
      { className: 'purple-200', label: '200', hex: '#D4D2EF' },
      { className: 'purple-300', label: '300', hex: '#9A95DA' },
      { className: 'purple-400', label: '400', hex: '#534BBE' },
      { className: 'purple-500', label: '500', hex: '#363187' },
      { className: 'purple-600', label: '600', hex: '#1E1B4B' },
    ],
  },
  {
    title: 'Slate Colors',
    colors: [
      { className: 'slate-50', label: '50', hex: '#f8fafc' },
      { className: 'slate-100', label: '100', hex: '#f1f5f9' },
      { className: 'slate-200', label: '200', hex: '#e2e8f0' },
      { className: 'slate-300', label: '300', hex: '#cbd5e1' },
      { className: 'slate-400', label: '400', hex: '#94a3b8' },
      { className: 'slate-500', label: '500', hex: '#64748b' },
      { className: 'slate-600', label: '600', hex: '#334155' },
    ],
  },
  {
    title: 'Element Colors',
    columns: [
      {
        title: 'Peach',
        colors: [
          { className: 'peach-50', label: '50', hex: '#FFF9F4' },
          { className: 'peach-100', label: '100', hex: '#FFF5EC' },
          { className: 'peach-200', label: '200', hex: '#FFF1E5' },
          { className: 'peach-300', label: '300', hex: '#FFEBDA' },
        ],
      },
      {
        title: 'Blue',
        colors: [
          { className: 'blue-50', label: '50', hex: '#EFF5FA' },
          { className: 'blue-100', label: '100', hex: '#E0EBF5' },
          { className: 'blue-200', label: '200', hex: '#D0E1F1' },
          { className: 'blue-300', label: '300', hex: '#B1CEE7' },
          { className: 'blue-400', label: '400', hex: '#73A6D4' },
          { className: 'blue-500', label: '500', hex: '#3A7FBB' },
          { className: 'blue-600', label: '600', hex: '#26547D' },
        ],
      },
      {
        title: 'Red',
        colors: [
          { className: 'red-50', label: '50', hex: '#FFEBEB' },
          { className: 'red-100', label: '100', hex: '#FFD6D6' },
          { className: 'red-200', label: '200', hex: '#FFC2C2' },
          { className: 'red-300', label: '300', hex: '#FE9A9A' },
          { className: 'red-400', label: '400', hex: '#FE4A49' },
          { className: 'red-500', label: '500', hex: '#F40101' },
          { className: 'red-600', label: '600', hex: '#A20101' },
        ],
      },
      {
        title: 'Pink',
        colors: [
          { className: 'pink-50', label: '50', hex: '#FCEEF1' },
          { className: 'pink-100', label: '100', hex: '#F8DDE3' },
          { className: 'pink-200', label: '200', hex: '#F5CCD5' },
          { className: 'pink-300', label: '300', hex: '#EEAABA' },
          { className: 'pink-400', label: '400', hex: '#E0607E' },
          { className: 'pink-500', label: '500', hex: '#CC284F' },
          { className: 'pink-600', label: '600', hex: '#881B34' },
        ],
      },
      {
        title: 'Yellow',
        colors: [
          { className: 'yellow-50', label: '50', hex: '#FFFDEB' },
          { className: 'yellow-100', label: '100', hex: '#FFFCD7' },
          { className: 'yellow-200', label: '200', hex: '#FEFAC2' },
          { className: 'yellow-300', label: '300', hex: '#FEF79A' },
          { className: 'yellow-400', label: '400', hex: '#FDF149' },
          { className: 'yellow-500', label: '500', hex: '#F2E202' },
          { className: 'yellow-600', label: '600', hex: '#A29702' },
        ],
      },
      {
        title: 'Orange',
        colors: [
          { className: 'orange-50', label: '50', hex: '#FFF2EB' },
          { className: 'orange-100', label: '100', hex: '#FFE3CC' },
          { className: 'orange-200', label: '200', hex: '#FFD8C2' },
          { className: 'orange-300', label: '300', hex: '#FFBE99' },
          { className: 'orange-400', label: '400', hex: '#FF8B47' },
          { className: 'orange-500', label: '500', hex: '#F55A00' },
          { className: 'orange-600', label: '600', hex: '#A33C00' },
        ],
      },
    ],
  },
];


const Colors = () => {
  const [copiedColor, setCopiedColor] = useState(null);

  const handleCopy = (hex) => {
    navigator.clipboard.writeText(hex);
    setCopiedColor(hex);
    setTimeout(() => setCopiedColor(null), 2000); // Tooltip will show for 2 seconds
  };

  return (
    <>
      {colorCategories.map((mainCategory) => (
        <div key={mainCategory.title}>
          <Title level={3}>{mainCategory.title}</Title>
          {mainCategory.colors ? (
            <Row gutter={[16, 16]}>
              {mainCategory.colors.map((color) => (
                <Col span={3} key={color.className}>
                  <Tooltip
                    title={`Copied ${color.hex}`}
                    visible={copiedColor === color.hex}
                    placement="top"
                  >
                    <div
                      className={`color-swatch ${color.className}`}
                      style={{
                        padding: '20px',
                        textAlign: 'center',
                        border: '1px solid #e2e8f0',
                        borderRadius: '4px',
                        position: 'relative',
                        color: color.className.match(/(400|500|600)$/) ? '#ffffff' : '#334155',
                      }}
                    >
                      <Button
                        icon={<CopyOutlined />}
                        size="small"
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          border: 'none',
                          background: 'transparent',
                          color: color.className.match(/(400|500|600)$/) ? '#ffffff' : '#334155',
                        }}
                        onClick={() => handleCopy(color.hex)}
                      />
                      <div>{color.label}</div>
                      <div>{color.hex}</div>
                    </div>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          ) : (
            <Row gutter={[16, 16]}>
              {mainCategory.columns.map((column) => (
                <Col span={3} key={column.title}>
                  <Title level={5}>{column.title}</Title>
                  {column.colors.map((color) => (
                    <Tooltip
                      title={`Copied ${color.hex}`}
                      visible={copiedColor === color.hex}
                      placement="top"
                      key={color.className}
                    >
                      <div
                        className={`color-swatch ${color.className}`}
                        style={{
                          padding: '20px',
                          textAlign: 'center',
                          border: '1px solid #e2e8f0',
                          borderRadius: '4px',
                          position: 'relative',
                          color: color.className.match(/(400|500|600)$/) ? '#ffffff' : '#334155',
                        }}
                      >
                        <Button
                          icon={<CopyOutlined />}
                          size="small"
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            border: 'none',
                            background: 'transparent',
                            color: color.className.match(/(400|500|600)$/) ? '#ffffff' : '#334155',
                          }}
                          onClick={() => handleCopy(color.hex)}
                        />
                        <div>{color.label}</div>
                        <div>{color.hex}</div>
                      </div>
                    </Tooltip>
                  ))}
                </Col>
              ))}
            </Row>
          )}
          <Divider />
        </div>
      ))}
    </>
  );
};

export default Colors;
