import React from 'react';

// Import the SVGs
import PngIcon from '../../../Assets/AIOIcons/SVG/PNG.svg';
import JpgIcon from '../../../Assets/AIOIcons/SVG/JPG.svg';
import GifIcon from '../../../Assets/AIOIcons/SVG/GIF.svg';
import XlsIcon from '../../../Assets/AIOIcons/SVG/EXL.svg';
import DocIcon from '../../../Assets/AIOIcons/SVG/WORD.svg';
import PptIcon from '../../../Assets/AIOIcons/SVG/PPT.svg';
import ZipIcon from '../../../Assets/AIOIcons/SVG/ZIP.svg';
import RarIcon from '../../../Assets/AIOIcons/SVG/RAR.svg';

const FilesTableName = ({ name, type }) => {
  // Determine the file extension and return the appropriate SVG or FontAwesome icon
  const getFileIcon = (fileName) => {
    if (type === 'folder') {
      return <i className="fi fi-sr-folder-open file-icon"></i>; // Folder icon
    }

    const extension = fileName.split('.').pop().toLowerCase();

    switch (extension) {
      case 'png':
        return <img src={PngIcon} alt="PNG Icon" className="file-icon" />;
      case 'jpg':
        return <img src={JpgIcon} alt="JPG Icon" className="file-icon" />;
      case 'gif':
        return <img src={GifIcon} alt="GIF Icon" className="file-icon" />;
      case 'xls':
      case 'xlsx':
        return <img src={XlsIcon} alt="Excel Icon" className="file-icon" />;
      case 'doc':
      case 'docx':
        return <img src={DocIcon} alt="Word Icon" className="file-icon" />;
      case 'ppt':
      case 'pptx':
        return <img src={PptIcon} alt="PowerPoint Icon" className="file-icon" />;
      case 'zip':
        return <img src={ZipIcon} alt="Zip Icon" className="file-icon" />;
      case 'rar':
        return <img src={RarIcon} alt="RAR Icon" className="file-icon" />;
      case 'pdf':
        return <i className="fi fi-sr-file-pdf file-icon"></i>;
      default:
        return <i className="fi fi-rr-file file-icon"></i>; // Default icon
    }
  };

  return (
    <span className="file-name">
      {getFileIcon(name)} {/* Render the appropriate icon */}
      {name}
    </span>
  );
};

export default FilesTableName;
