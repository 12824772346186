// Calendar.js

import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import tasks from './CalendarData';
import { Card, Row, Col } from 'antd';
import './Calendar.less'; // Ensure this file includes your styles
import SingleAvatar2 from '../../../Components/SingleAvatar2'; // Import SingleAvatar2
import CustomEvent from './CustomEvent'; // Import the CustomEvent component

const localizer = momentLocalizer(moment);

// Convert tasks to events compatible with react-big-calendar
const events = tasks.map(task => ({
  id: task.id,
  title: task.text,
  start: new Date(task.start_date),
  end: new Date(task.end_date),
  resource: task.assignee,
  status: task.status, // Include status
}));

// Function to assign class names based on task status
const eventPropGetter = (event, start, end, isSelected) => {
  let className = '';

  switch (event.status) {
    case 'Completed':
      className = 'cp-task-completed';
      break;
    case 'In Progress':
      className = 'cp-task-in-progress';
      break;
    case 'Not Started':
      className = 'cp-task-not-started';
      break;
    case 'In Review':
      className = 'cp-task-in-review';
      break;
    default:
      className = '';
  }

  return { className };
};

const MyCalendar = () => (
  <div className="cp-calendar-container">
    <Row>
      <Col span={24}>
        <Card className="cp-calendar-card">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            className="cp-calendar"
            views={['month', 'week', 'day']}
            eventPropGetter={eventPropGetter}
            components={{
              event: CustomEvent, // Use the custom event component
            }}
          />
        </Card>
      </Col>
    </Row>
  </div>
);

export default MyCalendar;
