const SampleTasksData = [
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Figma Mockups',
    subTask: 'Home Page Design',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Shanto',
    assignee: 'Shanto',
    taskCreated: '06/01/2024',
    dueDate: '06/01/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Figma Mockups',
    subTask: 'Contact Page Design',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Shanto',
    assignee: 'Shanto',
    taskCreated: '06/03/2024',
    dueDate: '06/03/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Figma Mockups',
    subTask: 'About Page Design',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Shanto',
    assignee: 'Shanto',
    taskCreated: '06/05/2024',
    dueDate: '06/05/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Figma Mockups',
    subTask: 'Services Page Design',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Shanto',
    assignee: 'Shanto',
    taskCreated: '06/07/2024',
    dueDate: '06/07/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Silver Wave Marketing',
    subClientName: 'Oceanic High',
    project: 'Website Redesign',
    task: 'Frontend Development',
    subTask: 'Landing Page Design',
    hoursAllotted: 8,
    hoursTracked: 4,
    accountManager: 'John',
    assignee: 'John',
    taskCreated: '07/01/2024',
    dueDate: '07/10/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Golden Gate Media',
    subClientName: 'Sunset Realty',
    project: 'SEO Optimization',
    task: 'Content Creation',
    subTask: 'Blog Posts',
    hoursAllotted: 12,
    hoursTracked: 12,
    accountManager: 'Jane',
    assignee: 'Jane',
    taskCreated: '07/15/2024',
    dueDate: '07/20/2024',
    status: 'Completed',
  },
  {
    clientName: 'Greenfield Solutions',
    subClientName: 'EcoLife',
    project: 'App Development',
    task: 'Backend Development',
    subTask: 'API Integration',
    hoursAllotted: 20,
    hoursTracked: 15,
    accountManager: 'Samantha',
    assignee: 'Samantha',
    taskCreated: '06/20/2024',
    dueDate: '07/01/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Highland Creatives',
    subClientName: 'Mountain Trek',
    project: 'Brand Identity',
    task: 'Logo Design',
    subTask: 'Concept Creation',
    hoursAllotted: 6,
    hoursTracked: 6,
    accountManager: 'Mike',
    assignee: 'Mike',
    taskCreated: '07/10/2024',
    dueDate: '07/15/2024',
    status: 'Completed',
  },
  {
    clientName: 'Dynamic Marketing',
    subClientName: 'Velocity Sports',
    project: 'Social Media Campaign',
    task: 'Ad Creation',
    subTask: 'Instagram Ads',
    hoursAllotted: 10,
    hoursTracked: 8,
    accountManager: 'Linda',
    assignee: 'Linda',
    taskCreated: '07/05/2024',
    dueDate: '07/12/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Blue Sky Advertising',
    subClientName: 'Aviation Academy',
    project: 'Website Launch',
    task: 'Testing and QA',
    subTask: 'User Testing',
    hoursAllotted: 15,
    hoursTracked: 5,
    accountManager: 'Alex',
    assignee: 'Alex',
    taskCreated: '06/25/2024',
    dueDate: '07/05/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Peak Performance',
    subClientName: 'Alpine Adventures',
    project: 'Marketing Strategy',
    task: 'Market Research',
    subTask: 'Competitor Analysis',
    hoursAllotted: 10,
    hoursTracked: 10,
    accountManager: 'Dave',
    assignee: 'Dave',
    taskCreated: '07/01/2024',
    dueDate: '07/10/2024',
    status: 'Completed',
  },
  {
    clientName: 'Starlight Media',
    subClientName: 'Cosmic Travels',
    project: 'Video Production',
    task: 'Editing',
    subTask: 'Post-Production',
    hoursAllotted: 20,
    hoursTracked: 20,
    accountManager: 'Sara',
    assignee: 'Sara',
    taskCreated: '07/08/2024',
    dueDate: '07/20/2024',
    status: 'Completed',
  },
  {
    clientName: 'Innovatech',
    subClientName: 'Tech Titans',
    project: 'Software Development',
    task: 'Feature Implementation',
    subTask: 'User Authentication',
    hoursAllotted: 25,
    hoursTracked: 20,
    accountManager: 'Mark',
    assignee: 'Mark',
    taskCreated: '06/30/2024',
    dueDate: '07/15/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Bright Future Education',
    subClientName: 'StudyMate',
    project: 'E-Learning Platform',
    task: 'Content Development',
    subTask: 'Course Modules',
    hoursAllotted: 18,
    hoursTracked: 18,
    accountManager: 'Emma',
    assignee: 'Emma',
    taskCreated: '07/10/2024',
    dueDate: '07/25/2024',
    status: 'Completed',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Backend Setup',
    subTask: 'WordPress and Elementor Setup',
    hoursAllotted: 10,
    hoursTracked: 10,
    accountManager: 'Sazzad',
    assignee: 'Sazzad',
    taskCreated: '06/10/2024',
    dueDate: '06/10/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Backend Setup',
    subTask: 'MyListing Theme Configuration',
    hoursAllotted: 15,
    hoursTracked: 15,
    accountManager: 'Sazzad',
    assignee: 'Sazzad',
    taskCreated: '06/15/2024',
    dueDate: '06/15/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Backend Setup',
    subTask: 'Subscription Features Setup',
    hoursAllotted: 25,
    hoursTracked: 25,
    accountManager: 'Sazzad',
    assignee: 'Sazzad',
    taskCreated: '06/20/2024',
    dueDate: '06/20/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Backend Setup',
    subTask: 'Plugin Integration',
    hoursAllotted: 20,
    hoursTracked: 20,
    accountManager: 'Sazzad',
    assignee: 'Sazzad',
    taskCreated: '06/25/2024',
    dueDate: '06/25/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Front End Polishing',
    subTask: 'Visual Enhancements',
    hoursAllotted: 20,
    hoursTracked: 20,
    accountManager: 'Shanto',
    assignee: 'Shanto',
    taskCreated: '06/27/2024',
    dueDate: '06/27/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Front End Polishing',
    subTask: 'Mobile Optimization',
    hoursAllotted: 10,
    hoursTracked: 10,
    accountManager: 'Alex',
    assignee: 'Alex',
    taskCreated: '06/29/2024',
    dueDate: '06/29/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Quality Testing',
    subTask: 'Functionality Testing',
    hoursAllotted: 10,
    hoursTracked: 10,
    accountManager: 'Alex',
    assignee: 'Alex',
    taskCreated: '07/01/2024',
    dueDate: '07/01/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Quality Testing',
    subTask: 'Performance Testing',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Alex',
    assignee: 'Alex',
    taskCreated: '07/03/2024',
    dueDate: '07/03/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Catalyst Marketing',
    subClientName: 'Tulsa REIA',
    project: 'Website Development',
    task: 'Communication & Meetings',
    subTask: 'Project Milestone Meetings',
    hoursAllotted: 10,
    hoursTracked: 10,
    accountManager: 'Mayowa',
    assignee: 'Mayowa',
    taskCreated: '07/05/2024',
    dueDate: '07/05/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Blue Empress Marketing',
    subClientName: 'Corum Education',
    project: 'Branding and Website Development',
    task: 'Logo and Branding Design',
    subTask: 'Logo Design',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Mizan',
    assignee: 'Mizan',
    taskCreated: '06/15/2024',
    dueDate: '06/15/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Blue Empress Marketing',
    subClientName: 'Corum Education',
    project: 'Branding and Website Development',
    task: 'Logo and Branding Design',
    subTask: 'Branding Elements Creation',
    hoursAllotted: 10,
    hoursTracked: 10,
    accountManager: 'Mizan',
    assignee: 'Mizan',
    taskCreated: '06/20/2024',
    dueDate: '06/20/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Blue Empress Marketing',
    subClientName: 'Corum Education',
    project: 'Branding and Website Development',
    task: 'Figma Mockups',
    subTask: 'Homepage Mockup',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Mamun',
    assignee: 'Mamun',
    taskCreated: '06/25/2024',
    dueDate: '06/25/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Blue Empress Marketing',
    subClientName: 'Corum Education',
    project: 'Branding and Website Development',
    task: 'Figma Mockups',
    subTask: 'Subpages Mockup',
    hoursAllotted: 5,
    hoursTracked: 5,
    accountManager: 'Mamun',
    assignee: 'Mamun',
    taskCreated: '06/30/2024',
    dueDate: '06/30/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Blue Empress Marketing',
    subClientName: 'Corum Education',
    project: 'Branding and Website Development',
    task: 'WordPress Website Development',
    subTask: 'Initial Setup',
    hoursAllotted: 20,
    hoursTracked: 20,
    accountManager: 'Shanto',
    assignee: 'Shanto',
    taskCreated: '07/05/2024',
    dueDate: '07/05/2024',
    status: 'Not Started',
  },
  {
    clientName: 'Blue Empress Marketing',
    subClientName: 'Corum Education',
    project: 'Branding and Website Development',
task: 'WordPress Website Development',
subTask: 'Feature Integration',
hoursAllotted: 30,
hoursTracked: 30,
accountManager: 'Shanto',
assignee: 'Shanto',
taskCreated: '07/15/2024',
dueDate: '07/15/2024',
status: 'Completed',
},
{
clientName: 'Blue Empress Marketing',
subClientName: 'Corum Education',
project: 'Branding and Website Development',
task: 'WordPress Website Development',
subTask: 'Content and SEO Optimization',
hoursAllotted: 30,
hoursTracked: 30,
accountManager: 'Shanto',
assignee: 'Shanto',
taskCreated: '07/25/2024',
dueDate: '07/25/2024',
status: 'Completed',
},
{
clientName: 'Blue Empress Marketing',
subClientName: 'Corum Education',
project: 'Branding and Website Development',
task: 'Moodle Integration & Testing',
subTask: 'Moodle Setup',
hoursAllotted: 10,
hoursTracked: 10,
accountManager: 'Alex',
assignee: 'Alex',
taskCreated: '07/30/2024',
dueDate: '07/30/2024',
status: 'Completed',
},
{
clientName: 'Blue Empress Marketing',
subClientName: 'Corum Education',
project: 'Branding and Website Development',
task: 'Moodle Integration & Testing',
subTask: 'Quality Testing',
hoursAllotted: 5,
hoursTracked: 5,
accountManager: 'Alex',
assignee: 'Alex',
taskCreated: '08/05/2024',
dueDate: '08/05/2024',
status: 'Completed',
},

{
clientName: 'Blue Empress Marketing',
subClientName: 'Corum Education',
project: 'Branding and Website Development',
task: 'Communication & Account Management',
subTask: 'Client Meetings',
hoursAllotted: 5,
hoursTracked: 5,
accountManager: 'Mayowa',
assignee: 'Shanto',
taskCreated: '08/15/2024',
dueDate: '08/15/2024',
status: 'Not Started',
},
{
    clientName: 'Bright Future Education',
    subClientName: 'StudyMate',
    project: 'E-Learning Platform',
    task: 'UI/UX Design',
    subTask: 'Wireframing',
    hoursAllotted: 12,
    hoursTracked: 10,
    accountManager: 'Mayowa',
    assignee: 'Alex',
    taskCreated: '07/05/2024',
    dueDate: '07/15/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Bright Future Education',
    subClientName: 'StudyMate',
    project: 'E-Learning Platform',
    task: 'Content Review',
    subTask: 'Proofreading',
    hoursAllotted: 8,
    hoursTracked: 8,
    accountManager: 'Mayowa',
    assignee: 'Alex',
    taskCreated: '07/10/2024',
    dueDate: '07/12/2024',
    status: 'Completed',
  },
  {
    clientName: 'Bright Future Education',
    subClientName: 'StudyMate',
    project: 'E-Learning Platform',
    task: 'Marketing Strategy',
    subTask: 'Social Media Campaign',
    hoursAllotted: 20,
    hoursTracked: 15,
    accountManager: 'Mayowa',
    assignee: 'Alex',
    taskCreated: '07/15/2024',
    dueDate: '07/30/2024',
    status: 'In Progress',
  },
  {
    clientName: 'Bright Future Education',
    subClientName: 'StudyMate',
    project: 'E-Learning Platform',
    task: 'Technical Support',
    subTask: 'User Support',
    hoursAllotted: 10,
    hoursTracked: 10,
    accountManager: 'Mayowa',
    assignee: 'Alex',
    taskCreated: '07/20/2024',
    dueDate: '07/25/2024',
    status: 'Completed',
  },
  {
    clientName: 'Bright Future Education',
    subClientName: 'StudyMate',
    project: 'E-Learning Platform',
    task: 'Feature Enhancement',
    subTask: 'New Module Development',
    hoursAllotted: 30,
    hoursTracked: 25,
    accountManager: 'Mayowa',
    assignee: 'Shanto',
    taskCreated: '07/25/2024',
    dueDate: '08/10/2024',
    status: 'In Progress',
  },
];

export default SampleTasksData;
