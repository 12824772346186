import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './MiscPages.less';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/');
  };

  return (
    <div>
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i class="fi fi-rr-not-found-alt"></i>
        </span>
        404 Error | Page Not Found
      </p>

      <div className="not-found-container">
       <img src="https://i.ibb.co/bWTx8b2/404-1.png" alt="404" className="not-found-image" />
       <p className="not-found-text">Oops! The page you are looking for cannot be found.</p>
       <Button  className="not-found-button" onClick={handleBackToDashboard}>
         Back To Dashboard
       </Button>
     </div>

    </div>
  );
};

export default NotFoundPage;
