// ClientChat.js
import React, { useState } from 'react';
import BreadcrumbComponent from '../../Components/breadcrumb'; // Import BreadcrumbComponent
import ClientChatTitle from './ClientChatComponents/ClientChatTitle'; // Import ClientChatTitle
import ClientChatMessages from './ClientChatComponents/ClientChatMessages'; // Import ClientChatMessages
import { teamMembers, clients, initialMessages } from './ClientChatComponents/ClientChatData';

import './ClientsPages.less';

const ClientChat = () => {
  const [expanded, setExpanded] = useState(false); // State for expanding/collapsing messages
  const [settings, setSettings] = useState({ notificationsDisabled: false }); // Settings state (can add more)

  const handleAddMember = (project) => {
    console.log(`Adding member to project: ${project}`);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const updateSettings = (newSettings) => {
    setSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
  };

  // Breadcrumb items
  const breadcrumbItems = [
    { title: 'Clients', link: '/clients' },
    { title: 'Catalyst Marketing Group', link: '/clients/catalyst-marketing' },
    { title: 'Chat', link: '/clients/catalyst-marketing/chat' }
  ];

  return (
    <div className="ct-chat-main-container">
      {/* Breadcrumb Component */}
      <div className="ct-chat-fixed-container">
      <div className="ct-chat-breadcrumb-container">
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      {/* Title Section */}
      <ClientChatTitle
    teamMembers={teamMembers}
    clients={clients}
    handleAddMember={handleAddMember}
    expanded={expanded}
    toggleExpand={toggleExpand}
    updateSettings={updateSettings}
  />

      </div>

      {/* Messages Section */}
      <ClientChatMessages
        initialMessages={initialMessages}
        expanded={expanded}
        settings={settings}
      />
    </div>
  );
};

export default ClientChat;
