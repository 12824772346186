// Tickets.js

import React, { useState } from 'react';
import { Button } from 'antd';
import { NavLink } from 'react-router-dom';

import TicketsTable from './TicketsComponents/TicketsTable';
import TicketStats from './TicketsComponents/TicketStats';
import MemberList from './TicketsComponents/MemberList';
import ChartDashboard from './TicketsComponents/ChartDashboard';
import TicketDrawer from './TicketsComponents/TicketDrawer';

import './Tickets.less';

const Tickets = () => {
  // State to manage the visibility of the drawer
  const [visibility, setVisibility] = useState({
    drawer: false,
  });

  // Function to toggle visibility of the drawer
  const toggleVisibility = (type) => {
    setVisibility((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  return (
    <div id="tickets-dashboard">
      {/* Member List Component */}
      <MemberList />

      {/* Ticket Statistics Component */}
      <TicketStats />

      {/* Chart Dashboard Component */}
      <ChartDashboard />

      {/* Tickets Table Section */}
      <div className="whitebox">
        <div className="whitebox-title">
          <h3>All Tickets</h3>
          <NavLink to="/Tickets-Table-Page">
            <Button className="button-2">View All</Button>
          </NavLink>
        </div>
        {/* Tickets Table without Pagination */}
        <TicketsTable currentPage={1} rowsPerPage={10} /> {/* Show all tickets */}
      </div>

      {/* Create New Ticket Button */}
      <Button
        type="primary"
        className="create-ticket-btn"
        onClick={() => toggleVisibility('drawer')}
      >
        Create New Ticket
      </Button>

      {/* Ticket Drawer Component */}
      <TicketDrawer
        visible={visibility.drawer}
        onClose={() => toggleVisibility('drawer')}
      />
    </div>
  );
};

export default Tickets;
