import React from 'react';
import { Select, Button } from 'antd';
import FilesTable from './FilesComponents/FilesTable';
import FilesBreadcrumb from './FilesComponents/FilesBreadcrumb';
import { useFilesLogic } from './FilesComponents/FilesLogic';
import DesignFeedback from './DesignFeedback';
import './FilesPages.less';

const { Option } = Select;

const FilesPage = () => {
  const { currentFolder, currentPath, selectedImage, navigateToFolder, goBack, selectImage } = useFilesLogic();

  const handleBreadcrumbClick = (index) => {
    if (index === 0) {
      navigateToFolder('My Files');
    } else {
      goBack(index);
    }
  };

  const handleSortChange = (value) => {
    // Implement sorting logic here based on the selected value
  };

  return (
    <div className="fp-files-container">
      <FilesBreadcrumb path={currentPath} onNavigate={handleBreadcrumbClick} />

      {selectedImage ? (
        <DesignFeedback selectedImage={selectedImage} />
      ) : (
        <>
          <div className="fp-files-table-topbar">
            <div className="fp-files-table-topbar-left">
              <span>Sort By: </span>
              <Select value="Date" onChange={handleSortChange} className="fp-sort-dropdown">
                <Option value="FileType">File Type</Option>
                <Option value="Date">Date</Option>
                <Option value="FileSize">File Size</Option>
              </Select>
            </div>
            <div className="fp-files-table-topbar-right">
              <Button className="fp-add-file-button"><i className="fi fi-rr-plus-small"></i>Add File</Button>
              <Button className="fp-add-folder-button"><i className="fi fi-rr-plus-small"></i>Add Folder</Button>
            </div>
          </div>

          <FilesTable
            displayedRows={currentFolder}
            navigateToFolder={navigateToFolder}
            selectImage={selectImage} // Pass the selectImage function here
          />
        </>
      )}
    </div>
  );
};

export default FilesPage;
