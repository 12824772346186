import { useState } from 'react';
import projectsData from '../../Data/SampleProjects';
import ProjectNameColumn from './ProjectsTableColumns/ProjectNameColumn';
import TeamCell from './ProjectsTableColumns/TeamCell';
import DateColumn from './ProjectsTableColumns/DateColumn';
import ProgressColumn from './ProjectsTableColumns/ProgressColumn';
import StatusColumn from './ProjectsTableColumns/StatusColumn'; // Import the new StatusColumn

const useProjectTableLogic = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState(projectsData);

  const currentRows = projects.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const showModal = (record) => {
    const projectWithDetails = {
      ...record,
      members: record.teamMembers.map(tm => tm.id),
      clients: [record.client.id]
    };
    setSelectedProject(projectWithDetails);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDateChange = (index, id, dates) => {
    const [startDate, endDate] = dates;
    setProjects(prevProjects =>
      prevProjects.map((proj, projIndex) =>
        projIndex === index ? { ...proj, startDate, endDate } : proj
      )
    );
  };

  const handleStatusChange = (project, newStatus) => {
    setProjects(prevProjects =>
      prevProjects.map((proj) =>
        proj.name === project.name ? { ...proj, projectStatus: newStatus } : proj
      )
    );
  };

  const columns = [
    { Header: 'Project', accessor: 'name', render: (text, record) => ProjectNameColumn.render(text, record) },
    {
      Header: 'Progress', accessor: 'progressBar', render: (text, record) => (
        <ProgressColumn project={record} />
      )
    },
    {
      Header: 'Status', accessor: 'status', render: (text, record) => (
        <StatusColumn project={record} handleStatusChange={handleStatusChange} />
      )
    },
    {
      Header: 'Timeline', accessor: 'date', render: (text, record, index) => (
        <DateColumn record={record} index={index} handleDateChange={handleDateChange} />
      )
    },
    {
      Header: 'Team', accessor: 'team', render: (text, record) => (
        <TeamCell record={record} showModal={() => showModal(record)} />
      )
    }
  ];

  return {
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    isModalVisible,
    handleCancel,
    selectedProject,
    projects,
    currentRows,
    columns,
    showModal
  };
};

export default useProjectTableLogic;
