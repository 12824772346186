import React, { useState } from 'react';
import { Input, Button, Avatar, List } from 'antd';

const { TextArea } = Input;

const TaskComments = ({
  comments,
  handleCommentChange,
  handlePostComment
}) => {
  const [commentList, setCommentList] = useState([
    { id: 1, name: 'Noman Hossain', message: 'Here is a sample message.', date: '16 days ago' },
    { id: 2, name: 'Sazzad Mahmud', message: 'Here is another sample message.', date: '2 minutes ago' }
  ]);

  const handlePost = () => {
    handlePostComment();
    setCommentList([
      ...commentList,
      { id: commentList.length + 1, name: 'Noman Hossain', message: comments, date: 'Just now' }
    ]);
  };

  return (
    <div className="tdm-task-comments">
      <h3 className="tdm-comments-title">Comments</h3>
      <List
        itemLayout="horizontal"
        className="tdm-comments-list"
        dataSource={commentList}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar>{item.name.split(' ').map(n => n[0]).join('')}</Avatar>}
              title={<span>{item.name} <span className="tdm-message-date"> {item.date}</span></span>}
              description={<span className="tdm-comment-message">{item.message}</span>}
            />
          </List.Item>
        )}
      />
      <TextArea
        value={comments}
        onChange={handleCommentChange}
        rows={4}
        className="tdm-comments-textarea"
        placeholder="Type / for menu"
      />
      <Button type="secondary" onClick={handlePost} className="tdm-comments-button">
        Comment
      </Button>
    </div>
  );
};

export default TaskComments;
