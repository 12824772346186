// ClientMainBanner.js
import React from 'react';
import { Typography } from 'antd';
import TeamAvatars from '../../../Components/TeamAvatars'; // Import TeamAvatars

const { Title } = Typography;

const ClientMainBanner = () => {
  // Define teamMembers and clients
  const teamMembers = [
    { name: 'Alice Jonson' },
    { name: 'Bob Smith' },
    { name: 'Charlie Brown' },
    { name: 'Noman Hossain' },
    { name: 'Kim Peterson' },
    { name: 'Osama Bin Laden' }
  ];

  const clients = [
    { name: 'Emma Watson' },
    { name: 'David Johnson' },
    { name: 'Sophia Williams' }
  ];

  const handleAddMember = () => {
    // Implement the function if needed
    console.log('Add member clicked');
  };

  return (
    <div className="ct-banner-container">
      <div className="ct-banner-background">
        <img
          src="https://i.ibb.co/RHJH7fX/pexels-seven11nash-380768.jpg"
          alt="Banner"
          className="ct-banner-image"
        />
      </div>
      <div className="ct-banner-overlay"></div>
      <div className="ct-banner-content">
        <div className="ct-logo-container">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgCLvewkx3TpO8QJN6fKLs1R8Ucp2KCHoTZw&s"
            alt="CMG Logo"
            className="ct-logo-image"
          />
        </div>
        <div className="ct-banner-title">
          <Title level={2} className="ct-title">
            Glo Tanning
          </Title>
        </div>
        {/* Right-side: Team Avatars */}
        <div className="ct-banner-icons">
          <div className="ct-team-container">
            <TeamAvatars
              teamMembers={teamMembers}
              clients={clients}
              handleAddMember={handleAddMember}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientMainBanner;
