import React, { useState } from 'react';
import { Tooltip, Avatar } from 'antd';
import { lightColors, darkColors, hashStringToIndex } from './AvatarColors'; // Import from AvatarColors
import AddMemberButton from './AddMemberButton';
import ManageTeamModal from '../Modals/ManageTeamModal';
import './TeamAvatars.less';
import './UserAvatars.less';

// Function to get two random colors for a given name based on hash
const getAvatarColors = (name) => {
  const isDark = hashStringToIndex(name, 2) % 2 === 0; // Alternate between light and dark colors
  const colors = isDark ? darkColors : lightColors;

  const color1Index = hashStringToIndex(name + '1', colors.length);
  const color2Index = hashStringToIndex(name + '2', colors.length);

  const color1 = colors[color1Index];
  const color2 = colors[color2Index];

  const isLightColor = lightColors.includes(color1) && lightColors.includes(color2);

  return { color1, color2, isLightColor };
};

// Helper function to create Avatar with colors and a Tooltip
const createAvatar = (name, size = '34', fontSize = '14') => {
  const { color1, color2, isLightColor } = getAvatarColors(name);
  const initials = name.split(' ').map(n => n[0]).join('').slice(0, 2).toUpperCase();
  const avatarStyle = {
    background: `linear-gradient(135deg, ${color1}, ${color2})`,
    color: isLightColor ? '#000' : '#FFF',
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${fontSize}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <Tooltip title={name} key={name}>
      <Avatar style={avatarStyle}>
        {initials}
      </Avatar>
    </Tooltip>
  );
};

const TeamAvatars = ({ teamMembers, clients, handleAddMember }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onAddMemberClick = () => {
    setIsModalVisible(true);
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  // Display up to 4 team members with initials, 5th for the extra count
  const displayedTeamMembers = teamMembers.slice(0, 4);
  const extraTeamMembers = teamMembers.slice(4); // Remaining members for the tooltip

  // Display up to 2 clients with initials, 3rd for the extra count
  const displayedClients = clients.slice(0, 2);
  const extraClients = clients.slice(2); // Remaining clients for the tooltip

  return (
    <div className="team-avatars-container">
      {/* Team Members Avatar Group */}
      <div className="team-avatars">
        {displayedTeamMembers.map((member) => createAvatar(member.name))}
        {extraTeamMembers.length > 0 && (
          <Tooltip title={extraTeamMembers.map((member) => member.name).join(', ')}>
            <Avatar
              style={{
                backgroundColor: '#fde3cf',
                color: '#f56a00',
                width: '34px',
                height: '34px',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {`+${extraTeamMembers.length}`}
            </Avatar>
          </Tooltip>
        )}
      </div>

      {/* Clients Avatar Group */}
      <div className="client-avatars">
        {displayedClients.map((client) => createAvatar(client.name))}
        {extraClients.length > 0 && (
          <Tooltip title={extraClients.map((client) => client.name).join(', ')}>
            <Avatar
              style={{
                backgroundColor: '#fde3cf',
                color: '#f56a00',
                width: '34px',
                height: '34px',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {`+${extraClients.length}`}
            </Avatar>
          </Tooltip>
        )}
      </div>

      {/* Add Member Button */}
      <AddMemberButton className="add-member-button" project="Project Name" handleAddMember={onAddMemberClick} />

      {/* Manage Team Modal */}
      <ManageTeamModal
        isVisible={isModalVisible}
        toggleModal={toggleModal}
        teamMembers={teamMembers}
        clientsData={clients}
      />
    </div>
  );
};

export default TeamAvatars;
