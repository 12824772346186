// Modal.js

import React, { useState } from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import DefaultModal from '../../Modals/DefaultModal'; // Adjust the import path if necessary
import './InternalPages.less'; // Include the styles

const ModalComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const handleOk = () => {
    message.success('Modal saved!');
    toggleModal();
  };

  const codeToCopy = `
import React, { useState } from 'react';
import { Button } from 'antd';
import DefaultModal from './DefaultModal';

const MyModalComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const handleOk = () => {
    // Your save logic here
    toggleModal();
  };

  return (
    <>
      <Button type="primary" onClick={toggleModal}>
        Open Modal
      </Button>
      <DefaultModal
        isVisible={isVisible}
        toggleModal={toggleModal}
        title="Sample Modal"
        icon="user-add"
        footerContent={[
          <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
            Cancel
          </Button>,
          <Button key="submit" className="modal-save-button" type="primary" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        <p>This is a sample modal using DefaultModal component.</p>
      </DefaultModal>
    </>
  );
};

export default MyModalComponent;
`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeToCopy.trim()).then(
      () => message.success('Code copied to clipboard!'),
      () => message.error('Failed to copy code.')
    );
  };

  return (
    <div className="section-container">
      <h3>Modal</h3>
      <div className="section-and-code">
        {/* Modal Display */}
        <div className="modal-display">
          <Button type="primary" onClick={toggleModal}>
            Open Modal
          </Button>
          <DefaultModal
            isVisible={isVisible}
            toggleModal={toggleModal}
            title="Sample Modal"
            icon="user-add"
            footerContent={[
              <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
                Cancel
              </Button>,
              <Button key="submit" className="modal-save-button" type="primary" onClick={handleOk}>
                Save
              </Button>,
            ]}
          >
            <p>This is a sample modal using DefaultModal component.</p>
          </DefaultModal>
        </div>

        {/* Code Display with Copy Button */}
        <div className="code-container">
          <Button
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            className="copy-code-button"
          >
            Copy Code
          </Button>
          <pre className="code-snippet">
{codeToCopy}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default ModalComponent;
