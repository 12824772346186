import React, { useState, useEffect } from 'react';
import { Table, Dropdown, Menu, Button, Tooltip } from 'antd';
import { EllipsisOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';

import clientsData from '../../Data/SampleClients';

const Clients = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(null);
  const [copiedPhone, setCopiedPhone] = useState(null);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text);
    if (type === 'email') {
      setCopiedEmail(text);
      setTimeout(() => setCopiedEmail(null), 2000);
    } else {
      setCopiedPhone(text);
      setTimeout(() => setCopiedPhone(null), 2000);
    }
  };

  const renderContactInfo = (record) => (
    <Menu>
      <Menu.Item key="1">
        Email: {record.clientEmail}{' '}
        <Tooltip title="Copied!" visible={copiedEmail === record.clientEmail}>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(record.clientEmail, 'email')}
          />
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="2">
        Phone: {record.phoneNumber}{' '}
        <Tooltip title="Copied!" visible={copiedPhone === record.phoneNumber}>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(record.phoneNumber, 'phone')}
          />
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item key="1">Send email</Menu.Item>
      <Menu.Item key="2">Send Text</Menu.Item>
      <Menu.Item key="3">Update Status</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
      responsive: ['lg']
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      responsive: ['lg']
    },
    {
      title: 'Contact Info',
      key: 'contactInfo',
      render: (text, record) => (
        <Dropdown overlay={renderContactInfo(record)} trigger={['click']}>
          <Button icon={<EyeOutlined />} />
        </Dropdown>
      )
    },
    {
      title: 'Hours',
      key: 'hours',
      render: (text, record) => (
        <div>
          <div>Purchased: {record.hoursPurchased}</div>
          <div>Used: {record.hoursSpent}</div>
          <div>Left: {record.hoursLeft}</div>
        </div>
      )
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      responsive: ['lg']
    },
    {
      title: 'Client Status',
      dataIndex: 'clientStatus',
      key: 'clientStatus',
      render: status => {
        let color;
        switch (status) {
          case 'Active':
            color = 'green';
            break;
          case 'Inactive':
            color = 'red';
            break;
          case 'Free Trial':
            color = 'blue';
            break;
          default:
            color = 'gray';
        }
        return <span style={{ color }}>{status}</span>;
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: () => (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="slate-100" icon={<EllipsisOutlined />} />
        </Dropdown>
      )
    }
  ];

  return (

      <div style={{ padding: '24px 0' }}>
        <div style={{ overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={clientsData}
            rowKey="clientEmail"
            pagination={{ pageSize: 5 }}
            rowClassName={(record, index) =>
              index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
            }
          />
        </div>
      </div>

  );
};

export default Clients;
