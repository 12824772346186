// src/utils/projectActions.js

export const addProject = (projects, newProject) => {
  return [...projects, newProject];
};

export const removeProject = (projects, projectId) => {
  return projects.filter(project => project.id !== projectId);
};

export const updateProject = (projects, projectId, updatedProject) => {
  return projects.map(project =>
    project.id === projectId ? { ...project, ...updatedProject } : project
  );
};
