// data/SampleAutomations.js

export const automationsData = [
  {
    number: 1,
    title: 'Excel Sheet Row Update Sends an Email',
    description: 'When a new row is added to an Excel sheet, an email is automatically sent.',
    steps: [
      { id: 1, step: 'New row added' },
      { id: 2, step: 'Email is drafted' },
      { id: 3, step: 'Email is sent' },
    ],
    stats: { today: 5, last7Days: 30, last30Days: 120, allTime: 200 },
    status: 'Active',
    createdDate: '2023-05-01',
  },
  {
    number: 2,
    title: 'New Trello Card Creates Google Calendar Event',
    description: 'When a new card is added to Trello, a Google Calendar event is created.',
    steps: [
      { id: 1, step: 'New card added to Trello' },
      { id: 2, step: 'Event details are drafted' },
      { id: 3, step: 'Event is created in Google Calendar' },
      { id: 4, step: 'Reminder is set' },
    ],
    stats: { today: 3, last7Days: 21, last30Days: 90, allTime: 150 },
    status: 'Active',
    createdDate: '2023-06-15',
  },
  {
    number: 3,
    title: 'Form Submission Adds Contact to CRM',
    description: 'When a form is submitted, a new contact is added to the CRM.',
    steps: [
      { id: 1, step: 'Form submitted' },
      { id: 2, step: 'Contact is created' },
      { id: 3, step: 'Confirmation email is sent' },
      { id: 4, step: 'Follow-up task is created' },
      { id: 5, step: 'Notification sent to sales team' },
    ],
    stats: { today: 7, last7Days: 49, last30Days: 210, allTime: 300 },
    status: 'Active',
    createdDate: '2023-07-20',
  },
  {
    number: 4,
    title: 'New Slack Message Triggers SMS Alert',
    description: 'When a new message is posted in Slack, an SMS alert is sent.',
    steps: [
      { id: 1, step: 'New message in Slack' },
      { id: 2, step: 'SMS is drafted' },
      { id: 3, step: 'SMS is sent' },
    ],
    stats: { today: 2, last7Days: 14, last30Days: 60, allTime: 100 },
    status: 'Inactive',
    createdDate: '2023-08-05',
  },
  {
    number: 5,
    title: 'New GitHub Issue Creates Jira Ticket',
    description: 'When a new issue is created in GitHub, a ticket is created in Jira.',
    steps: [
      { id: 1, step: 'New issue in GitHub' },
      { id: 2, step: 'Ticket is created in Jira' },
      { id: 3, step: 'Notification is sent to team' },
      { id: 4, step: 'Ticket is prioritized' },
    ],
    stats: { today: 4, last7Days: 28, last30Days: 120, allTime: 180 },
    status: 'Active',
    createdDate: '2023-09-10',
  },
  {
    number: 6,
    title: 'New Task in Asana Updates Salesforce',
    description: 'When a new task is added in Asana, it updates Salesforce.',
    steps: [
      { id: 1, step: 'New task added in Asana' },
      { id: 2, step: 'Salesforce is updated' },
      { id: 3, step: 'Sales team notified' },
      { id: 4, step: 'Task completion tracked' },
    ],
    stats: { today: 1, last7Days: 7, last30Days: 28, allTime: 50 },
    status: 'Inactive',
    createdDate: '2023-10-05',
  },
  {
    number: 7,
    title: 'New Contact in HubSpot Triggers Welcome Email',
    description: 'When a new contact is added in HubSpot, a welcome email is sent.',
    steps: [
      { id: 1, step: 'New contact added in HubSpot' },
      { id: 2, step: 'Welcome email is drafted' },
      { id: 3, step: 'Welcome email is sent' },
      { id: 4, step: 'Follow-up task created' },
      { id: 5, step: 'Sales team notified' },
    ],
    stats: { today: 6, last7Days: 36, last30Days: 144, allTime: 250 },
    status: 'Active',
    createdDate: '2023-11-01',
  },
  {
    number: 8,
    title: 'New Survey Response Creates Google Sheets Entry',
    description: 'When a new survey response is received, it creates a new entry in Google Sheets.',
    steps: [
      { id: 1, step: 'New survey response received' },
      { id: 2, step: 'Entry is created in Google Sheets' },
    ],
    stats: { today: 0, last7Days: 0, last30Days: 0, allTime: 5 },
    status: 'Inactive',
    createdDate: '2023-12-15',
  },
  {
    number: 9,
    title: 'Dropbox File Upload Sends Notification',
    description: 'When a file is uploaded to Dropbox, a notification is sent.',
    steps: [
      { id: 1, step: 'File uploaded to Dropbox' },
      { id: 2, step: 'Notification is drafted' },
      { id: 3, step: 'Notification is sent' },
      { id: 4, step: 'Confirmation received' },
    ],
    stats: { today: 5, last7Days: 25, last30Days: 100, allTime: 180 },
    status: 'Active',
    createdDate: '2024-01-10',
  },
  {
    number: 10,
    title: 'New Tweet Triggers Slack Alert',
    description: 'When a new tweet is posted, a Slack alert is triggered.',
    steps: [
      { id: 1, step: 'New tweet posted' },
      { id: 2, step: 'Slack alert is drafted' },
      { id: 3, step: 'Slack alert is sent' },
      { id: 4, step: 'Confirmation received' },
    ],
    stats: { today: 3, last7Days: 18, last30Days: 72, allTime: 120 },
    status: 'Active',
    createdDate: '2024-02-20',
  },
  {
    number: 11,
    title: 'New Zendesk Ticket Creates Trello Card',
    description: 'When a new ticket is created in Zendesk, a Trello card is created.',
    steps: [
      { id: 1, step: 'New ticket in Zendesk' },
      { id: 2, step: 'Trello card is created' },
    ],
    stats: { today: 1, last7Days: 5, last30Days: 20, allTime: 50 },
    status: 'Inactive',
    createdDate: '2024-03-05',
  },
  {
    number: 12,
    title: 'GitLab Merge Request Creates Jenkins Job',
    description: 'When a merge request is made in GitLab, a Jenkins job is created.',
    steps: [
      { id: 1, step: 'Merge request in GitLab' },
      { id: 2, step: 'Jenkins job is created' },
      { id: 3, step: 'Notification sent to team' },
      { id: 4, step: 'Job completion tracked' },
      { id: 5, step: 'Results documented' },
    ],
    stats: { today: 4, last7Days: 16, last30Days: 64, allTime: 100 },
    status: 'Active',
    createdDate: '2024-04-01',
  },
  {
    number: 13,
    title: 'New Google Form Submission Creates PDF',
    description: 'When a Google Form is submitted, a PDF is created.',
    steps: [
      { id: 1, step: 'Form submitted' },
      { id: 2, step: 'PDF is created' },
      { id: 3, step: 'Notification sent' },
    ],
    stats: { today: 2, last7Days: 12, last30Days: 48, allTime: 80 },
    status: 'Inactive',
    createdDate: '2024-05-15',
  },
  {
    number: 14,
    title: 'New Facebook Post Creates Instagram Post',
    description: 'When a new post is made on Facebook, it creates an Instagram post.',
    steps: [
      { id: 1, step: 'New post on Facebook' },
      { id: 2, step: 'Instagram post is drafted' },
      { id: 3, step: 'Instagram post is published' },
      { id: 4, step: 'Engagement tracked' },
      { id: 5, step: 'Results documented' },
      { id: 6, step: 'Report generated' },
    ],
    stats: { today: 5, last7Days: 35, last30Days: 140, allTime: 200 },
    status: 'Active',
    createdDate: '2024-06-10',
  },
  {
    number: 15,
    title: 'New LinkedIn Connection Sends Follow-Up Email',
    description: 'When a new connection is made on LinkedIn, a follow-up email is sent.',
    steps: [
      { id: 1, step: 'New connection on LinkedIn' },
      { id: 2, step: 'Follow-up email is drafted' },
      { id: 3, step: 'Follow-up email is sent' },
      { id: 4, step: 'Response tracked' },
      { id: 5, step: 'Next steps planned' },
      { id: 6, step: 'Action items assigned' },
    ],
    stats: { today: 3, last7Days: 15, last30Days: 60, allTime: 100 },
    status: 'Active',
    createdDate: '2024-07-01',
  },
];
