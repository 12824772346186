// DesignGuidelines.js

import React from 'react';
import Colors from './Colors';
import Buttons from './Buttons';
import Borders from './Borders';
import Text from './Text';
import Spacing from './Spacing';
import Table from './Table';
import Pagination from './Pagination';
import Whitebox from './Whitebox';
import ModalComponent from './Modal';
import CardComponent from './Card'; // Import the Card component

const DesignGuidelines = () => {
  return (
    <div className="dg-container">
      <Colors />
      <Buttons />
      <Borders />
      <Text />
      <Spacing />
      <Table />
      <Pagination />
      <Whitebox />
      <ModalComponent />
      <CardComponent /> {/* Include the Card section */}
    </div>
  );
};

export default DesignGuidelines;
