import React from 'react';
import { Table, Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Use useNavigate from react-router-dom v6
import CustomAvatarGroup from './UserAvatars2'; // Correctly import CustomAvatarGroup
import AddMemberButton from './AddMemberButton';
import getStatusTag from './StatusTags';

const ProjectTable = ({ projects, teamMembers, handleAddMember }) => {
  const navigate = useNavigate();

  const onMenuClick = (menuItem) => {
    if (menuItem.key === "1") {
      navigate("/work/individual-project");
    }
    // Handle other menu items if needed
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div>
          <div>{text}</div>
          <div className="lite-text">Client: {record.client}</div>
        </div>
      ),
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      render: (memberIds, record) => {
        const projectMembers = memberIds.map(id => teamMembers.find(member => member.id === id));
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CustomAvatarGroup members={projectMembers} maxCount={5} />
            <AddMemberButton project={record} handleAddMember={handleAddMember} />
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => getStatusTag(status),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={
          <Menu onClick={onMenuClick}>
            <Menu.Item key="1">View</Menu.Item>
            <Menu.Item key="2">Edit</Menu.Item>
            <Menu.Item key="3">Settings</Menu.Item>
          </Menu>
        } trigger={['click']}>
          <Button className="slate-100" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <Table columns={columns} dataSource={projects} pagination={false} />
  );
};

export default ProjectTable;
