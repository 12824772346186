// DashboardChartDates.js

import moment from 'moment';

const fillMissingDates = (data, startDate, endDate) => {
  const dateMap = new Map(data.map(item => [item.d, item]));
  const filledData = [];
  let currentDate = moment(startDate);
  const end = moment(endDate);

  while (currentDate <= end) {
    const dateStr = currentDate.format('YYYY-MM-DD');
    if (dateMap.has(dateStr)) {
      filledData.push(dateMap.get(dateStr));
    } else {
      filledData.push({
        d: dateStr,
        t: 0,
        aC: 0,
        hW: 0,
        hB: 0
      });
    }
    currentDate = currentDate.add(1, 'day');
  }

  return filledData;
};

const today = moment().format('YYYY-MM-DD');
const startDate90DaysAgo = moment().subtract(90, 'days').format('YYYY-MM-DD');
const startDate60DaysAgo = moment().subtract(60, 'days').format('YYYY-MM-DD');
const startDate30DaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');
const startDate7DaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
const startDate180DaysAgo = moment().subtract(180, 'days').format('YYYY-MM-DD');
const startDate365DaysAgo = moment().subtract(365, 'days').format('YYYY-MM-DD');

export { fillMissingDates, today, startDate90DaysAgo, startDate60DaysAgo, startDate30DaysAgo, startDate7DaysAgo, startDate180DaysAgo, startDate365DaysAgo };
