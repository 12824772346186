import moment from 'moment';

// Function to parse time from "HH:MM:SS" format to decimal hours
const parseTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours + minutes / 60 + seconds / 3600;
};

// Function to fill missing dates and calculate totals
const fillAndTransformData = (data, startDate, endDate) => {
  const dateMap = new Map();

  data.forEach(item => {
    const dateStr = moment(item.d, 'MM/DD/YY').format('YYYY-MM-DD');
    if (!dateMap.has(dateStr)) {
      dateMap.set(dateStr, { timeSpent: 0, timeLeft: null });
    }
    dateMap.get(dateStr).timeSpent += parseTime(item.tS);
    dateMap.get(dateStr).timeLeft = parseTime(item.tL);
  });

  const filledData = [];
  let currentTimeLeft = 0;
  let currentDate = moment(startDate);
  const end = moment(endDate);

  // Set initial currentTimeLeft to the last known timeLeft before startDate
  for (let i = 0; i < data.length; i++) {
    const itemDate = moment(data[i].d, 'MM/DD/YY');
    if (itemDate.isBefore(currentDate)) {
      currentTimeLeft = parseTime(data[i].tL);
    }
  }

  while (currentDate <= end) {
    const dateStr = currentDate.format('YYYY-MM-DD');
    if (dateMap.has(dateStr)) {
      const { timeSpent, timeLeft } = dateMap.get(dateStr);
      currentTimeLeft = timeLeft;
      filledData.push({ d: dateStr, tS: timeSpent, tL: currentTimeLeft });
    } else {
      filledData.push({ d: dateStr, tS: 0, tL: currentTimeLeft });
    }
    currentDate = currentDate.add(1, 'day');
  }

  return filledData;
};

const today = moment().format('YYYY-MM-DD');
const startDate90DaysAgo = moment().subtract(90, 'days').format('YYYY-MM-DD');
const startDate60DaysAgo = moment().subtract(60, 'days').format('YYYY-MM-DD');
const startDate30DaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');
const startDate7DaysAgo = moment().subtract(7, 'days').format('YYYY-MM-DD');
const startDate180DaysAgo = moment().subtract(180, 'days').format('YYYY-MM-DD');
const startDate365DaysAgo = moment().subtract(365, 'days').format('YYYY-MM-DD');

export { fillAndTransformData, today, startDate90DaysAgo, startDate60DaysAgo, startDate30DaysAgo, startDate7DaysAgo, startDate180DaysAgo, startDate365DaysAgo };
