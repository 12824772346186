const tasks = [
  {
    id: 1,
    text: "Project Kickoff",
    start_date: "2024-08-01",
    duration: 3,
    parent: 0,
    progress: 0,
    assignee: "John Doe",
    status: "completed",
  },
  {
    id: 2,
    text: "Initial Meeting",
    start_date: "2024-08-01",
    duration: 1,
    parent: 1,
    progress: 0,
    assignee: "John Doe",
    status: "completed",
  },
  {
    id: 3,
    text: "Requirements Gathering",
    start_date: "2024-08-02",
    duration: 2,
    parent: 1,
    progress: 0,
    assignee: "Jane Smith",
    status: "completed",
  },
  {
    id: 4,
    text: "Design Phase",
    start_date: "2024-08-05",
    duration: 5,
    parent: 0,
    progress: 0,
    assignee: "Emily Davis",
    status: "in progress",
  },
  {
    id: 5,
    text: "Create Wireframes",
    start_date: "2024-08-05",
    duration: 2,
    parent: 4,
    progress: 0,
    assignee: "Emily Davis",
    status: "completed",
  },
  {
    id: 6,
    text: "UI Design",
    start_date: "2024-08-07",
    duration: 3,
    parent: 4,
    progress: 0,
    assignee: "Michael Brown",
    status: "in progress",
  },
  {
    id: 7,
    text: "Development Phase",
    start_date: "2024-08-10",
    duration: 10,
    parent: 0,
    progress: 0,
    assignee: "John Doe",
    status: "not started",
  },
  {
    id: 8,
    text: "Frontend Development",
    start_date: "2024-08-10",
    duration: 5,
    parent: 7,
    progress: 0,
    assignee: "Jane Smith",
    status: "not started",
  },
  {
    id: 9,
    text: "Backend Development",
    start_date: "2024-08-15",
    duration: 5,
    parent: 7,
    progress: 0,
    assignee: "Michael Brown",
    status: "not started",
  },
  {
    id: 10,
    text: "Testing Phase",
    start_date: "2024-08-20",
    duration: 4,
    parent: 0,
    progress: 0,
    assignee: "Emily Davis",
    status: "not started",
  },
  {
    id: 11,
    text: "Unit Testing",
    start_date: "2024-08-20",
    duration: 2,
    parent: 10,
    progress: 0,
    assignee: "Jane Smith",
    status: "not started",
  },
  {
    id: 12,
    text: "Integration Testing",
    start_date: "2024-08-22",
    duration: 2,
    parent: 10,
    progress: 0,
    assignee: "Michael Brown",
    status: "in review",
  },
];

export default tasks;
