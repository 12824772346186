// ChartDashboard.js

import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const ChartDashboard = () => {
  // Data for the line chart
  const lineData = [
    { date: 'Aug 05', created: 120, solved: 90, inProgress: 60 },
    { date: 'Aug 06', created: 210, solved: 160, inProgress: 100 },
    { date: 'Aug 07', created: 170, solved: 140, inProgress: 110 },
    { date: 'Aug 08', created: 200, solved: 130, inProgress: 140 },
    { date: 'Aug 09', created: 150, solved: 170, inProgress: 130 },
    { date: 'Aug 10', created: 160, solved: 120, inProgress: 180 },
    { date: 'Aug 11', created: 230, solved: 200, inProgress: 150 },
  ];

  // Data for the doughnut chart
  const doughnutData = [
    { name: 'High', value: 45 },
    { name: 'Medium', value: 30 },
    { name: 'Low', value: 25 },
  ];

  // Colors for the doughnut chart
  const COLORS = ['#FE4A49', '#363187', '#FDF149']; // 500

  return (
    <div className="tk-charts-container">
      <div className="tk-tickets-over-time">
      <div className="whitebox">
        <h3 className="">Tickets Over Time</h3>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={lineData} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid stroke="#F3F4F6" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="bottom" height={36} />
            <Line
              type="monotone"
              dataKey="created"
              stroke="#1D4ED8"
              strokeWidth={2}
              dot={false}
              name="Ticket Created"
            />
            <Line
              type="monotone"
              dataKey="solved"
              stroke="#6D28D9"
              strokeWidth={2}
              dot={false}
              name="Tickets Solved"
            />
            <Line
              type="monotone"
              dataKey="inProgress"
              stroke="#F59E0B"
              strokeWidth={2}
              dot={false}
              name="In Progress"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      </div>
      <div className="tk-priority">
      <div className="whitebox ">
        <h3 className="c-chart-title">Tickets By Priority</h3>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={doughnutData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={80}
              outerRadius={120}
              
              paddingAngle={0}
              label
            >
              {doughnutData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend verticalAlign="bottom" height={36} />
          </PieChart>
        </ResponsiveContainer>
      </div>
      </div>
    </div>
  );
};

export default ChartDashboard;
