import React, { useState, useEffect } from 'react';
import { Layout, Button, message } from 'antd';
import BreadcrumbComponent from '../../Components/breadcrumb';
import CustomAvatarGroup from '../../Components/UserAvatars2';
import AddMemberButton from '../../Components/AddMemberButton';
import ManageTeamModal from '../../Modals/ManageTeamModal';
import { projects, teamMembers as allTeamMembers, clientsData } from '../../Data/ProjectsSampleData';
import ClientMainBanner from './ClientMainPageComponents2/ClientMainBanner';
import ClientMainFolders from './ClientMainPageComponents2/ClientMainFolders';
import './ClientMainPageComponents2/ClientMainPage.less';

const { Content } = Layout;

const ClientMainPage2 = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  useEffect(() => {
    const clientProject = projects.find(project => project.clients.includes(1));
    if (clientProject) {
      const members = clientProject.members.map(memberId => {
        return allTeamMembers.find(member => member.id === memberId);
      });
      setTeamMembers(members);
    }
  }, []);

  const showModal = () => {
    const project = projects.find(project => project.id === 1);
    setSelectedProject(project);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    message.success('New member added');
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const breadcrumbItems = [
    // { title: '', link: '/', icon: 'fi-rr-home' },
    { title: 'Clients', link: '/clients' },
    { title: 'Glo Tanning' },
  ];

  return (
    <div className="ct-client-main-page-container">
    <div className="ct-main-page-breadcrumb">
      <BreadcrumbComponent items={breadcrumbItems} />
      </div>



      <Content className="ct-content">
        <ClientMainBanner />



        <div className="whitebox">
        <div className="whitebox-title">
        <h3> My Workplace </h3>
        <Button className="button-3"> <i className="fi fi-rr-settings-sliders"></i> </Button>
        </div>
        <ClientMainFolders />
        </div>


      </Content>

      <ManageTeamModal
        isVisible={isModalVisible}
        selectedProject={selectedProject}
        toggleModal={handleCancel}
        teamMembers={allTeamMembers}
        clientsData={clientsData}
      />
    </div>
  );
};

export default ClientMainPage2;
