import React from 'react';
import { Button } from 'antd';
import sampleNotifications from '../../Data/SampleNotifications';
import './Notifications.less';

const getIconClass = (type) => {
  switch (type) {
    case 'Task Created':
      return 'fi fi-rr-note';
    case 'Task Completed':
      return 'fi fi-rr-note';
    case 'Project Created':
      return 'fi fi-rr-briefcase';
    case 'Project Completed':
      return 'fi fi-rr-briefcase';
    case 'Payment Made':
      return 'fi fi-rr-credit-card';
    case 'Hours Low':
      return 'fi fi-rr-alarm-clock';
    case 'Hours Finished':
      return 'fi fi-rr-hourglass-end';
    default:
      return 'fi fi-rr-bell';
  }
};

const getIconContainerClass = (type) => {
  switch (type) {
    case 'Task Created':
      return 'nf-task-created-icon-container';
    case 'Task Completed':
      return 'nf-task-completed-icon-container';
    case 'Project Created':
      return 'nf-project-created-icon-container';
    case 'Project Completed':
      return 'nf-project-completed-icon-container';
    case 'Payment Made':
      return 'nf-payment-made-icon-container';
    case 'Hours Low':
      return 'nf-hours-low-icon-container';
    case 'Hours Finished':
      return 'nf-hours-finished-icon-container';
    default:
      return 'nf-default-icon-container';
  }
};

const latestNotifications = sampleNotifications.slice(0, 5);

const NotificationsDropdown = ({ onViewAllNotifications }) => {
  return (
    <div className="nf-dropdown-menu">
      <div className="nf-dropdown-title">
        Notifications
      </div>
      {latestNotifications.map((notification, index) => (
        <div key={index} className="nf-dropdown-item">
          <div className="nf-notification-cell">
            <div className={`nf-icon-container ${getIconContainerClass(notification.type)}`}>
              <i className={`nf-icon ${getIconClass(notification.type)}`}></i>
            </div>
            <div className="nf-text-container">
              <div className="nf-dropdown-top-row">
                <div className="nf-type">{notification.type}</div>
                <div className="nf-time">{notification.time}</div>
              </div>
              <div className="nf-name">{notification.notificationName}</div>
            </div>
          </div>
        </div>
      ))}
      <div className="nf-view-all-item">
        <Button type="link" className="nf-view-all-button" onClick={onViewAllNotifications}>
          View All
        </Button>
      </div>
    </div>
  );
};

export default NotificationsDropdown;
