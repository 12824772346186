import React, { useState } from 'react';
import DesignFeedbackComments from './DesignFeedbackComponents/DesignFeedbackComments';
import DesignFeedbackOptions from './DesignFeedbackComponents/DesignFeedbackOptions';
import './FilesPages.less';

const DesignFeedback = ({ selectedImage }) => {
  const [comments, setComments] = useState([]);
  const [highlightedCommentId, setHighlightedCommentId] = useState(null);
  const [isOptionsCollapsed, setIsOptionsCollapsed] = useState(false);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);

  const addComment = (x, y) => {
    const newComment = {
      id: comments.length + 1,
      x,
      y,
      text: '',
      responses: [],
    };
    setComments([...comments, newComment]);
  };

  const updateCommentText = (index, newText) => {
    const updatedComments = comments.map((comment, i) =>
      i === index ? { ...comment, text: newText } : comment
    );
    setComments(updatedComments);
  };

  const deleteComment = (index) => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);
    setHighlightedCommentId(null);
  };

  const addResponse = (index, responseText) => {
    const updatedComments = comments.map((comment, i) =>
      i === index ? { ...comment, responses: [...comment.responses, responseText] } : comment
    );
    setComments(updatedComments);
  };

  const handleMarkerClick = (index) => {
    setHighlightedCommentId(comments[index].id);
  };

  const toggleOptionsBar = () => {
    setIsOptionsCollapsed(!isOptionsCollapsed);
  };

  const toggleCommentsVisibility = () => {
    setIsCommentsVisible(!isCommentsVisible);
  };

  return (
    <div className="fp-main-main-container">
      <div className="fp-design-feedback-parent-container">
        {selectedImage && (
          <div className="fp-image-name">
            <i className="fi fi-br-picture"></i> {selectedImage.name}
          </div>
        )}
        <div className="fp-design-feedback-inside-container">
          <div className={`fp-left-container ${isOptionsCollapsed ? 'collapsed' : 'open'}`}>
            {selectedImage ? (
              <DesignFeedbackComments
                selectedImage={selectedImage}
                comments={comments}
                addComment={addComment}
                updateCommentText={updateCommentText}
                deleteComment={deleteComment}
                addResponse={addResponse}
                handleMarkerClick={handleMarkerClick}
                highlightedCommentId={highlightedCommentId}
              />
            ) : (
              <p>No image selected for feedback</p>
            )}
          </div>
        </div>
      </div>

      <DesignFeedbackOptions
        isCollapsed={isOptionsCollapsed}
        toggleOptionsBar={toggleOptionsBar}
        isCommentsVisible={isCommentsVisible}
        toggleCommentsVisibility={toggleCommentsVisibility}
        comments={comments}
        updateCommentText={updateCommentText}
        deleteComment={deleteComment}
        addResponse={addResponse}
        highlightedCommentId={highlightedCommentId}
      />
    </div>
  );
};

export default DesignFeedback;
