import React, { useContext } from 'react';
import { Layout, Button, Row, Col, Dropdown, Badge } from 'antd';
import './WorkPages.less';
import FilterSortByOverlay from './FilterSortByOverlay';
import { AppStateContext } from '../../Context/AppStateContext';
import FilterGroupByOverlay from './FilterGroupByOverlay';
import FilterNewSectionOverlay from './FilterNewSectionOverlay';

const { Header } = Layout;

const FilterSection = () => {
  const { sortByColumns, groupByColumns } = useContext(AppStateContext);

  return (
    <div className="pmt-header">
    <div className="pmt-header-left">

      <div>
        <Dropdown dropdownRender={() => <FilterSortByOverlay />} trigger={['click']}>
          <Badge count={sortByColumns.length} overflowCount={99}>
            <Button  className="pmt-header-button">
              <i className="fi fi-sr-sort-alt"></i> Sort
            </Button>
          </Badge>
        </Dropdown>
      </div>
      <div>
        <Dropdown dropdownRender={() => <FilterGroupByOverlay />} trigger={['click']}>
          <Badge count={groupByColumns.length} overflowCount={99}>
            <Button  className="pmt-header-button">
              <i className="fi fi-rr-category-alt"></i> Group
            </Button>
          </Badge>
        </Dropdown>
      </div>
      {groupByColumns.includes('section') && (
        <div>
          <Dropdown dropdownRender={() => <FilterNewSectionOverlay />} trigger={['click']}>
            <Button  className="pmt-header-button pmt-header-button-last">
              <i className="fi fi-rr-add-folder"></i> Add Section
            </Button>
          </Dropdown>
        </div>
      )}


    </div>
    <div className="pmt-header-right">
    <Button  className="pmt-header-add-task-button">
      <i className="fi fi-rr-plus"></i> Add Task
    </Button>
    </div>

    </div>
  );
};

export default FilterSection;
