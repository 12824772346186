// DashboardWelcome.js
import React from 'react';
import moment from 'moment';

const DashboardWelcome = () => {
  const todayDate = moment().format('dddd, MMMM D');

  return (
    <div className="db-welcome-container">
      <p className="db-date"><i className="fi fi-rr-calendar"></i> {todayDate}</p>
      <p className="db-greeting">Hi Noman, welcome back!</p>
    </div>
  );
};

export default DashboardWelcome;
