import React, { useState, useEffect, useContext } from 'react';
import { Select } from 'antd';
import { AppStateContext } from '../../../Context/AppStateContext'; // Adjust the path if necessary
import SingleAvatar2 from '../../../Components/SingleAvatar2'; // Adjust the path if necessary

const { Option } = Select;

const EditableAssigneeCell = ({ value: initialValue, row: { index }, column: { id }, updateData }) => {
  const [value, setValue] = useState(initialValue);
  const { projects, teamMembers } = useContext(AppStateContext);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (value) => {
    setValue(value);
    updateData(index, id, value);
  };


  // Find the project by projectId
  const project = projects.find(project => project.id === 1);

  // Filter the team members for the project
  const projectTeamMembers = project ? teamMembers.filter(member => project.members.includes(member.id)) : [];

  const options = projectTeamMembers.map(member => (
    <Option key={member.id} value={member.name}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SingleAvatar2 name={member.name} />
        <span style={{ marginLeft: '8px' }}>{member.name}</span>
      </div>
    </Option>
  ));

  return (
    <Select value={value} onChange={onChange} style={{ width: '100%' }}>
      {options}
    </Select>
  );
};

const AssigneeColumn = {
  Header: 'Assignee',
  accessor: 'assignee',
  width: 100,
  Cell: EditableAssigneeCell,
  className: 'pmt-assignee-column',
};

export default AssigneeColumn;
