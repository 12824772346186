import React, { useState, useEffect, useRef } from 'react';
import { Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './Searchbar.less';
import { clients, projects, teamMembers, comments, tasks } from '../Data/SampleSearchResults'; // Import comments and tasks

const allData = [...clients, ...projects, ...teamMembers, ...comments, ...tasks]; // Include comments and tasks
const { Search } = Input;

const SearchbarInput = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState(['All']);
  const searchContainerRef = useRef(null);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value.length >= 2) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  };

  const handleClickOutside = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      setShowResults(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredData = allData.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedCategories.includes('All') || selectedCategories.includes(item.type))
  );

  const getIcon = (type) => {
    switch (type) {
      case 'Client':
        return <i className="fi fi-rr-users-alt"></i>;
      case 'Project':
        return <i className="fi fi-rr-briefcase"></i>;
      case 'Task':
        return <i className="fi fi-rr-list-check"></i>;
      case 'Comment':
        return <i className="fi fi-rr-comment-alt"></i>;
      case 'Team Member':
        return <i className="fi fi-rr-user"></i>;
      default:
        return null;
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const handleCategoryClick = (category) => {
    if (category === 'All') {
      setSelectedCategories(['All']);
    } else {
      setSelectedCategories((prevCategories) => {
        if (prevCategories.includes(category)) {
          const newCategories = prevCategories.filter((cat) => cat !== category);
          return newCategories.length > 0 ? newCategories : ['All'];
        } else {
          return [...prevCategories.filter((cat) => cat !== 'All'), category];
        }
      });
    }
  };

  const isCategorySelected = (category) => selectedCategories.includes(category);

  return (
    <div className="search-input-container" ref={searchContainerRef}>
      <Search
        placeholder="Search..."
        enterButton={<SearchOutlined />}
        size="large"
        className="search-input"
        value={searchQuery}
        onChange={handleSearchChange}
      />
      {showResults && (
        <div className="searchbar-results-container">
          <div className="filter-categories">
            <i className="fi fi-rr-filter"></i>
            <span>Filter Categories</span>
          </div>
          <div className="searchbar-category-buttons">
            <Button
              className={`searchbar-category-button ${isCategorySelected('All') ? 'active' : ''}`}
              onClick={() => handleCategoryClick('All')}
            >
              All
            </Button>
            <Button
              className={`searchbar-category-button ${isCategorySelected('Client') ? 'active' : ''}`}
              onClick={() => handleCategoryClick('Client')}
            >
              Clients
            </Button>
            <Button
              className={`searchbar-category-button ${isCategorySelected('Project') ? 'active' : ''}`}
              onClick={() => handleCategoryClick('Project')}
            >
              Projects
            </Button>
            <Button
              className={`searchbar-category-button ${isCategorySelected('Task') ? 'active' : ''}`}
              onClick={() => handleCategoryClick('Task')}
            >
              Tasks
            </Button>
            <Button
              className={`searchbar-category-button ${isCategorySelected('Comment') ? 'active' : ''}`}
              onClick={() => handleCategoryClick('Comment')}
            >
              Comments
            </Button>
            <Button
              className={`searchbar-category-button ${isCategorySelected('Team Member') ? 'active' : ''}`}
              onClick={() => handleCategoryClick('Team Member')}
            >
              Team
            </Button>
          </div>
          <div className="searchbar-results">
            {filteredData.length > 0 ? (
              <>
                {filteredData.some(item => item.type === 'Client') && (
                  <div className="searchbar-category-header">
                    <div className="search-icon-container">
                      <i className="fi fi-rr-users-alt"></i>
                    </div>
                    <span>Clients</span>
                  </div>
                )}
                {filteredData.filter(item => item.type === 'Client').map(item => (
                  <div key={item.id} className="searchbar-result-item searchbar-result-category-Client">
                    <span className="result-icon">{getIcon(item.type)}</span>
                    <span className="result-name">{truncateText(item.name, 100)}</span>
                    <span className="result-type">{item.type}</span>
                  </div>
                ))}
                {filteredData.some(item => item.type === 'Project') && (
                  <div className="searchbar-category-header">
                    <div className="search-icon-container">
                      <i className="fi fi-rr-briefcase"></i>
                    </div>
                    <span>Projects</span>
                  </div>
                )}
                {filteredData.filter(item => item.type === 'Project').map(item => (
                  <div key={item.id} className="searchbar-result-item searchbar-result-category-Project">
                    <span className="result-icon">{getIcon(item.type)}</span>
                    <span className="result-name">{truncateText(item.name, 100)}</span>
                    <span className="result-type">{item.type}</span>
                  </div>
                ))}
                {filteredData.some(item => item.type === 'Task') && (
                  <div className="searchbar-category-header">
                    <div className="search-icon-container">
                      <i className="fi fi-rr-list-check"></i>
                    </div>
                    <span>Tasks</span>
                  </div>
                )}
                {filteredData.filter(item => item.type === 'Task').map(item => (
                  <div key={item.id} className="searchbar-result-item searchbar-result-category-Task">
                    <span className="result-icon">{getIcon(item.type)}</span>
                    <span className="result-name">{truncateText(item.name, 100)}</span>
                    <span className="result-type">{item.type}</span>
                  </div>
                ))}
                {filteredData.some(item => item.type === 'Comment') && (
                  <div className="searchbar-category-header">
                    <div className="search-icon-container">
                      <i className="fi fi-rr-comment-alt"></i>
                    </div>
                    <span>Comments</span>
                  </div>
                )}
                {filteredData.filter(item => item.type === 'Comment').map(item => (
                  <div key={item.id} className="searchbar-result-item searchbar-result-category-Comment">
                    <span className="result-icon">{getIcon(item.type)}</span>
                    <span className="result-name">{truncateText(item.name, 100)}</span>
                    <span className="result-type">{item.type}</span>
                  </div>
                ))}
                {filteredData.some(item => item.type === 'Team Member') && (
                  <div className="searchbar-category-header">
                    <div className="search-icon-container">
                      <i className="fi fi-rr-user"></i>
                    </div>
                    <span>Team</span>
                  </div>
                )}
                {filteredData.filter(item => item.type === 'Team Member').map(item => (
                  <div key={item.id} className="searchbar-result-item searchbar-result-category-Team">
                    <span className="result-icon">{getIcon(item.type)}</span>
                    <span className="result-name">{truncateText(item.name, 100)}</span>
                    <span className="result-type">{item.type}</span>
                  </div>
                ))}
              </>
            ) : (
              <div className="searchbar-no-results">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchbarInput;
