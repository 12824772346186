import React, { useState } from 'react';
import { Menu, Badge, Avatar, Dropdown, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import '../custom.less'; // Ensure custom.less is imported to apply styles
import './Searchbar.less';
import { useMediaQuery } from 'react-responsive';
import SearchbarInput from './SearchbarInput'; // Import the SearchbarInput component
import NotificationsDropdown from '../Pages/NotificationsPage/NotificationsDropdown'; // Import the NotificationsDropdown component
import AddTeamMemberModal from '../Modals/AddTeamMemberModal'; // Import the AddTeamMemberModal component

const Searchbar = ({ handleToggleSidebar }) => {
  const [visible, setVisible] = useState({ profile: false, notifications: false });
  const [isAddTeamMemberModalVisible, setIsAddTeamMemberModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleVisibleChange = (name, isVisible) => {
    setVisible((prevVisible) => ({ ...prevVisible, [name]: isVisible }));
  };

  const handleViewAllNotifications = () => {
    setVisible((prevVisible) => ({ ...prevVisible, notifications: false }));
    navigate('/notifications');
  };

  const showAddTeamMemberModal = () => {
    setIsAddTeamMemberModalVisible(true);
    setVisible((prevVisible) => ({ ...prevVisible, profile: false })); // Close the profile dropdown
  };

  const handleHelpClick = () => {
    navigate('/account?tab=support');
    setVisible((prevVisible) => ({ ...prevVisible, profile: false })); // Close the profile dropdown
  };

  const profileMenu = (
    <Menu className="profile-menu">
      <div className="profile-avatar">
        <Avatar size={48} className="user-profile-avatar">
          NH
        </Avatar>
        <div className="profile-info">
          <div className="profile-name">Noman Hossain</div>
          <div className="profile-company">BlueFlower Media</div>
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item key="profile" icon={<i className="fi fi-rr-user"></i>}>
        <Link to="/account?tab=profile">
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="settings" icon={<i className="fi fi-rr-settings"></i>}>
        <Link to="/account?tab=settings">
          <span>Settings</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="invite" icon={<i className="fi fi-rr-user-add"></i>} onClick={showAddTeamMemberModal}>
        <span>Invite Team Member</span>
      </Menu.Item>
      <Menu.Item key="help" icon={<i className="fi fi-rr-life-ring"></i>} onClick={handleHelpClick}>
        <span>Support</span>
      </Menu.Item>
      <Menu.Item key="logout" icon={<i className="fi fi-rr-exit"></i>}>
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div className="searchbar-container">
      <div className="logo-container">
        <Button type="text" onClick={handleToggleSidebar} className="hamburger-icon">
          <MenuOutlined />
        </Button>

        <Link to="/" className="logo-link">
          <div className="logo-image">
            <img src="https://i.ibb.co/BB8Nt8w/aio2-cropped.png" alt="aio2-cropped" className="logo-img" />
          </div>
        </Link>
      </div>

      <SearchbarInput /> {/* Use the new SearchbarInput component here */}

      <div className="avatar-container">
        <Dropdown
          overlay={<NotificationsDropdown onViewAllNotifications={handleViewAllNotifications} />}
          trigger={['click']}
          // visible={visible.notifications}
          // onVisibleChange={(isVisible) => handleVisibleChange('notifications', isVisible)}
        >
          <Badge count={5} offset={[-2, 3]} className="hide-on-small-screens">
            <Avatar size="large" className="avatar-bell">
              <i className="fi fi-rr-bell" style={{ fontSize: '20px', color: 'inherit' }}></i>
            </Avatar>
          </Badge>
        </Dropdown>
        <Dropdown
          overlay={profileMenu}
          trigger={['click']}
          visible={visible.profile}
          onVisibleChange={(isVisible) => handleVisibleChange('profile', isVisible)}
        >
          <div className="dropdown-trigger">
            <Avatar size="large" className="avatar-profile-initials">
              NH
            </Avatar>
            {visible.profile ? (
              <i className="fi fi-rr-angle-small-up"></i>
            ) : (
              <i className="fi fi-rr-angle-small-down"></i>
            )}
          </div>
        </Dropdown>
      </div>
      <AddTeamMemberModal
        isVisible={isAddTeamMemberModalVisible}
        toggleModal={() => setIsAddTeamMemberModalVisible(false)}
      />
    </div>
  );
};

export default Searchbar;
