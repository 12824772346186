import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';


const { Header, Content } = Layout;

const Home = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>

        <Content style={{ padding: '24px 0' }}>
          {children}
        </Content>
      </Layout>
  
  );
};

export default Home;
