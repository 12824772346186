// CalendarData.js

const tasks = [
  {
    id: 1,
    text: "Project Kickoff",
    start_date: "2024-08-01",
    end_date: "2024-08-03",
    duration: 3,
    parent: 0,
    progress: 100,
    assignee: "John Doe",
    status: "Completed",
  },
  {
    id: 2,
    text: "Initial Meeting",
    start_date: "2024-08-01",
    end_date: "2024-08-01",
    duration: 1,
    parent: 1,
    progress: 100,
    assignee: "John Doe",
    status: "Completed",
  },
  {
    id: 3,
    text: "Requirements Gathering",
    start_date: "2024-08-02",
    end_date: "2024-08-03",
    duration: 2,
    parent: 1,
    progress: 100,
    assignee: "Jane Smith",
    status: "Completed",
  },
  {
    id: 4,
    text: "Design Phase",
    start_date: "2024-08-05",
    end_date: "2024-08-09",
    duration: 5,
    parent: 0,
    progress: 100,
    assignee: "Emily Davis",
    status: "Completed",
  },
  {
    id: 5,
    text: "Create Wireframes",
    start_date: "2024-08-05",
    end_date: "2024-08-06",
    duration: 2,
    parent: 4,
    progress: 100,
    assignee: "Emily Davis",
    status: "Completed",
  },
  {
    id: 6,
    text: "UI Design",
    start_date: "2024-08-07",
    end_date: "2024-08-09",
    duration: 3,
    parent: 4,
    progress: 100,
    assignee: "Michael Brown",
    status: "Completed",
  },
  {
    id: 7,
    text: "Development Phase",
    start_date: "2024-08-10",
    end_date: "2024-08-19",
    duration: 10,
    parent: 0,
    progress: 80,
    assignee: "John Doe",
    status: "In Review",
  },
  {
    id: 8,
    text: "Frontend Development",
    start_date: "2024-08-10",
    end_date: "2024-08-14",
    duration: 5,
    parent: 7,
    progress: 100,
    assignee: "Jane Smith",
    status: "Completed",
  },
  {
    id: 9,
    text: "Backend Development",
    start_date: "2024-08-15",
    end_date: "2024-08-19",
    duration: 5,
    parent: 7,
    progress: 60,
    assignee: "Michael Brown",
    status: "In Review",
  },
  {
    id: 10,
    text: "Testing Phase",
    start_date: "2024-08-20",
    end_date: "2024-08-23",
    duration: 4,
    parent: 0,
    progress: 50,
    assignee: "Emily Davis",
    status: "In Progress",
  },
  {
    id: 11,
    text: "Unit Testing",
    start_date: "2024-08-20",
    end_date: "2024-08-21",
    duration: 2,
    parent: 10,
    progress: 40,
    assignee: "Jane Smith",
    status: "In Progress",
  },
  {
    id: 12,
    text: "Integration Testing",
    start_date: "2024-08-22",
    end_date: "2024-08-23",
    duration: 2,
    parent: 10,
    progress: 0,
    assignee: "Michael Brown",
    status: "Not Started",
  },
];

export default tasks;
