import React, { useState, useContext } from 'react';
import SubtaskRow from './SubtaskRow';
import TaskDetailsModal from '../../Modals/TaskDetailsModal/TaskDetailsModal';
import { AppStateContext } from '../../Context/AppStateContext';
import './WorkPages.less';

const TableRow = ({ row, index, prepareRow }) => {
  const { groupByColumns, tasks, setTasks, dragData, setDragData } = useContext(AppStateContext);
  prepareRow(row);
  // const isExpanded = expandedTaskIds.includes(row.original.taskId);
  const [isExpanded, setIsExpended] = useState(false)
  const [modalVisible, setModalVisible] = useState(false);
  function getClassNames() {
    const classNames = ['pmt-row']
    if (row.original.subtasks.length === 0) {
      classNames.push('task-without-subtasks')
    }
    if (row.original.taskId === dragData.to) {
      classNames.push('drop-target')
    }
    return classNames.join(" ")
  }
  const handleModalOpen = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };
  const onDragStart = (event) => {
    dragData.from = row.original.taskId
    setDragData({ ...dragData })
  };

  const onDragOver = (event) => {
    event.preventDefault(); // Necessary to allow the drop
    if (dragData.from > -1) {
      dragData.to = row.original.taskId
      setDragData({ ...dragData })
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    if (dragData.from > -1) {
      const task = {
        from: tasks.findIndex((item) => item.taskId === dragData.from),
        to: tasks.findIndex((item) => item.taskId === dragData.to)
      }
      if (task.from > -1 && task.to > -1) {
        groupByColumns.forEach((item) => {
          tasks[task.from][item] = tasks[task.to][item]
        })
        setTasks([...tasks])
      }
      setDragData({ from: -1, to: -1 })
    }
  };
  return (
    <>
      <tr {...row.getRowProps()}
        id={'task-' + row.original.taskId}
        onDragStart={onDragStart}
        onDrop={onDrop}
        className={getClassNames()}
        draggable={true}
      >
        {row.cells.map((cell) => (
          <td onDragOver={onDragOver} {...cell.getCellProps()} className="pmt-cell">
            {cell.column.id === 'taskName' ? (
              <div onDragOver={onDragOver} className="pmt-task-cell-content">
                <div className="pmt-task-name">
                  {row.original.subtasks.length > 0 && (
                    <span
                      className={`pmt-expand-icon ${isExpanded ? 'pmt-expanded' : ''}`}
                      onClick={() => setIsExpended(!isExpanded)}
                    >
                      {isExpanded ? <i className="fi fi-sr-caret-down"></i> : <i className="fi fi-sr-caret-right"></i>}
                    </span>
                  )}
                  {cell.render('Cell')}
                </div>
                {row.original.subtasks.length > 0 && (
                  <span className="pmt-subtask-info">
                    <i className="fi fi-rr-diagram-subtask"></i> {row.original.subtasks.length}
                  </span>
                )}
                <span className="pmt-task-details-icon" onClick={handleModalOpen}>
                  <i className="fi fi-rr-arrow-up-right-from-square"></i>
                </span>
              </div>
            ) : (
              cell.render('Cell')
            )}
          </td>
        ))}
      </tr>

      {isExpanded &&
        row.original.subtasks.map((subtask, subIndex) => (
          <SubtaskRow
            key={subtask.subtaskId}
            subtask={subtask}
            taskIndex={index}
            subtaskIndex={subIndex}
            taskId={row.original.taskId}
          />
        ))}

      <TaskDetailsModal
        task={row.original}
        visible={modalVisible}
        onClose={handleModalClose}
      />
    </>
  );
};

export default TableRow;
