// NotificationsSort.js

import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import NotificationSettingsModal from '../../Modals/NotificationSettingsModal'; // Import the new modal

const NotificationsSort = ({ rowsPerPage, setRowsPerPage }) => {
  const [visibleDate, setVisibleDate] = useState(false);
  const [visibleType, setVisibleType] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility

  const handleMenuClick = (e) => {
    console.log('Sorting option selected:', e.key);
  };

  const sortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="asc">Ascending</Menu.Item>
      <Menu.Item key="desc">Descending</Menu.Item>
    </Menu>
  );

  const rowsMenu = (
    <Menu onClick={(e) => setRowsPerPage(Number(e.key))}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
      <Menu.Item key="50">50</Menu.Item>
      <Menu.Item key="100">100</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="ct-sort-container">
      <div className="ct-sort-options-row">
        <div className="ct-sort-buttons">
          <span className="ct-sort-text">Sort by: </span>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleDate}
            onVisibleChange={setVisibleDate}
          >
            <Button>
              Date <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleType}
            onVisibleChange={setVisibleType}
          >
            <Button>
              Notification Type <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <div className="ct-add-client-row">
            <div className="ct-rows-text">Rows: </div>
            <Dropdown overlay={rowsMenu} trigger={['click']}>
              <Button className="ct-rows-button">
                {rowsPerPage} <i className="fi fi-rr-angle-small-down"></i>
              </Button>
            </Dropdown>
          </div>
        </div>
        <Button className="nf-notification-settings" onClick={showModal}>
          <i className="nf-notification-settings-icon fi fi-rr-settings"></i>
          Notification Settings
        </Button>
      </div>

      <NotificationSettingsModal
        isVisible={isModalVisible}
        toggleModal={handleCancel}
      />
    </div>
  );
};

export default NotificationsSort;
