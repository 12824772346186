// AddSingleCard.js

import React, { useState, useRef } from 'react';
import { Input, Button } from 'antd';
import TextEditor from '../../../Components/TextEditor';
import OverviewFiles from './OverviewFiles'; // Import the new OverviewFiles component
import '../WorkPages.less';

const AddSingleCard = () => {
  const [card, setCard] = useState({ title: '', content: '', files: [] });
  const [isPosted, setIsPosted] = useState(false);
  const fileInputRef = useRef(null);

  const handleCardChange = (field, value) => {
    setCard({ ...card, [field]: value });
  };

  const handlePost = () => {
    setIsPosted(true);
  };

  const handleEdit = () => {
    setIsPosted(false);
  };

  const handleDelete = () => {
    setCard({ title: '', content: '', files: [] });
    setIsPosted(false);
  };

  const handleFileChange = (e) => {
    setCard({ ...card, files: [...card.files, ...e.target.files] });
  };

  return (
    <div className="pmt-add-card-container">
      {isPosted ? (
        <div className="pmt-posted-card">
          <div className="pmt-posted-card-title-container">
            <h3 className="pmt-posted-card-title">{card.title}</h3>
            <div className="pmt-edit-button-container">
              <button onClick={handleEdit} className="pmt-edit-button">
                <i className="fi fi-rr-edit"></i>
              </button>
            </div>
          </div>
          <div
            className="pmt-posted-card-content"
            dangerouslySetInnerHTML={{ __html: card.content }}
          />
          {/* Display attached files in the posted view */}
          {card.files && card.files.length > 0 && (
            <OverviewFiles files={card.files} />
          )}
        </div>
      ) : (
        <div className="pmt-card-container">
          <div className="pmt-card-editor">
            <Input
              placeholder="Project Overview"
              value={card.title}
              onChange={(e) => handleCardChange('title', e.target.value)}
            />
            <TextEditor
              value={card.content}
              onChange={(value) => handleCardChange('content', value)}
              className="pmt-text-editor"
            />
            <div className="pmt-card-buttons">
              <Button
                className="button-2"
                onClick={() => fileInputRef.current.click()}
              >
                <i className="fi fi-rr-paperclip" style={{ marginRight: '8px' }}></i>
                Attach Files
              </Button>
              <Button onClick={handlePost} className="button-1">
                Post
              </Button>
            </div>
            {/* Display attached files below the buttons */}
            {card.files && card.files.length > 0 && (
              <OverviewFiles files={card.files} />
            )}
            <input
              type="file"
              multiple
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddSingleCard;
