// ClientsTableColumns/NotesColumn.js

import React, { useState } from 'react';

const NotesCell = ({ initialNotes }) => {
  const [notes, setNotes] = useState(initialNotes || '');

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  return (
    <textarea
      value={notes}
      onChange={handleChange}
      className="ct-notes-textarea"
      rows="3"
    />
  );
};

const NotesColumn = {
  title: 'Notes',
  key: 'notes',
  className: 'ct-notes',
  render: (text, record) => <NotesCell initialNotes={record.notes} />,
};

export default NotesColumn;
