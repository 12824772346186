import React from 'react';
import { Group, Circle, Text } from 'react-konva';
import customCursor from './customCursor'; // Import the custom cursor

const CommentMarker = ({ x, y, id, highlighted, onClick }) => {
  return (
    <Group
      x={x}
      y={y}
      onClick={onClick}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = 'pointer';
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = `url(${customCursor}) 12 12, crosshair`; // Adjust the hotspot if necessary
      }}
    >
      {highlighted && (
        <Circle
          radius={16}  // Larger radius for the outer circle
          fill="none"  // No fill, just a border
          stroke="yellow"  // Yellow border color (or any color you prefer)
          strokeWidth={4}  // Thicker border width for visibility
        />
      )}
      <Circle radius={10} fill="red" />
      <Text
        x={-6}
        y={-8}
        text={id.toString()}
        fontSize={16}
        fill="white"
        fontStyle="bold"
      />
    </Group>
  );
};

export default CommentMarker;
