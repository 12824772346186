import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { getAvatarColor, lightColors } from './AvatarColors';
import './UserAvatars.less';

const SingleAvatar = ({ name }) => {
  if (!name) return null;

  const color = getAvatarColor(name);
  const isLightColor = lightColors.includes(color);
  const initials = name.split(' ').map(n => n[0]).join('').slice(0, 2).toUpperCase();
  return (
    <Tooltip title={name}>
      <Avatar style={{ backgroundColor: color, color: isLightColor ? '#000' : '#FFF', width: '34px', height: '34px', fontSize: '14px' }}>
        {initials}
      </Avatar>
    </Tooltip>
  );
};

export default SingleAvatar;
