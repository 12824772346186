import teamMembers from './TeamSampleData';
import clientsData from './SampleClients';

const projects = [
  { id: 1, name: 'Glo Tanning', client: 'Client A', status: 'In Progress', members: [1, 2, 3, 4, 5, 6, 7, 8], clients: [1] },
  { id: 2, name: 'Sport Outreach', client: 'Client B', status: 'On Hold', members: [1, 6, 7, 8], clients: [2] },
  { id: 3, name: 'Larrisa Ling Law', client: 'Client C', status: 'Completed', members: [1], clients: [3] },
  { id: 4, name: 'Meducation', client: 'Client D', status: 'Not Started', members: [1, 9], clients: [4] },
  { id: 5, name: 'Backwater Kings', client: 'Client E', status: 'In Progress', members: [1], clients: [5, 6] },
];

export { projects, teamMembers, clientsData };
