import React, { useContext, useState, useRef } from 'react';
import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './WorkPages.less';
import { AppStateContext } from '../../Context/AppStateContext';
import { addTaskAction, foccusTaskRow } from '../../Utils/taskActions';

const SectionAdd = () => {
  const { groupByColumns, tasks, setTasks } = useContext(AppStateContext);
  const [inputVisible, setInputVisible] = useState(false)
  const ref = useRef(null)
  function addSectionAction() {
    const sectionTitle = ref.current?.input.value
    if (sectionTitle) {
      const newTask = addTaskAction(tasks)
      newTask.section = sectionTitle
      newTask.taskName = ""
      const taskId = newTask.taskId
      setTasks([...tasks])
      foccusTaskRow(taskId)
    }
    setInputVisible(false)

  }
  function onKeyDown(e) {
    if (e.key === 'Enter') {
      addSectionAction()
    }
  }
  return (
    <div className='add-section'>
      {(groupByColumns.includes('section')) && <>
        {inputVisible ? <Input ref={ref} placeholder='Section Title' onBlur={addSectionAction} onKeyDown={onKeyDown} /> :
          <Button type={"link"} icon={<PlusOutlined />} onClick={() => {
            setTimeout(() => {
              ref.current?.focus()
            }, 5)
            setInputVisible(true)
          }}>
            Add Section
          </Button>}
      </>}
    </div>
  );
};

export default SectionAdd;
