import React from 'react';
import { Table } from 'antd';

const HoursBar = ({ project }) => {
  const remainingHours = project.estimatedHours - project.usedHours;
  const totalHours = project.estimatedHours;
  const usedPercentage = (project.usedHours / totalHours) * 100;

  const isOverUsed = project.usedHours > project.estimatedHours;
  const overUsedHours = project.usedHours - project.estimatedHours;
  const overUsedPercentage = (overUsedHours / totalHours) * 100;
  const remainingPercentage = isOverUsed ? 0 : 100 - usedPercentage;

  return (
    <div className="db-hours-bar-container">
      <div className="db-bar-info">
        <span className="db-bar-title">{totalHours} hrs</span>
      </div>
      <div className="db-progress-bar-container">
        <div className="db-progress-bar db-progress-bar-used" style={{ width: `${Math.min(usedPercentage, 100)}%` }}></div>
        {isOverUsed && (
          <div className="db-progress-bar db-progress-bar-overused" style={{ width: `${overUsedPercentage}%` }}></div>
        )}
        {!isOverUsed && (
          <div className="db-progress-bar db-progress-bar-remaining" style={{ width: `${remainingPercentage}%` }}></div>
        )}
      </div>
      <div className="db-legend">
        <div className="db-legend-row">
          <div className="db-legend-item">
            <span className="db-project-circle db-used-circle"></span> <p>Used</p>
          </div>
          {isOverUsed ? (
            <div className="db-legend-item">
              <span className="db-project-circle db-overused-circle"></span> <p>Overused</p>
            </div>
          ) : (
            <div className="db-legend-item">
              <span className="db-project-circle db-remaining-circle"></span> <p>Remaining</p>
            </div>
          )}
        </div>
        <div className="db-legend-row">
          <div className="db-legend-item">
            {project.usedHours}
            <span className="db-project-percentage"> ({usedPercentage.toFixed(0)}%) </span>
          </div>
          {isOverUsed ? (
            <div className="db-legend-item">
              {overUsedHours}
              <span className="db-project-percentage"> ({overUsedPercentage.toFixed(0)}%) </span>
            </div>
          ) : (
            <div className="db-legend-item">
              {remainingHours}
              <span className="db-project-percentage"> ({remainingPercentage.toFixed(0)}%) </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TasksBar = ({ project }) => {
  const totalTasks = project.tasksOverview.total;
  const notStartedPercentage = (project.tasksOverview.notStarted / totalTasks) * 100;
  const inProgressPercentage = (project.tasksOverview.inProgress / totalTasks) * 100;
  const completedPercentage = (project.tasksOverview.completed / totalTasks) * 100;
  const inReviewPercentage = (project.tasksOverview.inReview / totalTasks) * 100; // Calculate "In Review" percentage

  const data = [
    {
      key: '1',
      status: 'Not Started',
      tasks: project.tasksOverview.notStarted,
      percentage: `(${notStartedPercentage.toFixed(0)}%)`,
      circleClass: 'db-not-started-circle'
    },
    {
      key: '2',
      status: 'In Progress',
      tasks: project.tasksOverview.inProgress,
      percentage: `(${inProgressPercentage.toFixed(0)}%)`,
      circleClass: 'db-in-progress-circle'
    },
    {
      key: '3',
      status: 'In Review', // New status
      tasks: project.tasksOverview.inReview, // Number of tasks in "In Review"
      percentage: `(${inReviewPercentage.toFixed(0)}%)`,
      circleClass: 'db-in-review-circle' // Add a CSS class for the circle color
    },
    {
      key: '4',
      status: 'Completed',
      tasks: project.tasksOverview.completed,
      percentage: `(${completedPercentage.toFixed(0)}%)`,
      circleClass: 'db-completed-circle'
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      className: 'status-column',
      render: (text, record) => (
        <span><span className={`db-project-circle ${record.circleClass}`}></span> {text}</span>
      )
    },
    {
      title: '',
      dataIndex: 'tasks',
      key: 'tasks',
      className: 'tasks-column',
    },
    {
      title: '',
      dataIndex: 'percentage',
      key: 'percentage',
      className: 'percentage-column',
    },
  ];

  return (
    <div className="db-tasks-bar-container">
      <div className="db-bar-info">
        <span className="db-bar-title">{totalTasks} tasks</span>
      </div>
      <div className="db-progress-bar-container">
        <div className="db-progress-bar db-progress-bar-not-started" style={{ width: `${notStartedPercentage}%` }}></div>
        <div className="db-progress-bar db-progress-bar-in-progress" style={{ width: `${inProgressPercentage}%` }}></div>
        <div className="db-progress-bar db-progress-bar-in-review" style={{ width: `${inReviewPercentage}%` }}></div> {/* New progress bar for "In Review" */}
        <div className="db-progress-bar db-progress-bar-completed" style={{ width: `${completedPercentage}%` }}></div>
      </div>
      <Table className="team-tasks-summary-table" dataSource={data} columns={columns} pagination={false} showHeader={false} />
    </div>
  );
};

const DashboardProjectBarChart = ({ project }) => (
  <div>
    <HoursBar project={project} />
    <TasksBar project={project} />
  </div>
);

export { HoursBar, TasksBar, DashboardProjectBarChart };
