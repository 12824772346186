import React, { useContext, useState } from 'react';
import { Button, Row, Col, Input } from 'antd';
import './WorkPages.less';
import { AppStateContext } from '../../Context/AppStateContext';
import { addTaskAction, foccusTaskRow } from '../../Utils/taskActions';

const FilterNewSectionOverlay = () => {
  const { tasks, setTasks } = useContext(AppStateContext);
  const [sectionTitle, setSectionTitle] = useState("")
  const [taskTitle, setTaskTitle] = useState("")
  function addSectionAction() {
    const newTask = addTaskAction(tasks)
    if (sectionTitle) {
      newTask.section = sectionTitle
    }
    newTask.taskName = taskTitle
    const taskId = newTask.taskId
    setTasks([...tasks])
    foccusTaskRow(taskId)

  }
  return <div className='task-sort-dropdown-overlay'>
    <Row gutter={16}>
      <Col>
        <Input placeholder='Section Title' value={sectionTitle} onChange={(e) => {
          setSectionTitle(e.target.value);
        }} />
      </Col>
      <Col>
        <Input placeholder='Task Title' value={taskTitle} onChange={(e) => {
          setTaskTitle(e.target.value);
        }} />
      </Col>
      <Col>
        <Button type="primary" className='input' onClick={addSectionAction} >Add Section</Button>
      </Col>
    </Row>
  </div>
};

export default FilterNewSectionOverlay;
