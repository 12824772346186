import { useState, useEffect, useContext } from 'react';
import { AppStateContext } from '../../Context/AppStateContext'; // Adjust the path if necessary
import dayjs from 'dayjs';
import SingleAvatar2 from '../../Components/SingleAvatar2'; // Adjust the path if necessary
import getPriorityTag from '../../Components/PriorityTags'; // Adjust the path if necessary
import getStatusTag from '../../Components/StatusTags2'; // Adjust the path if necessary
import { Select } from 'antd';

const { Option } = Select;

export const useTaskDetailsLogic = (task) => {
  const [taskName, setTaskName] = useState(task.taskName);
  const [assignee, setAssignee] = useState(task.assignee);
  const [dueDate, setDueDate] = useState(task.dueDate ? dayjs(task.dueDate) : null);
  const [timeSpent, setTimeSpent] = useState(task.timeSpent);
  const [projectManager, setProjectManager] = useState(task.projectManager);
  const [priority, setPriority] = useState(task.priority);
  const [status, setStatus] = useState(task.status);
  const [subtasks, setSubtasks] = useState(task.subtasks);
  const [comments, setComments] = useState('');
  const { projects, teamMembers } = useContext(AppStateContext);

  useEffect(() => {
    setTaskName(task.taskName);
    setAssignee(task.assignee);
    setDueDate(task.dueDate ? dayjs(task.dueDate) : null);
    setTimeSpent(task.timeSpent);
    setProjectManager(task.projectManager);
    setPriority(task.priority);
    setStatus(task.status);
    setSubtasks(task.subtasks);
  }, [task]);

  const handleTaskNameChange = (e) => {
    setTaskName(e.target.value);
  };

  const handleAssigneeChange = (value) => {
    setAssignee(value);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleTimeSpentChange = (value) => {
    setTimeSpent(value);
  };

  const handleProjectManagerChange = (value) => {
    setProjectManager(value);
  };

  const handlePriorityChange = (value) => {
    setPriority(value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleSubtaskChange = (subtaskId, field, value) => {
    setSubtasks((prevSubtasks) =>
      prevSubtasks.map((subtask) =>
        subtask.subtaskId === subtaskId ? { ...subtask, [field]: value } : subtask
      )
    );
  };

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  const handlePostComment = () => {
    // Logic to handle posting comment
    console.log('Comment posted:', comments);
    setComments('');
  };

  // Find the project by projectId
  const project = projects.find((project) => project.id === 1); // Adjust project ID as necessary

  // Filter the team members for the project
  const projectTeamMembers = project ? teamMembers.filter((member) => project.members.includes(member.id)) : [];

  const assigneeOptions = projectTeamMembers.map((member) => (
    <Option key={member.id} value={member.name}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SingleAvatar2 name={member.name} />
        <span style={{ marginLeft: '8px' }}>{member.name}</span>
      </div>
    </Option>
  ));

  const projectManagerOptions = [
    { name: 'Sazzad Mahmud' },
    { name: 'Noman Hossain' },
  ].map((manager) => (
    <Option key={manager.name} value={manager.name}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SingleAvatar2 name={manager.name} />
        <span style={{ marginLeft: '8px' }}>{manager.name}</span>
      </div>
    </Option>
  ));

  const priorityOptions = ['Low', 'Medium', 'High', 'Urgent'].map((level) => (
    <Option key={level} value={level}>
      {getPriorityTag(level)}
    </Option>
  ));

  const statusOptions = ['Not Started', 'In Progress', 'On Hold', 'Completed', 'In Review'].map((state) => (
    <Option key={state} value={state}>
      {getStatusTag(state)}
    </Option>
  ));

  return {
    taskName,
    assignee,
    dueDate,
    timeSpent,
    projectManager,
    priority,
    status,
    subtasks,
    comments,
    assigneeOptions,
    projectManagerOptions,
    priorityOptions,
    statusOptions,
    handleTaskNameChange,
    handleAssigneeChange,
    handleDueDateChange,
    handleTimeSpentChange,
    handleProjectManagerChange,
    handlePriorityChange,
    handleStatusChange,
    handleSubtaskChange,
    handleCommentChange,
    handlePostComment,
    projectTeamMembers
  };
};
