// HoursTrackerTableSort.js

import React, { useState } from 'react';
import { Button, Dropdown, Menu, Modal, Select } from 'antd';

const { Option } = Select;

const HoursTrackerTableSort = ({ rowsPerPage, setRowsPerPage }) => {
  const [visibleDate, setVisibleDate] = useState(false);
  const [visibleTimeSpent, setVisibleTimeSpent] = useState(false);
  const [visibleAssignee, setVisibleAssignee] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMenuClick = (e) => {
    console.log('Sorting option selected:', e.key);
  };

  const dateSortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="earliest">Earliest First</Menu.Item>
      <Menu.Item key="newest">Newest First</Menu.Item>
    </Menu>
  );

  const timeSpentSortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="smallest">Smallest First</Menu.Item>
      <Menu.Item key="largest">Largest First</Menu.Item>
    </Menu>
  );

  const assigneeSortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="alphabetical">Alphabetical Order</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="ht-sort-container">
      <div className="ht-sort-options-row">
        <div className="ht-sort-buttons">
          <span className="ht-sort-text">Sort: </span>
          <Dropdown
            overlay={dateSortingMenu}
            visible={visibleDate}
            onVisibleChange={setVisibleDate}
          >
            <Button>
              Date <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={timeSpentSortingMenu}
            visible={visibleTimeSpent}
            onVisibleChange={setVisibleTimeSpent}
          >
            <Button>
              Time Spent <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={assigneeSortingMenu}
            visible={visibleAssignee}
            onVisibleChange={setVisibleAssignee}
          >
            <Button>
              Assignee <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>

          <div className="ht-add-automation-row">
            <div className="ht-rows-text">Rows: </div>
            <Select
              defaultValue={rowsPerPage}
              style={{ width: 60 }}
              onChange={(value) => setRowsPerPage(value)}
              className="ht-rows-button"
            >
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
            </Select>
          </div>
        </div>
        <div className="ht-rows-options">
  
        </div>
      </div>
    </div>
  );
};

export default HoursTrackerTableSort;
