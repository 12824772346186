import React from 'react';
import { Avatar, Popover } from 'antd';
import { lightColors, darkColors, hashStringToIndex } from '../AvatarColors';
import AddMemberButton from '../AddMemberButton'; // Adjust the import path if necessary
import './UserAvatarsGrid.less';

// Function to get two random colors for a given name based on hash
const getAvatarColors = (name) => {
  const isDark = hashStringToIndex(name, 2) % 2 === 0; // Alternate between light and dark colors
  const colors = isDark ? darkColors : lightColors;

  const color1Index = hashStringToIndex(name + '1', colors.length);
  const color2Index = hashStringToIndex(name + '2', colors.length);

  const color1 = colors[color1Index];
  const color2 = colors[color2Index];

  const isLightColor = lightColors.includes(color1) && lightColors.includes(color2);

  return { color1, color2, isLightColor };
};

// UserAvatar component
const UserAvatar = ({ name, jobTitle, size = '34', fontSize = '14' }) => {
  if (!name) return null;

  const { color1, color2, isLightColor } = getAvatarColors(name);
  const initials = name.split(' ').map(n => n[0]).join('').slice(0, 2).toUpperCase();

  const gradientStyle = {
    background: `linear-gradient(135deg, ${color1}, ${color2})`,
    color: isLightColor ? '#000' : '#FFF',
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${fontSize}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const popoverContent = (
    <div>
      <div className="avatar-popover-name">{name}</div>
      <div className="avatar-popover-job-title">{jobTitle}</div>
    </div>
  );

  return (
    <Popover content={popoverContent} placement="top">
      <Avatar style={gradientStyle}>
        {initials}
      </Avatar>
    </Popover>
  );
};

// Custom AvatarGrid component
const UserAvatarsGrid = ({ members, project, handleAddMember }) => {
  const displayedMembers = members.filter(member => member && member.name);

  return (
    <div className="avatar-grid">
      {displayedMembers.map((member, index) => (
        <div key={index} className="avatar-grid-item">
          <UserAvatar name={member.name} jobTitle={member.jobTitle} />
        </div>
      ))}
      <div className="avatar-grid-item">
        <AddMemberButton project={project} handleAddMember={handleAddMember} />
      </div>
    </div>
  );
};

export default UserAvatarsGrid;
export { UserAvatar };
