export const sampleFilesData = [

  {
  name: 'Sample Images',
  type: 'folder',
  date: '2024-08-15',
  files: [
    { name: 'image-1.jpg', date: '2024-08-15', size: '1.5 MB', type: 'file', url: require('../Assets/unsamples/image-1.jpg') },
    { name: 'image-2.jpg', date: '2024-08-15', size: '1.6 MB', type: 'file', url: require('../Assets/unsamples/image-2.jpg') },
    { name: 'image-3.jpg', date: '2024-08-15', size: '1.4 MB', type: 'file', url: require('../Assets/unsamples/image-3.jpg') },
    { name: 'image-4.jpg', date: '2024-08-15', size: '1.7 MB', type: 'file', url: require('../Assets/unsamples/image-4.jpg') },
    { name: 'image-5.jpg', date: '2024-08-15', size: '1.8 MB', type: 'file', url: require('../Assets/unsamples/image-5.jpg') },
    { name: 'image-6.jpg', date: '2024-08-15', size: '1.5 MB', type: 'file', url: require('../Assets/unsamples/image-6.jpg') },
    { name: 'image-7.jpg', date: '2024-08-15', size: '1.6 MB', type: 'file', url: require('../Assets/unsamples/image-7.jpg') },
    { name: 'image-8.jpg', date: '2024-08-15', size: '1.7 MB', type: 'file', url: require('../Assets/unsamples/image-8.jpg') },
    { name: 'image-9.jpg', date: '2024-08-15', size: '1.9 MB', type: 'file', url: require('../Assets/unsamples/image-9.jpg') },
    { name: 'image-10.jpg', date: '2024-08-15', size: '2.0 MB', type: 'file', url: require('../Assets/unsamples/image-10.jpg') },
  ],
},






  {
    name: 'Apple Marketing Campaign',
    type: 'folder',
    date: '2024-08-10',
    files: [
      {
        name: 'Social Media Assets',
        type: 'folder',
        date: '2024-08-11',
        files: [
          { name: 'Company Logo.png', date: '2024-08-11', size: '2.1 MB', type: 'file' },
          { name: 'Event Banner.png', date: '2024-08-18', size: '2.9 MB', type: 'file' },
          { name: 'Product Image.jpg', date: '2024-08-01', size: '3.4 MB', type: 'file' },
          { name: 'Team Celebration Photo.jpg', date: '2024-07-17', size: '3.4 MB', type: 'file' },
        ],
      },
      {
        name: 'Budget Reports',
        type: 'folder',
        date: '2024-08-09',
        files: [
          { name: 'Budget Report 2024.xls', date: '2024-08-09', size: '4.0 MB', type: 'file' },
          { name: 'Expense Report.xlsx', date: '2024-08-02', size: '2.5 MB', type: 'file' },
          { name: 'Quarterly Report.xlsx', date: '2024-08-14', size: '4.3 MB', type: 'file' },
          { name: 'Expense Spreadsheet.xls', date: '2024-07-14', size: '4.2 MB', type: 'file' },
        ],
      },
      { name: 'Marketing Strategy.ppt', date: '2024-08-03', size: '3.6 MB', type: 'file' },
    ],
  },
  {
    name: 'Microsoft Social Media Campaign',
    type: 'folder',
    date: '2024-08-06',
    files: [
      {
        name: 'Presentations',
        type: 'folder',
        date: '2024-08-05',
        files: [
          { name: 'Sales Presentation.pptx', date: '2024-08-05', size: '3.0 MB', type: 'file' },
          { name: 'Campaign Overview.pptx', date: '2024-08-05', size: '4.8 MB', type: 'file' },
          { name: 'Corporate Presentation.ppt', date: '2024-07-21', size: '3.9 MB', type: 'file' },
          { name: 'Client Presentation.pptx', date: '2024-07-15', size: '3.7 MB', type: 'file' },
        ],
      },
      {
        name: 'Documents',
        type: 'folder',
        date: '2024-08-08',
        files: [
          { name: 'Project Proposal.pdf', date: '2024-08-12', size: '1.5 MB', type: 'file' },
          { name: 'Employee Roster.docx', date: '2024-08-10', size: '3.4 MB', type: 'file' },
          { name: 'Contract Document.doc', date: '2024-08-04', size: '1.9 MB', type: 'file' },
          { name: 'Legal Document.pdf', date: '2024-07-31', size: '1.8 MB', type: 'file' },
        ],
      },
    ],
  },
  {
    name: 'Amazon Product Launch',
    type: 'folder',
    date: '2024-08-07',
    files: [
      { name: 'Product Brochure.pdf', date: '2024-08-20', size: '2.3 MB', type: 'file' },
      { name: 'Product Video.mp4', date: '2024-08-06', size: '10.2 MB', type: 'file' },
      { name: 'Product Showcase.jpg', date: '2024-08-12', size: '3.5 MB', type: 'file' },
      { name: 'Final Design.png', date: '2024-07-28', size: '2.6 MB', type: 'file' },
    ],
  },
  {
    name: 'Google Ad Campaign',
    type: 'folder',
    date: '2024-08-09',
    files: [
      {
        name: 'Assets',
        type: 'folder',
        date: '2024-08-10',
        files: [
          { name: 'Design Wireframe.pdf', date: '2024-08-04', size: '2.0 MB', type: 'file' },
          { name: 'Website Screenshot.png', date: '2024-08-08', size: '2.7 MB', type: 'file' },
          { name: 'Infographic Design.png', date: '2024-07-28', size: '2.9 MB', type: 'file' },
          { name: 'Social Media Graphics.jpg', date: '2024-07-23', size: '4.5 MB', type: 'file' },
        ],
      },
      { name: 'App Design Mockup.png', date: '2024-08-15', size: '2.8 MB', type: 'file' },
    ],
  },
  {
    name: 'Facebook Rebranding Project',
    type: 'folder',
    date: '2024-08-01',
    files: [
      {
        name: 'Reports',
        type: 'folder',
        date: '2024-08-03',
        files: [
          { name: 'Market Analysis.docx', date: '2024-08-10', size: '3.3 MB', type: 'file' },
          { name: 'Training Manual.pdf', date: '2024-08-09', size: '4.1 MB', type: 'file' },
          { name: 'Vision Statement.pdf', date: '2024-07-13', size: '1.9 MB', type: 'file' },
          { name: 'Executive Summary.pdf', date: '2024-07-12', size: '2.0 MB', type: 'file' },
        ],
      },
      {
        name: 'Contracts',
        type: 'folder',
        date: '2024-08-05',
        files: [
          { name: 'Client Agreement.pdf', date: '2024-07-29', size: '1.3 MB', type: 'file' },
          { name: 'Service Agreement.pdf', date: '2024-07-25', size: '1.7 MB', type: 'file' },
          { name: 'Contract.pdf', date: '2024-08-07', size: '1.5 MB', type: 'file' },
          { name: 'Workshop Agenda.pdf', date: '2024-07-24', size: '1.3 MB', type: 'file' },
        ],
      },
    ],
  },
  { name: 'Team Photo.jpg', date: '2024-07-25', size: '3.9 MB', type: 'file' },
  { name: 'Presentation Slides.pptx', date: '2024-07-24', size: '3.2 MB', type: 'file' },

  { name: 'Website Assets.zip', date: '2024-07-30', size: '6.7 MB', type: 'file' },
  { name: 'Company Policies.docx', date: '2024-07-26', size: '2.4 MB', type: 'file' },
  { name: 'Customer Feedback.docx', date: '2024-07-22', size: '1.8 MB', type: 'file' },
  { name: 'Annual Report.pdf', date: '2024-07-27', size: '3.8 MB', type: 'file' },
];

export default sampleFilesData;
