import React, { useState } from 'react';
import NotificationsTable from './NotificationsTable';
import './Notifications.less';
import NotificationsSort from './NotificationsSort';

const Notifications = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10); // State to handle rows per page

  return (
    <div className="nf-notifications-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-bell"></i>
        </span>
        Notifications
      </p>
            <NotificationsSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> {/* Pass props here */}
      <div className="nf-notifications-table-container">

        <NotificationsTable rowsPerPage={rowsPerPage} /> {/* Pass rowsPerPage to table */}
      </div>
    </div>
  );
};

export default Notifications;
