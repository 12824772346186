import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import TableRowsOptions from '../TableRowsOptions'; // Import the TableRowsOptions component
import AddClientModal from '../../../Modals/AddClientModal'; // Import the new AddClientModal

const ClientsTableSort = ({ rowsPerPage, setRowsPerPage }) => { // Receive props here
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visibleAccountManager, setVisibleAccountManager] = useState(false);
  const [visibleHours, setVisibleHours] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleMenuClick = (e) => {
    console.log('Sorting option selected:', e.key);
  };

  const sortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="asc">Ascending</Menu.Item>
      <Menu.Item key="desc">Descending</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="ct-sort-container">
      <div className="ct-sort-options-row">
        <div className="ct-sort-buttons">
          <span className="ct-sort-text">Sort: </span>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleStatus}
            onVisibleChange={setVisibleStatus}
          >
            <Button>
              Status <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleAccountManager}
            onVisibleChange={setVisibleAccountManager}
          >
            <Button>
              Account Manager <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleHours}
            onVisibleChange={setVisibleHours}
          >
            <Button>
              Hours <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>

          <div className="ct-add-client-row">
            <div className="ct-rows-text">
              Rows:
            </div>
            <TableRowsOptions rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> {/* Pass props here */}
          </div>
        </div>
        <div className="ct-rows-options">
          <Button className="ct-add-client-button" onClick={showModal}>Add New Client</Button>
          <AddClientModal isVisible={isModalVisible} toggleModal={handleCancel} /> {/* Use the new AddClientModal */}
        </div>
      </div>
    </div>
  );
};

export default ClientsTableSort;
