import React, { useState, useEffect, useContext } from 'react';
import { Input } from 'antd';
import { AppStateContext } from '../../../Context/AppStateContext';
import { addSubtaskAction, addTaskAction, foccusTaskRow, getGroupIndexFromSubtask } from '../../../Utils/taskActions';

export const EditableTaskNameCell = ({
  value: initialValue,
  row,
  column,
  taskIndex,
  subtaskIndex,
  updateData
}) => {
  const [value, setValue] = useState(initialValue);
  const { tasks, setTasks, addSubtaskBelow, groupByColumns, taskGroups } = useContext(AppStateContext);
  const { index } = row
  const { id } = column
  const isSubtask = !!row.original.subtaskId

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (isSubtask) {
      if (groupByColumns.length < 1) {
        tasks[taskIndex].subtasks[subtaskIndex][id] = value
        setTasks([...tasks])
      } else {
        const { subtaskId } = row.original
        const groupIndex = getGroupIndexFromSubtask(taskGroups, taskIndex, subtaskIndex, subtaskId)
        const { taskId } = taskGroups[groupIndex].tasks[taskIndex]
        const gtIndex = tasks.findIndex((item) => item.taskId === taskId)
        tasks[gtIndex].subtasks[subtaskIndex][id] = value
        setTasks([...tasks])
      }
    } else {
      updateData(index, id, value);
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && !!value) {
      if (groupByColumns.length < 1) {
        if (isSubtask) {
          addSubtaskBelow(taskIndex, subtaskIndex);
        } else {
          addTaskAction(tasks, index);
          setTasks([...tasks])
        }
        setTimeout(() => {
          const newInput = document.querySelector(isSubtask ? `.input-${taskIndex}-${subtaskIndex + 1}` : `.input-${index + 1}`)

          if (newInput) {
            newInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            newInput.focus()
          }
        }, 10)
      } else {
        if (isSubtask) {
          const { subtaskId } = row.original
          const groupIndex = getGroupIndexFromSubtask(taskGroups, taskIndex, subtaskIndex, subtaskId)
          const { taskId } = taskGroups[groupIndex].tasks[taskIndex]
          const gtIndex = tasks.findIndex((item) => item.taskId === taskId)
          addSubtaskAction(tasks, gtIndex, subtaskIndex);
          setTasks([...tasks])
          setTimeout(() => {
            const newInput = document.querySelector(`.pmt-group-table .input-${taskIndex}-${subtaskIndex + 1}`)
            // console.log(newInput)
            newInput.focus()
          }, 10)
        } else {
          const taskId = row.original.taskId
          const gtIndex = tasks.findIndex((item) => item.taskId === taskId)
          addTaskAction(tasks, gtIndex);
          const newTask = tasks[gtIndex + 1]
          groupByColumns.forEach((item) => {
            newTask[item] = row.original[item]
          })
          setTasks([...tasks])
          foccusTaskRow(newTask.taskId)
        }
      }
    }
  };

  return <Input
    value={value}
    className={isSubtask ? `input-${taskIndex}-${subtaskIndex}` : `input-${index}`}
    onChange={onChange}
    onBlur={onBlur}
    onKeyPress={onKeyPress} />;
};

const TaskNameColumn = {
  Header: 'Task Name',
  accessor: 'taskName',
  width: 300,
  Cell: EditableTaskNameCell,
  className: 'pmt-task-column',
};

export default TaskNameColumn;
