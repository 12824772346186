// Automations.js

import React, { useState } from 'react';
import { Pagination } from 'antd';
import './AutomationsPages.less';
import { automationsData } from '../../Data/SampleAutomations';
import NameColumn from './AutomationsTableColumns/NameColumn';
import ExecutionsColumn from './AutomationsTableColumns/ExecutionsColumn';
import CreatedDateColumn from './AutomationsTableColumns/CreatedDateColumn';
import StatusColumn from './AutomationsTableColumns/StatusColumn';
import { useAutomationsTableLogic } from './AutomationsTableColumns/AutomationsTableLogic';
import AutomationsTableSort from './AutomationsTableColumns/AutomationsTableSort';
import StepsRow from './AutomationsTableColumns/StepsRow';
import RowOptions from './AutomationsTableColumns/RowOptions'; // Import the new RowOptions

const TableRow = ({ row, index, columns, expandedRows, toggleRow }) => (
  <>
    <tr className="at-table-row">
      {columns.map((column, colIndex) => (
        <td key={column.accessor} className="at-cell">
          {column.render ? column.render(null, row, index) : row[column.accessor]}
          {colIndex === columns.length - 1 && <div className="row-options-container"><RowOptions /></div>} {/* Add RowOptions at the end of the last column */}
        </td>
      ))}
    </tr>
    {expandedRows.has(index) && (
      <tr className="at-expanded-row">
        <td colSpan={columns.length}>
          <StepsRow steps={row.steps} />
        </td>
      </tr>
    )}
  </>
);

const Automations = () => {
  const { isMobile } = useAutomationsTableLogic();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Manage rows per page
  const [expandedRows, setExpandedRows] = useState(new Set());

  // Get current page rows
  const currentRows = automationsData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const toggleRow = (index) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(index)) {
        newExpandedRows.delete(index);
      } else {
        newExpandedRows.add(index);
      }
      return newExpandedRows;
    });
  };

  const columns = [
    { Header: 'Name', accessor: 'name', render: (text, record, index) => NameColumn.render(text, record, { index, expandedRows, toggleRow }) },
    { Header: 'Executions', accessor: 'executions', render: ExecutionsColumn.render },
    { Header: 'Date Created', accessor: 'createdDate', render: CreatedDateColumn.render },
    { Header: 'Status', accessor: 'status', render: StatusColumn.render },
  ];

  return (
    <div className="at-automations-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-user-robot"></i>
        </span>
        All Automations
      </p>
      <AutomationsTableSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
      <div className="at-table-container">
      <div className="main-table-main-container">
        <div className="main-table-container">
          <table className="main-table">
            <thead className="main-table-head">
              <tr className="main-table-head-row">
                {columns.map((column) => (
                  <th key={column.accessor} className="main-table-head-cell">
                    {column.Header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row, index) => (
                <TableRow key={row.number} row={row} index={index} columns={columns} expandedRows={expandedRows} toggleRow={toggleRow} />
              ))}
            </tbody>
          </table>
        </div>

        </div>
        <div className="main-pagination-container">
        <Pagination
          current={currentPage}
          pageSize={rowsPerPage}
          total={automationsData.length}
          onChange={setCurrentPage}
          className="main-pagination"
        />
        </div>
      </div>
    </div>
  );
};

export default Automations;
