// ClientChatData.js

export const teamMembers = [
  { name: 'Alice Jonson' },
  { name: 'Bob Smith' },
  { name: 'Charlie Brown' },
  { name: 'Noman Hossain' },
  { name: 'Kim Peterson' },
  { name: 'Osama Bin Laden' }
];

// Clients with first and last names
export const clients = [
  { name: 'Emma Watson' },
  { name: 'David Johnson' },
  { name: 'Sophia Williams' }
];

// Initial messages with varied content length about a website design project
export const initialMessages = [
  {
    id: 1,
    user: 'Alice Jonson',
    content: 'Hey team, I’ve reviewed the initial mockup for the client’s website redesign. Overall, it looks great, but there are a few tweaks we need to make. The client mentioned they want the hero section to be more interactive with some animations. We’ll need to update that before our next meeting.',
    time: '9:15 AM',
    date: '04/09/2023'
  },
  {
    id: 2,
    user: 'Bob Smith',
    content: 'I’ve just finished integrating the new navigation bar design. However, I noticed an issue with the mobile responsiveness, particularly in the tablet view. The menu is overlapping with the logo. I’ll work on fixing this, but I might need some suggestions on how best to handle it for smaller screens.',
    time: '10:00 AM',
    date: '04/09/2023'
  },
  {
    id: 3,
    user: 'Noman Hossain',
    content: 'Thanks for the updates, Alice and Bob. I’m currently optimizing the images for the portfolio section to reduce load times. Also, I’ll look into adding animations to the hero section as requested. I think we can use CSS animations for this to keep things lightweight and performant. I’ll have an updated version ready by tomorrow.',
    time: '11:30 AM',
    date: '04/09/2023'
  },
  {
    id: 4,
    user: 'Emma Watson',
    content: 'I’ve updated the contact form design as per the client’s request. It’s now more compact and visually appealing.',
    time: '1:00 PM',
    date: '04/09/2023'
  },
  {
    id: 5,
    user: 'David Johnson',
    content: 'I’ve noticed that the footer isn’t responsive on smaller screen sizes. Should I start working on that or is someone else handling it?',
    time: '2:45 PM',
    date: '04/09/2023'
  },
  {
    id: 6,
    user: 'Sophia Williams',
    content: 'I just checked the updated portfolio section. It looks great! I’ll start adding the client testimonials to it by the end of today.',
    time: '4:00 PM',
    date: '04/09/2023'
  },
  {
    id: 7,
    user: 'Alice Jonson',
    content: 'Also, we need to make sure the blog posts have consistent formatting across all pages. Some articles have different font sizes in the headings.',
    time: '10:20 AM',
    date: '05/09/2023'
  },
  {
    id: 8,
    user: 'Noman Hossain',
    content: 'I’ll check the blog section and make sure everything is uniform. Thanks for catching that, Alice.',
    time: '2:30 PM',
    date: '05/09/2023'
  },
  {
    id: 9,
    user: 'Charlie Brown',
    content: 'I’ve finished setting up the staging environment. Feel free to test out the latest changes there before we push them live.',
    time: '11:00 AM',
    date: '08/09/2023'
  },
  {
    id: 10,
    user: 'Emma Watson',
    content: 'I think we should also add a hover effect to the buttons to make the call-to-action clearer. What do you all think?',
    time: '9:30 AM',
    date: '11/09/2023'
  },
  {
    id: 11,
    user: 'David Johnson',
    content: 'I agree with Emma. A subtle hover effect could improve the user experience.',
    time: '10:00 AM',
    date: '11/09/2023'
  },
  {
    id: 12,
    user: 'Sophia Williams',
    content: 'Hover effect sounds good. We could use a slight color change and maybe a box shadow to make the buttons pop more. I’ve just finished integrating the new navigation bar design. However, I noticed an issue with the mobile responsiveness, particularly in the tablet view. The menu is overlapping with the logo. I’ll work on fixing this, but I might need some suggestions on how best to handle it for smaller screens.',
    time: '9:00 AM',
    date: '14/09/2023'
  },
  {
    id: 13,
    user: 'Noman Hossain',
    content: 'I’ll add a hover effect to the buttons. I’ll make sure it’s subtle so it doesn’t distract from the content. Should be ready by tomorrow afternoon.',
    time: '3:15 PM',
    date: '14/09/2023'
  },
  {
    id: 14,
    user: 'Bob Smith',
    content: 'Quick update: I’ve fixed the logo overlap issue on the tablet view. Everything should be responsive now.',
    time: '11:45 AM',
    date: '15/09/2023'
  },
  {
    id: 15,
    user: 'Alice Jonson',
    content: 'Awesome! I’ll give it a quick test to make sure all the changes look good on my end as well.',
    time: '10:05 AM',
    date: '18/09/2023'
  },
  {
    id: 16,
    user: 'Charlie Brown',
    content: 'One last thing, let’s ensure the image alt tags are properly set for SEO optimization before we go live.',
    time: '4:50 PM',
    date: '21/09/2023'
  }
];
