import React, { useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Form, Input, Row, Col, DatePicker, Select, Tag } from 'antd';
import moment from 'moment';

const { Option } = Select;

const customizeRequiredMark = (label, { required }) => (
  <>
    {required ? <Tag color="error">Required</Tag> : <Tag color="warning">optional</Tag>}
    {label}
  </>
);

const EditTaskModal = ({ visible, onCancel, onSave, task }) => {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = useState('optional');

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const initialValues = {
    ...task,
    taskCreated: task.taskCreated ? moment(task.taskCreated) : null,
    dueDate: task.dueDate ? moment(task.dueDate) : null,
  };

  return (
    <Modal
      visible={visible}
      title="Edit Task"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onSave(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={onRequiredTypeChange}
        requiredMark={requiredMark === 'customize' ? customizeRequiredMark : requiredMark}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="clientName"
              label="Client Name"
              rules={[{ required: true, message: 'Please input the client name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="subClientName"
              label="Sub Client Name"
              rules={[{ required: true, message: 'Please input the sub client name!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="project"
              label="Project"
              rules={[{ required: true, message: 'Please input the project!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="task"
              label="Task"
              rules={[{ required: true, message: 'Please input the task!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="subTask"
              label="Sub Task"
              rules={[{ required: true, message: 'Please input the sub task!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="hoursAllotted"
              label="Hours Allotted"
              rules={[{ required: true, message: 'Please input the hours allotted!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="assignee"
              label="Assignee"
              rules={[{ required: true, message: 'Please input the assignee!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="accountManager"
              label="Account Manager"
              rules={[{ required: true, message: 'Please input the account manager!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="taskCreated"
              label="Date Created"
              rules={[{ required: true, message: 'Please select the date created!' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="dueDate"
              label="Date Due"
              rules={[{ required: true, message: 'Please select the date due!' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: 'Please select the status!' }]}
            >
              <Select>
                <Option value="Not Started">Not Started</Option>
                <Option value="In Progress">In Progress</Option>
                <Option value="Completed">Completed</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default EditTaskModal;
