import React, { useState, useEffect } from 'react';
import { Layout, Button, message } from 'antd';
import BreadcrumbComponent from '../../Components/breadcrumb';
import CustomAvatarGroup from '../../Components/UserAvatars2';
import AddMemberButton from '../../Components/AddMemberButton';
import ManageTeamModal from '../../Modals/ManageTeamModal';
import { projects, teamMembers as allTeamMembers, clientsData } from '../../Data/ProjectsSampleData';
import ClientMainBanner from './ClientMainPageComponents/ClientMainBanner';
import ClientMainFolders from './ClientMainPageComponents/ClientMainFolders';
import './ClientsPages.less';



const { Content } = Layout;

const ClientMainPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  useEffect(() => {
    const clientProject = projects.find(project => project.clients.includes(1));
    if (clientProject) {
      const members = clientProject.members.map(memberId => {
        return allTeamMembers.find(member => member.id === memberId);
      });
      setTeamMembers(members);
    }
  }, []);

  const showModal = () => {
    const project = projects.find(project => project.id === 1);
    setSelectedProject(project);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    message.success('New member added');
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const breadcrumbItems = [
    // { title: '', link: '/', icon: 'fi-rr-home' },
    { title: 'Clients', link: '/clients' },
    { title: 'Glo Tanning' },
  ];

  return (
    <div className="ct-client-main-page-container">
    <div className="ct-main-page-breadcrumb">
      <BreadcrumbComponent items={breadcrumbItems} />
      </div>



      <Content className="ct-content">

        {/* Page Title and Icon */}
        <div className="tp-page-title ">
          <div className="tp-page-title-icon-container">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.33289 14.6667H10.6662C13.3462 14.6667 13.8262 13.5933 13.9662 12.2867L14.4662 6.95333C14.6462 5.32667 14.1796 4 11.3329 4H4.66623C1.81956 4 1.35289 5.32667 1.53289 6.95333L2.03289 12.2867C2.17289 13.5933 2.65289 14.6667 5.33289 14.6667Z" stroke="#626262" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path opacity="0.4" d="M5.33301 4.00016V3.46683C5.33301 2.28683 5.33301 1.3335 7.46634 1.3335H8.53301C10.6663 1.3335 10.6663 2.28683 10.6663 3.46683V4.00016" stroke="#626262" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <g opacity="0.4">
                <path d="M9.33366 8.66667V9.33333C9.33366 9.34 9.33366 9.34 9.33366 9.34667C9.33366 10.0733 9.32699 10.6667 8.00033 10.6667C6.68033 10.6667 6.66699 10.08 6.66699 9.35333V8.66667C6.66699 8 6.66699 8 7.33366 8H8.66699C9.33366 8 9.33366 8 9.33366 8.66667Z" stroke="#626262" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.433 7.3335C12.893 8.4535 11.133 9.12016 9.33301 9.34683" stroke="#626262" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M1.74609 7.51318C3.24609 8.53985 4.93943 9.15985 6.66609 9.35318" stroke="#626262" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>

          </div>
          <div className="tp-page-title-text">Client Main Page</div>
        </div>

        <ClientMainBanner />



        <div className="whitebox">
        <div className="whitebox-title">
        <h3> My Workplace </h3>
        <Button className="button-3"> <i className="fi fi-rr-settings-sliders"></i> </Button>
        </div>
        <ClientMainFolders />
        </div>


      </Content>

      <ManageTeamModal
        isVisible={isModalVisible}
        selectedProject={selectedProject}
        toggleModal={handleCancel}
        teamMembers={allTeamMembers}
        clientsData={clientsData}
      />
    </div>
  );
};

export default ClientMainPage;
