// AddProjectModal.js

import React from 'react';
import { Form, Input, Select, DatePicker, Button, message } from 'antd';
import DefaultModal from './DefaultModal';
import teamMembersData from '../Data/TeamSampleData';
import SingleAvatar2NoTooltip from '../Components/SingleAvatar2NoTooltip';
import getStatusTag from '../Components/StatusTags';
import EditableTimeSpentFormInput from '../Components/EditableTimeSpentFormInput';
import '../Components/Components.less';
import './Modals.less';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AddProjectModal = ({ isVisible, toggleModal }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log('Form values:', values);
    message.success('Your project has been added successfully!');
    form.resetFields();
    toggleModal();
  };

  const handleCancel = () => {
    message.info('Your project has been cancelled.');
    toggleModal();
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()}>
      Add Project
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title="Add New Project"
      icon="add-folder"
      footerContent={footerContent}
      className="add-project-modal"
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form add-project-form">
        <Form.Item name="name" label="Project Name" rules={[{ required: true, message: 'Please enter the project name' }]}>
          <Input placeholder="Enter project name" />
        </Form.Item>

        <div className="form-row">
          <Form.Item name="client" label="Client" className="form-item-half">
            <Input placeholder="Enter client name" />
          </Form.Item>

          <Form.Item name="accountManager" label="Account Manager" className="form-item-half">
            <Select placeholder="Select account manager">
              {teamMembersData.map(member => (
                <Option key={member.id} value={member.name}>
                  <div className="ant-select-item-option-content">
                    <SingleAvatar2NoTooltip name={member.name} />
                    {member.name}
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item name="teamMembers" label="Team Members">
          <Select mode="multiple" placeholder="Select team members">
            {teamMembersData.map(member => (
              <Option key={member.id} value={member.name}>
                <div className="ant-select-item-option-content">
                  <SingleAvatar2NoTooltip name={member.name} />
                  {member.name}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-row">
          <Form.Item name="projectStatus" label="Project Status" className="form-item-half">
            <Select placeholder="Select project status">
              <Option value="Not Started">{getStatusTag('Not Started')}</Option>
              <Option value="In Progress">{getStatusTag('In Progress')}</Option>
              <Option value="On Hold">{getStatusTag('On Hold')}</Option>
              <Option value="Completed">{getStatusTag('Completed')}</Option>
              <Option value="In Review">{getStatusTag('In Review')}</Option>
            </Select>
          </Form.Item>

          <Form.Item name="estimatedHours" label="Estimated Hours" className="form-item-half">
            <EditableTimeSpentFormInput />
          </Form.Item>
        </div>

        <Form.Item name="timeline" label="Timeline">
          <RangePicker style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </DefaultModal>
  );
};

export default AddProjectModal;
