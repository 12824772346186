import React, { useState } from 'react';
import { Button, Upload, message, List } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const TaskAttachments = () => {
  const [attachments, setAttachments] = useState([]);

  const handleUpload = ({ file, fileList }) => {
    if (file.status === 'done') {
      message.success(`${file.name} file uploaded successfully`);
      setAttachments([...attachments, file]);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }
  };

  return (
    <div className="task-attachments">
      <Upload
        multiple={false}
        customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
        onChange={handleUpload}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Add Attachment</Button>
      </Upload>
      <List
        dataSource={attachments}
        renderItem={item => (
          <List.Item>
            <a href={URL.createObjectURL(item.originFileObj)} download={item.name}>
              {item.name}
            </a>
          </List.Item>
        )}
      />
    </div>
  );
};

export default TaskAttachments;
