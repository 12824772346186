import React from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Menu, Dropdown } from 'antd'; // Import Ant Design Menu and Dropdown
import KanbanTask from './KanbanTask';
import { EllipsisOutlined } from '@ant-design/icons'; // Import Ant Design Ellipsis Icon

function KanbanColumn({ id, items }) {
  // Create unique class names based on the column ID
  const columnClassName = `kb-column kb-column-${id.replace(/\s+/g, '-').toLowerCase()}`;
  const circleClassName = `kb-column-circle kb-column-circle-${id.replace(/\s+/g, '-').toLowerCase()}`;
  const rectangleClassName = `kb-column-rectangle kb-column-rectangle-${id.replace(/\s+/g, '-').toLowerCase()}`;

  // Menu items for the dropdown
  const menu = (
    <Menu>
      <Menu.Item key="1">Collapse Column</Menu.Item>
      <Menu.Item key="2">Delete Column</Menu.Item>
      <Menu.Item key="3">Settings</Menu.Item>
    </Menu>
  );

  return (
    <div className={columnClassName}>
      <div className="kb-column-header">
        {/* Column Title and Menu */}
        <div className="kb-column-title">
          <span>
            <span className={circleClassName}></span> {id}
             <span className="kb-column-task-amount"> {items.length} </span>
          </span>
        </div>
        <div className="kb-column-menu-dots">
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <EllipsisOutlined className="kb-column-menu-dots" />
        </Dropdown>
        </div>
      </div>

      {/* Rectangle under the title */}
      <div className="kb-rectangle-container">
        <div className={rectangleClassName}></div>
      </div>

      <div className="kb-column-inside">
        {/* Add Task Button */}
        <button className="kb-add-task-button">
          <i className="fi fi-br-plus"></i>
        </button>

        <SortableContext id={id} items={items} strategy={verticalListSortingStrategy}>
          {items.map((item) => (
            <KanbanTask key={item} id={item} />
          ))}
        </SortableContext>
      </div>
    </div>
  );
}

export default KanbanColumn;
