import React, { useMemo, useContext } from 'react';
import { useTable } from 'react-table';
import { AppStateContext } from '../../Context/AppStateContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import columns, { groupByOptions } from './TableColumns/TableColumn';  // Import columns
import './WorkPages.less';
import { updateTaskAction, getGroupIndex, addTaskAction, foccusTaskRow } from '../../Utils/taskActions';
import PGGroupTitleField from './PGGroupTitleField';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const TableSingle = ({ groupIndex }) => {
  const { tasks, setTasks, taskGroups, groupByColumns, sortByColumns, columnOrder } = useContext(AppStateContext);
  const group = groupIndex !== undefined ? taskGroups[groupIndex] : null
  const orderedColumns = useMemo(() => columnOrder.map((columnId) =>
    columns.find((col) => col.accessor === columnId)
  ), [columnOrder]);

  const updateData = (rowIndex, columnId, value) => {
    if (group) {    // If Table is a group Table
      const task = group.tasks[rowIndex]
      if (task[columnId] === value) return
      const taskIndex = tasks.findIndex((item) => (item.taskId === task.taskId))
      updateTaskAction(tasks, taskIndex, { [columnId]: value }, sortByColumns);
      setTasks([...tasks])

      setTimeout(() => {
        const newTableIndex = getGroupIndex(taskGroups, task.taskId)
        const newRowIndex = taskGroups[newTableIndex].tasks.findIndex((item) => (item.taskId === task.taskId))
        const element = document.querySelector(`.pmt-group-table:nth-child(${newTableIndex + 2}) tr.pmt-row:nth-child(${newRowIndex + 1})`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          element.classList.add('glow-effect');

          setTimeout(() => {
            element.classList.remove('glow-effect');
          }, 2000)
        }
      }, 10)
    } else {
      if (tasks[rowIndex][columnId] === value) return
      const oldTaskId = tasks[rowIndex].taskId
      updateTaskAction(tasks, rowIndex, { [columnId]: value }, sortByColumns);
      setTasks([...tasks])
      if (sortByColumns.length > 0) {
        setTimeout(() => {
          const newRowIndex = tasks.findIndex((item) => (item.taskId === oldTaskId))
          const element = document.querySelector(`tr.pmt-row:nth-child(${newRowIndex + 1})`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.classList.add('glow-effect');

            setTimeout(() => {
              element.classList.remove('glow-effect');
            }, 2000)
          }
        }, 10)
      }
    }
  };

  const handleAddTask = () => {
    const newTask = addTaskAction(tasks);
    const tableTasks = group ? group.tasks : tasks
    groupByColumns.forEach((item) => {
      newTask[item] = tableTasks[tableTasks.length - 1][item]
    })
    setTasks([...tasks])
    foccusTaskRow(newTask.taskId)
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: orderedColumns,
      data: group ? group.tasks : tasks,
      updateData,
    }
  );
  function getTable() {

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <TableHeader key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => (
            <TableRow
              key={row.original.taskId}
              row={row}
              index={index}
              prepareRow={prepareRow}
            />
          ))}
          <tr className="pmt-add-task-row">
            <td colSpan={orderedColumns.length} className="pmt-add-task-cell">
              <Button icon={<PlusOutlined />} onClick={handleAddTask} className="pmt-add-task-button">
                Add Task
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  if (group) {
    return <div className='pmt-group-table'>
      <div className='header'>{Object.keys(group.attributes).map((key, index) => <span key={index}>
        <b>{groupByOptions.find((item) => item.key === key)?.label} :</b>{key === "section" ? <PGGroupTitleField groupIndex={groupIndex} /> : group.attributes[key]}

      </span>)}</div>
      {getTable()}
    </div>
  } else {
    return <>{getTable()}</>
  }

};

export default TableSingle;
