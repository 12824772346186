import React, { useState } from 'react';
import { Select } from 'antd';
import getStatusTag from '../../../Components/StatusTags'; // Ensure the correct path to your status tag component

const { Option } = Select;

const StatusColumn = ({ project, handleStatusChange }) => {
  const [status, setStatus] = useState(project.projectStatus);

  const handleChange = (value) => {
    setStatus(value);
    handleStatusChange(project, value);
  };

  return (
    <Select
      value={status}
      onChange={handleChange}
      className="status-dropdown"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Option value="Not Started">{getStatusTag('Not Started')}</Option>
      <Option value="In Progress">{getStatusTag('In Progress')}</Option>
      <Option value="On Hold">{getStatusTag('On Hold')}</Option>
      <Option value="Completed">{getStatusTag('Completed')}</Option>
      <Option value="In Review">{getStatusTag('In Review')}</Option>
    </Select>
  );
};

export default StatusColumn;
