import React, { useState } from 'react';
import { Layout, Button, Modal, List, Avatar, Tabs } from 'antd';
import BreadcrumbComponent from '../../Components/breadcrumb'; // Ensure correct import
import ProjectTable from '../../Components/ProjectTable'; // Ensure correct import
import ManageTeamModal from '../../Modals/ManageTeamModal'; // Ensure correct import
import { projects as initialProjects, teamMembers as initialTeamMembers, clientsData as initialClientsData } from '../../Data/ProjectsSampleData';

const Projects2 = () => {
  const [projects, setProjects] = useState(initialProjects);
  const [teamMembers, setTeamMembers] = useState(initialTeamMembers);
  const [clientsData, setClientsData] = useState(initialClientsData);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleAddMember = (project) => {
    setSelectedProject(project);
    setIsModalVisible(true);
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const breadcrumbItems = [
    { title: '', link: '/', icon: 'fi-rr-home' },
    { title: 'Work', link: '/work' },
    { title: 'Projects', link: '/projects' },
  ];

  return (
    <div>
      <BreadcrumbComponent items={breadcrumbItems} />

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px', gap: '10px' }}>
        <Button className="ant-btn-secondary" icon={<i className="fi fi-rr-columns-3" style={{ fontSize: '14px' }}></i>}>
          Manage Columns
        </Button>
        <Button type="primary" icon={<i className="fi fi-br-plus" style={{ fontSize: '14px' }}></i>}>
          Create Project
        </Button>
      </div>

      <ProjectTable projects={projects} teamMembers={teamMembers} handleAddMember={handleAddMember} />

      {isModalVisible && (
        <ManageTeamModal
          isVisible={isModalVisible}
          selectedProject={selectedProject}
          toggleModal={toggleModal}
          teamMembers={teamMembers}
          clientsData={clientsData}
        />
      )}
    </div>
  );
};

export default Projects2;
