import React, { useContext } from 'react';
import columns from './TableColumns/TableColumn'; // Adjust the path if necessary
import { AppStateContext } from '../../Context/AppStateContext'; // Adjust the path if necessary

const SubtaskRow = ({ subtask, taskIndex, subtaskIndex, taskId }) => {
  const { updateSubtask } = useContext(AppStateContext);
  const row = { original: subtask, index: subtaskIndex };
  const projectId = 1; // Set the project ID here

  // const onKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     console.log('Enter key pressed for subtask:', taskId, subtask.subtaskId);
  //     addSubtaskBelow(taskId, subtaskIndex);
  //   }
  // };

  return (
    <tr className="pmt-subtask-row">
      {columns.map((column, index) => {
        const CellComponent = column.Cell;
        return (
          <td key={index} className={`pmt-cell ${column.className}`}>
            <CellComponent
              value={subtask[column.accessor]}
              row={row}
              column={{ id: column.accessor }}
              updateData={(taskIndex, columKey, newValue) => {
                const newTaskUpdate = { [columKey]: newValue }
                updateSubtask(taskIndex, subtaskIndex, newTaskUpdate);
              }}
              projectId={projectId} // Pass projectId for the assignee column
              // onKeyPress={onKeyPress} // Attach the onKeyPress handler
              taskId={taskId}
              taskIndex={taskIndex}
              subtaskIndex={subtaskIndex}
            />
          </td>
        );
      })}
    </tr>
  );
};

export default SubtaskRow;
