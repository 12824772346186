import React from 'react';
import { Row, Col } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import SingleAvatar from '../../Components/SingleAvatar';

const names = [
  'Madisyn Burke', 'Jax Bradley', 'Vanessa Ross', 'Wesley Burch', 'Freyja Hardy',
  'Jayceon Davenport', 'Adrianna Reilly', 'Alvaro Glass', 'Clare Combs', 'Ahmad Phelps',
  'Laney Woodard', 'Westley Vincent', 'Allyson Melton', 'Lennon Morris', 'Genesis McMahon',
  'Jakob Rocha', 'Emmie Salas', 'Zaiden Vang', 'Madisyn Arnold', 'Abraham Sandoval',
  'Elsie Blackburn', 'Zahir Jones', 'Sophia Castaneda', 'Collin Krueger', 'Kamari Luna',
  'Erick Aguilar', 'Josie Townsend', 'Alexis Galindo', 'Corinne Harris', 'Samuel Ibarra',
  'Madilynn Kelly', 'Cooper Sherman', 'Addilyn Ellis', 'Cole Vo', 'Artemis Sawyer',
  'Jefferson Conway', 'Ryann Shepard', 'Damari Copeland', 'Dayana Henderson', 'Beau Palmer',
  'Juniper Wyatt', 'Sam Stevenson', 'Regina Cobb', 'Raphael Madden', 'Violette Erickson',
  'Johnny Decker', 'Aleena Guerrero', 'Bryce Cortez', 'Haven James', 'Jaxson Lim',
  'Giavanna Haynes', 'Kason Richard', 'Davina Kramer', 'Kylan Mathews', 'Sloan Griffin',
  'Ayden Cobb', 'Aviana Kline', 'Ramon Luna', 'Journey Nicholson', 'Rodrigo Young',
  'Zoey Rivera', 'Charles Schultz', 'Briella Garrison', 'Noe Welch', 'Amira Perkins',
  'Kyrie Pruitt', 'Brylee McDonald', 'Calvin Hunt', 'Genevieve Saunders', 'Kasen Simmons',
  'Reagan Villalobos', 'Reuben Bailey', 'Kennedy Krueger', 'Jones Douglas', 'Aniyah Hobbs',
  'Brendan Medina', 'Elliana Atkins', 'Cason Brandt', 'Loretta Waters', 'Maximilian Sloan',
  'Selene Figueroa', 'Spencer Cole', 'Margaret Hanna', 'Aydin Hancock', 'Katelyn Bruce',
  'Uriah Wells', 'Cecilia Owens', 'Adriel Fischer', 'Maci Holloway', 'Sutton Cross',
  'Nayeli Smith', 'Liam Long', 'Jade Hubbard', 'Forrest Lu', 'Emani Rollins',
  'Wes Donaldson', 'Natasha Duke', 'Kalel Fitzpatrick', 'Annabella Novak', 'Bishop Dixon'
];

const Test = () => {
  // Split names into groups of 5
  const groups = [];
  for (let i = 0; i < names.length; i += 5) {
    groups.push(names.slice(i, i + 5));
  }

  return (
    <div style={{ padding: '20px' }}>
      <h2>New Avatars with SingleAvatar2</h2>
      {groups.map((group, index) => (
        <Row gutter={[16, 16]} key={index} style={{ marginBottom: '20px' }}>
          {group.map((name, idx) => (
            <Col span={4} key={idx}>
              <SingleAvatar2 name={name} />
            </Col>
          ))}
        </Row>
      ))}

      <h2>Old Avatars with SingleAvatar</h2>
      {groups.map((group, index) => (
        <Row gutter={[16, 16]} key={index} style={{ marginBottom: '20px' }}>
          {group.map((name, idx) => (
            <Col span={4} key={idx}>
              <SingleAvatar name={name} />
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default Test;
