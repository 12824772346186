import React from 'react';
import { Card, Row, Col, Button } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';


const TicketCategory = () => {
    const categories = [
        { title: 'Design & Development', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Bug & Report', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Support', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'QA Support', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Marketing', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Refund Policy', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Error', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Sales Department', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Sales Department', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Sales Department', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Sales Department', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
        { title: 'Sales Department', description: 'Add task to see more info about the task or leave comments for the particular project for me.' },
    ];

    return (



        

<div className="ticket-category-container">

           
        {/* Page Title and Icon */}
        <div className=" tpp-main-container">
            <div className="tpp-tp-page-title">
           <div className="tpp-tp-page-title-icon-container">
        <i className="fi fi-sr-folder"></i>
          </div>
          <div className="tpp-tp-page-title-text">Ticket Details</div>
          </div>
        </div>


      <div class="acc-menu-container">
    <ul class="acc-menu-list">
        <li class="acc-menu-item"> 
 Meta  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 6.50003C12 6.50003 9.05407 10.5 8 10.5C6.94587 10.5 4 6.5 4 6.5" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
</svg></li>
        <li class="acc-menu-item"> Pools <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 6.50003C12 6.50003 9.05407 10.5 8 10.5C6.94587 10.5 4 6.5 4 6.5" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</li>
        <li class="acc-menu-item"> Chapters <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 6.50003C12 6.50003 9.05407 10.5 8 10.5C6.94587 10.5 4 6.5 4 6.5" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</li>
    </ul>
    <button class="acc-add-category-btn"><i class="fa fa-plus"></i> Add Category</button>
</div>


            <Row gutter={[16, 16]}>
                {categories.map((category, index) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={index}>
                        <Card
                            className="ticket-category-card"
                            bodyStyle={{ padding: 0 }} // Remove padding since it's handled in CSS
                        >
                            <div className="ticket-category-options">
                                <EllipsisOutlined />
                            </div>
                            <div className="ticket-category-card-body">
                                <div className="ticket-category-title">{category.title}</div>
                                <div className="ticket-category-description">{category.description}</div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default TicketCategory;
