// src/utils/teamActions.js

export const addTeamMember = (teamMembers, newMember) => {
  return [...teamMembers, newMember];
};

export const removeTeamMember = (teamMembers, memberId) => {
  return teamMembers.filter(member => member.id !== memberId);
};

export const updateTeamMember = (teamMembers, memberId, updatedMember) => {
  return teamMembers.map(member =>
    member.id === memberId ? { ...member, ...updatedMember } : member
  );
};
