import React, { useState, useEffect } from 'react';
import { initGantt, clearGantt, setScaleConfig } from './GanttLogic';
import tasks from './GanttTaskData';
import GanttControls from './GanttControls';

const GanttChart = () => {
  const [activeScale, setActiveScale] = useState('day');

  useEffect(() => {
    initGantt(tasks);

    // Set the height to 100% of the screen height minus 180 pixels
    const ganttContainer = document.getElementById('gantt_here');
    if (ganttContainer) {
      ganttContainer.style.height = `calc(100vh - 280px)`;
    }

    return () => {
      clearGantt();
    };
  }, []);

  const changeScale = (scale) => {
    setScaleConfig(scale);
    setActiveScale(scale);
  };

  return (
    <div className="gt-gantt-chart">
      <GanttControls activeScale={activeScale} changeScale={changeScale} />
      <div id="gantt_here" className="gt-gantt-container" style={{ width: '100%' }}></div>
    </div>
  );
};

export default GanttChart;
