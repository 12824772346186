import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import ProjectManagementTable from './ProjectManagementTable';
import ProjectOverview from './ProjectOverview'; // Import ProjectOverview
import BreadcrumbComponent from '../../Components/breadcrumb'; // Import the new Breadcrumb component
import '../../custom.less'; // Ensure custom.less is imported to apply styles
import './WorkPages.less';
import { AppStateContext } from '../../Context/AppStateContext';
import Kanban from './Kanban/Kanban'; // Import the Kanban component
import Gantt from './Gantt/Gantt'; // Correct import for Gantt component
import Calendar from './Calendar/Calendar'; // Import the new Calendar component

const { Header, Content } = Layout;

const IndividualProject = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [view, setView] = useState('overview'); // Default view is set to 'overview'

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const handleMenuClick = (e) => {
    setView(e.key);
  };

  const breadcrumbItems = [
    { title: '', link: '/', icon: 'fi-rr-home' },
    { title: 'Work', link: '/work' },
    { title: 'Projects', link: '/projects' },
    { title: 'Glo Tanning' },
  ];

  return (
    <Layout style={{ minHeight: '100vh' }} className="">

      <p className="ct-page-title">
        <span className="ct-page-title-icon">
          <i className="fi fi-rr-briefcase"></i>
        </span>
        Glo Tanning
      </p>

      <Content style={{ padding: '0px' }}>

        <Menu className="main-page-menu" onClick={handleMenuClick} selectedKeys={[view]} mode="horizontal">
          <Menu.Item key="overview" className="menu-item-with-icon">
            <i className="fi fi-rr-memo-pad"></i>
            Overview
          </Menu.Item>
          <Menu.Item key="Table" className="menu-item-with-icon">
            <i className="fi fi-rr-table-list"></i>
            Table
          </Menu.Item>
          <Menu.Item key="kanban" className="menu-item-with-icon">
            <i className="fi fi-rr-rectangles-mixed"></i>
            Kanban
          </Menu.Item>
          <Menu.Item key="gantt" className="menu-item-with-icon">
            <i className="fi fi-rr-chart-gantt"></i>
            Gantt
          </Menu.Item>
          <Menu.Item key="calendar" className="menu-item-with-icon">
            <i className="fi fi-rr-calendar"></i>
            Calendar
          </Menu.Item>
        </Menu>
        <div className="pmt-main-layout">
          {view === 'Table' && <ProjectManagementTable />}
          {view === 'overview' && <ProjectOverview />}
          {view === 'kanban' && <Kanban />}
          {view === 'gantt' && <Gantt />} {/* Added Gantt component rendering */}
          {view === 'calendar' && <Calendar />} {/* Add Calendar component rendering */}
        </div>

      </Content>

    </Layout>
  );
};

export default IndividualProject;
