// Buttons.js

import React from 'react';

const Buttons = () => {
  return (
    <div className="dg-buttons-container">
    <h3> Buttons </h3>
    <div className="button-row" style={{ display: 'flex', gap: '10px' }}>
      <button className="button-1">Button 1</button>
      <button className="button-2">Button 2</button>
      <button className="button-3">Button 3</button>
      <button className="button-4">Button 4</button>
      <button className="button-5">Button 5</button>
      <button className="button-6">Button 6</button>
      <button className="button-7">Button 7</button>
    </div>
    </div>
  );
}

export default Buttons;
