// HoursTrackerTable.js

import React from 'react';

const HoursTrackerTable = ({ rows }) => {
  const columns = [
    { Header: 'Date', accessor: 'd' },
    { Header: 'Task', accessor: 't' },
    { Header: 'Assignee', accessor: 'a' },
    { Header: 'Time Spent', accessor: 'tS' },
    { Header: 'Time Left', accessor: 'tL' },
  ];

  return (
    <div className="main-table-container">
      <table className="main-table">
        <thead className="main-table-head">
          <tr>
            {columns.map((column) => (
              <th key={column.accessor} className="main-header-cell">
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index} className="main-table-row">
              {columns.map((column) => (
                <td key={column.accessor} className="ht-cell">
                  {row[column.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HoursTrackerTable;
