// DailyOverview.js

import React from 'react';


const DailyOverview = () => {
  const data = [
    { icon: 'fi fi-rr-folder', color: 'db-green', value: 21, label: 'Active Projects:' },
    { icon: 'fi fi-rr-minus-circle', color: 'db-green', value: 3, label: 'Not Started Projects:' },
    { icon: 'fi fi-rr-exclamation', color: 'db-purple', value: 12, label: 'Overdue Projects:' },
    { icon: 'fi fi-rr-check-circle', color: 'db-gray', value: 14, label: 'Completed Projects:' },
    { icon: 'fi fi-rr-users', color: 'db-green', value: 22, label: 'Team Members:' },
    { icon: 'fi fi-rr-building', color: 'db-green', value: 47, label: 'Active Clients:' },
  ];

  return (
    <div className="db-overview-container">
      <h2 className="db-overview-title">Overview</h2>
      <div className="db-overview-list">
        {data.map((item, index) => (
          <div className="db-overview-item" key={index}>
            <div className={`db-icon-container ${item.color}`}>
              <i className={item.icon}></i>
            </div>
            <div className="db-overview-details">
            <p className="db-overview-label">{item.label}</p>
              <h3 className="db-overview-value">{item.value}</h3>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyOverview;
