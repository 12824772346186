// TeamOverviewSort.js

import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import AddTeamMemberModal from '../../Modals/AddTeamMemberModal'; // Import the AddTeamMemberModal component

const TeamOverviewSort = ({ rowsPerPage, setRowsPerPage }) => {
  const [visibleAssigned, setVisibleAssigned] = useState(false);
  const [visibleInProgress, setVisibleInProgress] = useState(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility

  const handleMenuClick = (e) => {
    console.log('Sorting option selected:', e.key);
  };

  const sortingMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="asc">Ascending</Menu.Item>
      <Menu.Item key="desc">Descending</Menu.Item>
    </Menu>
  );

  const rowsMenu = (
    <Menu onClick={(e) => setRowsPerPage(Number(e.key) * 4)}> {/* Multiply by 4 to get the correct number of team members */}
      <Menu.Item key="3">3</Menu.Item>
      <Menu.Item key="5">5</Menu.Item>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="50">50</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="ct-sort-container">
      <div className="ct-sort-options-row">
        <div className="ct-sort-buttons">
          <span className="ct-sort-text">Sort: </span>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleAssigned}
            onVisibleChange={setVisibleAssigned}
          >
            <Button>
              Tasks Assigned <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleInProgress}
            onVisibleChange={setVisibleInProgress}
          >
            <Button>
              In Progress <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <Dropdown
            overlay={sortingMenu}
            visible={visibleCompleted}
            onVisibleChange={setVisibleCompleted}
          >
            <Button>
              Completed <i className="fi fi-rr-angle-small-down"></i>
            </Button>
          </Dropdown>
          <div className="ct-add-client-row">
            <div className="ct-rows-text">Rows: </div>
            <Dropdown overlay={rowsMenu} trigger={['click']}>
              <Button className="ct-rows-button">
                {rowsPerPage / 4} <i className="fi fi-rr-angle-small-down"></i>
              </Button>
            </Dropdown>
          </div>
        </div>
        <Button className="nf-add-team-member" onClick={showModal}>
          <i className="nf-add-team-member-icon fi fi-rr-user-add"></i>
          Add Team Member
        </Button>
      </div>
      <AddTeamMemberModal isVisible={isModalVisible} toggleModal={handleCancel} /> {/* Use the AddTeamMemberModal component */}
    </div>
  );
};

export default TeamOverviewSort;
