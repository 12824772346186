import React from 'react';

const DesignFeedbackOptions = ({
  isCollapsed,
  toggleOptionsBar,
  isCommentsVisible,
  toggleCommentsVisibility,
  comments = [],
  updateCommentText,
  deleteComment,
  addResponse,
  highlightedCommentId,
}) => {
  return (
    <div className={isCollapsed ? 'fp-options-bar-collapsed' : 'fp-options-bar-open'}>

      <div className="options-content">
        <div className="options-list">
          {/* Comment Button */}

          <div className="collapse-button-container" onClick={toggleOptionsBar}>
            <div className="collapse-button">
              {isCollapsed ? <i className="fi fi-br-angle-right"></i> : <i className="fi fi-br-angle-left"></i>}
            </div>
          </div>
          <div className="option-item" onClick={toggleCommentsVisibility}>
            <i className="fi fi-rr-comment-alt"></i>
            {!isCollapsed && <span className="option-text">Comments</span>}
          </div>
          {isCommentsVisible && (
            <div className="comments-section">
              {comments.map((comment, index) => (
                <div key={comment.id} className={`comment-item ${highlightedCommentId === comment.id ? 'highlighted' : ''}`}>
                  <textarea
                    value={comment.text}
                    onChange={(e) => updateCommentText(index, e.target.value)}
                  />
                  <button onClick={() => deleteComment(index)}>Delete</button>
                  {comment.responses.map((response, responseIndex) => (
                    <p key={responseIndex}>{response}</p>
                  ))}
                  <input
                    type="text"
                    onChange={(e) => addResponse(index, e.target.value)}
                    placeholder="Add a response..."
                  />
                </div>
              ))}
            </div>
          )}
          {/* Other Buttons */}
          <div className="option-item">
            <i className="fi fi-rr-paint-brush"></i>
            {!isCollapsed && <span className="option-text">Draw</span>}
          </div>
          <div className="option-item">
            <i className="fi fi-rr-hand-pointer"></i>
            {!isCollapsed && <span className="option-text">Pointer</span>}
          </div>
          <div className="option-item">
            <i className="fi fi-rr-expand"></i>
            {!isCollapsed && <span className="option-text">Expand</span>}
          </div>
          <div className="option-item">
            <i className="fi fi-rr-link"></i>
            {!isCollapsed && <span className="option-text">Link</span>}
          </div>
          <div className="option-item">
            <i className="fi fi-rr-download"></i>
            {!isCollapsed && <span className="option-text">Download</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignFeedbackOptions;
