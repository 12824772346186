import React, { useState } from 'react';
import SingleAvatar2 from '../../Components/SingleAvatar2'; // Ensure the correct path to your avatar component
import teamMembers from '../../Data/TeamSampleData'; // Ensure the correct path to your data file
import './TeamOverview.less'; // Ensure to create and import the CSS file
import { EllipsisOutlined } from '@ant-design/icons'; // Import the Ellipsis icon from Ant Design
import TeamOverviewTable from './TeamOverviewTable'; // Import the TeamOverviewTable component
import { Pagination } from 'antd'; // Import Pagination from Ant Design
import TeamOverviewSort from './TeamOverviewSort'; // Import the TeamOverviewSort component

const TeamOverview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12); // Default is 3 rows, 12 members
  const pageSize = rowsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentMembers = teamMembers.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <div className="to-main-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-users-alt"></i>
        </span>
        Team Overview
      </p>

      <TeamOverviewSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> {/* Add the sort component */}

      <div className="team-overview-container">
        {currentMembers.map((member) => (
          <div key={member.id} className="team-card">
            <div className="team-card-header">
              <div className="team-card-name-cell">
                <SingleAvatar2 name={member.name} size={64} fontSize={24} />
                <div className="team-card-info">
                  <span className="to-name">{member.name}</span>
                  <span className="to-job-title">{member.jobTitle}</span>
                </div>
              </div>
              <div className="team-card-options-cell">
                <EllipsisOutlined className="team-card-settings" />
              </div>
            </div>
            <div className="team-card-data">
              <TeamOverviewTable member={member} /> {/* Render the table for each member */}
            </div>
          </div>
        ))}
      </div>

      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={teamMembers.length}
        onChange={handlePageChange}
        className="team-pagination"
      />
    </div>
  );
};

export default TeamOverview;
