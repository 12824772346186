import React, { useState } from 'react';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import EditCompanyDetailsModal from '../../Modals/EditCompanyDetailsModal'; // Ensure the correct path
import EditPersonalDetailsModal from '../../Modals/EditPersonalDetailsModal'; // Ensure the correct path
import CompanyDetails from './CompanyDetails'; // Import the new CompanyDetails component
import PersonalDetails from './PersonalDetails'; // Import the new PersonalDetails component
import './AccountPages.less';

const AccountProfile = () => {
  const [companyLogo, setCompanyLogo] = useState('https://via.placeholder.com/150');
  const [personalPicture, setPersonalPicture] = useState(null);
  const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
  const [isPersonalModalVisible, setIsPersonalModalVisible] = useState(false);

  const handleCompanyLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCompanyLogo(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePersonalPictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPersonalPicture(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleCompanyModal = () => {
    setIsCompanyModalVisible(!isCompanyModalVisible);
  };

  const togglePersonalModal = () => {
    setIsPersonalModalVisible(!isPersonalModalVisible);
  };

  return (
    <div className="ap-profile-container">
      <div className="ap-profile-banner">
        <button className="ap-update-banner-button"> <i class="fi fi-rr-pencil"></i> Edit Banner</button>
      </div>

      <div className="ap-combined-details-container">
        <div className="ap-combined-details-title-row">
          <div className="ap-combined-details-title">Account Info</div>
          <div className="ap-combined-details-button"><i class="fi fi-rr-pencil"></i> Edit Profile</div>
        </div>

        <CompanyDetails
          companyLogo={companyLogo}
          handleCompanyLogoChange={handleCompanyLogoChange}
          toggleCompanyModal={toggleCompanyModal}
        />
        <PersonalDetails
          personalPicture={personalPicture}
          handlePersonalPictureChange={handlePersonalPictureChange}
          togglePersonalModal={togglePersonalModal}
        />
      </div>

      <EditCompanyDetailsModal isVisible={isCompanyModalVisible} toggleModal={toggleCompanyModal} />
      <EditPersonalDetailsModal isVisible={isPersonalModalVisible} toggleModal={togglePersonalModal} />
    </div>
  );
};

export default AccountProfile;
