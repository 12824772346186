import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import './AuthenticationPages.less';

const imagesAndQuotes = [
  {
    imgSrc: 'https://i.ibb.co/7tpzdnm/image-10.png',
    quote: "The average task should be 3-5 hours and the average sub-task 30 mins to 2 hours.",
  },
  {
    imgSrc: 'https://i.ibb.co/BTbYRTx/image-11.png',

    quote: "Doing the important things first? Or the easy things first? Anything is better than doing nothing!",
  },
  {
    imgSrc: 'https://i.ibb.co/YWJ1677/image-12.png',
    quote: "Delegate effectively. Remember, it's not just about offloading work, it's about empowering your team.",
  },
  {
    imgSrc: 'https://i.ibb.co/q1qpPd7/image-13.png',
    quote: "A well-defined project scope saves time and reduces stress.",
  },


];

const AuthenticationSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * imagesAndQuotes.length);
    setCurrentSlide(randomIndex);
  }, []);

  return (
    <Col className="authentication-image-column" xs={0} md={9}>
    <div className="authentication-image-background">
      <div className="authentication-image-container">
        <img
          src={imagesAndQuotes[currentSlide].imgSrc}
          alt="Authentication Illustration"
          className="authentication-image"
        />
      </div>
      <div className="authentication-register-right-text">
        {imagesAndQuotes[currentSlide].quote}
      </div>
      </div>
    </Col>
  );
};

export default AuthenticationSlider;
