import React from 'react';
import { useNavigate } from 'react-router-dom';

const ClientInfoComponent = ({ record }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/clients/clients-main-page');
  };

  return (
    <div className="ct-client-info-cell">
      <div className="ct-company-name" onClick={handleNavigate} style={{ cursor: 'pointer' }}>
        <i className="fi fi-rr-city"></i>{record.companyName}
      </div>
      <div className="ct-client-name">
        <i className="fi fi-rr-user"></i>{record.clientName}
      </div>
    </div>
  );
};

const ClientInfoColumn = {
  title: 'Client',
  key: 'clientInfo',
  responsive: ['lg'],
  className: 'ct-client-info',
  render: (text, record) => <ClientInfoComponent record={record} />,
};

export default ClientInfoColumn;
