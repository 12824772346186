import React from 'react';
import { Breadcrumb } from 'antd';
import '../FilesPages.less';

const FilesBreadcrumb = ({ path, onNavigate }) => {
  return (
    <div className="fp-breadcrumb-parent-container">
      <div className="fp-page-title-icon-container">
        <i className="fi fi-sr-folder"></i>
      </div>
      <Breadcrumb
        className="files-breadcrumb"
        separator={<i className="fi fi-rr-angle-small-right"></i>} // Custom separator
      >
        <Breadcrumb.Item onClick={() => onNavigate(0)} className="files-breadcrumb-item">
          <span className="files-breadcrumb-link">My Files</span>
        </Breadcrumb.Item>
        {path.slice(1).map((folder, index) => (
          <Breadcrumb.Item
            key={index}
            onClick={() => onNavigate(index + 1)}
            className="files-breadcrumb-item"
          >
            {index === path.length - 2 ? (
              <span className="files-breadcrumb-current">{folder}</span>
            ) : (
              <span className="files-breadcrumb-link">{folder}</span>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default FilesBreadcrumb;
