import React, { useState } from 'react';
import { Table, Checkbox, Menu, Dropdown, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import teamMembersData from '../../Data/TeamSampleData';
import './AccountPages.less';
import AddTeamMemberModal from '../../Modals/AddTeamMemberModal';

const AccountTeam = () => {
  const [teamMembers, setTeamMembers] = useState(
    teamMembersData.map((member, index) => ({
      ...member,
      key: index,
      isGeneral: true,
      isAdmin: false,
    }))
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  const handleCheckboxChange = (key, type) => {
    const updatedMembers = teamMembers.map((member) => {
      if (member.key === key) {
        return { ...member, [type]: !member[type] };
      }
      return member;
    });
    setTeamMembers(updatedMembers);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setRowsPerPage(pageSize);
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <div className="ap-team-name">
          <SingleAvatar2 name={record.name} />
          <span>{record.name}</span>
        </div>
      ),
    },
    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Permissions',
      key: 'permissions',
      render: (record) => (
        <div className="ap-team-permissions">
          <Checkbox
            checked={record.isGeneral}
            onChange={() => handleCheckboxChange(record.key, 'isGeneral')}
          >
            Team Member
          </Checkbox>
          <Checkbox
            checked={record.isAdmin}
            onChange={() => handleCheckboxChange(record.key, 'isAdmin')}
          >
            Admin
          </Checkbox>
        </div>
      ),
    },
    {
      title: '',
      key: 'options',
      render: () => (
        <div className="row-options-container">
          <Dropdown
            overlay={(
              <Menu>
                <Link to='/team/member-details'>
                  <Menu.Item key="1">Edit Team Member</Menu.Item>
                </Link>
                <Menu.Item key="2">Delete Permanently</Menu.Item>
              </Menu>
            )}
            trigger={['click']}
          >
            <div className="row-options">
              <i className="fi fi-br-menu-dots-vertical"></i>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const paginatedMembers = teamMembers.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div className="ap-team-container">
      <div className="ap-team-title-row">
        <h2>Team Members</h2>
        <button className="ap-team-add-member-button" onClick={() => setIsAddModalVisible(true)}> Add Team Member </button>
      </div>
      <Table columns={columns} dataSource={paginatedMembers} pagination={false} />
      <div className="ap-team-pagination-container">
        <Pagination
          current={currentPage}
          pageSize={rowsPerPage}
          total={teamMembers.length}
          onChange={handlePageChange}
        />
      </div>
      <AddTeamMemberModal
        isVisible={isAddModalVisible}
        toggleModal={() => setIsAddModalVisible(false)}
      />
    </div>
  );
};

export default AccountTeam;
