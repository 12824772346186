// Whitebox.js

import React from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './InternalPages.less'; 

const Whitebox = () => {
  // Code for the first whitebox
  const codeToCopy1 = `
<div className="whitebox">
  <h3>Whitebox</h3>
</div>
  `;

  // Code for the second whitebox
  const codeToCopy2 = `
<div className="whitebox">
  <div className="whitebox-title">
    <h3>Whitebox</h3>
    <button className="button-2">Settings</button>
  </div>
</div>
  `;

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code.trim()).then(
      () => message.success('Code copied to clipboard!'),
      () => message.error('Failed to copy code.')
    );
  };

  return (
    <div className="section-container">
      <h3>Whitebox</h3>

      {/* First Whitebox */}
      <div className="section-and-code">
        {/* Whitebox Display */}
        <div className="whitebox-container">
          <div className="whitebox">
            <h3>Whitebox</h3>
          </div>
        </div>

        {/* Code Display with Copy Button */}
        <div className="code-container">
          <Button
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(codeToCopy1)}
            className="copy-code-button"
          >
            Copy Code
          </Button>
          <pre className="code-snippet">
{codeToCopy1}
          </pre>
        </div>
      </div>

      {/* Second Whitebox */}
      <div className="section-and-code">
        {/* Whitebox Display */}
        <div className="whitebox-container">
          <div className="whitebox">
            <div className="whitebox-title">
              <h3>Whitebox</h3>
              <Button className="button-2">Settings</Button>
            </div>
          </div>
        </div>

        {/* Code Display with Copy Button */}
        <div className="code-container">
          <Button
            icon={<CopyOutlined />}
            onClick={() => copyToClipboard(codeToCopy2)}
            className="copy-code-button"
          >
            Copy Code
          </Button>
          <pre className="code-snippet">
{codeToCopy2}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default Whitebox;
