import React, { useState } from 'react';
import { List, Tooltip, Dropdown, Menu, Button } from 'antd';
import { UserAvatar } from '../../../Components/UserAvatars2';
import ClientChatTextBox from './ClientChatTextBox';
import ClientChatReactions from './ClientChatReactions';
import { SmileOutlined } from '@ant-design/icons'; // Import an icon for the add reaction button

const ClientChatMessages = ({ initialMessages, expanded, settings }) => {
  const [messages, setMessages] = useState(
    initialMessages.map((msg) => ({ ...msg, reactions: {} }))
  );

  // Add this line to define hoveredMessageId state
  const [hoveredMessageId, setHoveredMessageId] = useState(null);

  // Function to handle message sending
  const handleSendMessage = (newMessage) => {
    const now = new Date();
    const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const date = now.toLocaleDateString('en-GB'); // 'dd/mm/yyyy'
    setMessages([
      ...messages,
      {
        id: messages.length + 1,
        user: 'Noman Hossain (Me)',
        content: newMessage,
        time,
        date,
        reactions: {}
      }
    ]);
  };

  // Function to handle adding a reaction
  const handleAddReaction = (messageId, reactionKey) => {
    const userName = 'Noman Hossain'; // Replace with the current user's name
    setMessages((prevMessages) =>
      prevMessages.map((msg) => {
        if (msg.id === messageId) {
          const reactions = { ...msg.reactions };
          if (!reactions[reactionKey]) {
            reactions[reactionKey] = [userName];
          } else if (!reactions[reactionKey].includes(userName)) {
            reactions[reactionKey] = [...reactions[reactionKey], userName];
          } else {
            // If user already reacted, remove their reaction
            reactions[reactionKey] = reactions[reactionKey].filter((name) => name !== userName);
            if (reactions[reactionKey].length === 0) {
              delete reactions[reactionKey];
            }
          }
          return { ...msg, reactions };
        }
        return msg;
      })
    );
  };

  // Function to format date as 'Monday, Aug 20th'
  const formatDateHeader = (dateStr) => {
    const dateParts = dateStr.split('/');
    const dateObj = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
    const options = { weekday: 'long', month: 'short', day: 'numeric' };
    return dateObj.toLocaleDateString(undefined, options);
  };

  // Process messages to include date headers
  const processedMessages = [];
  let lastDate = null;

  messages.forEach((msg) => {
    if (msg.date !== lastDate) {
      lastDate = msg.date;
      processedMessages.push({
        type: 'dateHeader',
        date: msg.date,
        formattedDate: formatDateHeader(msg.date),
        id: `date-${msg.date}`
      });
    }
    processedMessages.push(msg);
  });

  // Reaction menu
  const reactionEmojis = {
    check: '✅',
    thumbsUp: '👍',
    eyes: '👀',
    celebrate: '🎉',
    laugh: '😂',
  };

  const reactionMenu = (messageId) => (
    <Menu
      onClick={(e) => handleAddReaction(messageId, e.key)}
      className="ct-chat-reaction-menu"
    >
      {Object.entries(reactionEmojis).map(([key, emoji]) => (
        <Menu.Item key={key} className="ct-chat-reaction-menu-item">
          {emoji}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="ct-chat-messages-container">
      {/* Message List */}
      <List
        className="ct-chat-message-list"
        itemLayout="horizontal"
        dataSource={processedMessages}
        renderItem={(item) => {
          if (item.type === 'dateHeader') {
            return (
              <List.Item className="ct-chat-date-header">
                <div className="ct-chat-date-header-content">{item.formattedDate}</div>
              </List.Item>
            );
          }
          return (
            <List.Item
              className="ct-chat-message-item"
              onMouseEnter={() => setHoveredMessageId(item.id)}
              onMouseLeave={() => setHoveredMessageId(null)}
            >
              <List.Item.Meta
                className="ct-chat-message-meta"
                avatar={
                  <UserAvatar
                    name={item.user}
                    size="34"
                    fontSize="14"
                    className="ct-chat-avatar"
                  />
                }
                title={
                  <div className="ct-chat-message-header">
                    <span className="ct-chat-username">{item.user}</span>
                    <span className="ct-chat-time">{item.time}</span>
                  </div>
                }
                description={
                  <div className="ct-chat-message-content">
                    {expanded
                      ? item.content
                      : item.content.length > 160
                      ? `${item.content.substring(0, 160)}...`
                      : item.content}
                  </div>
                }
              />
              {/* Reactions are displayed beneath the message */}
              <div>
                {hoveredMessageId === item.id && (
                  <div className="ct-chat-message-actions">
                    <Dropdown
                      overlay={reactionMenu(item.id)}
                      trigger={['click']}
                      placement="bottomCenter"
                    >
                      <Tooltip title="Add Reaction">
                        <Button
                          shape="circle"
                          icon={<SmileOutlined />}
                          className="ct-chat-add-reaction-button"
                        />
                      </Tooltip>
                    </Dropdown>
                  </div>
                )}
                {/* Reactions */}
                {item.reactions && Object.keys(item.reactions).length > 0 && (
                  <ClientChatReactions
                    reactions={item.reactions}
                    onAddReaction={(reactionKey) => handleAddReaction(item.id, reactionKey)}
                  />
                )}
              </div>
            </List.Item>
          );
        }}
      />

      {/* ClientChatTextBox for sending messages */}
      <ClientChatTextBox onSendMessage={handleSendMessage} />
    </div>
  );
};

export default ClientChatMessages;
