// HoursTrackerStats.js

import React, { useEffect, useState } from 'react';
import { fillAndTransformData, today, startDate90DaysAgo, startDate60DaysAgo, startDate30DaysAgo, startDate7DaysAgo, startDate180DaysAgo, startDate365DaysAgo } from '../../Utils/HoursTrackerLogic';
import hoursData from '../../Data/SampleHoursData';
import './HoursTracker.less';

const HoursTrackerStats = ({ selectedRange }) => {
  const [totalTimeSpent, setTotalTimeSpent] = useState(0);
  const [averageTimeSpent, setAverageTimeSpent] = useState(0);

  useEffect(() => {
    const updateStats = () => {
      let newData;
      switch (selectedRange) {
        case '7':
          newData = fillAndTransformData(hoursData, startDate7DaysAgo, today);
          break;
        case '30':
          newData = fillAndTransformData(hoursData, startDate30DaysAgo, today);
          break;
        case '60':
          newData = fillAndTransformData(hoursData, startDate60DaysAgo, today);
          break;
        case '90':
          newData = fillAndTransformData(hoursData, startDate90DaysAgo, today);
          break;
        case '180':
          newData = fillAndTransformData(hoursData, startDate180DaysAgo, today);
          break;
        case '365':
          newData = fillAndTransformData(hoursData, startDate365DaysAgo, today);
          break;
        default:
          newData = fillAndTransformData(hoursData, startDate30DaysAgo, today);
      }

      const totalHours = newData.reduce((acc, item) => acc + item.tS, 0);
      const averageHours = totalHours / 22; // Assuming 22 workdays in a month

      setTotalTimeSpent(totalHours);
      setAverageTimeSpent(averageHours);
    };

    updateStats();
  }, [selectedRange]);

  return (
    <div className="ht-stats-container">


      <div className="ht-stats-column">
        <div className="ht-stats-title">Hrs in last <span className="ht-stats-days-value">{selectedRange}</span> Days:</div>
        <div className="ht-stats-value">{totalTimeSpent.toFixed(2)}</div>
      </div>
      <div className="ht-stats-column">
        <div className="ht-stats-title">Avg hrs/Workday:</div>
        <div className="ht-stats-value">{averageTimeSpent.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default HoursTrackerStats;
