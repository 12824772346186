import React from 'react';
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import AuthenticationSlider from './AuthenticationSlider';
import './AuthenticationPages.less';

const { Title, Text } = Typography;

const ForgotPasswordPage = () => {
  const handleSubmit = (values) => {
    console.log('Form Values:', values);
  };

  return (
    <div className="authentication-register-container">
      <Row className="authentication-register-row">
        <Col className="authentication-form-column" xs={24} md={15}>
          <div className="authentication-logo-container">
            <img src="https://i.ibb.co/1LvD024/aio-logo-new.png" alt="AIO Solutions" className="authentication-logo" />
          </div>

          <div className="authentication-form-form">
            <div className="authentication-title">Reset Your Password</div>
            <Form layout="vertical" onFinish={handleSubmit} className="authentication-form">
              <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter your email' }]}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="authentication-submit-button">Reset Password</Button>
              </Form.Item>
              <Text className="authentication-login-link">
                Remembered your password? <Link to="/login">Sign In</Link>
              </Text>
            </Form>
          </div>
        </Col>
        <AuthenticationSlider />
      </Row>
    </div>
  );
};

export default ForgotPasswordPage;
