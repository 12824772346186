import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import getStatusTag from '../../../Components/StatusTags2';

const { Option } = Select;

const EditableStatusCell = ({ value: initialValue, row: { index }, column: { id }, updateData }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (value) => {
    setValue(value);
    updateData(index, id, value);
  };

  const onBlur = () => {
    updateData(index, id, value);
  };

  return (
    <Select
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{ width: '100%' }}
      dropdownClassName="status-dropdown"
    >
      <Option value="Not Started">{getStatusTag("Not Started")}</Option>
      <Option value="On Hold">{getStatusTag("On Hold")}</Option>
      <Option value="In Progress">{getStatusTag("In Progress")}</Option>
      <Option value="In Review">{getStatusTag("In Review")}</Option>
      <Option value="Completed">{getStatusTag("Completed")}</Option>

    </Select>
  );
};

const StatusColumn = {
  Header: 'Status',
  accessor: 'status',
  width: 100,
  Cell: EditableStatusCell,
  className: 'pmt-status-column',
};

export default StatusColumn;
