import React from 'react';
import { Table, Avatar } from 'antd';
import { EllipsisOutlined, FilterOutlined, SortAscendingOutlined, GroupOutlined, CheckCircleOutlined } from '@ant-design/icons';
import clientData from './SimpleClientdata';
import './SimpleClientPage.css';  // Import the updated CSS

const SimpleClientPage = () => {



  

  // Define table columns
  const columns = [
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="smp-client-name-container">
          <Avatar style={{ backgroundColor: '#1890ff' }}>{record.name.charAt(0)}</Avatar>
          <div className="smp-client-name-info">
            <strong>{text}</strong>
            <br />
            <span className="smp-company-name">{record.company}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Contact Info',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div className="smp-contact-info">
          <p>{record.email}</p>
          <p>{record.phone}</p>
        </div>
      ),
    },
    {
      title: 'Hours & Tasks',
      key: 'hoursAndTasks',
      render: (record) => (
        <div className="smp-hours-tasks-container">
          {/* First Progress Bar */}
          <div className="smp-overall-progress-bar">
            <div className="smp-progress-completed" style={{ width: `${(record.tasksCompleted / record.totalTasks) * 100}%` }} />
            <div className="smp-progress-remaining" style={{ width: `${((record.totalTasks - record.tasksCompleted) / record.totalTasks) * 100}%` }} />
          </div>
          <div className="smp-hours-info">{record.hours}hrs</div>

          {/* Second Progress Bar */}
          <div className="smp-task-status">
            <span>Not Started</span>
            <span>In Progress</span>
            <span>Completed</span>
          </div>
          <div className="smp-split-progress-bar">
            <div className="smp-progress-not-started" style={{ width: '40%' }} />
            <div className="smp-progress-in-progress" style={{ width: '20%' }} />
            <div className="smp-progress-completed" style={{ width: '40%' }} />
          </div>
        </div>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (currency) => (
        <div className="smp-currency-container">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="14" fill="#EFFCF2"/>
            <path d="M11.2266 15.9412C11.2266 17.0162 12.0516 17.8829 13.0766 17.8829H15.1682C16.0599 17.8829 16.7849 17.1245 16.7849 16.1912C16.7849 15.1745 16.3432 14.8162 15.6849 14.5829L12.3266 13.4162C11.6682 13.1829 11.2266 12.8245 11.2266 11.8079C11.2266 10.8745 11.9516 10.1162 12.8432 10.1162H14.9349C15.9599 10.1162 16.7849 10.9829 16.7849 12.0579" stroke="#A9A9A9" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 9V19" stroke="#A9A9A9" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.9998 22.3337C18.6022 22.3337 22.3332 18.6027 22.3332 14.0003C22.3332 9.39795 18.6022 5.66699 13.9998 5.66699C9.39746 5.66699 5.6665 9.39795 5.6665 14.0003C5.6665 18.6027 9.39746 22.3337 13.9998 22.3337Z" stroke="#A9A9A9" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>{currency}</span>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <button className="smp-status-button">
          <CheckCircleOutlined className="status-icon" />
          {status}
        </button>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: () => (
        <button className="smp-action-button">
          <EllipsisOutlined />
        </button>
      ),
    },
  ];

  return (
    <div className="smp-client-main-page-container">

 {/* Page Title and Icon */}
 <div className="tp-page-title">
        <div className="tp-page-title-icon-container">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.10671 7.24634C6.04004 7.23967 5.96004 7.23967 5.88671 7.24634C4.30004 7.19301 3.04004 5.89301 3.04004 4.29301C3.04004 2.65967 4.36004 1.33301 6.00004 1.33301C7.63337 1.33301 8.96004 2.65967 8.96004 4.29301C8.95337 5.89301 7.69337 7.19301 6.10671 7.24634Z" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.9402 2.66699C12.2335 2.66699 13.2735 3.71366 13.2735 5.00033C13.2735 6.26033 12.2735 7.28699 11.0268 7.33366C10.9735 7.32699 10.9135 7.32699 10.8535 7.33366" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.77348 9.70699C1.16014 10.787 1.16014 12.547 2.77348 13.6203C4.60681 14.847 7.61348 14.847 9.44681 13.6203C11.0601 12.5403 11.0601 10.7803 9.44681 9.70699C7.62014 8.48699 4.61348 8.48699 2.77348 9.70699Z" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2266 13.333C12.7066 13.233 13.1599 13.0397 13.5332 12.753C14.5732 11.973 14.5732 10.6863 13.5332 9.90634C13.1666 9.62634 12.7199 9.43967 12.2466 9.33301" stroke="#626262" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </div>
        <div className="tp-page-title-text">Client</div>
      </div>

      <div className="smp-client-page-header">
        {/* Action buttons for Filter, Sort, Group */}
        <div className="smp-action-buttons">
          <button className="smp-custom-btn">
            <FilterOutlined className="button-icon" />
            Filter
          </button>
          <button className="smp-custom-btn">
            <SortAscendingOutlined className="button-icon" />
            Sort
          </button>
          <button className="smp-custom-btn">
            <GroupOutlined className="button-icon" />
            Group
          </button>

          {/* Add Client button aligned to the right */}
          <button className="smp-add-client-btn">
            <span className="smp-add-icon">+</span> Add Client
          </button>
        </div>
      </div>
      
      <Table 
        className='simple-client-page'
        columns={columns} 
        dataSource={clientData} 
        rowKey="id" 
        pagination={false}  // Removing pagination for a clean table look
      />
    </div>
  );
};

export default SimpleClientPage;
