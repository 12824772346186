// Card.js

import React from 'react';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const CardComponent = () => {
  const codeToCopy = `
<div className="main-card">
  <!-- Content here -->
</div>
  `;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeToCopy.trim()).then(
      () => message.success('Code copied to clipboard!'),
      () => message.error('Failed to copy code.')
    );
  };

  return (
    <div className="section-container">
      <h3>Card</h3>
      <div className="section-and-code">
        {/* Card Display */}
        <div className="card-display">
          <div className="main-card">
            Card
          </div>
        </div>

        {/* Code Display with Copy Button */}
        <div className="code-container">
          <Button
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            className="copy-code-button"
          >
            Copy Code
          </Button>
          <pre className="code-snippet">
{codeToCopy}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
