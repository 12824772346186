import React from 'react';
import GanttChart from './GanttComponents/GanttChart';
import './Gantt.less';

const Gantt = () => {
  return (
    <div className="gt-gantt-page">
      

      <div className="gt-gantt-content">
        <GanttChart />
      </div>
    </div>
  );
};

export default Gantt;
