import React from 'react';
import { Carousel, Menu, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import projectsData from '../../Data/SampleProjects'; // Ensure this path is correct
import './Dashboard.less'; // Import the CSS file
import getStatusTag from '../../Components/StatusTags2'; // Import the status tag function
import DashboardProjectColors from './DashboardProjectColors'; // Import the DashboardProjectColors component
import { HoursBar, TasksBar } from './DashboardProjectBarChart'; // Import the separate charts

const DashboardProjects = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const chunkedProjects = [];
  for (let i = 0; i < projectsData.length; i += 4) {
    chunkedProjects.push(projectsData.slice(i, i + 4));
  }

  const calculateDaysLeft = (endDate) => {
    const today = new Date();
    const end = new Date(endDate);
    const timeDiff = end - today;
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysLeft >= 0 ? `${daysLeft} Days Left` : 'Time Over';
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">View Project</Menu.Item>
      <Menu.Item key="2">Edit Project</Menu.Item>
      <Menu.Item key="3">Settings</Menu.Item>
    </Menu>
  );

  return (
    <div className="db-project-main-container">
      <Carousel dots arrows>
        {chunkedProjects.map((chunk, index) => (
          <div className="db-project-carousel-container" key={index}>
            <div className="db-projects-title-row">
              <div className="db-carousel-title"> Priority Projects </div>
              <div className="db-view-projects-button" onClick={() => navigate('/projects')}>
                All Projects
                <div className="db-view-projects-button-icon-container">
                  <i className="fi fi-rr-angle-small-right"></i>
                </div>
              </div>
            </div>
            <div className="db-carousel-background">
              {chunk.map((project, idx) => (
                <div key={idx} className="db-project-carousel-item">
                  <div className="db-project-carousel-topbar">
                    {getStatusTag(project.projectStatus)}
                    <p className="db-days-left">{calculateDaysLeft(project.endDate)}</p>
                  </div>
                  <div
                    className="db-project-name-container"
                    style={{
                      backgroundColor: DashboardProjectColors({ projectName: project.name }).props.style.backgroundColor,
                    }}
                  >
                    <div className="db-project-name">
                      {project.name}
                      <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                        <div className="db-project-menu-icon">
                          <i className="fi fi-br-menu-dots"></i>
                        </div>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="db-project-barchart-container">
                    <HoursBar project={project} />
                  </div>

                  <div className="db-project-barchart-container">
                    <TasksBar project={project} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default DashboardProjects;
