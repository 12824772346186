import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import hoursData from '../../Data/SampleHoursData';
import { fillAndTransformData, today, startDate90DaysAgo, startDate60DaysAgo, startDate30DaysAgo, startDate7DaysAgo, startDate180DaysAgo, startDate365DaysAgo } from '../../Utils/HoursTrackerLogic';
import HoursTrackerMenu from './HoursTrackerMenu';
import HoursTrackerStats from './HoursTrackerStats';
import { formatXAxis, getTickValues } from './HoursTrackerXAxis';
import './HoursTracker.less';

const HoursTrackerChart = () => {
  const [selectedRange, setSelectedRange] = useState('30');
  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(['timeSpent', 'timeLeft']);
  const [ticks, setTicks] = useState([]);

  useEffect(() => {
    updateData(selectedRange);
  }, [selectedRange]);

  const updateData = (range) => {
    let newData;
    switch (range) {
      case '7':
        newData = fillAndTransformData(hoursData, startDate7DaysAgo, today);
        break;
      case '30':
        newData = fillAndTransformData(hoursData, startDate30DaysAgo, today);
        break;
      case '60':
        newData = fillAndTransformData(hoursData, startDate60DaysAgo, today);
        break;
      case '90':
        newData = fillAndTransformData(hoursData, startDate90DaysAgo, today);
        break;
      case '180':
        newData = fillAndTransformData(hoursData, startDate180DaysAgo, today);
        break;
      case '365':
        newData = fillAndTransformData(hoursData, startDate365DaysAgo, today);
        break;
      default:
        newData = fillAndTransformData(hoursData, startDate30DaysAgo, today);
    }
    setData(newData);
    setTicks(getTickValues(newData, range)); // Set the ticks for the XAxis
  };

  const handleRangeChange = (range) => {
    setSelectedRange(range);
    updateData(range);
  };

  const handleFilterChange = (value) => {
    if (value === 'all') {
      setSelectedValues(['timeSpent', 'timeLeft']);
    } else {
      const newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((val) => val !== value)
        : [...selectedValues, value];
      setSelectedValues(newSelectedValues);
    }
  };

  // Calculate the maximum and minimum values for Y-axis
  const minY = Math.floor(Math.min(...data.map(d => Math.min(d.tS, d.tL))) / 10) * 10;
  const maxY = Math.ceil(Math.max(...data.map(d => Math.max(d.tS, d.tL))) / 10) * 10;

  // Generate ticks based on minY and maxY
  const yTicks = [];
  for (let i = minY; i <= maxY; i += 10) {
    yTicks.push(i);
  }

  return (
    <div className="ht-chart-container">
      <HoursTrackerMenu
        selectedRange={selectedRange}
        handleRangeChange={handleRangeChange}
        selectedValues={selectedValues}
        handleFilterChange={handleFilterChange}
      />

      <div className="ht-chart">
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="d" tickFormatter={formatXAxis} ticks={ticks}>
              <Label value="Time" offset={-5} position="insideBottom" />
            </XAxis>
            <YAxis domain={[minY, maxY]} ticks={yTicks}>
              <Label value="Hours Usage" angle={-90} position="insideLeft" />
            </YAxis>
            <Tooltip />
            {data.some(d => d.tL < 0) && <ReferenceLine y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />}
            {selectedValues.includes('timeLeft') && (
              <Area
                type="linear"
                dataKey="tL"
                stroke="#82ca9d"
                fill="url(#colorTimeLeft)"
                strokeWidth={2}
                dot={false}
              />
            )}
            {selectedValues.includes('timeSpent') && (
              <Area
                type="linear"
                dataKey="tS"
                stroke="#8884d8"
                fill="url(#colorTimeSpent)"
                strokeWidth={2}
                dot={false}
              />
            )}
            <defs>
              <linearGradient id="colorTimeLeft" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#22C55E" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#22C55E" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorTimeSpent" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0.7} />
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </div>
        <HoursTrackerStats selectedRange={selectedRange} />
    </div>

  );
};

export default HoursTrackerChart;
