import React, { useState } from 'react';
import { Row, Col, Select, Button, Tooltip, message } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;

const MAX_SORTS = 2; // Limit to 2 sorts

const TasksFilter = ({ sorts, setSorts }) => {
  const handleSortChange = (value, key, index) => {
    const newSorts = [...sorts];
    newSorts[index][key] = value;
    setSorts(newSorts);
  };

  const handleAddSort = () => {
    if (sorts.length < MAX_SORTS) {
      setSorts([...sorts, { field: '', order: 'asc' }]);
    } else {
      message.info(`You can only add up to ${MAX_SORTS} sorts.`);
    }
  };

  const handleRemoveSort = (index) => {
    const newSorts = sorts.filter((_, i) => i !== index);
    setSorts(newSorts);
  };

  return (
    <Row gutter={16} style={{ marginBottom: '24px' }}>
      <Col span={24}>
        {sorts.map((sort, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Select
              placeholder="Sort by"
              value={sort.field}
              style={{ width: '40%' }}
              onChange={(value) => handleSortChange(value, 'field', index)}
            >
              <Option value="status">Status</Option>
              <Option value="taskCreated">Date Created</Option>
            </Select>
            {sort.field === 'status' && (
              <Select
                placeholder="Order"
                value={sort.order}
                style={{ width: '30%', marginLeft: '8px' }}
                onChange={(value) => handleSortChange(value, 'order', index)}
              >
                <Option value="Not Started">Not Started</Option>
                <Option value="In Progress">In Progress</Option>
                <Option value="Completed">Completed</Option>
              </Select>
            )}
            {sort.field === 'taskCreated' && (
              <Select
                placeholder="Order"
                value={sort.order}
                style={{ width: '30%', marginLeft: '8px' }}
                onChange={(value) => handleSortChange(value, 'order', index)}
              >
                <Option value="asc">Oldest First</Option>
                <Option value="desc">Newest First</Option>
              </Select>
            )}
            <Tooltip title="Remove Sort">
              <Button
                icon={<CloseOutlined />}
                style={{ marginLeft: '8px' }}
                onClick={() => handleRemoveSort(index)}
              />
            </Tooltip>
          </div>
        ))}
        {sorts.length < MAX_SORTS && (
          <Button type="dashed" icon={<PlusOutlined />} onClick={handleAddSort} style={{ marginTop: '8px' }}>
            Add Sort
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default TasksFilter;
