import React from 'react';

const CompanyDetails = ({ companyLogo, handleCompanyLogoChange, toggleCompanyModal }) => {
  return (
    <div className="ap-company-details-container">
      <div className="ap-profile-title-row">
        <h2>Company Details:</h2>
      </div>
      <div className="ap-company-details">
        <div className="ap-company-content">
        <div className="ap-company-logo-container">
          <div className="ap-company-logo">
            <img src={companyLogo} alt="Company Logo" />
            <input type="file" onChange={handleCompanyLogoChange} style={{ display: 'none' }} id="upload-logo" />
            <label htmlFor="upload-logo" className="ap-upload-logo-button">
              <i className="fi fi-rr-upload"></i> Upload Logo
            </label>
          </div>
          </div>
          <div className="ap-company-info">
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-briefcase"></i>
                </div>
                <span>Company Name: Darlene INC</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-bulb"></i>
                </div>
                <span>Tagline: Innovating the future</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-comment-alt"></i>
                </div>
                <span>Company Overview: Darlene INC is a leader in innovation solution, driving progress & efficiency</span>
              </div>
            </div>
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-phone-call"></i>
                </div>
                <span>Phone Number: (555) 123-4568</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-envelope"></i>
                </div>
                <span>Email: darlene@gmail.com</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-users"></i>
                </div>
                <span>Team Size: 24</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-marker"></i>
                </div>
                <span>Address: 2118 Thornridge Cir. Syracuse, Connecticut 35624</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
