// AutomationstableColumns/StepsRow.js

import React from 'react';
import { Timeline } from 'antd';
import '../AutomationsPages.less'; // Ensure the correct path to your less file

const StepsRow = ({ steps, notes }) => {
  return (
    <div className="at-steps-container">
      <div className="at-steps-column">
      <div className="at-steps-text"> Steps: </div>
        <Timeline className="at-timeline">
          {steps.map((stepObj, index) => (
            <Timeline.Item
              key={index}
              className="at-timeline-item"
              dot={<div className="at-step-circle">{index + 1}</div>}
            >
              <span className="at-step-content">{stepObj.step}</span>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      <div className="at-notes-column">
        <div className="at-notes-text"> Notes: </div>
        <div className="at-notes">
          <textarea className="at-notes-textarea" defaultValue={notes}></textarea>
        </div>
      </div>
    </div>
  );
};

export default StepsRow;
