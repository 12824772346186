// ClientMainFolders.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const ClientMainFolders = () => {
  const navigate = useNavigate();

  return (
    <div className="ct-folders-container">
      {/* First Row */}
      <div className="ct-folders-row">
        {/* Projects Folder */}
        <div className="ct-folder-card ct-projects-box">
          <div className="ct-folder-header">
            <div className="ct-folder-icon">
              <i className="fi fi-rr-folder"></i>
            </div>
            <div className="ct-folder-date">Jun 19, 2024</div>
          </div>
          <h3 className="ct-folder-title">Projects</h3>
          <div className="ct-folder-content">
            <div className="ct-folder-grid">
              <div className="ct-folder-grid-item ct-projects-not-started">
                <div className="ct-folder-stat-title">Not Started</div>
                <div className="ct-folder-stat-value">12</div>
              </div>
              <div className="ct-folder-grid-item ct-projects-in-progress">
                <div className="ct-folder-stat-title">In Progress</div>
                <div className="ct-folder-stat-value">08</div>
              </div>
              <div className="ct-folder-grid-item ct-projects-in-review">
                <div className="ct-folder-stat-title">In Review</div>
                <div className="ct-folder-stat-value">06</div>
              </div>
              <div className="ct-folder-grid-item ct-projects-completed">
                <div className="ct-folder-stat-title">Completed</div>
                <div className="ct-folder-stat-value">04</div>
              </div>
            </div>
          </div>
          <div className="ct-folder-date">Jun 19, 2024</div>
        </div>

        {/* Files Folder */}
        <div className="ct-folder-card ct-files-box">
          <div className="ct-folder-header">
            <div className="ct-folder-icon">
              <i className="fi fi-rr-file"></i>
            </div>
            <div className="ct-folder-date">Jun 19, 2024</div>
          </div>
          <h3 className="ct-folder-title">Files</h3>
          <div className="ct-folder-content">
            <div className="ct-folder-grid">
              <div className="ct-folder-grid-item ct-files-total-files">
                <div className="ct-folder-stat-title">Total Files</div>
                <div className="ct-folder-stat-value">123</div>
              </div>
              <div className="ct-folder-grid-item ct-files-last-updated">
                <div className="ct-folder-stat-title">Last Updated</div>
                <div className="ct-folder-stat-value">Sep 27, 2023</div>
              </div>
            </div>
          </div>
        </div>

        {/* Hours Tracker Folder */}
        <div className="ct-folder-card ct-hours-box">
          <div className="ct-folder-header">
            <div className="ct-folder-icon">
              <i className="fi fi-rr-clock"></i>
            </div>
            <div className="ct-folder-date">Jun 19, 2024</div>
          </div>
          <h3 className="ct-folder-title">Hours Tracker</h3>
          <div className="ct-folder-content">
            <div className="ct-folder-grid">
              <div className="ct-folder-grid-item ct-hours-total-purchased">
                <div className="ct-folder-stat-title">Total Hours Purchased</div>
                <div className="ct-folder-stat-value">480</div>
              </div>
              <div className="ct-folder-grid-item ct-hours-current-package">
                <div className="ct-folder-stat-title">Current Hours Package</div>
                <div className="ct-folder-stat-value">80</div>
              </div>
              <div className="ct-folder-grid-item ct-hours-remaining">
                <div className="ct-folder-stat-title">Remaining Hours</div>
                <div className="ct-folder-stat-value">27h 35m</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Second Row */}
      <div className="ct-folders-row">
        {/* Automations Folder */}
        <div className="ct-folder-card ct-automations-box">
          <div className="ct-folder-header">
            <div className="ct-folder-icon">
              <i className="fi fi-rr-robot"></i>
            </div>
            <div className="ct-folder-date">Jun 19, 2024</div>
          </div>
          <h3 className="ct-folder-title">Automations</h3>
          <div className="ct-folder-content">
            <div className="ct-folder-grid">
              <div className="ct-folder-grid-item ct-automations-active">
                <div className="ct-folder-stat-title">Active Automations</div>
                <div className="ct-folder-stat-value">17</div>
              </div>
              <div className="ct-folder-grid-item ct-automations-inactive">
                <div className="ct-folder-stat-title">Inactive Automations</div>
                <div className="ct-folder-stat-value">3</div>
              </div>
            </div>
          </div>
        </div>

        {/* Chat Folder */}
        <div className="ct-folder-card ct-chat-box">
          <div className="ct-folder-header">
            <div className="ct-folder-icon">
              <i className="fi fi-rr-comment"></i>
            </div>
            <div className="ct-folder-date">Jun 19, 2024</div>
          </div>
          <h3 className="ct-folder-title">Chat</h3>
          <div className="ct-folder-content">
            <div className="ct-folder-grid">
              <div className="ct-folder-grid-item ct-chat-last-message">
                <div className="ct-folder-stat-title">Last Message</div>
                <div className="ct-folder-stat-value">Sep 27, 2023</div>
              </div>
            </div>
          </div>
        </div>
      </div>








{/* Projects Folder */}
<div className="ct-folder-card light-auto-card">
        <div className="ct-folder-header">
          <div className="ct-folder-icon">
          <svg width="48" height="48" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M88 80.28V48.72C88 26.32 70 8 48 8C26 8 8 26.32 8 48.72V80.28C8 85.32 10.9999 86.68 14.6799 83.32L18.6799 79.68C20.1599 78.32 22.56 78.32 24.04 79.68L32.04 87C33.52 88.36 35.9199 88.36 37.3999 87L45.3999 79.68C46.8799 78.32 49.28 78.32 50.76 79.68L58.76 87C60.24 88.36 62.6401 88.36 64.1201 87L72.1201 79.68C73.6001 78.32 76 78.32 77.48 79.68L81.48 83.32C85 86.68 88 85.32 88 80.28Z" fill="#22C55E"/>
<path d="M48.0001 64.2802C41.7201 64.2802 35.44 62.3201 30.2 58.4001C28.88 57.4001 28.6002 55.5201 29.6002 54.2001C30.6002 52.8801 32.4801 52.6001 33.8001 53.6001C42.1601 59.8801 53.84 59.8801 62.2 53.6001C63.52 52.6001 65.4 52.8801 66.4 54.2001C67.4 55.5201 67.1201 57.4001 65.8001 58.4001C60.5601 62.3201 54.2801 64.2802 48.0001 64.2802Z" fill="#22C55E"/>
<path d="M48.0005 45.52C53.2582 45.52 57.5205 41.2578 57.5205 36C57.5205 30.7422 53.2582 26.48 48.0005 26.48C42.7427 26.48 38.4805 30.7422 38.4805 36C38.4805 41.2578 42.7427 45.52 48.0005 45.52Z" fill="#22C55E"/>
</svg>


          </div>
          <div className="ct-folder-date">Jun 19, 2024</div>
        </div>
        <h3 className="ct-folder-title">Automation</h3>
        <div className="ct-folder-content">
          <div className="ct-folder-grid">
            <div className="ct-folder-grid-item">
              <div className="ct-folder-stat-title">Not Started</div>
              <div className="ct-folder-stat-value">12</div>
            </div>
            <div className="ct-folder-grid-item">
              <div className="ct-folder-stat-title">In Progress</div>
              <div className="ct-folder-stat-value">08</div>
            </div>
            <div className="ct-folder-grid-item">
              <div className="ct-folder-stat-title">In Review</div>
              <div className="ct-folder-stat-value">06</div>
            </div>
            <div className="ct-folder-grid-item">
              <div className="ct-folder-stat-title">Completed</div>
              <div className="ct-folder-stat-value">04</div>
            </div>
          </div>
        </div>
      </div>






    </div>
  );
};

export default ClientMainFolders;
