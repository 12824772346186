import React, { useState } from 'react';
import { Pagination as AntPagination, Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './InternalPages.less';

const Pagination = () => {
  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = 50; // Example total items
  const itemsPerPage = 10;

  // Prepare the code to copy as a string
  const codeToCopy = `
    <div className="main-pagination-container">
      <Pagination
        current={1}
        pageSize={10}
        total={50}
        className="main-pagination"
      />
    </div>
  `;

  // Copy the pagination code to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(codeToCopy.trim()).then(
      () => message.success('Code copied to clipboard!'),
      () => message.error('Failed to copy code.')
    );
  };

  return (
    <div className="section-container">
      <h3> Pagination </h3>
      <div className="section-and-code">
        {/* Pagination Display */}
        <div className="main-pagination-container">
          <AntPagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={totalItems}
            onChange={setCurrentPage}
            className="main-pagination"
          />
        </div>

        {/* Code Display with Copy Button */}
        <div className="code-container">
          <Button
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            className="copy-code-button"
          >
            Copy Code
          </Button>
          <pre className="code-snippet">
{codeToCopy}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
