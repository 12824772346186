import React, { createContext, useEffect, useRef, useState } from 'react';
import { projects as initialProjects, teamMembers as initialTeamMembers, clientsData as initialClientsData } from '../Data/ProjectsSampleData';
import tasksData from '../Data/ProjectsTableSampleData';
import { addSubtask, updateSubtask, deleteSubtask, addNewSubtask, addSubtaskAction, groupTasksAction, sortTaskAction } from '../Utils/taskActions';
import { addTeamMember, removeTeamMember, updateTeamMember } from '../Utils/teamActions';
import { addClient, removeClient, updateClient } from '../Utils/clientActions';
import { addProject, removeProject, updateProject } from '../Utils/projectActions';
import { moveColumn } from '../Utils/dndActions';

const AppStateContext = createContext();
tasksData.map((item, index) => {
  item.position = index + 1
  return item
})
const AppStateProvider = ({ children }) => {
  const [projects, setProjects] = useState(initialProjects);
  const [teamMembers, setTeamMembers] = useState(initialTeamMembers);
  const [clientsData, setClientsData] = useState(initialClientsData);
  const [tasks, setTasks] = useState(tasksData);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columnOrder, setColumnOrder] = useState(['taskName', 'assignee', 'status', 'dueDate', 'timeSpent']);
  const [groupByColumns, setGroupByColumns] = useState(['section']);
  const [sortByColumns, setSortByColumns] = useState([]);
  const [taskGroups, setTaskGroups] = useState([]);
  const [dragData, setDragData] = useState({ from: -1, to: -1 }); // from & to is a taskId
  const [dragColumn, setDragColumn] = useState({ from: -1, to: -1 }); // from & to is a taskId
  const init = useRef(true)
  useEffect(() => {
    if (init.current) {
      init.current = false;
      return
    }
    if (groupByColumns.length > 0) {
      const newTaskGroups = groupTasksAction(tasks, groupByColumns)
      newTaskGroups.forEach((item) => {
        sortTaskAction(item.tasks, sortByColumns)
      })
      setTaskGroups([...newTaskGroups])
    }else{
      setTaskGroups([])
    }
  }, [tasks, sortByColumns, groupByColumns])
  const handleAddMember = (project) => {
    setSelectedProject(project);
    setIsModalVisible(true);
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleMoveColumn = (sourceIndex, destinationIndex) => {
    setColumnOrder(prevOrder => moveColumn(prevOrder, sourceIndex, destinationIndex));
  };

  return (
    <AppStateContext.Provider
      value={{
        projects,
        teamMembers,
        clientsData,
        tasks, setTasks,
        selectedProject,
        isModalVisible,
        groupByColumns, setGroupByColumns,
        sortByColumns, setSortByColumns,
        taskGroups, setTaskGroups,
        dragData, setDragData,
        dragColumn, setDragColumn,
        handleAddMember,
        toggleModal,
        addSubtask: (taskId, newSubtask) => setTasks(prevTasks => {
          console.log('addSubtask context called with:', taskId, newSubtask);
          return addSubtask(prevTasks, taskId, newSubtask);
        }),
        updateSubtask: (taskIndex, subtaskIndex, updatedSubtask) => setTasks(prevTasks => {
          console.log('updateSubtask context called with:', taskIndex, subtaskIndex, updatedSubtask);
          return updateSubtask(prevTasks, taskIndex, subtaskIndex, updatedSubtask);
        }),
        deleteSubtask: (taskId, subtaskId) => setTasks(prevTasks => {
          console.log('deleteSubtask context called with:', taskId, subtaskId);
          return deleteSubtask(prevTasks, taskId, subtaskId);
        }),
        addNewSubtask: (taskId) => setTasks(prevTasks => {
          console.log('addNewSubtask context called with:', taskId);
          return addNewSubtask(prevTasks, taskId);
        }),
        addSubtaskBelow: (taskIndex, subtaskIndex) => setTasks(prevTasks => {
          console.log('addSubtaskBelow context called with:', { taskIndex, subtaskIndex });
          return addSubtaskAction(prevTasks, taskIndex, subtaskIndex);
        }),
        addTeamMember: (newMember) => setTeamMembers(prevMembers => addTeamMember(prevMembers, newMember)),
        removeTeamMember: (memberId) => setTeamMembers(prevMembers => removeTeamMember(prevMembers, memberId)),
        updateTeamMember: (memberId, updatedMember) => setTeamMembers(prevMembers => updateTeamMember(prevMembers, memberId, updatedMember)),
        addClient: (newClient) => setClientsData(prevClients => addClient(prevClients, newClient)),
        removeClient: (clientId) => setClientsData(prevClients => removeClient(prevClients, clientId)),
        updateClient: (clientId, updatedClient) => setClientsData(prevClients => updateClient(prevClients, clientId, updatedClient)),
        addProject: (newProject) => setProjects(prevProjects => addProject(prevProjects, newProject)),
        removeProject: (projectId) => setProjects(prevProjects => removeProject(prevProjects, projectId)),
        updateProject: (projectId, updatedProject) => setProjects(prevProjects => updateProject(prevProjects, projectId, updatedProject)),
        columnOrder, setColumnOrder,
        moveColumn: handleMoveColumn
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export { AppStateContext, AppStateProvider };
