// Spacing.js

import React from 'react';
import './InternalPages.less'; // Assuming this file will contain the styles for the squares

const Spacing = () => {
  const spacings = [
    { label: 'mini', size: 4 },
    { label: 'small', size: 8 },
    { label: 'small-medium', size: 12 },
    { label: 'medium', size: 16 },
    { label: 'standard', size: 24 },
    { label: 'large', size: 32 },
    { label: 'extra large', size: 40 },
  ];

  return (
    <div className="spacing-main-container">
      <h3>Spacing</h3>
      <div className="spacing-container">
        {spacings.map(({ label, size }) => (
          <div key={size} className="spacing-item">
            <p>{`${label} (${size}px)`}</p> {/* Label with size value */}
            <div
              className="spacing-box"
              style={{
                width: `${size}px`,
                height: `${size}px`,
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Spacing;
