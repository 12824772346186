import React, { useState } from 'react';
import { Drawer, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import AddTaskDescription from './AddTaskDescription'; // Import AddTaskDescription
import TaskAttachments from './TaskAttachments'; // Import TaskAttachments
import TaskSummary from './TaskSummary'; // Import TaskSummary
import ModalSubtasks from './ModalSubtasks'; // Import ModalSubtasks
import TaskComments from './TaskComments'; // Import TaskComments
import { useTaskDetailsLogic } from './TaskDetailsLogic'; // Import the custom hook
import '../Modals.less';
import '../../custom.less';

const TaskDetailsModal = ({ task, visible, onClose }) => {
  const [attachments, setAttachments] = useState([]);
  const {
    taskName,
    assignee,
    dueDate,
    timeSpent,
    projectManager,
    priority,
    status,
    subtasks,
    comments,
    assigneeOptions,
    projectManagerOptions,
    priorityOptions,
    statusOptions,
    handleTaskNameChange,
    handleAssigneeChange,
    handleDueDateChange,
    handleTimeSpentChange,
    handleProjectManagerChange,
    handlePriorityChange,
    handleStatusChange,
    handleSubtaskChange,
    handleCommentChange,
    handlePostComment,
    projectTeamMembers
  } = useTaskDetailsLogic(task);

  const handleUpload = ({ file, fileList }) => {
    if (file.status === 'done') {
      message.success(`${file.name} file uploaded successfully`);
      setAttachments([...attachments, file]);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }
  };

  return (
    <Drawer
      placement="right"
      width={740}
      onClose={onClose}
      visible={visible}
      className="task-details-modal"
    >
      <div className="task-details-topbar">
        <div className="task-details-left">
          <span className="task-icon modal-icon-container">
            <i className="fi fi-rr-memo-pad modal-icon"></i>
          </span>
          <span className="task-text">Task Details</span>
        </div>
        <div className="task-details-right">
          <span className="small-button"><i class="fi fi-rr-clone"></i></span>
          <Upload
            multiple={false}
            customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
            onChange={handleUpload}
            showUploadList={false}
          >
            <span className="small-button"><i class="fi fi-rr-paperclip-vertical"></i></span>
          </Upload>
          <span className="small-button"><i className="fi fi-rr-menu-dots"></i></span>
          <span className="tdm-close-icon" onClick={onClose}>
            <span className="small-button"><i className="fi fi-rr-exit"></i></span>
          </span>
        </div>
      </div>

      <div className="tdm-modal-body">
        <Input
          className="tdm-task-cell-content border-1"
          value={taskName}
          onChange={handleTaskNameChange}
        />

        <TaskSummary
          assignee={assignee}
          dueDate={dueDate}
          timeSpent={timeSpent}
          projectManager={projectManager}
          priority={priority}
          status={status}
          assigneeOptions={assigneeOptions}
          projectManagerOptions={projectManagerOptions}
          priorityOptions={priorityOptions}
          statusOptions={statusOptions}
          handleAssigneeChange={handleAssigneeChange}
          handleDueDateChange={handleDueDateChange}
          handleTimeSpentChange={handleTimeSpentChange}
          handleProjectManagerChange={handleProjectManagerChange}
          handlePriorityChange={handlePriorityChange}
          handleStatusChange={handleStatusChange}
        />

        <AddTaskDescription />

        <ModalSubtasks
          subtasks={subtasks}
          handleSubtaskChange={handleSubtaskChange}
          projectTeamMembers={projectTeamMembers}
        />

        <div className="task-attachments-section">
          <TaskAttachments attachments={attachments} />
        </div>


      </div>
      <TaskComments
        comments={comments}
        handleCommentChange={handleCommentChange}
        handlePostComment={handlePostComment}
      />
    </Drawer>
  );
};

export default TaskDetailsModal;
