import React, { useState } from 'react';
import { Card, Typography, Button, Avatar, Divider, Dropdown, Menu } from 'antd';
import { UploadOutlined, SendOutlined, DownOutlined } from '@ant-design/icons';
import TextEditor from '../../Components/TextEditor'; // Importing TextEditor component
import './Tickets.less';
import FileAttachments from './TicketsComponents/FileAttachments';





const { Title, Text } = Typography;

const TicketDetails = () => {
  const [comment, setComment] = useState('');

  const handleCommentChange = (html) => {
    setComment(html);
  };

  const handleSend = () => {
    // Add logic to send comment
    console.log('Comment sent:', comment);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">Technical</Menu.Item>
      <Menu.Item key="2">General</Menu.Item>
      <Menu.Item key="3">Billing</Menu.Item>
    </Menu>
  );

  return (
    <div id="ticket-details">
      <div className="ticket-details-container">

        <p className="td-main-page-title" >
          <span className="td-main-page-title-icon">
            <i class="fi fi-rs-ticket-alt"></i>
          </span>
          Ticket-Details
        </p>

        {/* Ticket Details Header */}
        <Card className="ticket-header">

          <Text style={{ marginRight: '6px' }}>From</Text>

          <Dropdown overlay={menu} trigger={['click']}>
            <Button className="dropdown-button">
              AIO Solution Support <DownOutlined />
            </Button>
          </Dropdown>
          <Divider />
          <Title level={2}>This is the landing page for users.</Title>
          <div className="tags">
            <div>
              <Button type="default" style={{ marginRight: '6px' }}>Law</Button>
              <Button type="default" style={{ marginRight: '6px' }}>Open Ticket</Button>
              <Button type="default">Design & Development</Button>
            </div>
          </div>


          <Divider />
          <Text>
            Hello Kristian, Thanks for letting me know you have logged into the AIO client portal...
          </Text>
          <div className="ticket-info" style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '48px' }}>
            <Text>Ticket ID #TC-100</Text>
            <Text>Request Date 08/13/2024</Text>
          </div>
        </Card>

        {/* Attachment Section */}

        <FileAttachments />


        {/* Threads Section */}
        <Card className="threads-section">

          <Title level={4} style={{ marginTop: 0 }}>
            Threads On landing page
          </Title>

          {/* Thread 1 */}
          <div className="thread-message" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
            <Avatar>K</Avatar>
            <div className="thread-content" style={{ marginLeft: '12px' }}>

              <Text strong style={{ display: 'block', marginTop: '4px' }}>Kristian</Text>
              <Text type="secondary">Jun 13 2024 (2 days ago)</Text>
              <Text style={{ display: 'block', marginTop: '8px' }}> Greetings! Thanks so much for helping us with these amazing designs at the last minute...</Text>

            </div>
          </div>

          <Divider />

          {/* Thread 2 */}
          <div className="thread-message" style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Avatar>M</Avatar>
            <div className="thread-content" style={{ marginLeft: '12px' }}>
              <Text strong style={{ display: 'block', marginTop: '4px' }}>Mayowa Ajakaiye (All-in-One Workforce)</Text>
              <Text type="secondary">Jun 13 2024 (2 days ago)</Text>

              <Text style={{ display: 'block', marginTop: '4px' }}>Hello <a href="#">@Kristian</a>, The images we shared for Tulsa REIA are the available fields...</Text>

            </div>
          </div>
        </Card>

        {/* Reply Section */}
        <Card className="reply-section">
          <TextEditor value={comment} onChange={handleCommentChange} />
          <div className="reply-actions" style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '48px' }}>
            <Button icon={<UploadOutlined />} type="default">Upload File</Button>
            <Button icon={<SendOutlined />} type="primary" onClick={handleSend}>Send Message</Button>

          </div>
        </Card>


      </div>
    </div>
  );
};

export default TicketDetails;
