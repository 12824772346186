// ClientChatTitle.js
import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import TeamAvatars from '../../../Components/TeamAvatars'; // Import TeamAvatars


const ClientChatTitle = ({
  teamMembers,
  clients,
  handleAddMember,
  expanded,
  toggleExpand,
  updateSettings
}) => {
  // Toolbar Menu for Options
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => updateSettings({ notificationsDisabled: true })}
      >
        Disable Notifications
      </Menu.Item>
      <Menu.Item key="2">Settings</Menu.Item>
      <Menu.Item key="3">Expand Last 25 Messages</Menu.Item>
    </Menu>
  );

  return (
    <div className="ct-chat-title-row">
      <div className="ct-chat-title-column ct-chat-title-column-1">
        <div className="ct-chat-title-icon-text">
          <div className="ct-chat-title-icon-container">
            <i className="fi fi-rr-messages ct-chat-title-icon"></i>
          </div>
          <span className="ct-chat-title-text">Catalyst Marketing Group</span>
        </div>
      </div>
      <div className="ct-chat-title-column ct-chat-title-column-2">
        {/* Replace Avatar Group and Add Member Button with TeamAvatars */}
        <TeamAvatars
          teamMembers={teamMembers}
          clients={clients}
          handleAddMember={handleAddMember}
        />

        <div className="ct-chat-options-buttons">
          {/* Expand/Collapse and Options Buttons */}
          <Button
            onClick={toggleExpand}
            style={{ marginLeft: '10px' }}
            className="ct-chat-expand-button"
          >
            {expanded ? (
              <i className="fi fi-rr-down-left-and-up-right-to-center"></i> // Collapse icon
            ) : (
              <i className="fi fi-rr-arrow-up-right-and-arrow-down-left-from-center"></i> // Expand icon
            )}
          </Button>

          <Dropdown overlay={menu}>
            <Button
              style={{ marginLeft: '10px' }}
              className="ct-chat-options-button"
            >
              <i className="fi fi-rr-menu-dots-vertical"></i>
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default ClientChatTitle;
