import React, { useContext, useRef, useState } from 'react';
import { AppStateContext } from '../../Context/AppStateContext';
import { Dropdown, Input } from 'antd';
import { sortTaskAction } from '../../Utils/taskActions';

const PGGroupTitleField = ({ groupIndex }) => {
  const { taskGroups, tasks, setTasks, sortByColumns } = useContext(AppStateContext);
  const group = taskGroups[groupIndex]
  const currentValue = group.attributes['section']
  const [value, setValue] = useState(currentValue)
  const ref = useRef(null)
  if (groupIndex === undefined) return null
  function updateSectionTitle() {
    const taskIds = group.tasks.map(item => item.taskId)
    tasks.forEach(item => {
      if (taskIds.includes(item.taskId)) {
        item.section = value
      }
    })
    sortTaskAction(tasks, sortByColumns)
    setTasks([...tasks])
  }
  function onBlur() {
    updateSectionTitle()
  }
  function onKeyDown(e) {
    if (e.key === 'Enter') {
      updateSectionTitle()
    }
  }
  return <>
    <Dropdown overlay={<Input ref={ref} value={value} onChange={(e) => {
      setValue(e.target.value);
    }} onKeyDown={onKeyDown} onBlur={onBlur} />} onOpenChange={(open) => {
      if (open) {
        setTimeout(() => {
          ref.current.focus()
        }, 10)
      }
    }}>
      <span>{currentValue ? currentValue : "Untitled Section"}</span>
    </Dropdown>
  </>
};

export default PGGroupTitleField;
