import React from 'react';
import { Button } from 'antd';
import './UserAvatars.less';

const AddMemberButton = ({ project, handleAddMember }) => {
  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '34px',
    height: '34px',
    padding: 0,
  };

  return (
    <Button

      onClick={() => handleAddMember(project)}
      className="add-user-button"
      style={buttonStyle}
    >
    <i class="fi fi-rr-plus"></i>
    </Button>
  );
};

export default AddMemberButton;
