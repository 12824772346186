const clientData = [
    {
      id: 1,
      name: "Conor McGregor",
      company: "McGregor Enterprises",
      email: "conor@company.com",
      phone: "+1 234 567 890",
      hours: 20,
      tasksCompleted: 15,
      totalTasks: 50,
      currency: "1000 USD",
      status: "Active",
    },
    {
      id: 2,
      name: "Ronda Rousey",
      company: "Rousey Fitness",
      email: "ronda@company.com",
      phone: "+1 234 567 891",
      hours: 40,
      tasksCompleted: 40,
      totalTasks: 50,
      currency: "1200 USD",
      status: "In Progress",
    },
    {
      id: 3,
      name: "Khabib Nurmagomedov",
      company: "Khabib Sports",
      email: "khabib@company.com",
      phone: "+1 234 567 892",
      hours: 50,
      tasksCompleted: 50,
      totalTasks: 50,
      currency: "1500 USD",
      status: "Completed",
    },
    {
      id: 4,
      name: "Israel Adesanya",
      company: "Adesanya Ventures",
      email: "adesanya@company.com",
      phone: "+1 234 567 893",
      hours: 10,
      tasksCompleted: 5,
      totalTasks: 50,
      currency: "500 USD",
      status: "Not Started",
    },

    {
      id: 2,
      name: "Ronda Rousey",
      company: "Rousey Fitness",
      email: "ronda@company.com",
      phone: "+1 234 567 891",
      hours: 40,
      tasksCompleted: 40,
      totalTasks: 50,
      currency: "1200 USD",
      status: "In Progress",
    },
    {
      id: 3,
      name: "Khabib Nurmagomedov",
      company: "Khabib Sports",
      email: "khabib@company.com",
      phone: "+1 234 567 892",
      hours: 50,
      tasksCompleted: 50,
      totalTasks: 50,
      currency: "1500 USD",
      status: "Completed",
    },
    {
      id: 4,
      name: "Israel Adesanya",
      company: "Adesanya Ventures",
      email: "adesanya@company.com",
      phone: "+1 234 567 893",
      hours: 10,
      tasksCompleted: 5,
      totalTasks: 50,
      currency: "500 USD",
      status: "Not Started",
    },
    // Add more clients as needed
  ];
  
  export default clientData;
  