import React, { useState, useEffect, useContext } from 'react';
import { Drawer, Descriptions, Badge, Row, Col, Input, Select, DatePicker, ConfigProvider, Button } from 'antd';
import { AppStateContext } from '../Context/AppStateContext'; // Adjust the path if necessary
import SingleAvatar2 from '../Components/SingleAvatar2'; // Adjust the path if necessary
import EditableTimeSpentCell from '../Components/EditableTimeSpentCell'; // Adjust the path if necessary
import getPriorityTag from '../Components/PriorityTags'; // Adjust the path if necessary
import getStatusTag from '../Components/StatusTags2'; // Adjust the path if necessary
import AddTaskDescription from '../Components/AddTaskDescription'; // Import AddTaskDescription
import TaskAttachments from '../Components/TaskAttachments';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import './Modals.less';

const { Option } = Select;
const { TextArea } = Input;

const TaskDetailsModal = ({ task, visible, onClose }) => {
  const [taskName, setTaskName] = useState(task.taskName);
  const [assignee, setAssignee] = useState(task.assignee);
  const [dueDate, setDueDate] = useState(task.dueDate ? dayjs(task.dueDate) : null);
  const [timeSpent, setTimeSpent] = useState(task.timeSpent);
  const [projectManager, setProjectManager] = useState(task.projectManager);
  const [priority, setPriority] = useState(task.priority);
  const [status, setStatus] = useState(task.status);
  const [subtasks, setSubtasks] = useState(task.subtasks);
  const [comments, setComments] = useState('');
  const { projects, teamMembers } = useContext(AppStateContext);

  useEffect(() => {
    setTaskName(task.taskName);
    setAssignee(task.assignee);
    setDueDate(task.dueDate ? dayjs(task.dueDate) : null);
    setTimeSpent(task.timeSpent);
    setProjectManager(task.projectManager);
    setPriority(task.priority);
    setStatus(task.status);
    setSubtasks(task.subtasks);
  }, [task]);

  const handleTaskNameChange = (e) => {
    setTaskName(e.target.value);
  };

  const handleAssigneeChange = (value) => {
    setAssignee(value);
  };

  const handleDueDateChange = (date) => {
    setDueDate(date);
  };

  const handleTimeSpentChange = (value) => {
    setTimeSpent(value);
  };

  const handleProjectManagerChange = (value) => {
    setProjectManager(value);
  };

  const handlePriorityChange = (value) => {
    setPriority(value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleSubtaskChange = (subtaskId, field, value) => {
    setSubtasks((prevSubtasks) =>
      prevSubtasks.map((subtask) =>
        subtask.subtaskId === subtaskId ? { ...subtask, [field]: value } : subtask
      )
    );
  };

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  const handlePostComment = () => {
    // Logic to handle posting comment
    console.log('Comment posted:', comments);
    setComments('');
  };

  // Find the project by projectId
  const project = projects.find((project) => project.id === 1); // Adjust project ID as necessary

  // Filter the team members for the project
  const projectTeamMembers = project ? teamMembers.filter((member) => project.members.includes(member.id)) : [];

  const assigneeOptions = projectTeamMembers.map((member) => (
    <Option key={member.id} value={member.name}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SingleAvatar2 name={member.name} />
        <span style={{ marginLeft: '8px' }}>{member.name}</span>
      </div>
    </Option>
  ));

  const projectManagerOptions = [
    { name: 'Sazzad Mahmud' },
    { name: 'Noman Hossain' },
  ].map((manager) => (
    <Option key={manager.name} value={manager.name}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SingleAvatar2 name={manager.name} />
        <span style={{ marginLeft: '8px' }}>{manager.name}</span>
      </div>
    </Option>
  ));

  const priorityOptions = ['Low', 'Medium', 'High', 'Urgent'].map((level) => (
    <Option key={level} value={level}>
      {getPriorityTag(level)}
    </Option>
  ));

  const statusOptions = ['Not Started', 'In Progress', 'On Hold', 'Completed', 'In Review'].map((state) => (
    <Option key={state} value={state}>
      {getStatusTag(state)}
    </Option>
  ));

  return (
    <Drawer
      placement="right"
      width={740}
      onClose={onClose}
      visible={visible}
      className="task-details-modal"
    >
      <div className="task-details-topbar">
        <div className="task-details-left">
          <span className="task-icon ">
          <svg width="26" height="26" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="56" height="56" rx="28" fill="#F2FDF6" fill-opacity="0.1"/>
          <path d="M28 42.3333C20.0934 42.3333 13.6667 35.9067 13.6667 28C13.6667 20.0933 20.0934 13.6667 28 13.6667C35.9067 13.6667 42.3334 20.0933 42.3334 28C42.3334 35.9067 35.9067 42.3333 28 42.3333ZM28 15.6667C21.2 15.6667 15.6667 21.2 15.6667 28C15.6667 34.8 21.2 40.3333 28 40.3333C34.8 40.3333 40.3334 34.8 40.3334 28C40.3334 21.2 34.8 15.6667 28 15.6667Z" fill="#39C267"/>
          <path d="M26.1066 32.7733C25.8399 32.7733 25.5866 32.6666 25.3999 32.48L21.6266 28.7066C21.2399 28.32 21.2399 27.68 21.6266 27.2933C22.0133 26.9066 22.6533 26.9066 23.0399 27.2933L26.1066 30.36L32.9599 23.5066C33.3466 23.12 33.9866 23.12 34.3733 23.5066C34.7599 23.8933 34.7599 24.5333 34.3733 24.92L26.8133 32.48C26.6266 32.6666 26.3733 32.7733 26.1066 32.7733Z" fill="#39C267"/>
          </svg>

          </span>
          <span className="task-text">Task Details</span>
        </div>
        <div className="task-details-right">
        <span className="small-button"><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1 22.75H6.9C2.99 22.75 1.25 21.01 1.25 17.1V12.9C1.25 8.99 2.99 7.25 6.9 7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V17.1C16.75 21.01 15.01 22.75 11.1 22.75ZM6.9 8.75C3.8 8.75 2.75 9.8 2.75 12.9V17.1C2.75 20.2 3.8 21.25 6.9 21.25H11.1C14.2 21.25 15.25 20.2 15.25 17.1V12.9C15.25 9.8 14.2 8.75 11.1 8.75H6.9Z" fill="white"/>
        <path d="M17.1 16.75H16C15.59 16.75 15.25 16.41 15.25 16V12.9C15.25 9.8 14.2 8.75 11.1 8.75H8C7.59 8.75 7.25 8.41 7.25 8V6.9C7.25 2.99 8.99 1.25 12.9 1.25H17.1C21.01 1.25 22.75 2.99 22.75 6.9V11.1C22.75 15.01 21.01 16.75 17.1 16.75ZM16.75 15.25H17.1C20.2 15.25 21.25 14.2 21.25 11.1V6.9C21.25 3.8 20.2 2.75 17.1 2.75H12.9C9.8 2.75 8.75 3.8 8.75 6.9V7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V15.25Z" fill="white"/>
        </svg>
        </span>

        <span className="small-button"><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.33 21.34C11.24 21.34 10.15 20.93 9.32001 20.1C7.66001 18.44 7.66001 15.75 9.32001 14.09L11.8 11.62C12.09 11.33 12.57 11.33 12.86 11.62C13.15 11.91 13.15 12.39 12.86 12.68L10.38 15.15C9.31001 16.22 9.31001 17.97 10.38 19.04C11.45 20.11 13.2 20.11 14.27 19.04L18.16 15.15C19.34 13.97 19.99 12.4 19.99 10.73C19.99 9.05998 19.34 7.48998 18.16 6.30999C15.72 3.86999 11.76 3.86999 9.32001 6.30999L5.08001 10.55C4.09001 11.54 3.54001 12.86 3.54001 14.26C3.54001 15.66 4.09001 16.98 5.08001 17.97C5.37001 18.26 5.37001 18.74 5.08001 19.03C4.79001 19.32 4.31001 19.32 4.02001 19.03C2.75001 17.75 2.04001 16.06 2.04001 14.26C2.04001 12.46 2.74001 10.76 4.02001 9.48998L8.26001 5.24999C11.28 2.22999 16.2 2.22999 19.22 5.24999C20.68 6.70999 21.49 8.65998 21.49 10.73C21.49 12.8 20.68 14.75 19.22 16.21L15.33 20.1C14.5 20.93 13.42 21.34 12.33 21.34Z" fill="white"/>
        </svg>
        </span>
        
          <span className="small-button"><svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.9945 16H16.0065" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M23.9997 16H24.0117" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7.99976 16H8.01173" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </span>
          <span className="tdm-close-icon" onClick={onClose}>
            <span className="small-button"><svg width="26" height="26" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#F6F6F6" fill-opacity="0.1"/>
<path d="M28 42.3333C20.0933 42.3333 13.6667 35.9067 13.6667 28C13.6667 20.0933 20.0933 13.6667 28 13.6667C35.9067 13.6667 42.3333 20.0933 42.3333 28C42.3333 35.9067 35.9067 42.3333 28 42.3333ZM28 15.6667C21.2 15.6667 15.6667 21.2 15.6667 28C15.6667 34.8 21.2 40.3333 28 40.3333C34.8 40.3333 40.3333 34.8 40.3333 28C40.3333 21.2 34.8 15.6667 28 15.6667Z" fill="white"/>
<path d="M24.2267 32.7733C23.9733 32.7733 23.72 32.68 23.52 32.48C23.1333 32.0933 23.1333 31.4533 23.52 31.0667L31.0667 23.52C31.4533 23.1333 32.0933 23.1333 32.48 23.52C32.8667 23.9067 32.8667 24.5467 32.48 24.9333L24.9333 32.48C24.7467 32.68 24.48 32.7733 24.2267 32.7733Z" fill="white"/>
<path d="M31.7733 32.7733C31.52 32.7733 31.2667 32.68 31.0667 32.48L23.52 24.9333C23.1333 24.5467 23.1333 23.9067 23.52 23.52C23.9067 23.1333 24.5467 23.1333 24.9333 23.52L32.48 31.0667C32.8667 31.4533 32.8667 32.0933 32.48 32.48C32.28 32.68 32.0267 32.7733 31.7733 32.7733Z" fill="white"/>
</svg>
</span>
          </span>
        </div>
      </div>

      <div className="tdm-modal-body">
      <div class="task-container">

       
  
  </div>

  <div class="task-container">
  <label for="task-name" class="task-label">Task Name</label>

</div>

        <Input
        
          className="tdm-task-cell-content"
          value={taskName}
          onChange={handleTaskNameChange}
        />
        <div className="tdm-task-summary">
          <Row gutter={16} className="tdm-task-summary-row">
            <Col span={12} className="tdm-task-summary-col tdm-task-summary-col-left">
              <Descriptions title="" bordered column={1} className="tdm-descriptions">
                <Descriptions.Item label="Assignee:" className="tdm-descriptions-item">
                  <Select
                    value={assignee}
                    onChange={handleAssigneeChange}
                    style={{ width: '100%' }}
                    className="tdm-assignee-select"
                  >
                    {assigneeOptions}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Due Date:" className="tdm-descriptions-item">
                  <ConfigProvider locale="en">
                    <DatePicker
                      value={dueDate}
                      onChange={handleDueDateChange}
                      style={{ width: '100%' }}
                      format="YYYY-MM-DD"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="tdm-due-date-picker"
                    />
                  </ConfigProvider>
                </Descriptions.Item>
                <Descriptions.Item label="Time Spent:" className="tdm-descriptions-item">
                  <EditableTimeSpentCell
                    value={timeSpent}
                    onChange={handleTimeSpentChange}
                    className="tdm-time-spent-input"
                  />
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={12} className="tdm-task-summary-col tdm-task-summary-col-right">
              <Descriptions bordered column={1} className="tdm-descriptions">
                <Descriptions.Item label="Project Manager:" className="tdm-descriptions-item">
                  <Select
                    value={projectManager}
                    onChange={handleProjectManagerChange}
                    style={{ width: '100%' }}
                    className="tdm-project-manager-select"
                  >
                    {projectManagerOptions}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Priority:" className="tdm-descriptions-item">
                  <Select
                    value={priority}
                    onChange={handlePriorityChange}
                    style={{ width: '100%' }}
                    className="tdm-priority-select"
                  >
                    {priorityOptions}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Status:" className="tdm-descriptions-item">
                  <Select
                    value={status}
                    onChange={handleStatusChange}
                    style={{ width: '100%' }}
                    className="tdm-status-select"
                  >
                    {statusOptions}
                  </Select>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </div>

        <AddTaskDescription /> {/* Add the AddTaskDescription component here */}

        <div className="tdm-modal-subtasks">
      <Descriptions bordered column={1} title="Subtasks" className="tdm-descriptions">
        {subtasks.map((subtask) => (
          <Descriptions.Item key={subtask.subtaskId} className="tdm-descriptions-item">
            <Input
              value={subtask.taskName}
              onChange={(e) => handleSubtaskChange(subtask.subtaskId, 'taskName', e.target.value)}
              style={{ width: '68%' }}
              className="tdm-subtask-input"
            />
            <DatePicker
              value={subtask.dueDate ? dayjs(subtask.dueDate) : null}
              onChange={(date) => handleSubtaskChange(subtask.subtaskId, 'dueDate', date)}
              format="YYYY-MM-DD"
              getPopupContainer={(trigger) => trigger.parentNode}
              className="tdm-due-date-picker"
              style={{ width: '20%' }}
            />
            <Select
              value={subtask.assignee}
              onChange={(value) => handleSubtaskChange(subtask.subtaskId, 'assignee', value)}
              style={{ width: '10%' }}
              className="tdm-assignee-select"
              dropdownClassName="tdm-assignee-dropdown"
            >
              {projectTeamMembers.map((member) => (
                <Option key={member.id} value={member.name}>
                  <SingleAvatar2 name={member.name} />
                </Option>
              ))}
            </Select>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>

    <div className="task-attachments-section">
  <TaskAttachments />
</div>


        <div className="tdm-task-comments">
          <h3 className="tdm-comments-title">Comments</h3>
          <TextArea
            value={comments}
            onChange={handleCommentChange}
            rows={4}
            className="tdm-comments-textarea"
          />
          <Button type="primary" onClick={handlePostComment} className="tdm-comments-button">
            Post
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default TaskDetailsModal;
