// ProjectsTableColumns/ProjectNameColumn.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProjectNameColumn = {
  title: 'Project Name',
  key: 'projectName',
  render: (text, record) => <ProjectNameCell record={record} />
};

const ProjectNameCell = ({ record }) => {
  const navigate = useNavigate();

  const handleProjectClick = () => {
    navigate('/work/individual-project');
  };

  return (
    <div className="pt-project-info-cell" >
      <div className="pt-project-name" onClick={handleProjectClick} style={{ cursor: 'pointer' }}><i className="fi fi-rr-briefcase"></i>{record.name}</div>
      <div className="pt-client-name"><i className="fi fi-rr-city"></i>{record.client.clientName}</div>
    </div>
  );
};

export default ProjectNameColumn;
