// ProjectOverviewComponents/ProjectOverviewChatData.js

export const teamMembers = [
  { name: 'Alice Johnson' },
  { name: 'Bob Smith' },
  { name: 'Charlie Brown' },
];

export const initialMessages = [
  {
    id: 1,
    user: 'Alice Johnson',
    content: 'Hey team, how is the progress on the project?',
    time: '9:15 AM',
    date: '04/09/2023',
  },
  {
    id: 2,
    user: 'Bob Smith',
    content: 'We are on schedule, I just finished the first module.',
    time: '10:00 AM',
    date: '04/09/2023',
  },
  {
    id: 3,
    user: 'Charlie Brown',
    content: 'I am working on the design, will share updates soon.',
    time: '11:30 AM',
    date: '04/09/2023',
  },
  {
    id: 4,
    user: 'Charlie Brown',
    content: 'I just had an emergency, it might take me longer than expected. Sorry!',
    time: '11:40 AM',
    date: '04/09/2023',
  },
  {
    id: 5,
    user: 'Bob Smith',
    content: 'Hey now worries at all - hope things go well.',
    time: '11:53 AM',
    date: '04/09/2023',
  },
];
