import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './custom.less';  // Import custom Less file
import UnifiedLayout from './Components/UnifiedLayout';





import Home from './Pages/Home';
import IndividualProject from './Pages/WorkPages/IndividualProject';
import { AppStateProvider } from './Context/AppStateContext';

import Dashboard from './Pages/DashboardPage/Dashboard';

import Automations from './Pages/AutomationsPages/Automations';
import Test from './Pages/Internal/Test';
import Base from './Pages/Base';

import Clients from './Pages/ClientsPages/Clients';
import Clients3 from './Pages/ClientsPages/Clients3';
import ClientMainPage from './Pages/ClientsPages/ClientMainPage';
import ClientMainPage2 from './Pages/ClientsPages/ClientMainPage2';
import ClientChat from './Pages/ClientsPages/ClientChat';

import Jobs from './Pages/HRPages/Jobs';
import DesignGuidelines from './Pages/Internal/DesignGuidelines';
import Tasks from './Pages/WorkPages/Tasks';

import Projects2 from './Pages/WorkPages/Projects2';

import Projects from './Pages/Projects/Projects';


import HoursTracker from './Pages/HoursTrackerPage/HoursTracker';

import AccountPage from './Pages/AccountPages/AccountPage';

import Notifications from './Pages/NotificationsPage/Notifications';

import TeamOverview from './Pages/TeamOverviewPage/TeamOverview';

import NotFoundPage from './Pages/MiscPages/NotFoundPage';

import RegisterPage from './Pages/AuthenticationPages/RegisterPage';
import LoginPage from './Pages/AuthenticationPages/LoginPage';
import ForgotPasswordPage from './Pages/AuthenticationPages/ForgotPasswordPage';

import DesignFeedback from './Pages/FilesPages/DesignFeedback';

import Tickets from './Pages/TicketPages/Tickets';
import TicketDetails from './Pages/TicketPages/TicketDetails';
import TicketCategory from './Pages/TicketPages/TicketCategory';
import TicketPreview from './Pages/TicketPages/TicketPreview';


import FilesPage from './Pages/FilesPages/FilesPage';
import TicketsTablePage from './Pages/TicketPages/TicketsTablePage';




import SimpleClientPage from './Pages/SimpleClientPage/SimpleClientPage';
import ProjectOverview from './Pages/WorkPages/ProjectOverview';









const App = () => {
  return (
    <AppStateProvider>
      <Router>
        <Routes>
          <Route path="/" element={<UnifiedLayout><Dashboard /></UnifiedLayout>} />
          <Route path="/automations" element={<UnifiedLayout><Automations /></UnifiedLayout>} />
          <Route path="/test" element={<UnifiedLayout><Test /></UnifiedLayout>} />
          <Route path="/design-guidelines" element={<UnifiedLayout><DesignGuidelines /></UnifiedLayout>} />
          <Route path="/base" element={<UnifiedLayout><Base /></UnifiedLayout>} />
          <Route path="/clients" element={<UnifiedLayout><Clients /></UnifiedLayout>} />
          <Route path="/clients-3" element={<UnifiedLayout><Clients3 /></UnifiedLayout>} />
          <Route path="/clients/clients-main-page" element={<UnifiedLayout><ClientMainPage /></UnifiedLayout>} />
            <Route path="/clients/clients-main-page-2" element={<UnifiedLayout><ClientMainPage2 /></UnifiedLayout>} />
          <Route path="/clients/clients-chat" element={<UnifiedLayout><ClientChat /></UnifiedLayout>} />
          <Route path="/hr/jobs" element={<UnifiedLayout><Jobs /></UnifiedLayout>} />
          <Route path="/work/tasks" element={<UnifiedLayout><Tasks /></UnifiedLayout>} />
          <Route path="/work/individual-project" element={<UnifiedLayout><IndividualProject /></UnifiedLayout>} />
          <Route path="/work/projects" element={<UnifiedLayout><Projects2 /></UnifiedLayout>} />
          <Route path="/projects" element={<UnifiedLayout><Projects /></UnifiedLayout>} />
          <Route path="/hours-tracker" element={<UnifiedLayout><HoursTracker /></UnifiedLayout>} />
          <Route path="/account" element={<UnifiedLayout><AccountPage /></UnifiedLayout>} />
          <Route path="/notifications" element={<UnifiedLayout><Notifications /></UnifiedLayout>} />
          <Route path="/team-overview" element={<UnifiedLayout><TeamOverview /></UnifiedLayout>} />
          <Route path="/404-page" element={<UnifiedLayout><NotFoundPage /></UnifiedLayout>} />
          <Route path="/design-feedback" element={<UnifiedLayout><DesignFeedback /></UnifiedLayout>} />

          <Route path="/files" element={<UnifiedLayout><FilesPage /></UnifiedLayout>} />
          <Route path="/files/:imageName" element={<UnifiedLayout><DesignFeedback /></UnifiedLayout>} />

          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/tickets" element={<UnifiedLayout><Tickets /></UnifiedLayout>} />
          <Route path="/ticket-details" element={<UnifiedLayout><TicketDetails /></UnifiedLayout>} />

        
          <Route path="*" element={<UnifiedLayout><Home /></UnifiedLayout>} />


          <Route path="/Tickets-Category" element={<UnifiedLayout><TicketCategory /></UnifiedLayout>} />
          <Route path="/Tickets-Preview" element={<UnifiedLayout><TicketPreview /></UnifiedLayout>} />

          <Route path="clients/SimpleClientPage" element={<UnifiedLayout><SimpleClientPage /></UnifiedLayout>} />
          <Route path="/Tickets-Details" element={<UnifiedLayout><TicketDetails /></UnifiedLayout>} />
          <Route path="/clients-main-page" element={<UnifiedLayout><ClientMainPage /></UnifiedLayout>} />


          <Route path="/ProjectOverview" element={<UnifiedLayout><ProjectOverview /></UnifiedLayout>} />


         
          <Route path="/Tickets-Table-Page" element={<UnifiedLayout><TicketsTablePage /></UnifiedLayout>} />


        </Routes>
      </Router>
    </AppStateProvider>
  );
};

export default App;
