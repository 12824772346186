// Borders.js

import React from 'react';

const Borders = () => {
  return (
    <div className="dg-borders-main-container">
    <h3> Borders </h3>
    <div className="dg-borders-container">

      <div className="border-1" >
        <p>Border 1</p>
      </div>
      <div className="border-2" >
        <p>Border 2</p>
      </div>
      <div className="border-3" >
        <p>Border 3</p>
      </div>
      <div className="border-4" >
        <p>Border 4</p>
      </div>
    </div>
    </div>
  );
}

export default Borders;
