import React, { useRef, useState } from 'react';
import { Button, List, Card, Avatar } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'; // Correct imports for icons

const FileAttachments = ({ initialFiles = [] }) => {
  const [files, setFiles] = useState(initialFiles);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const removeFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div>
      <Card style={{ marginBottom: 0 }}>
       
        <Button
          type="primary"
          onClick={() => fileInputRef.current.click()}
          
        >
          Attach Files
        </Button>
        <input
          type="file"
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </Card>
      {files.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={files}
          renderItem={(file, index) => (
            <List.Item
              actions={[<DeleteOutlined key="delete" onClick={() => removeFile(index)} />]}
            >
              <List.Item.Meta
                title={file.name}
                description={`Size: ${file.size / 1000} KB`}
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default FileAttachments;
