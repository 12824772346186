import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Dropdown, Menu } from 'antd';
import FilesTableName from './FilesTableName';
import FilesTableDate from './FilesTableDate';
import FilesTableSize from './FilesTableSize';
import '../FilesPages.less';

const FilesTable = ({ displayedRows = [], navigateToFolder, selectImage }) => {
  const columns = [
    { Header: 'Name', accessor: 'name', Component: FilesTableName, className: 'name-column' },
    { Header: 'Date', accessor: 'date', Component: FilesTableDate, className: 'date-column' },
    { Header: 'Size', accessor: 'size', Component: FilesTableSize, className: 'size-column' },
    { Header: <i className="fi fi-br-menu-dots-vertical"></i>, accessor: 'actions', Component: FileActions, className: 'actions-column' },
  ];

  const renderRow = (row) => (
    <tr
      key={row.name}
      className="fp-table-row"
      onClick={() => row.type === 'folder' ? navigateToFolder(row.name) : row.url ? selectImage(row) : null} // Use selectImage for images
    >
      {columns.map((column) => (
        <td key={column.accessor} className={`fp-cell ${column.className}`}>
          <column.Component {...{ [column.accessor]: row[column.accessor], type: row.type }} /> {/* Pass type prop */}
        </td>
      ))}
    </tr>
  );

  return (
    <div className="main-table-main-container">
      <div className="main-table-container">
        <InfiniteScroll
          dataLength={displayedRows.length}
          hasMore={false} // No infinite scroll needed as we're navigating folders
        >
          <table className="main-table">
            <thead className="main-table-head">
              <tr>
                {columns.map((column) => (
                  <th key={column.accessor} className={`main-header-cell ${column.className}`}>
                    {column.Header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedRows.map((row) => renderRow(row))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </div>
  );
};

// FileActions Component to handle the options
const FileActions = () => {
  const menu = (
    <Menu>
      <Menu.Item key="download">
        <i className="fi fi-rr-download" style={{ marginRight: '8px' }}></i>
        Download
      </Menu.Item>
      <Menu.Item key="rename">
        <i className="fi fi-rr-edit" style={{ marginRight: '8px' }}></i>
        Rename
      </Menu.Item>
      <Menu.Item key="move">
        <i className="fi fi-rr-folder" style={{ marginRight: '8px' }}></i>
        Move
      </Menu.Item>
      <Menu.Item key="delete">
        <i className="fi fi-rr-trash" style={{ marginRight: '8px' }}></i>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className="fp-actions-icon-background">
        <i className="fi fi-rr-menu-dots-vertical"></i>
      </div>
    </Dropdown>
  );
};

export default FilesTable;
