import React from 'react';
import ImageAnnotator from './ImageAnnotator';

const DesignFeedbackComments = ({
  selectedImage,
  comments,
  addComment,
  updateCommentText,
  deleteComment,
  addResponse,
  handleMarkerClick,
  highlightedCommentId,
}) => {
  return (
    <ImageAnnotator
      imageUrl={selectedImage.url}
      comments={comments}
      addComment={addComment}
      onMarkerClick={handleMarkerClick}
      highlightedCommentId={highlightedCommentId}
      backgroundColor="white"
    />
  );
};

export default DesignFeedbackComments;
