// ProjectOverviewStatusChart.js

import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { taskStatusData } from './ProjectOverviewChartData';

const ProjectOverviewStatusChart = () => {
  // Get the last available date's data
  const lastDataPoint = taskStatusData[taskStatusData.length - 1];

  // Prepare data for the PieChart
  const pieChartData = [
    { name: 'Not Started', value: lastDataPoint.notStarted },
    { name: 'In Progress', value: lastDataPoint.inProgress },
    { name: 'In Review', value: lastDataPoint.inReview },
    { name: 'Completed', value: lastDataPoint.completed },
  ];

  // Colors corresponding to statuses (same as in ProjectOverviewChart.js)
  const COLORS = ['#cbd5e1', '#73A6D4', '#E0607E', '#3FDE7A'];

  return (
    <div className="pmt-status-chart-container">
      <ResponsiveContainer width="100%" height={240}>
        <PieChart>
          <Pie
            data={pieChartData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={80}
            innerRadius={40}
            fill="#8884d8"
            className="pmt-pie-chart"
          >
            {pieChartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                className={`pmt-pie-cell pmt-${entry.name.replace(/\s+/g, '-').toLowerCase()}`}
              />
            ))}
          </Pie>
          <Tooltip className="pmt-tooltip" />
      
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProjectOverviewStatusChart;
