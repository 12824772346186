import React, { useState, useEffect } from 'react';
import { Tag, Table, Layout, Dropdown, Menu, Button } from 'antd';
import '../../custom.less'; // Ensure custom.less is imported to apply styles
import SampleTasksData from '../../Data/SampleTasksData';
import TasksFilter from '../../Components/TasksFilter'; // Import the new TasksFilter component
import EditTaskModal from '../../Modals/EditTaskModal';
import BreadcrumbComponent from '../../Components/breadcrumb'; // Import the new Breadcrumb component
import getStatusTag from '../../Components/StatusTags'; // Import the new getStatusTag function
import './WorkPages.less';

const { Header, Content } = Layout;

const Tasks = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [sorts, setSorts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const applyFilters = () => {
    let filteredData = SampleTasksData;

    // Apply sorting
    sorts.forEach(sort => {
      if (sort.field === 'status') {
        filteredData = filteredData.filter(task => task.status === sort.order);
      } else if (sort.field === 'taskCreated' || sort.field === 'dueDate') {
        filteredData = filteredData.sort((a, b) => {
          const dateA = new Date(a[sort.field]);
          const dateB = new Date(b[sort.field]);
          if (sort.order === 'asc') {
            return dateA - dateB;
          } else {
            return dateB - dateA;
          }
        });
      }
    });

    return filteredData;
  };

  const showEditModal = (task) => {
    setSelectedTask(task);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = (values) => {
    console.log('Saved values:', values);
    setIsModalVisible(false);
    // Handle save logic here
  };

  const filteredData = applyFilters();

  const breadcrumbItems = [
    { title: '', link: '/', icon: 'fi-rr-home' },
    { title: 'Work', link: '/work' },
    { title: 'Tasks' },
  ];

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <BreadcrumbComponent items={breadcrumbItems} />


        {/* Page Title and Icon */}
        <div className="tp-page-title">
        <div className="tp-page-title-icon-container">
          <i className="fi fi-sr-folder"></i>
        </div>
        <div className="tp-page-title-text">Tasks</div>
      </div>


      {/* Filter Section */}
      <TasksFilter sorts={sorts} setSorts={setSorts} />

      <Content style={{ padding: '24px 0' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px', gap: '10px' }}>
        <Button className="ant-btn-secondary" icon={<i className="fi fi-rr-columns-3" style={{ fontSize: '14px' }}></i>}>
          Manage Columns
        </Button>
        <Button type="primary" icon={<i className="fi fi-br-plus" style={{ fontSize: '14px' }}></i>}>
          Create Task
        </Button>
      </div>
        <div style={{ overflowX: 'auto' }}>
          <Table
            columns={[
              {
                title: 'Client',
                dataIndex: 'clientName',
                key: 'clientName',
                render: (text, record) => (
                  <div>
                    <div>{record.clientName}</div>
                    <div className="lite-text">Sub-Client: {record.subClientName}</div>
                    <div className="lite-text">Project: {record.project}</div>
                  </div>
                ),
                responsive: ['lg'],
              },
              {
                title: 'Task',
                dataIndex: 'task',
                key: 'task',
                responsive: ['lg'],
              },
              {
                title: 'Sub Task',
                dataIndex: 'subTask',
                key: 'subTask',
                responsive: ['lg'],
              },
              {
                title: 'Hours',
                key: 'hours',
                render: (text, record) => (
                  <div>
                    <div className="lite-text">Allotted | Tracked</div>
                    <div>{record.hoursAllotted} | {record.hoursTracked}</div>
                  </div>
                ),
              },
              {
                title: 'Assignee',
                key: 'assignee',
                render: (text, record) => (
                  <div>
                    <div>{record.assignee}</div>
                    <div className="lite-text">Account Manager: {record.accountManager}</div>
                  </div>
                ),
                responsive: ['lg'],
              },
              {
                title: 'Timeline',
                key: 'timeline',
                render: (text, record) => {
                  const taskCreated = new Date(record.taskCreated);
                  const dueDate = new Date(record.dueDate);
                  const timeDiff = Math.ceil((dueDate - taskCreated) / (1000 * 60 * 60 * 24)); // Calculate the difference in days

                  return (
                    <div>
                      <div className="lite-text">Created: {record.taskCreated}</div>
                      <div className="lite-text">Due: {record.dueDate}</div>
                      <div className="lite-text">Span: {timeDiff} Days</div>
                    </div>
                  );
                },
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (status) => getStatusTag(status),
              },
              {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                  <Dropdown overlay={
                    <Menu>
                      <Menu.Item key="2" onClick={() => showEditModal(record)}>Edit Task</Menu.Item>
                      <Menu.Item key="3">Delete Task</Menu.Item>
                      <Menu.Item key="4">Settings</Menu.Item>
                    </Menu>
                  } trigger={['click']}>
                    <Button className="options-button" icon={<i className="fi fi-rr-menu-dots" />} />
                  </Dropdown>
                ),
              },
            ]}
            dataSource={filteredData}
            rowKey="subTask"
            pagination={{ pageSize: 10 }}
            rowClassName={(record, index) =>
              index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
            }
          />
        </div>
      </Content>

      <EditTaskModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSave}
        task={selectedTask}
      />
    </Layout>
  );
};

export default Tasks;
