import React from 'react';
import { Row, Col, Form, Input, Button, Card, List, Select } from 'antd';
import './AccountPages.less';

const { Option } = Select;

const AccountSupport = () => {
  const resources = [
    { title: 'Getting started with our software' },
    { title: 'Top 5 features you should know' },
    { title: 'Optimizing your workflow' },
    { title: 'Troubleshooting common issues' },
  ];

  return (
    <div className="ap-support-container">
      <Row gutter={16}>
        <Col span={15}>
          <Card title="Get In Touch">
          <div className="ap-support-form-container">
            <Form layout="vertical">
              <Form.Item label="Support Type">
                <Select placeholder="Select a support type">
                  <Option value="technical">Technical Issue</Option>
                  <Option value="billing">Billing Issue</Option>
                  <Option value="automation">Request Automation</Option>
                  <Option value="general">General</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Subject">
                <Input placeholder="Subject" />
              </Form.Item>
              <Form.Item label="Message">
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Form.Item className="ap-support-send-button-container">
                <Button className="ap-support-send-message-button" htmlType="submit">Send Message</Button>
              </Form.Item>
            </Form>
            </div>
          </Card>
        </Col>
        <Col span={9}>
          <Card title="Resources" className="ap-support-guide-card">
          <div className="ap-support-inner-container">
            <div className="ap-support-row-1">
            <List
              itemLayout="horizontal"
              dataSource={resources}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={<a href="#"><i class="fi fi-rr-arrow-up-right-from-square"></i>{item.title}</a>}
                  />
                </List.Item>
              )}
            />
            <Button className="ap-more-resources-link">More Resources →</Button>
            </div>
            <div className="ap-support-row-2">
            <div className="ap-support-image">
              <img src="https://i.ibb.co/tzgY671/Support.png" alt="Support" />
              </div>
              </div>
              </div>
          </Card>

        </Col>
      </Row>
    </div>
  );
};

export default AccountSupport;
